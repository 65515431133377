import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

import _svg from 'components/modules/svg';
import { UserActions } from 'reducers/user';

class SidebarDropdown extends Component {
	render() {
		const {
				setSubmenu,
				submenuOpen,
				type,
				submenuName,
				completed,
				name,
				imgLogo,
				navBorder,
				extraClass,
				routePath,
				location,
				children,
				hideSpacer
			} = this.props,
			arrow = (
				<img
					alt='downArrow'
					className={submenuOpen ? 'arrowDown' : 'arrowRight'}
					height={'8px'}
					width={'10px'}
					src={_svg.ChevronDown}
				/>
			);

		let extraSlideDownClass = navBorder ? 'nav-border' : '',
			headNav =
				type === 'standalone' ? (
					<div onClick={() => setSubmenu(submenuName, !submenuOpen)}>
						{completed ? (
							<img alt='check' className={'sidebarIcon'} src={_svg.CircleCheck} />
						) : (
							<img alt='checkmarkNotDone' className={'sidebarIcon'} src={_svg.CircleX} />
						)}

						<p className={'sidebarRouteName sidebarRouteDropdown'}>{name}</p>
						{arrow}
					</div>
				) : (
					<div>
						<div onClick={() => setSubmenu(submenuName, !submenuOpen)}>
							<img
								alt='Sidebar nav img'
								className={'sidebarIcon'}
								height={'15px'}
								width={'15px'}
								src={imgLogo}
							/>
							<p className={'sidebarRouteName'}>{name}</p>
							{arrow}
						</div>
					</div>
				);

		return (
			<SlideDown
				key={this.props.routePath}
				className={
					submenuOpen
						? 'my-dropdown-slidedown slidedown activeSlidedown ' + extraSlideDownClass
						: 'my-dropdown-slidedown slidedown ' + extraSlideDownClass
				}>
				<ul className={'sidebarDropdownUL'}>
					{submenuOpen ? (
						<li className={extraClass} key={routePath + name}>
							<NavLink location={location} to={routePath} className='nav-link'>
								{headNav}
							</NavLink>
							{children}
						</li>
					) : (
						<li key={routePath + name}>
							<NavLink location={location} to={routePath} className='nav-link'>
								{headNav}
							</NavLink>
						</li>
					)}
				</ul>

				{!hideSpacer && <div className={'sidebarSpacer'} />}
			</SlideDown>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setSubmenu: (str, val) => dispatch(UserActions.setSubmenus(str, val))
});

export default connect(null, mapDispatchToProps)(SidebarDropdown);
