import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import { UserActions } from 'reducers/user';
import { nextAppPage } from 'components/modules/nav';

class PersonalInformationParent extends Component {
	componentDidMount() {
		this.props.setSubmenu(true);
	}

	saveAndContinue = e => {
		e.preventDefault();
		e.stopPropagation();

		const { app, navigate, location } = this.props;
		navigate(nextAppPage(app, location.pathname));
	};

	onBack = e => {
		let { setSubmenu, navigate } = this.props;

		setSubmenu(false);
		navigate('/summary');
	};

	render() {
		if (!this.props.app_id) return null;

		return (
			<Card
				title='PERSONAL INFORMATION'
				content={
					<form id={'form'} onSubmit={this.saveAndContinue}>
						<p>
							You will be asked to provide information about yourself, your background, and your
							parent(s)/guardian(s).
						</p>

						<div className='clearfix' />
					</form>
				}
				buttons={
					<>
						<Button bsStyle='info' className='back-btn' fill onClick={this.onBack}>
							Back
						</Button>
						<Button form={'form'} autoFocus bsStyle='info' className='save-btn' fill type={'submit'}>
							Continue
						</Button>
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => {
		const { app_id, apps } = state.user;

		return {
			app: apps[app_id].json_obj,
			apps: apps,
			app_id: app_id
		};
	},
	mapDispatchToProps = dispatch => ({
		setSubmenu: val => dispatch(UserActions.setSubmenus('personal_info_open', val))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PersonalInformationParent));
