import React, { Component } from 'react';

export class Card extends Component {
	render() {
		const { title, noFooter, content, buttons, className } = this.props;

		return (
			<div className={`card card-${title} ${className}`}>
				{!!title && (
					<div className='cardHeader'>
						<h1 className='title'>{title}</h1>
					</div>
				)}

				<div className='cardContent'>{content}</div>

				{!noFooter && (
					<div className='card-footer'>
						<div className='card-footer-wrapper'>{buttons}</div>
					</div>
				)}
			</div>
		);
	}
}

export default Card;
