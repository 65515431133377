import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import API from 'components/api';
import { cleanString } from 'components/modules/_misc';
import { withinDateTimeRange } from 'components/modules/date';
import { formatNotificationBody } from 'components/modules/react_content';
import BasicNavMenu from 'components/Navbars/Basic';
import PracticeWarningModal from 'views/Public/Modals/PracticeWarning';
import { GlobalActions } from 'reducers/global';
import { UserActions } from 'reducers/user';

const { REACT_APP_ENV } = process.env,
	main = require('assets/homepageImages/UWs-logo/white.png'),
	image500 = require('assets/homepageImages/UWs-logo/white-500.png'),
	image800 = require('assets/homepageImages/UWs-logo/white-800.png'),
	image1080 = require('assets/homepageImages/UWs-logo/white-1080.png'),
	image1600 = require('assets/homepageImages/UWs-logo/white-1600.png');

class SignIn extends Component {
	constructor(props) {
		super(props);

		this.state = {
			practiceModal: REACT_APP_ENV === 'practice',
			sidebarExists: false,
			user_name: '',
			pwd: '',
			error: '',
			notification_index: 0,
			interval_id: -1
		};

		this.notifications = props.system_notifications.filter(
			n =>
				!n?.field_start_date ||
				!n?.field_end_date ||
				(n.field_display_on_login_screen === '1' && withinDateTimeRange(n.field_start_date, n.field_end_date))
		);
	}

	componentDidMount() {
		if (this.notifications.length > 1) {
			let funcSetInterval = setInterval(() => {
				const { notification_index } = this.state,
					new_index = notification_index === this.notifications.length - 1 ? 0 : notification_index + 1;

				this.setState({ notification_index: new_index });
			}, 5000);

			this.setState({ interval_id: funcSetInterval });
		}
	}

	componentDidUpdate(prevProps) {
		const { email, navigate, setForcePwdReset, direct_admit } = this.props,
			{ pwd } = this.state;

		if (email && !prevProps.email) {
			if (pwd.length < 12) {
				setForcePwdReset();
			} else {
				const need_to_link_direct_admit = direct_admit?.need_to_link && !direct_admit.user_id;
				navigate(need_to_link_direct_admit ? '/link-direct-admit' : '/my-account');
			}
		}
	}

	getAPI = () => {
		const { support_student_id, campus_admin } = this.props;
		return new API('', support_student_id, campus_admin);
	};

	onSignIn = e => {
		const { login, captureError } = this.props,
			{ user_name, pwd } = this.state;

		e.preventDefault();
		e.stopPropagation();

		this.getAPI()
			.login(user_name, pwd)
			.then(result => {
				const strError = result.result === 'success' && result.user ? '' : result.reason;
				this.setState({ error: strError });

				if (!strError) login(result.user, result.direct_admit_row || {});
			})
			.catch(ex => captureError(ex));
	};

	mobileSidebarToggle = e => {
		const { sidebarExists } = this.state;

		if (!sidebarExists) this.setState({ sidebarExists: true });

		e.preventDefault();

		if (document.documentElement.classList.contains('nav-open')) {
			document.documentElement.classList.toggle('nav-open'); //Toggle and remove all bodyClicks
			document.querySelectorAll('#bodyClick').forEach(e => e.remove()); //REMOVE ALL bodyClick elements
		} else {
			document.documentElement.classList.toggle('nav-open'); //Toggle and add bodyClick element

			let node = document.createElement('div');
			node.id = 'bodyClick';
			node.onclick = function () {
				if (this.parentElement) this.parentElement.removeChild(this);
				document.querySelectorAll('#bodyClick').forEach(e => e.remove()); //REMOVE ALL bodyClick elements
				document.documentElement.classList.toggle('nav-open');
			};

			document.body.appendChild(node);
		}
	};

	onChange = e => {
		let { name, value } = e.target,
			label = name === 'user_name' ? 'Email' : 'Password',
			valClean = cleanString(value);

		value = valClean.clean;
		if (valClean.dirty) {
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<div className='react-confirm-alert'>
							<div className='react-confirm-alert-body'>
								Invalid characters were removed from "{label}."
								<div className='react-confirm-alert-button-group'>
									<button onClick={onClose}>OK</button>
								</div>
							</div>
						</div>
					);
				}
			});
		}

		this.setState({ error: '', [name]: value });
	};

	renderNotifications = () => {
		const { notification_index } = this.state,
			content = formatNotificationBody(this.notifications[notification_index]);

		return (
			<div className='announcement-modal'>
				<img src={require('assets/homepageImages/Alert.png')} alt='' className='announcement-icon' />
				<div>{content}</div>
			</div>
		);
	};

	renderMainForm = () => {
		const { navigate } = this.props,
			{ user_name, pwd, error } = this.state,
			err_messages = {
				'Bad Username': (
					<>
						<h4 className={'text uwRedText'}>
							Sorry, we could not find an account with this email. Please make sure your information is
							entered correctly and try again.
						</h4>
						<h4 className={'text uwRedText'}>
							If you created an account prior to 8/1/2020, you will need to{' '}
							<a onClick={() => navigate('/create-account')}>create a new account</a>.
						</h4>
					</>
				),
				'Bad Password': (
					<>
						<h4 className={'text uwRedText'}>
							Sorry, the password you have entered is incorrect. Please make sure your information is
							entered correctly and try again.
						</h4>
						<h4 className={'text uwRedText'}>
							If you created an account prior to 8/1/2020, you will need to{' '}
							<a onClick={() => navigate('/create-account')}>create a new account</a>.
						</h4>
					</>
				),
				'User Blocked': (
					<>
						<h4 className={'text uwRedText'}>
							The account associated with this email has been deactivated
						</h4>
						<h4 className={'text uwRedText'}>
							Please <a onClick={() => navigate('/create-account')}>create a new account</a> using a
							different email address.
						</h4>
					</>
				)
			};

		return (
			<div className='right-column-wrap'>
				<div className='form-block w-form'>
					<h1 className='column-heading'>sign in to your account</h1>

					<form
						id='email-form'
						name='email-form'
						data-name='Email Form'
						className='form'
						onSubmit={e => this.onSignIn(e)}>
						<label htmlFor='username' className='field-label'>
							Email
						</label>
						<input
							type='text'
							className='text-field w-input'
							maxLength='256'
							name='user_name'
							data-name='Email'
							placeholder='Enter your email'
							value={user_name}
							required={true}
							onChange={this.onChange}
							id='username'
						/>

						<label htmlFor='Password' className='field-label'>
							Password
						</label>
						<input
							aria-label='Password input'
							type='password'
							className='text-field w-input'
							maxLength='256'
							name='pwd'
							data-name='Password'
							placeholder='Enter your password'
							value={pwd}
							required={true}
							minLength={8}
							onChange={this.onChange}
							id='password'
						/>

						{!!error && err_messages[error]}

						<input
							aria-label='Sign in submit'
							type='submit'
							value='Sign In'
							data-wait='Please wait...'
							className='submit-button w-button'
						/>
					</form>
				</div>
				<a className='link' onClick={() => navigate('/reset-password')}>
					Forgot My Password
				</a>
			</div>
		);
	};

	render = () => {
		const { practiceModal } = this.state;

		return (
			<div className='body-2' role='main'>
				<Modal show={practiceModal}>
					<PracticeWarningModal closeModal={() => this.setState({ practiceModal: false })} />
				</Modal>

				<div
					data-collapse='medium'
					data-animation='default'
					data-duration='400'
					data-easing='ease-out'
					data-easing2='ease-out'
					className='nav-2-columns w-nav'>
					<div className='navigation-container'>
						<a href='/' className='logo w-inline-block'>
							<img
								src={main}
								width='100'
								srcSet={`${image500} 500w, ${image800} 800w, ${image1080} 1080w, ${image1600} 1600w, ${main} 1647w`}
								sizes='(max-width: 479px) 55vw, (max-width: 991px) 200px, 100vw'
								alt=''
								className='image-3'
							/>
						</a>
						<div className='menu-button w-nav-button'>
							<div className='icon-2 w-icon-nav-menu' onClick={this.mobileSidebarToggle} />
						</div>
					</div>
				</div>

				<div className='two-column-view' role='navigation'>
					<BasicNavMenu />

					<div className='right-column'>
						{!!this.notifications.length && this.renderNotifications()}

						{this.renderMainForm()}
					</div>
				</div>
				<script
					src='https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.4.1.min.220afd743d.js?site=5ea1ccd99c38e78f25db182e'
					type='text/javascript'
					integrity='sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo='
					crossOrigin='anonymous'></script>
				<script src='assets/webflowJS/webflow.js' type='text/javascript'></script>
			</div>
		);
	};
}

const mapStateToProps = state => ({
		email: state.user.email,
		support_student_id: state.user.support_student_id,
		campus_admin: state.user.campus_admin,
		direct_admit: state.user.direct_admit,
		system_notifications: state.global.system_notifications
	}),
	mapDispatchToProps = dispatch => ({
		login: (obj, direct_admit) => dispatch(UserActions.login(obj, direct_admit)),
		setForcePwdReset: () => dispatch(UserActions.setForcePwdReset(true)),
		captureError: err => dispatch(GlobalActions.captureError(err))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));
