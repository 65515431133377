import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { Modal } from 'react-bootstrap';

import ContactUsModal from 'views/Public/Modals/ContactUs';

const main = require('assets/homepageImages/UWs-logo/white.png');

class BasicNavMenu extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false
		};
	}

	render() {
		const { navigate, location } = this.props,
			{ showModal } = this.state;

		return (
			<>
				<Modal show={showModal}>
					<ContactUsModal closeModal={() => this.setState({ showModal: false })} />
				</Modal>

				<div className='left-column'>
					<div className='div-block-2' onClick={() => navigate('/')}>
						<img src={main} sizes='(max-width: 991px) 100vw, (max-width: 1439px) 17vw, 220px' alt='' />
					</div>
					<div className='div-block-3'>
						<img
							onClick={() => navigate('/how-to-apply')}
							src={require('assets/homepageImages/Checklist.png')}
							sizes='(max-width: 991px) 100vw, 92.703125px'
							alt=''
							className='image-4'
						/>
						<div className='link' onClick={() => navigate('/how-to-apply')}>
							How to Apply
						</div>

						<img
							onClick={() => this.setState({ showModal: true })}
							src={require('assets/homepageImages/Phone.png')}
							sizes='(max-width: 991px) 100vw, 100px'
							alt=''
							className='image-4'
						/>
						<div className='link' onClick={() => this.setState({ showModal: true })}>
							Contact Us
						</div>

						{location.pathname === '/create-account' && (
							<>
								<img
									onClick={() => navigate('/sign-in')}
									src={require('assets/homepageImages/Login.png')}
									sizes='(max-width: 991px) 100vw, 99.234375px'
									alt=''
									className='image-4'
								/>
								<div className='link'>
									<a onClick={() => navigate('/sign-in')} className='link'>
										Have an account?
									</a>
								</div>
							</>
						)}

						{!['/create-account', '/link-direct-admit'].includes(location.pathname) && (
							<>
								<img
									onClick={() => navigate('/create-account')}
									src={require('assets/homepageImages/New-User.png')}
									sizes='(max-width: 991px) 100vw, 99.234375px'
									alt=''
									className='image-4'
								/>
								<div className='link'>
									<a onClick={() => navigate('/create-account')} className='link'>
										Need an account?
									</a>
								</div>
							</>
						)}
					</div>
					<div className='homepage'>
						<div className='back-to-home'>
							<a onClick={() => navigate('/')} className='link'>
								&lt; back to home
							</a>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withRouter(BasicNavMenu);
