import routes from 'components/routes';
import { routeEnabledForModifier } from 'components/modules/app';

export const INIT_INFO_ROUTES = [
		'/select-modifier',
		'/select-campus',
		'/course-of-study',
		'/course-of-study',
		'/select-semester',
		'/summary',
		'/direct-admit/select-campus'
	],
	IN_APP_ROUTES = [
		'/personal-information',
		'/basic-information',
		'/personal-information-additional',
		'/contact-information',
		'/parent-information',
		'/residency',
		'/academic-background',
		'/academic-background-hs',
		'/academic-background-hs-courses',
		'/academic-background-higher-education-check',
		'/academic-background-higher-education',
		'/academic-background-higher-education-courses',
		'/academic-background-test-scores',
		'/holistic-background',
		'/activities',
		'/employment',
		'/essay',
		'/submit',
		'/additional-applications',
		'/sign-application',
		'/payment',
		'/next-steps',
		'/save-and-return-later'
	],
	APP_ROUTES = [...INIT_INFO_ROUTES, ...IN_APP_ROUTES, '/contact-us'],
	getSpecificRoutes = whichType => {
		var returnRoutes = [];

		if (whichType === 'noSidebar') {
			routes.forEach(route => {
				if (route.noSidebar) returnRoutes.push(route.path);
			});
		} else if (whichType === 'infoSidebar') {
			routes.forEach(route => {
				if (route.infoSidebar) returnRoutes.push(route.path);
			});
		}

		return returnRoutes;
	},
	nextAppPage = (_app, current_path) => {
		const current_route = routes.find(x => x.path === (current_path || '/summary')),
			app_json = _app.json_obj || _app,
			next_incomplete_route = routes.find(
				r =>
					(!current_route || routes.indexOf(r) > routes.indexOf(current_route)) &&
					!app_json.screensCompleted[r.completedBoolean] &&
					routeEnabledForModifier(r.path, app_json)
			);

		return next_incomplete_route ? next_incomplete_route.path : '/personal-information';
	},
	prevAppPage = (_app, current_path) => {
		const current_route = routes.find(x => x.path === current_path),
			app_json = _app.json_obj || _app,
			prev_routes = routes.filter(
				r => routes.indexOf(r) < routes.indexOf(current_route) && routeEnabledForModifier(r.path, app_json)
			);

		return prev_routes.length ? prev_routes[prev_routes.length - 1].path : '/personal-information';
	},
	getIncompleteRoutes = app => {
		return routes.filter(
			r =>
				routeEnabledForModifier(r.path, app) &&
				(r.childOf || r.initInfoPage) &&
				r.completedBoolean &&
				!app.screensCompleted[r.completedBoolean]
		);
	};
