import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import { nextAppPage, prevAppPage } from 'components/modules/nav';
import { UserActions } from 'reducers/user';

class AcademicBackground extends Component {
	componentDidMount() {
		this.props.setSubmenu(true);
	}

	saveAndContinue = e => {
		e.preventDefault();
		e.stopPropagation();

		const { navigate, app, location } = this.props;
		navigate(nextAppPage(app, location.pathname));
	};

	onBack = e => {
		const { navigate, setSubmenu, app, location } = this.props;

		setSubmenu(false);
		navigate(prevAppPage(app, location.pathname));
	};

	render() {
		return (
			<Card
				title='ACADEMIC BACKGROUND'
				content={
					<form id='acadBG' onSubmit={this.saveAndContinue}>
						<div>
							<p>
								You will be asked to provide information about your educational background. This
								typically involves providing information about your high school/secondary school and
								college/post-secondary career.
							</p>
						</div>
						<div className='clearfix' />
					</form>
				}
				buttons={
					<>
						<Button bsStyle='info' className='back-btn' fill onClick={this.onBack}>
							Back
						</Button>
						<Button form='acadBG' bsStyle='info' className='save-btn' fill type='submit'>
							Continue
						</Button>
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => {
		const { apps, app_id } = state.user;
		return {
			app: apps[app_id].json_obj
		};
	},
	mapDispatchToProps = dispatch => ({
		setSubmenu: val => dispatch(UserActions.setSubmenus('academic_background_open', val))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AcademicBackground));
