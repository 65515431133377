import { routeEnabledForModifier } from 'components/modules/app';
import { legal_genders, genders, countries, states, heritages, tribes } from 'components/modules/opts';

const assemble_personal_info = (app, first_name, last_name, waivers) => {
		let { personal_information } = app,
			{
				middle_name,
				preferred_name,
				suffix,
				previous_names,
				gender,
				gender_identity,
				date_of_birth,
				social_security_number,
				taxpayer_identification_number,
				citizen_country_code,
				country_of_citizenship_other,
				birth_country_code,
				country_of_birth_other,
				birth_state,
				city_of_birth,
				family_earned_degree,
				working_with_agent,
				agent_name,
				self_graduate_wi_highschool,
				military_service,
				veteran_status,
				heritage_hispanic,
				selected_ethnicities,
				heritage_race,
				heritage_tribe_code,
				other_tribe,
				waive_fee,
				waive_fee_reason,
				tribal_enrollment_no
			} = personal_information,
			content = [{ label: 'Full Name', value: `${first_name} ${middle_name} ${last_name} ${suffix}` }],
			birth_vals = [],
			show_waiver = ['FM_D', 'TR_D'].includes(app.application_modifier);

		if (preferred_name) {
			content.push({ label: 'Preferred Name', value: preferred_name });
		}

		if (previous_names.length) {
			content.push({
				label: 'Previous Name(s)',
				value: previous_names
					.map(_name => `${_name.first_name} ${_name.middle_name} ${_name.last_name}`)
					.join(', ')
			});
		}

		if (gender) {
			content.push({ label: 'Legal Sex', value: legal_genders.find(g => g.val === gender)?.label });
		}

		if (gender_identity) {
			content.push({ label: 'Gender', value: genders.find(g => g.val === gender_identity)?.label });
		}

		if (date_of_birth) {
			content.push({ label: 'Date of Birth', value: date_of_birth });
		}

		if (social_security_number) {
			content.push({ label: 'Social Security Number', value: social_security_number });
		}

		if (taxpayer_identification_number) {
			content.push({ label: 'Taxpayer ID Number', value: taxpayer_identification_number });
		}

		if (country_of_citizenship_other) {
			content.push({ label: 'Country of Citizenship', value: country_of_citizenship_other });
		} else if (citizen_country_code) {
			content.push({
				label: 'Country of Citizenship',
				value: countries.find(c => c.val === citizen_country_code)?.label
			});
		}

		if (city_of_birth) birth_vals.push(city_of_birth);
		if (birth_state) birth_vals.push(states.find(s => s.val === birth_state)?.label);

		if (country_of_birth_other) {
			birth_vals.push(country_of_birth_other);
		} else if (birth_country_code) {
			birth_vals.push(countries.find(c => c.val === birth_country_code)?.label);
		}

		content.push({ label: 'Born', value: birth_vals.join(', ') });

		if (family_earned_degree) {
			const opts = {
				No: 'Neither of my parents and/or guardians earned a four-year college/university degree.',
				Yes: 'One/both of my parents and/or guardians earned a four-year college/university degree.'
			};

			content.push({ label: '', value: opts[family_earned_degree] });
		}

		if (working_with_agent) {
			const opts = {
				No: "I'm not working with an agent or advisor",
				Yes: "I'm working with an agent or advisor"
			};

			content.push({ label: '', value: opts[working_with_agent] });
		}

		if (agent_name) {
			content.push({ label: 'Agency or advisor name', value: agent_name });
		}

		if (self_graduate_wi_highschool && !routeEnabledForModifier('/academic-background-hs', app)) {
			const opts = {
				No: 'I did not graduate from a Wisconsin high school',
				Yes: 'I graduated from a Wisconsin high school'
			};

			content.push({ label: '', value: opts[self_graduate_wi_highschool] });
		}

		if (military_service?.length) {
			content.push({ label: 'Currently serving in the U.S. Military', value: military_service.join(', ') });
		}

		if (veteran_status?.length) {
			content.push({ label: 'U.S. Military veterans', value: veteran_status.join(', ') });
		}

		if (selected_ethnicities.length || heritage_race.length) {
			const _heritages = heritage_race.map(h => heritages.find(opt => opt.val === h)?.label);
			content.push({ label: 'I identify myself as:', value: selected_ethnicities.concat(_heritages).join(', ') });
		}

		if (heritage_hispanic) {
			const opts = {
				N: "I'm not of Hispanic or Latino/a origin.",
				Y: 'I am of Hispanic or Latino/a origin.'
			};

			content.push({ label: '', value: opts[heritage_hispanic] });
		}

		if (other_tribe) {
			content.push({ label: 'Tribal Enrollment', value: other_tribe });
		} else if (heritage_tribe_code) {
			content.push({ label: 'Tribal Enrollment', value: tribes.find(t => t.val === heritage_tribe_code)?.label });
		}

		if (tribal_enrollment_no) {
			content.push({ label: 'Tribal Enrollment Number', value: tribal_enrollment_no });
		}

		if (show_waiver && !!waive_fee) {
			if (waive_fee === 'No') {
				content.push({ label: '', value: "I don't believe I'm eligible for a waived application fee." });
			} else if (waive_fee_reason) {
				content.push({
					label: "I believe I'm eligible for a waived application fee because:",
					value: waivers.find(w => w.val === waive_fee_reason)?.label
				});
			} else {
				content.push({ label: '', value: "I believe I'm eligible for a waived application fee." });
			}
		}

		return content;
	},
	assemble_contact_info = (contact_information, phone_number) => {
		let { permanent_address, mailing_address, other_phone_type, other_phone_number, preferred_phone_number } =
				contact_information,
			content = [];

		if (permanent_address?.formatted_address) {
			content.push({ label: 'Address', value: permanent_address.formatted_address });
		}

		if (mailing_address?.formatted_address) {
			content.push({ label: 'Mailing Address', value: mailing_address.formatted_address });
		}

		if (phone_number) {
			let val = phone_number;
			if (other_phone_number && preferred_phone_number !== phone_number) val += ' (Preferred)';

			content.push({ label: 'Cell Phone Number', value: val });
		}

		if (other_phone_type && other_phone_number) {
			let phone_type = other_phone_type === 'Cell' ? 'Other Cell' : other_phone_type,
				lbl = `${phone_type} Phone Number`;

			if (preferred_phone_number !== other_phone_number) lbl += ' (Preferred)';

			content.push({ label: lbl, value: other_phone_number });
		}

		return content;
	},
	assemble_parent_info = parent_info => {
		let { parents } = parent_info,
			content = [];

		parents.forEach(parent => {
			const { first_name, last_name, relationship_to_applicant, current_address, parent_phone, email_address } =
				parent;

			if (first_name && last_name && relationship_to_applicant) {
				const relationship =
					relationship_to_applicant === 'Other' ? 'Other Guardian/Caretaker' : relationship_to_applicant;

				content.push({ label: `${first_name} ${last_name} (${relationship})`, header: 2 });
			}

			if (current_address?.formatted_address) {
				content.push({ label: 'Address', value: current_address.formatted_address });
			} else {
				const { address_1, address_2, city, state, other_state, zip, country, other_country } = current_address,
					arrFields = [address_1];

				if (address_2) {
					arrFields.push(address_2);
				}

				arrFields.push(city);

				if (other_state) {
					arrFields.push(other_state);
				} else if (state) {
					arrFields.push(states.find(s => s.val === state)?.label);
				}

				arrFields.push(zip);

				if (other_country) {
					arrFields.push(other_country);
				} else if (country) {
					arrFields.push(countries.find(c => c.val === country)?.label);
				}

				content.push({ label: 'Address', value: arrFields.join(', ') });
			}

			if (parent_phone) {
				content.push({ label: 'Phone', value: parent_phone });
			}

			if (email_address) {
				content.push({ label: 'Email', value: email_address });
			}
		});

		return content;
	},
	assemble_residency = (residency, parents, is_direct_admit) => {
		let {
				intend_claim_residency,
				parent_provided,
				provide_parent_info,
				relationship_unable_to_provide,
				residency_relationship,
				relationship_us_citizen,
				relationship_visa_type,
				relationship_visa_type_other,
				relationship_lived_wi_12_months,
				relationship_employed,
				relationship_income_taxes,
				relationship_registered_vote,
				relationship_valid_license,
				self_provided,
				self_lived_wi_12_months,
				self_live_in_wi,
				self_lived_wi_date,
				self_employed,
				self_income_taxes,
				self_registered_vote,
				self_valid_license,
				self_claim_dependent,
				self_he_enrolled
			} = residency,
			content = [];

		if (intend_claim_residency) {
			const opts = {
				No: "I don't believe I qualify for in-state tuition based on Wisconsin residency.",
				Yes: 'I believe I qualify for in-state tuition based on Wisconsin residency.'
			};

			content.push({ label: '', value: opts[intend_claim_residency] });
		}

		if (relationship_unable_to_provide) {
			content.push({ label: '', value: "I'm not able to provide parent or legal guardian information." });
		}

		if (residency_relationship && parent_provided && provide_parent_info !== 'No') {
			const parent = parents.find(p => p.relationship_to_applicant === residency_relationship);
			content.push({
				label:
					parent?.first_name && parent?.last_name
						? `${parent.first_name} ${parent.last_name} (${residency_relationship})`
						: residency_relationship,
				header: 2
			});

			if (relationship_us_citizen) {
				content.push({ label: 'U.S. Citizen', value: relationship_us_citizen });
			}

			if (relationship_visa_type_other) {
				content.push({ label: 'Visa type', value: relationship_visa_type_other });
			} else if (relationship_visa_type) {
				content.push({ label: 'Visa type', value: relationship_visa_type });
			}

			if (relationship_lived_wi_12_months) {
				content.push({
					label: 'Physically resided full-time in Wisconsin for the past 12 months',
					value: relationship_lived_wi_12_months
				});
			}

			if (relationship_employed) {
				content.push({
					label: 'Employed',
					value: relationship_employed === 'Wisconsin' ? `In ${relationship_employed}` : relationship_employed
				});
			}

			if (relationship_income_taxes) {
				content.push({
					label: 'Filed a Wisconsin resident income tax return for the most recent tax year',
					value: relationship_income_taxes
				});
			}

			if (relationship_registered_vote) {
				content.push({
					label: 'Wisconsin was the last place registered to vote/voted',
					value: relationship_registered_vote
				});
			}

			if (relationship_valid_license) {
				content.push({
					label: "Holds a valid Wisconsin driver's license",
					value: relationship_valid_license
				});
			}

			if (self_provided) {
				content.push({ label: is_direct_admit ? 'Student' : 'Applicant', header: 2 });
			}
		}

		if (self_provided) {
			if (self_live_in_wi) {
				content.push({ label: 'Live in Wisconsin', value: self_live_in_wi });
			}

			if (self_lived_wi_date) {
				content.push({ label: 'I started living in Wisconsin', value: self_lived_wi_date });
			}

			if (self_employed) {
				content.push({
					label: "I'm employed",
					value: self_employed === 'Wisconsin' ? 'In Wisconsin' : self_employed
				});
			}

			if (self_income_taxes) {
				content.push({
					label: 'Filed a Wisconsin resident income tax return for the most recent tax year',
					value: self_income_taxes
				});
			}

			if (self_registered_vote) {
				content.push({
					label: 'Wisconsin was the last place registered to vote/voted',
					value: self_registered_vote
				});
			}

			if (self_valid_license) {
				content.push({
					label: "Holds a valid Wisconsin driver's license",
					value: self_valid_license
				});
			}

			if (self_claim_dependent) {
				content.push({
					label: 'My parent(s)/guardian(s) claim me as a dependent for the most recent tax year',
					value: self_claim_dependent
				});
			}

			if (self_lived_wi_12_months) {
				content.push({
					label: 'Physically resided full-time in Wisconsin for the past 12 months',
					value: self_lived_wi_12_months
				});
			}

			if (self_he_enrolled) {
				content.push({
					label: 'Have enrolled in a higher education institution within the past 12 months',
					value: self_he_enrolled
				});
			}
		}

		return content;
	},
	assemble_acad_bg = (app, academic_background, documents) => {
		let {
				highschools,
				hs_course_schedule_file,
				hs_course_schedule_file_name,
				courses,
				colleges,
				college_course_schedule_file,
				college_course_schedule_file_name,
				college_courses_in_progress,
				act_transition_option,
				test_scores
			} = academic_background,
			content = [];

		highschools.forEach(hs => {
			const {
				currently_attend,
				selected_highschool,
				hs_attendance_start,
				hs_attendance_end,
				graduation_date,
				did_graduate,
				high_school_transcript,
				high_school_transcript_name
			} = hs;

			if (selected_highschool?.title) {
				content.push({ label: selected_highschool.title, header: 2 });
			}

			if (hs_attendance_start) {
				if (hs_attendance_end) {
					content.push({ label: 'Attended', value: `${hs_attendance_start} to ${hs_attendance_end}` });
				} else if (graduation_date) {
					content.push({ label: 'Attended', value: `${hs_attendance_start} to ${graduation_date}` });
				}
			} else if (graduation_date) {
				content.push({ label: did_graduate ? 'Graduated' : 'Will Graduate', value: graduation_date });
			}

			if (high_school_transcript_name) {
				content.push({
					label: 'Transcript file',
					value: high_school_transcript_name,
					download: { key: high_school_transcript, file_name: high_school_transcript_name }
				});
			} else if (high_school_transcript) {
				const doc = documents.find(d => d.key === high_school_transcript);
				if (doc) {
					content.push({
						label: 'Transcript file',
						value: doc.file_name,
						download: { key: doc.key, file_name: doc.file_name }
					});
				} else {
					content.push({ label: '', value: 'Transcript provided' });
				}
			}

			if (currently_attend === 'Yes' && courses.length) {
				content.push({
					label: 'Courses',
					value: courses.map(c => `${c.course_title} (${c.credits} credits)`).join(', ')
				});
			}

			if (hs_course_schedule_file_name) {
				content.push({
					label: 'Course schedule file',
					value: hs_course_schedule_file_name,
					download: { key: hs_course_schedule_file, file_name: hs_course_schedule_file_name }
				});
			} else if (hs_course_schedule_file) {
				const doc = documents.find(d => d.key === hs_course_schedule_file);
				if (doc) {
					content.push({
						label: 'Course schedule file',
						value: doc.file_name,
						download: { key: doc.key, file_name: doc.file_name }
					});
				} else {
					content.push({ label: '', value: 'Course schedule provided' });
				}
			}
		});

		colleges.forEach(c => {
			const {
					selected_college,
					currently_attend,
					college_start,
					college_end,
					graduation_date,
					degree_earned,
					degree_freetext,
					major
				} = c,
				strDegree = degree_earned === 'Other' ? degree_freetext : degree_earned;

			if (selected_college?.title) {
				content.push({ label: selected_college.title, header: 2 });
			}

			if (college_start) {
				if (college_end) {
					content.push({ label: 'Attended', value: `${college_start} to ${college_end}` });
				} else if (graduation_date) {
					content.push({ label: 'Attended', value: `${college_start} to ${graduation_date}` });
				}
			} else if (graduation_date) {
				content.push({ label: did_graduate ? 'Graduated' : 'Will Graduate', value: graduation_date });
			}

			if (strDegree) {
				content.push({ label: 'Degree', value: strDegree });
			}

			if (major) {
				content.push({ label: 'Major', value: major });
			}

			if (currently_attend === 'Yes') {
				const assoc_courses = (college_courses_in_progress || []).filter(
					c => c.college_associated === selected_college?.title
				);

				if (assoc_courses.length) {
					content.push({
						label: 'Courses',
						value: assoc_courses
							.map(c => `${c.course_title} ${c.course_number} (${c.credits} credits)`)
							.join(', ')
					});
				}
			}

			if (college_course_schedule_file_name) {
				content.push({
					label: 'Course schedule file',
					value: college_course_schedule_file_name,
					download: { key: college_course_schedule_file, file_name: college_course_schedule_file_name }
				});
			} else if (college_course_schedule_file) {
				const doc = documents.find(d => d.key === college_course_schedule_file);
				if (doc) {
					content.push({
						label: 'Course schedule file',
						value: doc.file_name,
						download: { key: doc.key, file_name: doc.file_name }
					});
				} else {
					content.push({ label: '', value: 'Course schedule provided' });
				}
			}
		});

		if ((app.application_modifier.includes('FM') && !!act_transition_option) || test_scores.length) {
			content.push({ label: 'Test Scores', header: 2 });
		}

		if (app.application_modifier.includes('FM') && !!act_transition_option) {
			if (act_transition_option.includes('Option 1')) {
				content.push({ label: '', value: 'Consider my ACT and/or SAT score for admission' });
			} else {
				content.push({ label: '', value: 'Do not consider my ACT and/or SAT score for admission' });
			}
		}

		['IELTS Scores', 'TOEFL Scores', 'Duolingo Scores'].forEach(type => {
			const _scores = test_scores.filter(score => score.score_type === type);

			if (_scores.length) {
				content.push({
					label: type,
					value: _scores
						.map(score => {
							const { date_taken, future_test, total_score } = score;

							if (future_test) return `scheduled for ${date_taken}`;

							return `${total_score} (taken ${date_taken})`;
						})
						.join(', ')
				});
			}
		});

		return content;
	},
	assemble_holistic_bg = (activities_employment, essay, documents) => {
		let {
				no_activities,
				activities,
				no_employment,
				employment,
				employment_resume_file,
				employment_resume_file_name,
				employment_to_report
			} = activities_employment,
			content = [];

		if (no_activities || activities.length) {
			content.push({ label: 'Activities', header: 2 });
		}

		if (no_activities) {
			content.push({ label: '', value: 'I have no activities to report' });
		}

		if (activities.length) {
			activities.forEach(activity => {
				const {
					activity_category,
					activity_name,
					participation_grade_level,
					level_of_participation,
					position_or_leadership_role,
					intend_to_participate
				} = activity;

				content.push({ label: activity_name, header: 3 });
				content.push({ label: 'Category', value: activity_category });
				content.push({ label: 'Level of Participation', value: level_of_participation });

				if (position_or_leadership_role) content.push({ label: 'Role', value: position_or_leadership_role });

				content.push({
					label: 'Participation Grade Levels',
					value: participation_grade_level.sort().join(', ')
				});

				if (intend_to_participate === 'Yes') {
					content.push({ label: '', value: 'I intend to participate in a similar activity in college' });
				}
			});
		}

		if (no_employment || employment.length || employment_resume_file) {
			content.push({ label: 'Employment', header: 2 });
		}

		if (no_employment) {
			content.push({ label: '', value: 'I have no work experience to report' });
		}

		if (employment_to_report === 'Manually Enter Employment' && !!employment.length) {
			employment.forEach(e => {
				let {
						self_employed,
						employer_company,
						job_title,
						current_job,
						employment_started,
						employment_ended,
						hours_per_week,
						summer_only
					} = e,
					strEmploymentDates = `${employment_started} - ${
						current_job === 'Yes' ? 'Current' : employment_ended
					}`;

				if (summer_only === 'Yes') strEmploymentDates += ' (summers only)';

				content.push({
					label: `${job_title}, ${self_employed === 'Yes' ? 'Self-Employed' : employer_company}`,
					header: 3
				});

				content.push({ label: 'Employed', value: strEmploymentDates });

				if (hours_per_week) content.push({ label: 'Hours/Week', value: hours_per_week });
			});
		}

		if (employment_to_report === 'Upload Resume') {
			if (employment_resume_file_name) {
				content.push({
					label: 'Resume file',
					value: employment_resume_file_name,
					download: { key: employment_resume_file, file_name: employment_resume_file_name }
				});
			} else if (employment_resume_file) {
				const doc = documents.find(d => d.key === employment_resume_file);
				if (doc) {
					content.push({
						label: 'Resume file',
						value: doc.file_name,
						download: { key: doc.key, file_name: doc.file_name }
					});
				} else {
					content.push({ label: 'Resume file', value: 'Resume provided' });
				}
			}
		}

		if (essay?.essay_html) {
			content.push({ label: 'Essay', header: 2 });
			content.push({ label: '', essay: true, value: essay.essay_html.join('') });
		}

		if (essay?.second_essay_html) {
			content.push({ label: 'Second Essay', header: 2 });
			content.push({ label: '', essay: true, value: essay.second_essay_html.join('') });
		}

		return content;
	};

export const app_summary = (app, first_name, last_name, phone_number, waivers, documents) => {
	const { contact_information, parent_info, residency, academic_background, activities_employment, essay } = app,
		is_direct_admit = app.application_modifier;

	let content = {
		'Personal Information': assemble_personal_info(app, first_name, last_name, waivers),
		'Contact Information': assemble_contact_info(contact_information, phone_number),
		'Parent Information': assemble_parent_info(parent_info),
		Residency: assemble_residency(residency, parent_info.parents, is_direct_admit),
		'Academic Background': assemble_acad_bg(app, academic_background, documents),
		'Holistic Background': assemble_holistic_bg(activities_employment, essay, documents)
	};

	Object.keys(content).forEach(key => {
		if (!content[key].length) delete content[key];
	});

	return content;
};
