import React, { Component } from 'react';
import { Tooltip } from 'react-tooltip';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';
import 'react-tooltip/dist/react-tooltip.css';

import { Card } from 'components/Card/Card';
import Select from 'components/controls/select';
import { DEFUNCT_CAMPUS_CODES } from 'components/modules/campus';
import { date_reached, months_passed, formatDateTime } from 'components/modules/date';

const app_type_opts = [
	{ val: 'FM_D', label: 'Freshman (Domestic)' },
	{ val: 'TR_D', label: 'Transfer (Domestic)' },
	{ val: 'RE_D', label: 'Re-Entry (Domestic)' },
	{ val: 'FM_I', label: 'Freshman (International)' },
	{ val: 'TR_I', label: 'Transfer (International)' },
	{ val: 'RE_I', label: 'Re-Entry (International)' }
];

class FindYourTerm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			component_chosen_campus_id: '',
			component_application_modifier: '',
			term_type: 'Standard Term',

			underGradClosed: [],
			underGradOpeningSoon: [],

			timeOffset: 0
		};
	}

	componentDidUpdate(prevProps, prevState) {
		const { component_chosen_campus_id } = this.state;

		if (component_chosen_campus_id !== prevState.component_chosen_campus_id) {
			this.setState({ term_type: component_chosen_campus_id === '108908' ? 'Flex Term' : 'Standard Term' });
		}
	}

	getUnderGraduateTerms(which) {
		const { terms } = this.props,
			{ component_chosen_campus_id, component_application_modifier, term_type } = this.state;

		let international = component_application_modifier.split('_')[1] === 'I',
			returnedTerms = [];

		//NO APP TYPE, RETURN NONE
		if (component_application_modifier) {
			let app_type_name = '';
			if (component_application_modifier.includes('FM_')) {
				app_type_name = 'freshman';
			} else if (component_application_modifier.includes('TR_')) {
				app_type_name = 'transfer';
			} else if (
				component_application_modifier.includes('RE_') ||
				component_application_modifier.includes('RET_')
			) {
				app_type_name = 're_entry';
			}

			//Look through defaults and see if any campus has override
			terms.default_undergrad
				.filter(x => x.field_master_term_type === term_type && !x.title.includes('Direct Admit'))
				.forEach(defaultTerm => {
					let unverified_term = '',
						term_overwritten = false,
						app_type_terms = defaultTerm['field_' + app_type_name];

					//Look through this term and find object related to international/domestic
					app_type_terms.forEach(obj => {
						if (
							(international && obj.field_type === 'International') ||
							(!international && obj.field_type === 'Domestic')
						) {
							unverified_term = defaultTerm;
						}
					});

					if (unverified_term) {
						terms.campus_undergrad
							.filter(x => x.field_master_term_type === term_type && x.field_program_specific !== '1')
							.forEach(campusTerm => {
								//Global term doesnt match
								if (unverified_term.field_global_term === campusTerm.field_global_term) {
									//Term DOESNT match campus
									if (campusTerm.field_campuses.includes(component_chosen_campus_id)) {
										app_type_terms = campusTerm['field_' + app_type_name + '_campus'];

										app_type_terms.forEach(obj => {
											if (
												(international && obj.field_type === 'International') ||
												(!international && obj.field_type === 'Domestic')
											) {
												unverified_term = campusTerm;
												term_overwritten = true;
											}
										});
									}
								}
							});

						//Do logic on final term
						let return_term = '',
							final_app_type_terms = term_overwritten
								? unverified_term['field_' + app_type_name + '_campus']
								: unverified_term['field_' + app_type_name];

						final_app_type_terms.forEach(obj => {
							if (
								(international && obj.field_type === 'International') ||
								(!international && obj.field_type === 'Domestic')
							) {
								unverified_term.open_date = obj.field_opens;
								unverified_term.close_date = obj.field_closes;

								if (obj.field_opens === obj.field_closes && obj.field_closes === obj.field_pay) {
									unverified_term = '';
								}

								if (!date_reached(obj.field_opens)) {
									if (months_passed(obj.field_opens) > -3 && which === 'OPENING') {
										return_term = unverified_term;
									}
								} else if (date_reached(obj.field_closes)) {
									if (months_passed(obj.field_closes) < 3 && which === 'CLOSED') {
										return_term = unverified_term;
									}
								} else if (which === 'AVAILABLE') {
									return_term = unverified_term;
								}
							}
						});

						if (return_term) returnedTerms.push(return_term);
					}
				});

			return returnedTerms;
		}
	}

	formatTerms(terms, which) {
		return terms.length
			? terms.map(term => {
					if (which === 'OPENING') {
						return (
							<p key={term.title} className='term-search-result'>
								{term.title} | Opening: {formatDateTime(term.open_date, true)}
							</p>
						);
					} else if (which === 'CLOSED') {
						return (
							<p key={term.title} className='term-search-result'>
								{term.title} | Closed: {formatDateTime(term.close_date, true)}
							</p>
						);
					} else {
						return (
							<p key={term.title} className='term-search-result'>
								{term.title}
							</p>
						);
					}
			  })
			: [
					<p key='no-terms' className='term-search-result'>
						No terms found.
					</p>
			  ];
	}

	onChange = e => {
		let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
		this.setState({ [e.target.name]: value });
	};

	render() {
		const { campuses } = this.props,
			{ component_application_modifier, component_chosen_campus_id } = this.state,
			filtered_campuses = campuses.filter(
				(campus, i, self) =>
					self.findIndex(c => c.title.split(' ').join('') === campus.title.split(' ').join('')) === i
			); // tms:  Flex appears twice (for PKS and MIL), but with different spacing in the title

		let termOptions = [],
			openingSoon = [],
			closed = [];

		if (!!component_application_modifier && !!component_chosen_campus_id) {
			termOptions = this.getUnderGraduateTerms('AVAILABLE');
			openingSoon = this.getUnderGraduateTerms('OPENING');
			closed = this.getUnderGraduateTerms('CLOSED');
		}

		return (
			<Card
				title='FIND YOUR TERM'
				content={
					<div>
						<p className='intro-copy'>
							Here are when undergraduate degree applications are opening up. Use the dropdown to see when
							you can apply.
						</p>

						<p id='tooltip' className='intro-copy'>
							Looking for Graduate & Non-Degree term dates?
							<img
								className='informationTooltip'
								src={require('assets/img/Information.png')}
								alt='Information'
							/>
						</p>

						<Tooltip
							anchorId='tooltip'
							className='tooltipContainer'
							delayHide={1000}
							effect='solid'
							content={
								<>
									<p>
										Graduate and Non-Degree term dates vary by the different programs available at
										each campus.
									</p>
									<p>
										To find out when one of these applications open check in with the campus you are
										applying to.
									</p>
								</>
							}
						/>

						<Row>
							<Select
								value={component_chosen_campus_id}
								label='Campus'
								placeholder='Select Campus'
								name='component_chosen_campus_id'
								opts={filtered_campuses.map(c => ({ val: c.id, label: c.title }))}
								onChange={val => this.setState({ component_chosen_campus_id: val })}
								required={true}
								cols={6}
							/>

							<Select
								label='App Type'
								placeholder='Select App Type'
								name='component_application_modifier'
								value={component_application_modifier}
								opts={app_type_opts}
								onChange={val => this.setState({ component_application_modifier: val })}
								required={true}
								cols={6}
							/>
						</Row>

						<h3>Open:</h3>
						{this.formatTerms(termOptions, '')}

						<h3>Coming soon:</h3>
						{this.formatTerms(openingSoon, 'OPENING')}

						<h3>Closed:</h3>
						{this.formatTerms(closed, 'CLOSED')}
					</div>
				}
			/>
		);
	}
}

const mapStateToProps = state => ({
	terms: state.global.terms,
	campuses: state.global.campuses.filter(c => !DEFUNCT_CAMPUS_CODES.includes(c.field_abbreviation))
});

export default withRouter(connect(mapStateToProps)(FindYourTerm));
