import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

import ContactUsModal from 'views/Public/Modals/ContactUs';
import _svg from 'components/modules/svg';
import withRouter from 'components/Wrappers/withRouter';

const main = require('assets/homepageImages/UWs-logo/white.png'),
	image500 = require('assets/homepageImages/UWs-logo/white-500.png'),
	image800 = require('assets/homepageImages/UWs-logo/white-800.png'),
	image1080 = require('assets/homepageImages/UWs-logo/white-1080.png'),
	image1600 = require('assets/homepageImages/UWs-logo/white-1600.png');

class Header extends Component {
	constructor(props) {
		super(props);

		this.state = {
			contact_us_modal: false
		};
	}

	mobileSidebarToggle = e => {
		e.preventDefault();

		if (document.documentElement.classList.contains('nav-open')) {
			document.documentElement.classList.toggle('nav-open');
			document.querySelectorAll('#bodyClick').forEach(e => e.remove());
		} else {
			document.documentElement.classList.toggle('nav-open');

			let node = document.createElement('div');

			node.id = 'bodyClick';
			node.onclick = function () {
				if (this.parentElement) this.parentElement.removeChild(this);

				document.querySelectorAll('#bodyClick').forEach(e => e.remove());
				document.documentElement.classList.toggle('nav-open');
			};

			document.body.appendChild(node);
		}
	};

	render = () => {
		const { navigate, location } = this.props,
			{ contact_us_modal } = this.state,
			{ REACT_APP_ENV } = process.env;

		return (
			<div
				data-collapse='medium'
				data-animation='default'
				data-duration='400'
				data-easing='ease-out'
				data-easing2='ease-out'
				className='navigation w-nav'>
				<Modal show={contact_us_modal}>
					<ContactUsModal closeModal={() => this.setState({ contact_us_modal: false })} />
				</Modal>

				<div className='navigation-container'>
					<a
						onClick={() => navigate('/')}
						aria-label='logo'
						aria-current='page'
						className='logo w-inline-block w--current'>
						<img
							src={main}
							width='100'
							srcSet={`${image500} 500w, ${image800} 800w, ${image1080} 1080w, ${image1600} 1600w, ${main} 1647w`}
							sizes='(max-width: 479px) 55vw, 200px'
							alt=''
							className='image-3'
						/>
					</a>

					{REACT_APP_ENV !== 'production' && (
						<div className='practice-homepage'>
							{REACT_APP_ENV === 'practice' ? 'PRACTICE' : 'DEVELOPMENT'}
						</div>
					)}

					<nav role='navigation' className='nav-menu w-nav-menu'>
						<div>
							<span className='nav-link'>DIRECT ADMIT WISCONSIN</span>

							<div>
								<a onClick={() => navigate('/direct-admit')} className='nav-link w-nav-link'>
									Accept Offer
								</a>
								<a
									target='_blank'
									href='https://www.wisconsin.edu/direct-admissions/'
									className='nav-link w-nav-link'>
									More Information
								</a>
							</div>
						</div>

						<a onClick={() => navigate('/how-to-apply')} className='nav-link w-nav-link'>
							HOW TO APPLY
						</a>
						<a onClick={() => navigate('/find-your-term')} className='nav-link w-nav-link'>
							FIND YOUR TERM
						</a>
						<a onClick={() => navigate('/find-a-program')} className='nav-link w-nav-link'>
							FIND A PROGRAM
						</a>
						<a onClick={() => this.setState({ contact_us_modal: true })} className='nav-link w-nav-link'>
							CONTACT US
						</a>
					</nav>
					<div className='menu-button w-nav-button'>
						<div className='icon-2 w-icon-nav-menu' onClick={this.mobileSidebarToggle} />
					</div>
				</div>
			</div>
		);
	};
}

export default withRouter(Header);
