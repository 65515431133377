import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';

import MobileNavbarLinks from './MobileNavbarLinks';
import _svg from 'components/modules/svg';

class Header extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sidebarExists: false
		};
	}

	mobileSidebarToggle = e => {
		const { sidebarExists } = this.state;

		if (!sidebarExists) this.setState({ sidebarExists: true });

		e.preventDefault();

		if (document.documentElement.classList.contains('nav-open')) {
			document.documentElement.classList.toggle('nav-open'); //Toggle and remove all bodyClicks
			document.querySelectorAll('#bodyClick').forEach(e => e.remove()); //REMOVE ALL bodyClick elements
		} else {
			document.documentElement.classList.toggle('nav-open'); //Toggle and add bodyClick element

			let node = document.createElement('div');
			node.id = 'bodyClick';
			node.onclick = () => {
				if (this.parentElement) this.parentElement.removeChild(this);
				document.querySelectorAll('#bodyClick').forEach(e => e.remove()); //REMOVE ALL bodyClick elements
				document.documentElement.classList.toggle('nav-open');
			};

			document.body.appendChild(node);
		}
	};

	render() {
		return (
			<Navbar fluid>
				<Navbar.Header>
					<img className='navBarLogo' src={_svg.UWS_white} alt='UWs Logo' />
					<Navbar.Toggle onClick={this.mobileSidebarToggle} />
				</Navbar.Header>
				<Navbar.Collapse>
					<MobileNavbarLinks />
				</Navbar.Collapse>
			</Navbar>
		);
	}
}

export default Header;
