import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, FormControl, Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import withRouter from 'components/Wrappers/withRouter';
import { cloneDeep } from 'components/modules/_misc';
import { FIND_CAMPUS_UNDERGRAD_TERMS } from 'components/modules/term';
import { date_reached, months_passed, formatDateTime, sort_by_date } from 'components/modules/date';
import { IS } from 'components/modules/programs';
import { FIND_TERM } from 'components/modules/term';
import { NoSidebarCard } from 'components/Card/NoSidebarCard';
import Button from 'components/CustomButton';
import { UserActions } from 'reducers/user';

class SelectSemester extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dual_enrollment_modal: false,
			dual_enrollment_no: false
		};

		this.terms = {
			open: [],
			future: [],
			past: []
		};

		this.setTerms();
	}

	setTerms = () => {
		const { app, apps, app_id, creating_additional_app, updateApp, terms } = this.props,
			{ field_app_type_special, field_campuses } = app.area_of_study.selected_primary_1,
			initial_information = cloneDeep(app.initial_information);

		this.term_type =
			IS.flex(app.area_of_study.selected_primary_1, false) ||
			(typeof field_app_type_special !== 'undefined' &&
				(field_campuses.includes('108908') || field_campuses.includes('108913')))
				? 'Flex Term'
				: 'Standard Term';

		if (app.application_modifier.includes('_S')) {
			this.setSpecialTerms();
		} else if (['GR_D', 'GR_I'].includes(app.application_modifier)) {
			this.setGradTerms();
		} else {
			this.terms = {
				open: this.getUnderGraduateTerms('AVAILABLE'),
				future: this.getUnderGraduateTerms('OPENING'),
				past: this.getUnderGraduateTerms('CLOSED')
			};

			if (creating_additional_app) {
				const _app = apps[app_id],
					term = FIND_TERM.by_app(_app.json_obj, terms),
					open_term = this.terms.open.find(opt => opt.field_global_term === term.field_global_term);

				if (open_term) {
					initial_information.chosen_semester = { id: open_term.id, title: open_term.title };
					updateApp({ initial_information: initial_information });
				} else {
					this.terms.open = [];
				}
			}
		}
	};

	setSpecialTerms = () => {
		let { terms, app } = this.props,
			{ id: program_id } = app.area_of_study.selected_primary_1;

		terms.special
			.filter(x => x.field_master_term_type === this.term_type)
			.forEach(term => {
				if (term.field_campuses.includes(app.initial_information.chosen_campus.id)) {
					if (term.field_programs_special.includes(program_id) || term.field_all_programs !== '0') {
						if (!date_reached(term.field_opens)) {
							if (months_passed(term.field_opens) > -3) {
								term.open_date = term.field_opens;
								term.close_date = term.field_closes;
								this.terms.future.push(term);
							}

							return;
						}

						if (date_reached(term.field_closes)) {
							if (months_passed(term.field_closes) < 3) {
								term.open_date = term.field_opens;
								term.close_date = term.field_closes;
								this.terms.past.push(term);
							}

							return;
						}

						this.terms.open.push(term);
					}
				}
			});
	};

	setGradTerms = () => {
		let { terms, app } = this.props,
			{ id: program_id } = app.area_of_study.selected_primary_1,
			international = app.application_modifier.split('_')[1] === 'I';

		terms.grad
			.filter(x => x.field_master_term_type === this.term_type)
			.forEach(term => {
				const open_date =
						international && !!term.field_international_opens
							? term.field_international_opens
							: term.field_opens,
					close_date =
						international && !!term.field_international_closes
							? term.field_international_closes
							: term.field_closes;

				if (!term.field_campuses.includes(app.initial_information.chosen_campus.id)) return; //Term DOESNT match campus

				if (!term.field_cdr_program.includes(program_id) && term.field_all_programs === '0') return; //Term DOESNT match program

				if (!date_reached(open_date)) {
					if (months_passed(open_date) > -3) {
						term.open_date = open_date;
						term.close_date = close_date;

						this.terms.future.push(term);
					}

					return;
				}

				if (date_reached(close_date)) {
					if (months_passed(close_date) < 3) {
						term.open_date = open_date;
						term.close_date = close_date;

						this.terms.past.push(term);
					}

					return;
				}

				this.terms.open.push(term);
			});
	};

	getUnderGraduateTerms(which) {
		const { app, terms } = this.props,
			international = app.application_modifier.split('_')[1] === 'I',
			_matches_domestic_status = t =>
				(international && t.field_type === 'International') || (!international && t.field_type === 'Domestic');

		let returnedTerms = [];

		//NO APP TYPE, RETURN NONE
		if (!app.application_modifier) return returnedTerms;

		let app_type_name = '';
		if (app.application_modifier.includes('FM_')) {
			app_type_name = 'freshman';
		} else if (app.application_modifier.includes('TR_')) {
			app_type_name = 'transfer';
		} else if (app.application_modifier.includes('RE_') || app.application_modifier.includes('RET_')) {
			app_type_name = 're_entry';
		}

		//Look through defaults and see if any campus has override
		terms.default_undergrad
			.filter(x => x.field_master_term_type === this.term_type && !x.title.includes('Direct Admit'))
			.forEach(defaultTerm => {
				let unverified_term,
					term_overwritten = false;

				//Look through this term and find object related to international/domestic
				let app_type_terms = defaultTerm['field_' + app_type_name];
				if (app_type_terms.some(_matches_domestic_status)) unverified_term = defaultTerm;

				if (!unverified_term) return;

				//Check for campus overwrites
				FIND_CAMPUS_UNDERGRAD_TERMS.by_app(app, terms)
					.filter(
						t =>
							t.field_master_term_type === this.term_type &&
							t.field_global_term === unverified_term.field_global_term &&
							t.field_campuses.includes(app.initial_information.chosen_campus.id)
					)
					.forEach(campusTerm => {
						app_type_terms = campusTerm['field_' + app_type_name + '_campus'];

						if (app_type_terms.some(_matches_domestic_status)) {
							unverified_term = campusTerm;
							term_overwritten = true;
						}
					});

				let return_term,
					final_app_type_terms = term_overwritten
						? unverified_term[`field_${app_type_name}_campus`]
						: unverified_term[`field_${app_type_name}`];

				final_app_type_terms.filter(_matches_domestic_status).forEach(obj => {
					unverified_term.open_date = obj.field_opens;
					unverified_term.close_date = obj.field_closes;

					if (obj.field_opens === obj.field_closes && obj.field_closes === obj.field_pay) {
						unverified_term = null;
					}

					if (!date_reached(obj.field_opens)) {
						if (which === 'OPENING' && months_passed(obj.field_opens) > -3) return_term = unverified_term;
						return;
					}

					if (date_reached(obj.field_closes)) {
						if (which === 'CLOSED' && months_passed(obj.field_closes) < 3) return_term = unverified_term;
						return;
					}

					if (which === 'AVAILABLE') return_term = unverified_term;
				});

				if (return_term) returnedTerms.push(return_term);
			});

		return returnedTerms;
	}

	formatTerms(terms, which) {
		let renderedTerms = [];

		if (which === 'OPENING') {
			terms.forEach(term => {
				renderedTerms.push(
					<p key={term.title}>
						{term.title} | Opening: {formatDateTime(term.open_date, true)}
					</p>
				);
			});
		} else if (which === 'CLOSED') {
			terms.forEach(term => {
				renderedTerms.push(
					<p key={term.title}>
						{term.title} | Closed: {formatDateTime(term.close_date, true)}
					</p>
				);
			});
		} else {
			terms.forEach(term => {
				renderedTerms.push(<p key={term.title}>{term.title}</p>);
			});
		}

		if (!terms.length) renderedTerms.push(<p key='no-terms'>No terms found.</p>);

		return renderedTerms;
	}

	onSubmit = e => {
		let { navigate, setScreensCompleted, app } = this.props;

		e.preventDefault();
		e.stopPropagation();

		if (app.application_modifier.includes('HS_')) {
			this.setState({ dual_enrollment_modal: true });
		} else {
			setScreensCompleted({ selectSemesterCompleted: true, summaryCompleted: false });
			navigate('/summary');
		}
	};

	onChange = e => {
		let { app, updateApp } = this.props,
			initial_information = cloneDeep(app.initial_information),
			term = this.terms.open.find(t => t.id === e.target.value);

		if (!app.date_submitted) {
			initial_information.chosen_semester = {
				id: term.id,
				title: term.title,
				global_term: term.field_global_term
			};
			updateApp({ initial_information: initial_information });
		}
	};

	renderModal() {
		let { navigate, app, terms, setScreensCompleted } = this.props,
			{ dual_enrollment_modal, dual_enrollment_no } = this.state,
			term = FIND_TERM.by_app(app, terms),
			str_that_term = term?.title ? `the ${term.title.split('(')[0].trim()}` : 'that';

		return (
			<Modal show={dual_enrollment_modal}>
				<Modal.Header>
					<Modal.Title className='h2'>High School Non-Degree Application</Modal.Title>
				</Modal.Header>
				<Modal.Body className='modalMinHeight'>
					{dual_enrollment_no ? (
						<>
							<p>
								Your application type (high school non-degree) is for students to take a college course
								while they attend high school.
							</p>
							<p>
								If this isn&apos;t you, you should answer yes to the question &quot;Have you graduated
								from high school/secondary school or earned your GED/HSED yet?&quot;
							</p>
						</>
					) : (
						<p>Will you still be attending high school during {str_that_term} term?</p>
					)}
				</Modal.Body>
				<Modal.Footer>
					{dual_enrollment_no ? (
						<Button
							className='tertiaryButton'
							fill
							onClick={() => this.setState({ dual_enrollment_modal: false, dual_enrollment_no: false })}>
							Okay
						</Button>
					) : (
						<>
							<Button
								className='tertiaryButton'
								fill
								onClick={() => this.setState({ dual_enrollment_no: true })}>
								No
							</Button>
							<Button
								bsStyle='info'
								className='save-btn'
								fill
								onClick={() => {
									this.setState({ dual_enrollment_modal: false });
									setScreensCompleted({ selectSemesterCompleted: true, summaryCompleted: false });
									navigate('/summary');
								}}>
								Yes
							</Button>
						</>
					)}
				</Modal.Footer>
			</Modal>
		);
	}

	render() {
		let { app, navigate, bypass_app_type, removeBypassObject, creating_additional_app } = this.props,
			{ chosen_semester, chosen_campus } = app.initial_information,
			show_eau_msg = chosen_campus?.title === 'UW-Eau Claire' && app.application_modifier?.includes('GR'),
			campusPage;

		campusPage = (
			<>
				<div className='col-md-12'>
					<FormGroup className='col-md-6 col-md-offset-3'>
						<FormControl
							className={creating_additional_app && ' disabledCampusCard'}
							autoFocus={true}
							aria-label='Select semester'
							disabled={creating_additional_app}
							componentClass='select'
							bsclass='form-control'
							name='chosen_semester_id'
							onChange={this.onChange}
							value={chosen_semester.id}>
							<option key='defaultblank' value=''>
								Select Term
							</option>

							{this.terms.open
								.sort((t1, t2) =>
									sort_by_date(t1?.close_date || t1?.field_closes, t2?.close_date || t2?.field_closes)
								)
								.map(term => {
									return (
										<option key={term.id} value={term.id}>
											{term.title}
										</option>
									);
								})}
						</FormControl>

						<div className='col-md-8 col-md-offset-2 terms-i-btn'>
							<a id='tooltip'>
								I can&apos;t find my term
								<img
									className='informationTooltip'
									src={require('assets/img/Information.png')}
									alt='Information'
								/>
							</a>

							<Tooltip
								anchorId='tooltip'
								className='tooltipContainer'
								delayHide={1000}
								effect='solid'
								content={
									<>
										<p>Terms opening soon:</p>
										{this.formatTerms(this.terms.future, 'OPENING')}
										<p>Terms recently closed:</p>
										{this.formatTerms(this.terms.past, 'CLOSED')}
									</>
								}
							/>
						</div>
					</FormGroup>
				</div>

				{show_eau_msg && (
					<p className='eau-msg'>
						To apply to a graduate program at UW-Eau Claire for spring 2025 or later, please{' '}
						<a href='https://graduate.uwec.edu/apply/' target='_blank'>
							complete this application
						</a>
						.
					</p>
				)}
			</>
		);

		if (!this.terms.open.length) {
			campusPage = (
				<>
					<div className='col-md-8 col-md-offset-2'>
						<a id='tooltip'>
							I can&apos;t find my term
							<img
								className='informationTooltip'
								src={require('assets/img/Information.png')}
								alt='Information'
							/>
						</a>
						<Tooltip
							anchorId='tooltip'
							className='tooltipContainer'
							delayHide={1000}
							effect='solid'
							content={
								<>
									<p>Terms opening soon:</p>
									{this.formatTerms(this.terms.future, 'OPENING')}
									<p>Terms recently closed:</p>
									{this.formatTerms(this.terms.past, 'CLOSED')}
								</>
							}
						/>
					</div>

					<div className='col-md-12'>
						{creating_additional_app ? 'That term is not available.' : 'There are no terms available.'}
					</div>
				</>
			);
		}

		return (
			<NoSidebarCard
				toAccount={() => navigate('/my-account')}
				fullWidth={true}
				header='When would you like to start school?'
				content={
					<form id='form' className='text-center' onSubmit={this.onSubmit}>
						{this.renderModal()}
						{campusPage}

						<div className='clearfix' />
					</form>
				}
				buttons={
					<>
						<Button bsStyle='info' className='back-btn' fill onClick={() => navigate('/course-of-study')}>
							Back
						</Button>
						<Button
							bsStyle='info'
							className='tertiaryButton'
							fill
							onClick={() => {
								if (bypass_app_type) {
									confirmAlert({
										title: 'Drop Term Bypass?',
										message: 'This will forgo the term bypass link you were provided.  Continue?',
										buttons: [
											{
												label: 'Yes',
												onClick: () => {
													removeBypassObject();
													navigate('/my-account');
												}
											},
											{
												label: 'No'
											}
										]
									});
								} else {
									navigate('/my-account');
								}
							}}>
							{bypass_app_type ? 'Exit' : 'My Account'}
						</Button>
						<Button
							form='form'
							bsStyle='info'
							className='save-btn'
							fill
							type='submit'
							disabled={!this.terms.open.length || !chosen_semester.id}>
							Save and Continue
						</Button>
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => {
		return {
			apps: state.user.apps,
			app_id: state.user.app_id,
			app: state.user.temp_app_rec.json_obj,
			terms: state.global.terms,
			bypass_app_type: state.user.obj_bypass.field_application_type,
			creating_additional_app: state.user.creating_additional_app
		};
	},
	mapDispatchToProps = dispatch => ({
		updateApp: obj => dispatch(UserActions.updateApp(obj)),
		setScreensCompleted: arg => dispatch(UserActions.setScreensCompleted(arg)),
		removeBypassObject: () => dispatch(UserActions.applyBypassObject())
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectSemester));
