import React, { Component } from 'react';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import _ from 'underscore';

import withRouter from 'components/Wrappers/withRouter';
import CheckboxList from 'components/controls/checkbox_list';
import { cloneDeep } from 'components/modules/_misc';
import { blockByRegion } from 'components/modules/campus';
import { parseAddressFromGoogleResponse } from 'components/modules/address';
import { DEFAULT, SUMMARIZE_INIT_INFO, cloneApp } from 'components/modules/app';
import { DIRECT_ADMIT_CAMPUS_TIERS } from 'components/modules/campus';
import { months_passed } from 'components/modules/date';
import { nextAppPage } from 'components/modules/nav';
import { legal_genders } from 'components/modules/opts';
import { FIND_TERM, FIND_FILTERED_TERMS, getDirectAdmitTerm, is_app_open } from 'components/modules/term';
import { NoSidebarCard } from 'components/Card/NoSidebarCard';
import Button from 'components/CustomButton';
import { GlobalActions } from 'reducers/global';
import { UserActions } from 'reducers/user';
import API from 'components/api';

class Summary extends Component {
	constructor(props) {
		super(props);

		const { apps } = props;

		this.state = {
			ask_clone_from_recent: false,
			save_init_info: false,
			working: false,
			ask_direct_admit_prefill: false,
			direct_admit_autofills: ['dob', 'grad_year', 'legal_sex'],
			verified_addr: {},
			creating_direct_admit: false
		};

		this.block_reason = this.blockCreateApp();
		this.cloneable_app_id = Object.keys(apps)
			.sort((a, b) => {
				// find the most recently updated app to clone
				if (a.date_last_updated < b.date_last_updated) return 1;
				if (a.date_last_updated > b.date_last_updated) return -1;

				return 0;
			})
			.find(_app_id => apps[_app_id].app_type === 'DA' || (!!apps[_app_id].campus && !!apps[_app_id].app_type));
	}

	componentDidMount() {
		this._mounted = true;

		const { app, bypass_term, terms, updateApp, setScreensCompleted, direct_admit } = this.props,
			{ street, city, state, zip } = direct_admit;

		setScreensCompleted({ summaryCompleted: true });

		if (bypass_term?.title) {
			let initial_information = cloneDeep(app.initial_information),
				term = FIND_FILTERED_TERMS.by_app(app, terms).find(
					t =>
						t.field_global_term === bypass_term.id &&
						t.field_campuses?.includes(app.initial_information.chosen_campus.id)
				);

			if (term) {
				initial_information.chosen_semester = {
					id: term.id,
					title: term.title,
					global_term: term.field_global_term
				};
				updateApp({ initial_information: initial_information });
			}
		}

		if (street && city && state && zip) {
			const str = [street, city, state, zip, 'USA'].join(' ');

			this.setState({ working: true });

			this.getAPI(true)
				.getAllValidAddresses(str)
				.then(resp => {
					const result = resp.results.find(rec => rec.address_components.length > 5);

					if (result) {
						this.setState({
							direct_admit_autofills: ['dob', 'grad_year', 'legal_sex', 'addr'],
							verified_addr: {
								...parseAddressFromGoogleResponse(result, {}),
								country: 'USA'
							}
						});
					}

					this.setState({ working: false });
				});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const {
				app,
				app_id,
				navigate,
				creating_additional_app,
				removeBypassObject,
				use_temp_app_rec,
				bypass_code,
				captureError
			} = this.props,
			{ ask_direct_admit_prefill, creating_direct_admit } = this.state;

		if (this._mounted) {
			if (app_id && !prevProps.app_id) {
				removeBypassObject();

				if (bypass_code)
					this.getAPI()
						.redeemBypassCode(bypass_code)
						.catch(ex => captureError(ex));

				navigate(creating_additional_app ? '/submit' : '/personal-information');
			}

			if (!use_temp_app_rec && prevProps.use_temp_app_rec) this.returnToApp();

			if (ask_direct_admit_prefill && !prevState.ask_direct_admit_prefill) {
				this.setState({ ask_clone_from_recent: false });
			}

			if (app.application_modifier === 'DA') {
				const change_made =
					!_.isEqual(app.personal_information, prevProps.app.personal_information) ||
					!_.isEqual(app.contact_information, prevProps.app.contact_information) ||
					!_.isEqual(app.academic_background, prevProps.app.academic_background);

				if (change_made || (creating_direct_admit && !prevState.creating_direct_admit)) {
					if (ask_direct_admit_prefill) {
						const bypass_date = bypass_code ? new Date().toString() : '';
						this.createApp('', bypass_date);
					} else {
						this.setState({ ask_direct_admit_prefill: true });
					}
				}
			}

			if (
				this.block_reason === 'claim-DA' &&
				!_.isEqual(app.initial_information, prevProps.app.initial_information)
			) {
				navigate('/direct-admit/select-campus');
			}
		}
	}

	componentWillUnmount() {
		const { saveApp } = this.props,
			{ save_init_info } = this.state;

		this._mounted = false;

		if (save_init_info) saveApp();
	}

	getAPI = google => {
		const { support_student_id, campus_admin } = this.props;
		return new API(google ? 'https://maps.googleapis.com/maps/api/geocode/' : '', support_student_id, campus_admin);
	};

	blockCreateApp = () => {
		const { app, apps, app_id, terms, campuses, direct_admit } = this.props,
			{ application_modifier: app_type } = app,
			{ chosen_campus, chosen_semester } = app.initial_information,
			{ tier, graduation_year, id: da_id } = direct_admit;

		if (app.application_modifier === 'DA') return ''; // tms, this block is being done on the campus selection page

		const term = FIND_TERM.by_app(app, terms),
			parent_campus_id = campus_id => campuses.find(c => c.id === campus_id).field_parent_campus || campus_id,
			match_exists = (_app_id, match_on_region) => {
				let { json_obj: _json_obj } = apps[_app_id],
					{ application_modifier: _app_type } = _json_obj,
					{ chosen_campus: _chosen_campus, direct_admit: _direct_admit } = _json_obj.initial_information,
					_term = FIND_TERM.by_app(_json_obj, terms),
					_campuses = [];

				if (!term || !_term || _app_id === app_id) return false;

				if (_chosen_campus?.id) {
					_campuses.push(_chosen_campus);
				} else if (Object.keys(_direct_admit).length) {
					_campuses = Object.keys(_direct_admit)
						.filter(code => !(chosen_campus.field_abbreviation === 'PLT' && code === 'BRB'))
						.map(code => campuses.find(c => c.field_abbreviation === code));
				}

				let campus_match = _campuses.some(_c =>
						match_on_region && !_c.title.includes('Flexible')
							? parent_campus_id(_c.id) === parent_campus_id(chosen_campus.id)
							: _c.id === chosen_campus.id
					),
					term_match =
						term.field_global_term === _term.field_global_term ||
						term.title.includes(_term.title.replace('Direct Admit', 'Fall')),
					app_type_match = app_type === _app_type;

				if (app_type_match) {
					if (app_type.includes('GR_') || app_type.includes('_S')) {
						app_type_match =
							app.area_of_study.selected_primary_1.id === _json_obj.area_of_study.selected_primary_1.id;
					}
				} else {
					app_type_match =
						app_type.replace('DA', 'FM_D').replace('FM', 'TR') ===
						_app_type.replace('DA', 'FM_D').replace('FM', 'TR');
				}

				return campus_match && term_match && app_type_match;
			};

		if (Object.keys(apps).find(_app_id => match_exists(_app_id, false))) return 'campus-match';

		if (
			blockByRegion(parent_campus_id(chosen_campus.id)) &&
			Object.keys(apps).find(_app_id => match_exists(_app_id, true))
		) {
			return 'region-match';
		}

		if (da_id) {
			const applicant_tier = Number(tier.substr(1)),
				campus_tier =
					DIRECT_ADMIT_CAMPUS_TIERS.findIndex(arr => arr.includes(chosen_campus.field_abbreviation)) + 1,
				tier_met = !!campus_tier && applicant_tier >= campus_tier;

			if (
				tier_met &&
				app.application_modifier?.includes('FM') &&
				chosen_semester.title.includes(`Fall ${graduation_year}`) &&
				getDirectAdmitTerm(terms, direct_admit)
			) {
				return 'claim-DA';
			}
		}

		return '';
	};

	getEssayRequirement = () => {
		let { campus_essay_prompts, app } = this.props;

		if (['FM_D', 'FM_I', 'TR_D', 'TR_I'].includes(app.application_modifier)) {
			let type_for_prompt = `${app.application_modifier}`.toLowerCase();
			type_for_prompt = type_for_prompt.replace('re_', 'tr_').replace('ret_', 'tr_');

			const udsPrompt = campus_essay_prompts.find(
					rec =>
						rec.field_campuses.includes(app.initial_information.chosen_campus.id) &&
						rec.field_global_essay_prompt_value === `undergrad-${type_for_prompt}`
				),
				{ field_essay_display: _req } = udsPrompt || {};

			return `${_req}`;
		}

		return 'required';
	};

	createApp = (cloned_from_id, term_bypassed) => {
		let { createApp } = this.props,
			essay_requirement = this.getEssayRequirement();

		this.setState({ working: true });
		createApp(cloned_from_id, term_bypassed, essay_requirement);
	};

	onSubmitDirectAdmit = () => {
		let { app, bypass_code, direct_admit, updateApp } = this.props,
			{ ask_direct_admit_prefill, direct_admit_autofills, verified_addr } = this.state,
			{ high_school, graduation_year, birthdate, legal_sex } = direct_admit,
			academic_background = cloneDeep(app.academic_background),
			bypass_date = bypass_code ? new Date().toString() : '';

		if (ask_direct_admit_prefill) {
			if (high_school.length) {
				academic_background.highschools = [cloneDeep(DEFAULT.HIGH_SCHOOL)];
				academic_background.highschools[0].selected_highschool = high_school[0];
			}

			if (direct_admit_autofills.length || high_school.length) {
				let personal_information = cloneDeep(app.personal_information),
					contact_information = cloneDeep(app.contact_information),
					dob_parts = birthdate.split('-');

				if (direct_admit_autofills.includes('dob')) {
					personal_information.date_of_birth = `${dob_parts[1]}/${dob_parts[2]}/${dob_parts[0]}`;
				}

				if (direct_admit_autofills.includes('grad_year') && high_school.length) {
					const grad_month = `06/${graduation_year}`;
					academic_background.highschools[0].graduation_date = grad_month;

					if (months_passed(grad_month) > 0) {
						academic_background.highschools[0].currently_attend = 'No';
						academic_background.highschools[0].did_graduate = 'Yes';
					} else if (months_passed(grad_month) < 0) {
						academic_background.highschools[0].currently_attend = 'Yes';
					}
				}

				if (direct_admit_autofills.includes('legal_sex')) personal_information.gender = legal_sex;
				if (direct_admit_autofills.includes('addr')) contact_information.permanent_address = verified_addr;

				const change_made =
					!_.isEqual(academic_background, app.academic_background) ||
					!_.isEqual(contact_information, app.contact_information) ||
					!_.isEqual(personal_information, app.personal_information);

				if (change_made) {
					updateApp({
						academic_background: academic_background,
						contact_information: contact_information,
						personal_information: personal_information
					});
				} else {
					this.setState({ creating_direct_admit: true });
				}
			} else {
				this.createApp('', bypass_date);
			}
		} else {
			this.setState({ ask_direct_admit_prefill: true });
		}
	};

	onSubmit = e => {
		let { app, app_id, creating_additional_app, bypass_code, setUseTempAppRec } = this.props,
			{ ask_clone_from_recent, ask_direct_admit_prefill } = this.state,
			bypass_date = bypass_code ? new Date().toString() : '';

		if (ask_direct_admit_prefill) {
			this.onSubmitDirectAdmit();
		} else if (creating_additional_app) {
			this.createApp('', bypass_date);
		} else if (app_id) {
			this.setState({ save_init_info: true });
			setUseTempAppRec(false);
		} else if (ask_clone_from_recent || !this.cloneable_app_id) {
			if (app.application_modifier === 'DA') {
				this.onSubmitDirectAdmit();
			} else {
				this.createApp('', bypass_date);
			}
		} else {
			this.setState({ ask_clone_from_recent: true });
		}
	};

	onClaimOffer = () => {
		let { app, apps, terms, updateApp, setUseTempAppRec, setAppId } = this.props,
			{ chosen_campus } = app.initial_information,
			wip_app_id = Object.keys(apps).find(_app_id => {
				let { json_obj, app_type, date_submitted, term } = apps[_app_id],
					current_term = app.initial_information?.chosen_semester?.title || '';

				return (
					app_type === 'DA' &&
					!date_submitted &&
					current_term.replace('Fall', 'Direct Admit').includes(term) &&
					is_app_open(json_obj, terms)
				);
			}),
			_json = wip_app_id ? apps[wip_app_id].json_obj : DEFAULT.APP_JSON,
			init_info = cloneDeep(_json.initial_information);

		init_info.direct_admit = {
			[chosen_campus.field_abbreviation]: app.area_of_study
		};

		updateApp({ initial_information: init_info });

		if (wip_app_id) {
			setAppId(wip_app_id, terms);
			setUseTempAppRec(true, true);
		}
	};

	returnToApp = () => {
		let { app_id, navigate, location, app, apps, setScreensCompleted, updateApp } = this.props,
			existing_json = apps[app_id].json_obj,
			screensCompleted = { ...app.screensCompleted },
			unsetEssayCompleted =
				app.application_modifier !== 'DA' &&
				(app.initial_information.chosen_campus.id !== existing_json.initial_information.chosen_campus.id ||
					(!app.application_modifier.indexOf('GR') &&
						app.area_of_study.selected_primary_1.id !== existing_json.area_of_study.selected_primary_1.id));

		if (app.application_modifier !== existing_json.application_modifier) {
			screensCompleted = {
				...DEFAULT.APP_JSON.screensCompleted,
				initialInformationCompleted: true,
				selectCampusCompleted: true,
				selectSemesterCompleted: true,
				selectAreaStudyCompleted: true,
				summaryCompleted: true
			};
		} else if (unsetEssayCompleted) {
			screensCompleted.essayCompleted = false;
		}

		app.essay.requirement = this.getEssayRequirement();

		setScreensCompleted(screensCompleted);
		updateApp({
			application_modifier: `${app.application_modifier}`,
			percentage_complete: cloneDeep(app.percentage_complete),
			initial_information: cloneDeep(app.initial_information),
			area_of_study: cloneDeep(app.area_of_study),
			submit: cloneDeep(DEFAULT.APP_JSON.submit),
			essay: cloneDeep(app.essay)
		});

		navigate(nextAppPage({ ...app, screensCompleted: screensCompleted }, location.pathname));
	};

	cloneFromRecent = e => {
		const { bypass_code, apps, updateApp } = this.props,
			app = cloneDeep(this.props.app),
			bypass_date = bypass_code ? new Date().toString() : '';

		if (app.application_modifier === 'DA') {
			cloneApp(app, apps[this.cloneable_app_id].json_obj);
			updateApp(app);
		} else {
			this.createApp(this.cloneable_app_id, bypass_date);
		}
	};

	onBack = e => {
		let { app, bypass_term, navigate } = this.props,
			{ ask_clone_from_recent, ask_direct_admit_prefill } = this.state;

		if (ask_clone_from_recent) {
			this.setState({ ask_clone_from_recent: false });
		} else if (ask_direct_admit_prefill) {
			this.setState({ ask_direct_admit_prefill: false });
		} else {
			navigate(bypass_term || app.application_modifier === 'DA' ? '/course-of-study' : '/select-semester');
		}
	};

	render() {
		const {
				app,
				app_id,
				apps,
				creating_additional_app,
				removeBypassObject,
				bypass_code,
				navigate,
				terms,
				majors,
				campuses,
				direct_admit,
				first_name
			} = this.props,
			{ ask_clone_from_recent, ask_direct_admit_prefill, working, direct_admit_autofills, verified_addr } =
				this.state;

		let summary_page,
			header = '';

		if (ask_clone_from_recent) {
			const _copy = [
				apps[this.cloneable_app_id].app_type === 'DA' ? 'Direct Admit form' : 'application',
				app.application_modifier === 'DA' ? 'Direct Admit form' : 'new application'
			];

			header = 'COPY FROM RECENT';

			summary_page = (
				<div className={'col-md-10 col-centered text-center'}>
					<p>
						Would you like to copy your information from your most recent {_copy[0]} into this {_copy[1]}?
						If you choose to copy your information please be sure to review the data that has copied over.
					</p>

					<div className={'summaryButtons'}>
						<Button bsStyle='info' className='back-btn' fill onClick={this.onSubmit} disabled={working}>
							No, I want to start over
						</Button>
						<Button
							bsStyle='info'
							className='save-btn'
							fill
							onClick={this.cloneFromRecent}
							disabled={working}>
							Yes, use previous information
						</Button>
					</div>
				</div>
			);
		} else if (ask_direct_admit_prefill) {
			let { graduation_year, birthdate, legal_sex, high_school } = direct_admit,
				date_components = birthdate.split('-'),
				opts = [
					{
						val: 'dob',
						label: (
							<>
								<strong>Date of Birth</strong>:&emsp;&emsp;
								{`${date_components[1]}/${date_components[2]}/${date_components[0]}`}
							</>
						)
					},
					{
						val: 'grad_year',
						label: (
							<>
								<strong>High School</strong>:&emsp;&emsp;
								{`${high_school[0].title} (June ${graduation_year} graduation date)`}
							</>
						)
					},
					{
						val: 'legal_sex',
						label: (
							<>
								<strong>Legal Sex</strong>:&emsp;&emsp;
								{legal_genders.find(opt => opt.val === legal_sex).label}
							</>
						)
					}
				];

			if (verified_addr.formatted_address) {
				opts.push({
					val: 'addr',
					label: (
						<>
							<strong>Address</strong>:&emsp;&emsp;{verified_addr.formatted_address}
						</>
					)
				});
			}

			header = `Thank you ${first_name} for expressing interest in your Universities of Wisconsin Direct Admit admission offers!`;

			summary_page = (
				<div className={'col-md-10 col-centered text-center'}>
					<p>
						We have received the following information from your high school which we can autofill into your
						account. Deselect items you would not like autofilled.
					</p>

					<CheckboxList
						values={direct_admit_autofills}
						label=''
						opts={opts}
						onChange={arr => this.setState({ direct_admit_autofills: arr })}
						name='direct_admit_autofills'
						classVals={['direct-admit-autofills']}
						cols={12}
					/>
				</div>
			);
		} else {
			header = 'REVIEW YOUR CHOICES';

			if (this.block_reason) {
				const msg = {
					'campus-match': <p>Your campus / term / app type exists in another application.</p>,
					'region-match': (
						<p>
							This university only allows one application per term. If you wish to change your campus
							location for this region please contact the appropriate Admission Office.
						</p>
					),
					'claim-DA': (
						<>
							<p>You already have that Direct Admit offer!</p>
							<p>Claiming the offer is faster, and you won't have to wait for an admissions decision.</p>
						</>
					)
				};

				if (this.block_reason === 'claim-DA') header = 'Good News!';

				summary_page = (
					<div className='col-md-12 col-centered text-center block-create'>{msg[this.block_reason]}</div>
				);
			} else if (app.application_modifier === 'DA') {
				summary_page = SUMMARIZE_INIT_INFO.direct_admit(app, majors, campuses);
			} else {
				summary_page = SUMMARIZE_INIT_INFO.standard(app, terms, majors);
			}
		}

		let saveButton = creating_additional_app ? (
				<Button
					bsStyle='info'
					className='save-btn'
					fill
					onClick={() => this.createApp(app_id)}
					disabled={working}>
					Continue To Submit
				</Button>
			) : (
				<Button bsStyle='info' className='save-btn' fill onClick={this.onSubmit} disabled={working}>
					{ask_direct_admit_prefill ? 'Get Started' : 'Next'}
				</Button>
			),
			myAccountButton = (
				<Button
					bsStyle='info'
					className='tertiaryButton'
					fill
					onClick={() => {
						if (bypass_code) {
							confirmAlert({
								title: 'Drop Term Bypass?',
								message: 'This will forgo the term bypass link you were provided.  Continue?',
								buttons: [
									{
										label: 'Yes',
										onClick: () => {
											removeBypassObject();
											navigate('/my-account');
										}
									},
									{
										label: 'No'
									}
								]
							});
						} else {
							navigate('/my-account');
						}
					}}>
					{bypass_code ? 'Exit' : 'My Account'}
				</Button>
			);

		return (
			<NoSidebarCard
				toAccount={() => navigate('/my-account')}
				header={header}
				content={
					<form>
						{summary_page}
						<div className='clearfix' />
					</form>
				}
				buttons={
					<>
						{!app.date_submitted && (
							<Button bsStyle='info' className='back-btn' fill onClick={this.onBack} disabled={working}>
								Back
							</Button>
						)}

						{myAccountButton}

						{this.block_reason === 'claim-DA' ? (
							<Button bsStyle='info' className='save-btn' fill onClick={() => this.onClaimOffer()}>
								Claim Offer
							</Button>
						) : !app.application_modifier ? (
							<p className={'uwRedText'}>Unable to continue without an application type!</p>
						) : (
							!ask_clone_from_recent && !this.block_reason && saveButton
						)}
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => {
		return {
			apps: state.user.apps,
			app: state.user.temp_app_rec.json_obj,
			app_id: state.user.app_id,
			campuses: state.global.campuses,
			majors: state.global.cdrMajors,
			terms: state.global.terms,
			campus_essay_prompts: state.global.essay_prompts.campus_specific,
			first_name: state.user.first_name,
			bypass_term: state.user.obj_bypass.field_specific_term,
			bypass_code: state.user.obj_bypass.coupon_code,
			use_temp_app_rec: state.user.use_temp_app_rec,
			creating_additional_app: state.user.creating_additional_app,
			direct_admit: state.user.direct_admit,
			campus_admin: state.user.campus_admin,
			support_student_id: state.user.support_student_id
		};
	},
	mapDispatchToProps = dispatch => ({
		createApp: (cloned_from_id, term_bypassed, essay_requirement) =>
			dispatch(UserActions.createApp(cloned_from_id, term_bypassed, essay_requirement)),
		setScreensCompleted: obj => dispatch(UserActions.setScreensCompleted(obj)),
		removeBypassObject: () => dispatch(UserActions.applyBypassObject()),
		setUseTempAppRec: (use_temp_app, unset_addtl) =>
			dispatch(UserActions.setUseTempAppRec(use_temp_app, unset_addtl)),
		updateApp: obj => dispatch(UserActions.updateApp(obj)),
		setAppId: (id, terms) => dispatch(UserActions.setAppId(id, terms)),
		saveApp: () => dispatch(UserActions.saveApp({})),
		captureError: err => dispatch(GlobalActions.captureError(err))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Summary));
