import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import escapeHtml from 'escape-html';
import { Text } from 'slate';

import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import RichTextEditor from 'components/RichTextEditor/RichText';
import { cloneDeep } from 'components/modules/_misc';
import { internalTransfer } from 'components/modules/app';
import { nextAppPage, prevAppPage } from 'components/modules/nav';
import { formatDateTime } from 'components/modules/date';
import { UserActions } from 'reducers/user';

const serialize = node => {
		if (Text.isText(node)) {
			if (node.italic) return `<i>${escapeHtml(node.text || '')}</i>`;
			if (node.bold) return `<b>${escapeHtml(node.text || '')}</b>`;
			if (node.underline) return `<u>${escapeHtml(node.text || '')}</u>`;

			return escapeHtml(node.text || '');
		}

		const children = (node.children || []).map(n => serialize(n)).join('');

		switch (node.type) {
			case 'quote':
				return `<blockquote><p>${children}</p></blockquote>`;
			case 'paragraph':
				return `<p>${children}</p>`;
			case 'link':
				return `<a href="${escapeHtml(node.url)}">${children}</a>`;
			case 'numbered-list':
				return `<ol>${children}</ol>`;
			case 'bulleted-list':
				return `<ul>${children}</ul>`;
			case 'list-item':
				return `<li>${children}</li>`;
			case 'heading-one':
				return `<h1>${children}</h1>`;
			case 'heading-two':
				return `<h2>${children}</h2>`;
			default:
				return children;
		}
	},
	countString = s => {
		if (s && !!s.length && s.match(/\b[-?(\w+)?]+\b/gi)) {
			s = s.replace(/(^\s*)|(\s*$)/gi, '');
			s = s.replace(/[ ]{2,}/gi, ' ');
			s = s.replace(/\n /, '\n');

			return s.split(' ').length;
		}

		return 0;
	},
	countWords = content => {
		let count = 0;

		content.forEach(value => {
			(value.children || []).forEach(child => {
				if (child.text) {
					count += countString(child.text);
				} else {
					(child.children || []).forEach(grandchild => {
						if (grandchild.text) count += countString(grandchild.text);
					});
				}
			});
		});

		return count;
	};

class Essay extends Component {
	constructor(props) {
		super(props);

		this.state = {
			intervalId: -1,
			essay_error: '',
			essayLastSaved: new Date(),
			save_and_continue: false,
			modal: false
		};

		this.FM_or_TR = props.app.application_modifier.includes('FM') || props.app.application_modifier.includes('TR');
		this.internal_transfer = internalTransfer(props.app);
		this.setPrompts();
	}

	componentDidMount() {
		this._mounted = true;

		const { setSubmenu } = this.props,
			{ intervalId } = this.state;

		setSubmenu();

		clearInterval(intervalId);
		this.setState({ intervalId: setInterval(this.onAutoSave, 30000) });
	}

	componentDidUpdate(prevProps, prevState) {
		const { navigate, app, location } = this.props,
			{ save_and_continue } = this.state;

		if (this._mounted && save_and_continue && !prevState.save_and_continue) {
			navigate(nextAppPage(app, location.pathname));
		}
	}

	componentWillUnmount() {
		this._mounted = false;

		clearInterval(this.state.intervalId);
		this.props.saveApp(!this.msgEssayError());
	}

	findCampusPrompts = () => {
		let { app, campus_essay_prompts } = this.props,
			{ id: campus_id } = app.initial_information.chosen_campus,
			{ id: prog_id } = app.area_of_study?.selected_primary_1 || {},
			campus_prompts = campus_essay_prompts.filter(
				rec =>
					rec.field_campuses.includes(campus_id) &&
					rec.field_global_essay_prompt_value === this.global_prompt.value
			),
			prompts = [];

		if (prog_id && !['-1', '0'].includes(prog_id)) {
			prompts = campus_prompts.filter(p => p.field_cdr_program.includes(prog_id));
		}

		if (!prompts.length) prompts = campus_prompts.filter(p => !p.field_cdr_program.length);

		return prompts;
	};

	setPrompts = () => {
		let { app, global_essay_prompts } = this.props,
			{ id } = app.initial_information.chosen_campus,
			{ field_abbreviation: campus_code } = app.initial_information.chosen_campus,
			additional_prompts = {
				MSN: {
					field_prompt:
						'Tell us why you would like to attend the University of Wisconsin–Madison. In addition, please include why you are interested in studying the major(s) you have selected. If you selected undecided, please describe your areas of possible academic interest.',
					field_things_to_include: [
						'Read the question/prompts closely and answer accordingly.',
						"Imagine responding to questions during an in-person interview. Use your own voice, tell your story, and don't ramble."
					]
				},
				LAC: {
					field_prompt: (
						<>
							<p>Please respond to ONE of the following:</p>
							<ul>
								<li>
									Tell us about any circumstances that may have had an impact on your academic
									performance.
								</li>
								<li>
									Tell us why you are interested in attending UW-La Crosse and what aspects of the
									campus are especially important to you.
								</li>
							</ul>
						</>
					)
				}
			};

		if (this.internal_transfer) {
			if (additional_prompts[campus_code]) this.prompt = additional_prompts[campus_code];
		} else {
			this.global_prompt = global_essay_prompts.find(
				rec =>
					!(
						(rec.field_campuses && !rec.field_campuses.includes(id)) ||
						(rec.field_application_types && !rec.field_application_types.includes(app.application_modifier))
					)
			);

			if (this.global_prompt) {
				let prompts = this.findCampusPrompts(),
					campus_prompt = prompts.find(rec => rec.field_essay_position === '1');

				this.prompt = campus_prompt || this.global_prompt;

				campus_prompt = prompts.find(rec => rec.field_essay_position === '2');

				if (campus_prompt) {
					this.prompt_2 = campus_prompt;
				} else if (this.FM_or_TR && additional_prompts[campus_code]) {
					this.prompt_2 = additional_prompts[campus_code];
				}
			}
		}
	};

	onBack = event => {
		const { navigate, app, location } = this.props;
		navigate(prevAppPage(app, location.pathname));
	};

	onAutoSave = () => {
		const { saveApp } = this.props,
			{ intervalId } = this.state;

		if (this._mounted) {
			saveApp(!this.msgEssayError(true));
		} else {
			clearInterval(intervalId);
		}
	};

	onUpdateEssayState = (value, i) => {
		let { app, updateApp } = this.props,
			essay = cloneDeep(app.essay);

		if (!app.date_submitted) {
			essay[i ? 'second_essay_text' : 'essay_text'] = value;
			essay[i ? 'second_essay_html' : 'essay_html'] = value.map(i => serialize(i));

			updateApp({ essay: essay });
			this.setState({ essay_error: '' });
		}
	};

	onSaveEssay = (e, onlySaving) => {
		let { app, setEssayCompleted, navigate, location, saveApp } = this.props,
			valid = !this.msgEssayError();

		setEssayCompleted(valid);

		if (valid) {
			if (!onlySaving) navigate(nextAppPage(app, location.pathname));
		} else {
			saveApp(false);
		}
	};

	msgEssayError = hide_error_msg => {
		let { app } = this.props,
			{ essay } = app,
			essay_error = '';

		if ((essay.requirement || 'required') === 'required') {
			const first_essay_blocked = !countWords(essay.essay_text),
				second_essay_blocked = !!this.prompt_2 && !countWords(essay.second_essay_text);

			if (first_essay_blocked && second_essay_blocked) {
				essay_error = 'You cannot submit blank essays.';
			} else if (first_essay_blocked) {
				essay_error = this.prompt_2 ? 'Your first essay is blank.' : 'You cannot submit a blank essay.';
			} else if (second_essay_blocked) {
				essay_error = 'Your second essay is blank.';
			}
		}

		this.setState({ essay_error: hide_error_msg ? '' : essay_error, essayLastSaved: new Date() });

		return essay_error;
	};

	renderModal = () => {
		const { modal } = this.state;

		return (
			<Modal show={modal}>
				<Modal.Header>
					<Modal.Title className='h2'>Universities of Wisconsin Obligation to Report</Modal.Title>
				</Modal.Header>
				<Modal.Body className={'modalMinHeight'}>
					<p className={'text'}>
						By law, all University of Wisconsin System employees are required to report suspected child
						maltreatment, child abuse and neglect. Information included in your essay pertaining to these
						topics will be reported to local authorities. For more information about mandated reporting,
						please review{' '}
						<a
							target='_blank'
							rel='noopener noreferrer'
							href='http://docs.legis.wisconsin.gov/code/executive_orders/2011_scott_walker/2011-54.pdf'>
							Executive Order 54
						</a>
						.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button bsStyle='info' className='save-btn' fill onClick={() => this.setState({ modal: false })}>
						I Understand
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	renderPrompt = (prompt, i) => {
		if (!prompt) return null;

		const { app } = this.props,
			txt = app.essay[i ? 'second_essay_text' : 'essay_text'],
			word_count = countWords(txt),
			hard_word_count = app.initial_information.chosen_campus.field_abbreviation === 'MSN';

		return (
			<React.Fragment key={i}>
				{!!this.prompt_2 && <h2>{i ? 'Second' : 'First'} Essay</h2>}

				{typeof prompt.field_prompt === 'string' ? (
					<p className='prompt'>{prompt.field_prompt}</p>
				) : (
					<section className='prompt'>{prompt.field_prompt}</section>
				)}

				<p>
					<span
						className='obligation-to-report'
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();

							this.setState({ modal: true });
						}}>
						Universities of Wisconsin Obligation to Report.
					</span>
				</p>

				{!!prompt.field_things_to_include?.length && (
					<section className='essay-tips'>
						<p>Tips for writing your essay</p>

						<ul>
							{prompt.field_things_to_include.map((rowText, index) => (
								<li key={index} className={'transitionLI'}>
									{rowText}
								</li>
							))}
						</ul>
					</section>
				)}

				<div className='col-md-12 rtf-wrapper essayrte'>
					<RichTextEditor
						autoFocus={false}
						updateEssayState={val => this.onUpdateEssayState(val, i)}
						initialValue={txt}
					/>
				</div>
				<p className='word-count'>
					Word Count: <strong>{word_count}</strong>
					{this.FM_or_TR && <small>({hard_word_count ? 'out of 650' : '250-650 words recommended'})</small>}
				</p>

				{app.essay.requirement === 'optional' && (
					<>
						<hr />
						<p className='optional-essay'>
							This essay is optional. You do not have to complete the essay, but it may be considered if
							you choose to complete it.
						</p>
						<hr />
					</>
				)}
			</React.Fragment>
		);
	};

	render() {
		const { app } = this.props,
			{ essay_error, essayLastSaved } = this.state;

		return (
			<Card
				title='ESSAY'
				content={
					<form id={'form'}>
						{this.renderModal()}

						{[this.prompt, this.prompt_2].map((prompt, i) => this.renderPrompt(prompt, i))}

						{!!essay_error && <h3 className={'uwRedText'}>{essay_error}</h3>}

						<div className='clearfix' />
						<p className='essay-last-saved'>
							{this.prompt_2 ? 'Essays' : 'Essay'} last saved:{' '}
							{formatDateTime(essayLastSaved, false, true)}
						</p>

						{!!this.global_prompt?.field_before_you_submit?.length && (
							<section className='essay-tips'>
								<p>Before you submit your {this.prompt_2 ? 'essays' : 'essay'}, please make sure to:</p>
								<ul>
									{this.global_prompt.field_before_you_submit.map((str, i) => (
										<li key={i}>{str}</li>
									))}
								</ul>
							</section>
						)}
					</form>
				}
				buttons={
					<>
						<Button bsStyle='info' className='back-btn' fill onClick={this.onBack}>
							Back
						</Button>
						<Button bsStyle='info' className='tertiaryButton' fill onClick={e => this.onSaveEssay(e, true)}>
							Save
						</Button>
						<Button
							form='form'
							bsStyle='info'
							className='save-btn'
							fill
							disabled={!app.screensCompleted.essayCompleted}
							onClick={e => this.onSaveEssay(e, false)}>
							Save and Continue
						</Button>
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => {
		const { app_id, apps } = state.user;

		return {
			app: apps[app_id]?.json_obj,
			app_id: app_id,
			global_essay_prompts: state.global.essay_prompts.global,
			campus_essay_prompts: state.global.essay_prompts.campus_specific
		};
	},
	mapDispatchToProps = dispatch => ({
		updateApp: obj => dispatch(UserActions.updateApp(obj)),
		saveApp: val => dispatch(UserActions.saveApp({ essayCompleted: val })),
		setSubmenu: () => dispatch(UserActions.setSubmenus('holistic_background_open', true)),
		setEssayCompleted: val => dispatch(UserActions.setScreensCompleted({ essayCompleted: val }))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Essay));
