import * as Sentry from '@sentry/react';

import { DEFUNCT_CAMPUS_CODES } from 'components/modules/campus';
import { withinDateRange } from 'components/modules/date';

const term_matches = (term, chosen_semester) => {
	const { id, title, global_term } = chosen_semester;
	return term.id === id && (global_term ? term.field_global_term === global_term : term.title === title);
};

export const FIND_CAMPUS_UNDERGRAD_TERMS = {
		by_app: (_app, _terms) => {
			let { id: prog_id } = _app?.area_of_study?.selected_primary_1 || {},
				{ id: campus_id } = _app.initial_information.chosen_campus,
				prog_terms = [..._terms.campus_undergrad].filter(
					t => !!prog_id && t.field_undergrad_program?.includes(prog_id)
				),
				campus_terms = [..._terms.campus_undergrad].filter(
					t =>
						t.field_campuses?.includes(campus_id) &&
						!t.field_undergrad_program?.length &&
						!prog_terms.find(_t => _t.title === t.title)
				);

			const has_empty_collections = Object.keys(_terms || {}).some(key => !(_terms[key] || []).length);
			if (has_empty_collections) Sentry.captureException(new Error('terms not populated yet'));

			return campus_terms.concat(prog_terms);
		},
		by_campus: (campus_id, _terms) =>
			[..._terms.campus_undergrad].filter(t => t.field_campuses?.includes(campus_id))
	},
	FIND_FILTERED_TERMS = {
		by_app: (_app, _terms) => {
			let campus_undergrad_terms = FIND_CAMPUS_UNDERGRAD_TERMS.by_app(_app, _terms);
			return [..._terms.special, ..._terms.grad, ..._terms.default_undergrad, ...campus_undergrad_terms];
		},
		by_campus: (campus_id, _terms) => {
			const campus_undergrad_terms = FIND_CAMPUS_UNDERGRAD_TERMS.by_campus(campus_id, _terms);
			return [..._terms.special, ..._terms.grad, ..._terms.default_undergrad, ...campus_undergrad_terms];
		}
	},
	FIND_TERM = {
		// tms:  check by id and title in case the campus modified a closed term
		by_app: (_app, _terms) =>
			FIND_FILTERED_TERMS.by_app(_app, _terms).find(t =>
				term_matches(t, _app.initial_information.chosen_semester)
			),
		by_campus: (campus_id, _term, _terms) => {
			const term = [
				..._terms.campus_undergrad,
				..._terms.special,
				..._terms.grad,
				..._terms.default_undergrad
			].find(t => term_matches(t, _term));

			if (!term) return null;

			return FIND_FILTERED_TERMS.by_campus(campus_id, _terms).find(
				t => t.field_global_term === term.field_global_term
			);
		}
	},
	find_term_open_close = (_app, _terms) => {
		let term = FIND_TERM.by_app(_app, _terms),
			international = _app.application_modifier.split('_')[1] === 'I',
			app_type_name = '';

		if (!term) return null;

		if (term.field_opens && term.field_closes && term.field_pay) return term;

		if (_app.application_modifier.includes('FM_') || _app.application_modifier === 'DA') {
			app_type_name = 'freshman';
		} else if (_app.application_modifier.includes('TR_')) {
			app_type_name = 'transfer';
		} else if (_app.application_modifier.includes('RE_') || _app.application_modifier.includes('RET_')) {
			app_type_name = 're_entry';
		}

		let app_type_terms = term[`field_${app_type_name}`] || [];
		if (!app_type_terms.length) app_type_terms = term[`field_${app_type_name}_campus`] || [];

		const open_close = app_type_terms.find(obj =>
			obj.field_type === international ? 'International' : 'Domestic'
		);

		return open_close;
	},
	is_app_open = (_app, _terms, for_payment) => {
		const { initial_information, application_modifier, application_bypass_object, term_bypassed } = _app,
			{ chosen_semester, chosen_campus } = initial_information;

		if (DEFUNCT_CAMPUS_CODES.includes(chosen_campus?.field_abbreviation)) return false;

		// tms:  application_bypass_object was added to apps before the redux refactor;
		//		 there's no date in that field, just default to never expiring them
		if (!chosen_semester.id || !application_modifier || application_bypass_object) return true; //No idea, default true

		if (term_bypassed) {
			let bypass_cutoff_date = new Date(term_bypassed);
			bypass_cutoff_date.setDate(bypass_cutoff_date.getDate() + 60);
			return bypass_cutoff_date > new Date();
		}

		let objDates = find_term_open_close(_app, _terms),
			{
				field_opens,
				field_closes,
				field_pay,
				field_international_opens,
				field_international_closes,
				field_international_pay
			} = objDates || {},
			international = _app.application_modifier?.includes('_I'),
			open_date = international && !!field_international_opens ? field_international_opens : field_opens,
			close_date = international && !!field_international_closes ? field_international_closes : field_closes;

		if (for_payment) close_date = international && !!field_international_pay ? field_international_pay : field_pay;

		if (open_date) return withinDateRange(open_date, close_date);

		return false;
	},
	getTermStartDate = t => {
		let strTermYears = t?.title?.match(/\d+/g) || [];

		if (strTermYears.length) {
			const year = Number(strTermYears[0]);

			if (t.title.toLowerCase().includes('spring')) return new Date(year, 0, 15);
			if (t.title.toLowerCase().includes('winter')) return new Date(year, 0, 1);
			if (t.title.toLowerCase().includes('summer')) return new Date(year, 4, 15);

			return new Date(year, 8, 1); // default to Fall
		}
	},
	getADirectAdmitTerm = terms =>
		terms.default_undergrad.some(term => {
			const { title, field_freshman } = term,
				is_open = field_freshman.some(rec => {
					const { field_type, field_closes, field_opens } = rec;
					return field_type === 'Domestic' && withinDateRange(field_opens, field_closes);
				});

			return title.includes('Direct Admit') && is_open;
		}),
	getDirectAdmitTerm = (terms, direct_admit) =>
		terms.default_undergrad.find(term => {
			const { title, field_freshman } = term,
				is_open = field_freshman.some(rec => {
					const { field_type, field_closes, field_opens } = rec;
					return field_type === 'Domestic' && withinDateRange(field_opens, field_closes);
				});

			return title === `Direct Admit ${direct_admit.graduation_year}` && is_open;
		});
