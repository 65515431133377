import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';

import withRouter from 'components/Wrappers/withRouter';
import { SENTRY } from 'components/modules/_misc';
import OnError from 'views/NotAvailable/OnError';
import { GlobalActions } from 'reducers/global';

const { REACT_APP_ENV } = process.env;

class ErrorBoundary extends Component {
	componentDidUpdate(prevProps) {
		const { err, email, app_id, prev_page, campus_admin, support_student_id } = this.props;

		if (!!err && err !== prevProps.err) {
			if (SENTRY.use) {
				Sentry.captureException(err, {
					user: { username: email || '<anonymous>' },
					tags: {
						environment: REACT_APP_ENV || '<undetermined>',
						set_app_id: app_id || '<none>',
						previous_page: prev_page || '<none>',
						campus_admin: campus_admin,
						support_student_id: support_student_id || '<none>'
					}
				});
			} else {
				console.log(err);
			}
		}
	}

	componentDidCatch(err, info) {
		this.props.captureError(err);
	}

	render() {
		const { children, err } = this.props;

		return err ? (
			<>
				<div className='constellation-bg'></div>
				<OnError />
			</>
		) : (
			children
		);
	}
}

const mapStateToProps = state => ({
		err: state.global.err,
		prev_page: state.global.prev_page,
		email: state.user.email,
		app_id: state.user.app_id,
		campus_admin: state.user.campus_admin,
		support_student_id: state.user.support_student_id
	}),
	mapDispatchToProps = dispatch => ({
		captureError: err => dispatch(GlobalActions.captureError(err))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary));
