import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import { Table, Row } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { cloneDeep } from 'components/modules/_misc';
import { DEFAULT } from 'components/modules/app';
import { months_passed } from 'components/modules/date';
import { nextAppPage, prevAppPage } from 'components/modules/nav';
import _svg from 'components/modules/svg';
import DateInput from 'components/controls/date';
import RadioList from 'components/controls/radio_list';
import Select from 'components/controls/select';
import Textbox from 'components/controls/textbox';
import { UserActions } from 'reducers/user';

const optsTestType = [
		{ val: 'IELTS Scores', label: 'IELTS' },
		{ val: 'TOEFL Scores', label: 'TOEFL' },
		{ val: 'Duolingo Scores', label: 'Duolingo' }
	],
	max_scores = {
		'IELTS Scores': 9,
		'TOEFL Scores': 120,
		'Duolingo Scores': 160
	},
	score_steps = {
		'IELTS Scores': 0.1,
		'TOEFL Scores': 1,
		'Duolingo Scores': 5
	};

class AcademicBackgroundTestScores extends Component {
	constructor(props) {
		super(props);

		const { app } = this.props;
		this.setPath(app.application_modifier);

		this.state = {
			index: -1,
			test_score: null,
			page_shown: this.pages[0]
		};
	}

	componentDidMount() {
		this.props.setSubmenu(true);
	}

	componentWillUnmount() {
		const _valid = this.isValidForm();
		this.props.saveApp(_valid);
	}

	setPath = str => {
		this.include_tests = str.includes('_I') && str !== 'RET_I';
		this.pages = [];

		if (str.includes('FM')) this.pages.push('act-option');
		if (this.include_tests) this.pages = [...this.pages, 'transition', 'test-scores', 'scheduled-tests'];
	};

	isValidForm = () => {
		const { app } = this.props,
			{ page_shown, test_score } = this.state,
			{ act_transition_option, self_report_scores, report_future_tests, test_scores } = app.academic_background,
			is_future = page_shown === 'scheduled-tests';

		if (test_score) {
			const { score_type, total_score } = test_score,
				max_score = max_scores[score_type];

			return (
				!!score_type &&
				this.checkDateValidity() &&
				(is_future || (!!total_score && !isNaN(total_score) && total_score >= 0 && total_score <= max_score))
			);
		}

		if (page_shown === 'act-option') return !!act_transition_option;
		if (page_shown === 'transition') return !!self_report_scores && !!report_future_tests;

		return test_scores.some(score => score.future_test === is_future);
	};

	checkDateValidity = () => {
		const { test_score, page_shown } = this.state,
			{ date_taken } = test_score,
			is_future = page_shown === 'scheduled-tests';

		if (date_taken.length !== 7) return false;

		if (is_future) return months_passed(date_taken) <= 0 && months_passed(date_taken) > -24;

		const year_taken = Number(date_taken.split('/')[1]);
		return year_taken >= 1964 && months_passed(date_taken) >= 0;
	};

	onCreateTest = () => {
		let { page_shown } = this.state,
			test_score = cloneDeep(DEFAULT.TEST_SCORE);

		test_score.future_test = page_shown === 'scheduled-tests';
		this.setState({ test_score: test_score });
	};

	onEditScore = i => {
		const { app } = this.props,
			{ test_scores } = app.academic_background;

		this.setState({ index: i, test_score: cloneDeep(test_scores[i]) });
	};

	onDeleteScore = i => {
		const { app, updateApp } = this.props;

		if (!app.date_submitted) {
			confirmAlert({
				title: 'Delete Entry',
				message: 'Are you sure you want to delete this?',
				buttons: [
					{
						label: 'Yes',
						onClick: () => {
							let academic_background = cloneDeep(app.academic_background);
							academic_background.test_scores.splice(i, 1);

							updateApp({ academic_background: academic_background });
							this.setState({ test_score: null });
						}
					},
					{
						label: 'No'
					}
				]
			});
		}
	};

	onSaveScore = e => {
		e.preventDefault();
		e.stopPropagation();

		let { app, updateApp } = this.props,
			{ index } = this.state,
			academic_background = cloneDeep(app.academic_background),
			test_score = cloneDeep(this.state.test_score);

		if (!app.date_submitted) {
			if (index < 0) {
				academic_background.test_scores.push(test_score);
			} else {
				academic_background.test_scores[index] = test_score;
			}

			updateApp({ academic_background: academic_background });
			this.setState({ test_score: null, index: -1 });
		}
	};

	onSubmit = e => {
		e.preventDefault();
		e.stopPropagation();

		let { app, location, setSubmenu, navigate } = this.props,
			{ page_shown } = this.state,
			{ self_report_scores, report_future_tests } = app.academic_background;

		if (!app.date_submitted) {
			if (page_shown === 'transition' && self_report_scores === 'No') page_shown = 'test-scores';
			if (page_shown === 'test-scores' && report_future_tests === 'No') page_shown = 'scheduled-tests';

			const index_shown = this.pages.indexOf(page_shown);

			if (index_shown === this.pages.length - 1) {
				setSubmenu(false);
				navigate(nextAppPage(app, location.pathname));
			} else {
				this.setState({ page_shown: this.pages[index_shown + 1], index: -1, test_score: null });
			}
		}
	};

	onChange = (val, prop) => {
		let { app, updateApp } = this.props,
			academic_background = cloneDeep(app.academic_background);

		if (!app.date_submitted) {
			academic_background[prop] = val;
			updateApp({ academic_background: academic_background });
		}
	};

	onChangeScore = (val, prop) => {
		let { app } = this.props,
			test_score = cloneDeep(this.state.test_score);

		if (!app.date_submitted) {
			test_score[prop] = val;
			if (prop === 'score_type') test_score.total_score = '';

			this.setState({ test_score: test_score });
		}
	};

	onBack = () => {
		let { navigate, location, app } = this.props,
			{ page_shown } = this.state,
			index_shown = this.pages.indexOf(page_shown);

		if (index_shown) {
			this.setState({ index: -1, test_score: null, page_shown: this.pages[index_shown - 1] });
		} else {
			navigate(prevAppPage(app, location.pathname));
		}
	};

	renderScoresTable(is_future) {
		let { app } = this.props,
			{ test_score } = this.state,
			save_object = is_future ? 'Exam Date' : 'Score',
			filtered_tests = app.academic_background.test_scores.filter(s => s.future_test === is_future),
			test_rows = filtered_tests.length
				? filtered_tests.map(score => {
						const i = app.academic_background.test_scores.indexOf(score);

						return (
							<tr key={`test_score${i}`}>
								<td data-label='Type'>{score.score_type}</td>
								<td data-label='Date taken'>{score.date_taken}</td>
								{!is_future && <td data-label='Total score'>{score.total_score || 'N/A'}</td>}
								<td data-label='Actions'>
									<Button
										bsStyle='info'
										onClick={() => this.onEditScore(i)}
										className='editBtn btn-xs'>
										<img
											alt='editIcon'
											className='imgDashboard'
											height='18px'
											width='18px'
											src={_svg.Edit}
										/>
										<p className='pDashboard'>Edit</p>
									</Button>

									<Button
										bsStyle='info'
										onClick={() => this.onDeleteScore(i)}
										className='deleteBtn btn-xs'>
										<img
											alt='deleteIcon'
											className='imgDashboard'
											height='18px'
											width='18px'
											src={_svg.Delete}
										/>
										<p className='pDashboard'>Delete</p>
									</Button>
								</td>
							</tr>
						);
				  })
				: [
						<tr key='none'>
							<td colSpan={is_future ? 3 : 4}>
								{is_future ? 'You have no scheduled test dates added.' : 'You have no scores added.'}
							</td>
						</tr>
				  ];

		if (!test_score) {
			test_rows.push(
				<tr key='add' className='addColumn'>
					<td colSpan={is_future ? 2 : 3}></td>
					<td data-label='Actions'>
						<Button bsStyle='info' onClick={() => this.onCreateTest()} className='editBtn btn-xs'>
							<img
								alt='editIcon'
								className='imgDashboard'
								height='18px'
								width='18px'
								src={_svg.GreenCirclePlus}
							/>
							<p className='pDashboard'>Add {save_object}</p>
						</Button>
					</td>
				</tr>
			);
		}

		return test_rows;
	}

	renderTestScoresPage = is_future => {
		const { app } = this.props,
			{ test_score, page_shown } = this.state,
			save_object = page_shown === 'scheduled-tests' ? 'Exam Date' : 'Score',
			filtered_tests = app.academic_background.test_scores.filter(s => s.future_test === is_future);

		return (
			<>
				{is_future ? (
					<p>
						Please enter test information for exams you <strong>plan to take in the future</strong>.
					</p>
				) : (
					<p>
						Please enter test information for exams you <strong>have taken</strong>. Providing this
						information now may reduce the number of steps and costs in applying.
					</p>
				)}
				<hr />

				{filtered_tests.length ? (
					<Table striped bordered hover>
						<thead>
							<tr>
								<th scope='col'>
									<strong>Type</strong>
								</th>
								<th scope='col'>
									<strong>{is_future ? 'Date Scheduled' : 'Date taken'} </strong>
								</th>
								{!is_future && (
									<th scope='col'>
										<strong>Total score</strong>
									</th>
								)}
								<th scope='col'>
									<strong>Actions</strong>
								</th>
							</tr>
						</thead>
						<tbody>{this.renderScoresTable(is_future)}</tbody>
					</Table>
				) : test_score ? null : (
					<Button bsStyle='info' className='save-btn add-content' fill onClick={() => this.onCreateTest()}>
						Add {save_object}
					</Button>
				)}

				{!!test_score && (
					<>
						<Button
							bsStyle='info'
							className='clearObject'
							fill
							onClick={() => this.setState({ test_score: null, index: -1 })}>
							Clear {page_shown === 'scheduled-tests' ? 'Date' : 'Score'}
						</Button>

						{this.renderTestScoreEditForm()}
					</>
				)}
			</>
		);
	};

	renderTestScoreEditForm = () => {
		let { test_score, page_shown } = this.state,
			{ date_taken, score_type, total_score } = test_score,
			is_future = page_shown === 'scheduled-tests',
			label = is_future ? 'Which test?' : 'Which test score?';

		return (
			<>
				<Row>
					<Select
						value={score_type}
						opts={optsTestType}
						onChange={val => this.onChangeScore(val, 'score_type')}
						name='score_type'
						label={label}
						placeholder='Select Test'
						required={true}
						cols={6}
					/>

					<DateInput
						value={date_taken}
						onChange={(val, isValid) => this.onChangeScore(val, 'date_taken', isValid)}
						label={is_future ? 'Planned test date' : 'Date taken'}
						required={true}
						cols={6}
						fullDate={false}
						isInvalid={!this.checkDateValidity()}
					/>
				</Row>

				{!is_future && !!score_type && (
					<Row>
						<Textbox
							type='number'
							value={total_score}
							onChange={val => this.onChangeScore(val, 'total_score')}
							name='total_score'
							label='Total score'
							placeholder='Total score'
							required={true}
							cols={6}
							min={score_type === 'Duolingo Scores' ? 10 : 0}
							max={max_scores[score_type]}
							step={score_steps[score_type]}
						/>
					</Row>
				)}
			</>
		);
	};

	renderACTOption = () => {
		const { app } = this.props,
			{ act_transition_option } = app.academic_background,
			opts = [
				{
					val: 'Option 1:  Yes, consider my ACT and/or SAT score for admission.',
					label: 'Yes, consider my ACT and/or SAT score for admission'
				},
				{
					val: 'Option 2:  No, do not consider/I am not sending my ACT and/or SAT score for admission.',
					label: 'No, do not consider/I am not sending my ACT and/or SAT score for admission'
				}
			];

		return (
			<>
				<p>
					The Universities of Wisconsin currently do NOT require an ACT and/or SAT score. This applies to
					undergraduate applicants submitting applications for admission to Fall 2025 to Summer 2027. All
					Universities of Wisconsin campuses will review your application and consider you for admission with
					or without one of these standardized test scores.
				</p>

				<Row>
					<RadioList
						name='act_transition_option'
						value={act_transition_option}
						onChange={val => this.onChange(val, 'act_transition_option')}
						opts={opts}
						label='Do you intend to send an ACT and/or SAT score to be reviewed with your application?'
						cols={12}
						required={true}
						forceNewLines={true}
					/>
				</Row>
			</>
		);
	};

	renderTransition = () => {
		const { app } = this.props,
			{ self_report_scores, report_future_tests } = app.academic_background;

		return (
			<>
				<p>You have the option to self-report your language proficiency test scores with:</p>

				<ul className='text'>
					<li className='text'>IELTS Scores</li>
					<li className='text'>TOEFL Scores</li>
					<li className='text'>Duolingo Scores</li>
				</ul>

				<p>Here are a few things to know about this section:</p>

				<ul>
					<li className='text'>This section is optional.</li>
					<li className='text'>
						This may decrease the amount of time it takes for your application to be reviewed.
					</li>
					<li className='text'>
						Students should check with the campus(es) that they are applying to for a list of accepted
						English tests.
					</li>
				</ul>

				<Row>
					<RadioList
						name='self_report_scores'
						value={self_report_scores}
						onChange={val => this.onChange(val, 'self_report_scores')}
						opts={['Yes', 'No']}
						label='Would you like to self-report your test score(s)?'
						cols={12}
						required={true}
						forceNewLines={true}
					/>
				</Row>
				<Row>
					<RadioList
						name='report_future_tests'
						value={report_future_tests}
						onChange={val => this.onChange(val, 'report_future_tests')}
						opts={['Yes', 'No']}
						label='Are you planning on taking a language proficiency test in the future?'
						cols={12}
						required={true}
						forceNewLines={true}
					/>
				</Row>
			</>
		);
	};

	renderBtnSubmit = () => {
		const { test_score, page_shown } = this.state,
			_valid = this.isValidForm();

		return test_score ? (
			<Button bsStyle='info' className='save-btn' fill disabled={!_valid} onClick={this.onSaveScore}>
				{`Save ${page_shown === 'scheduled-tests' ? 'Exam Date' : 'Score'}`}
			</Button>
		) : (
			<Button
				form='testscores'
				bsStyle='info'
				className='save-btn'
				disabled={!_valid}
				onClick={this.onSubmit}
				fill
				type='submit'>
				Save and Continue
			</Button>
		);
	};

	render() {
		const { page_shown } = this.state,
			pages = {
				'act-option': this.renderACTOption(),
				transition: this.renderTransition(),
				'test-scores': this.renderTestScoresPage(false),
				'scheduled-tests': this.renderTestScoresPage(true)
			};

		return (
			<Card
				title='Academic Background: Test Scores'
				content={
					<form id='testscores'>
						{pages[page_shown]}
						<div className='clearfix' />
					</form>
				}
				buttons={
					<>
						<Button bsStyle='info' className='back-btn' fill onClick={() => this.onBack()}>
							Back
						</Button>

						{this.renderBtnSubmit()}
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => {
		const { app_id, apps } = state.user;

		return {
			app: apps[app_id].json_obj,
			app_id: app_id
		};
	},
	mapDispatchToProps = dispatch => ({
		updateApp: obj => dispatch(UserActions.updateApp(obj)),
		saveApp: val => dispatch(UserActions.saveApp({ academicBackgroundTestScoresCompleted: val })),
		setSubmenu: val => dispatch(UserActions.setSubmenus('academic_background_open', val)),
		unsetAcademicBackgroundTestScoresCompleted: () =>
			dispatch(UserActions.setScreensCompleted({ academicBackgroundTestScoresCompleted: false }))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AcademicBackgroundTestScores));
