import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap';

export default class FileUpload extends Component {
	render() {
		let { onChange, name, classVals, displayName, cols, centered, required, hide_optional, toolTip } = this.props;

		if (!classVals) classVals = [];
		classVals.push(`col-md-${cols || 12}`);
		if (centered) classVals.push('col-centered');

		return (
			<div className={classVals.join(' ')}>
				<FormGroup name={name} type='file' accept='application/pdf' aria-label={`Upload your ${displayName}`}>
					<ControlLabel htmlFor={name}>
						{`Upload your ${displayName} (PDF only, 2MB limit)`}
						{!required && !hide_optional && <small>(Optional)</small>} {toolTip}
					</ControlLabel>
					<FormControl
						id={name}
						type='file'
						accept='application/pdf'
						onChange={e => onChange(e.target.files[0]) || {}}
					/>
				</FormGroup>
			</div>
		);
	}
}

FileUpload.propTypes = {
	name: PropTypes.string.isRequired,
	displayName: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	required: PropTypes.bool,
	hide_optional: PropTypes.bool,
	cols: PropTypes.number,
	classVals: PropTypes.array,
	toolTip: PropTypes.object
};
