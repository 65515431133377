import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Row, Table } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import API from 'components/api';
import { Card } from 'components/Card/Card';
import CheckboxList from 'components/controls/checkbox_list';
import DateInput from 'components/controls/date';
import FileUpload from 'components/controls/file_upload';
import RadioList from 'components/controls/radio_list';
import Textbox from 'components/controls/textbox';
import Select from 'components/controls/select';
import Button from 'components/CustomButton';
import { FILE_UPLOAD, cloneDeep } from 'components/modules/_misc';
import { DEFAULT } from 'components/modules/app';
import { create_proactive } from 'components/modules/ada';
import { str_current_month, months_passed } from 'components/modules/date';
import { nextAppPage, prevAppPage } from 'components/modules/nav';
import { states, countries } from 'components/modules/opts';
import _svg from 'components/modules/svg';
import { GlobalActions } from 'reducers/global';
import { UserActions } from 'reducers/user';

class Employment extends Component {
	constructor(props) {
		super(props);

		this.state = {
			index: -1,
			objEmployment: null,
			uploading_files: false,
			upload_error: '',
			valid_start_date: true,
			valid_end_date: true
		};

		this.orig_has_files = !!props.uploaded_files.length;
	}

	componentDidMount() {
		this._mounted = true;
		this.props.setSubmenu();

		setTimeout(() => {
			if (this._mounted) {
				create_proactive(
					'In the Holistic Background section of the online application you will have the opportunity to either manually enter or upload a PDF of your resume.',
					'5f11e7fbc9692d834fe74d83'
				);
			}
		}, 2000);
	}

	componentWillUnmount() {
		this._mounted = false;

		let { saveApp } = this.props,
			_valid = this.isValidForm(true);

		saveApp(_valid);
	}

	getAPI = () => {
		const { support_student_id, campus_admin } = this.props;
		return new API('', support_student_id, campus_admin);
	};

	isValidForm = unmounting => {
		let { app } = this.props,
			{ objEmployment } = this.state,
			{ no_employment, employment_to_report, employment, employment_resume_file } = app.activities_employment;

		if (objEmployment && !unmounting) {
			const {
				self_employed,
				employer_company,
				employer_country,
				employer_country_other,
				employer_state,
				employer_city,
				job_title,
				current_job,
				employment_started,
				employment_ended,
				hours_per_week,
				summer_only
			} = objEmployment;

			return (
				!!self_employed &&
				(self_employed === 'Yes' ||
					(!!employer_company &&
						!!employer_country &&
						employer_city &&
						(employer_country !== 'USA' || !!employer_state) &&
						(employer_country !== 'Other' || !!employer_country_other))) &&
				!!job_title &&
				!!current_job &&
				!!employment_started &&
				!!employment_ended &&
				(hours_per_week || 0) >= 0 &&
				!!summer_only
			);
		}

		return (
			(employment_to_report === 'Manually Enter Employment' && !!employment.length) ||
			(employment_to_report === 'Upload Resume' && !!employment_resume_file) ||
			no_employment
		);
	};

	onAddEmployment = e => {
		this.setState({ objEmployment: cloneDeep(DEFAULT.EMPLOYMENT), index: -1 });
	};

	onEditEmployment = i => e => {
		const { app } = this.props;

		this.setState({
			index: i,
			objEmployment: cloneDeep(app.activities_employment.employment[i])
		});
	};

	onDeleteEmployment = (i, e) => {
		let { app, updateApp } = this.props,
			activities_employment = cloneDeep(app.activities_employment);

		if (!app.date_submitted) {
			confirmAlert({
				title: 'Remove Entry',
				message: 'Are you sure you want to remove this employment record?',
				buttons: [
					{
						label: 'Yes',
						onClick: () => {
							if (activities_employment.employment.length === 1) {
								activities_employment.employment = [];
							} else {
								activities_employment.employment.splice(i, 1);
							}

							updateApp({ activities_employment: activities_employment });
						}
					},
					{
						label: 'No'
					}
				]
			});
		}
	};

	onSaveEmployment = e => {
		e.preventDefault();
		e.stopPropagation();

		let { app, updateApp } = this.props,
			{ objEmployment, index } = this.state,
			activities_employment = cloneDeep(app.activities_employment);

		if (this.isValidForm()) {
			if (objEmployment.self_employed === 'Yes') {
				objEmployment.employer_company = 'Self Employed';

				['employer_country', 'employer_country_other', 'employer_state', 'employer_city'].forEach(key => {
					objEmployment[key] = '';
				});
			}

			if (index < 0) {
				activities_employment.employment.push(objEmployment);
			} else {
				activities_employment.employment[index] = objEmployment;
			}

			if (activities_employment.employment_to_report) activities_employment.no_employment = false;

			updateApp({ activities_employment: activities_employment });
			this.setState({ objEmployment: null, index: -1 });
		}
	};

	onClearUploadedFile = e => {
		let { app, updateApp } = this.props;

		if (!app.date_submitted) {
			confirmAlert({
				title: 'Remove File',
				message: 'Are you sure you want to remove this file from your application?',
				buttons: [
					{
						label: 'Yes',
						onClick: () => {
							let activities_employment = cloneDeep(app.activities_employment);
							activities_employment.employment_resume_file = '';
							activities_employment.employment_resume_file_name = '';

							updateApp({ activities_employment: activities_employment });
						}
					},
					{
						label: 'No'
					}
				]
			});
		}
	};

	onSubmit = e => {
		e.preventDefault();
		e.stopPropagation();

		const { navigate, app, location } = this.props;
		if (this.isValidForm()) navigate(nextAppPage(app, location.pathname));
	};

	onUploadResume = file => {
		let { app, app_id, updateApp, addDocument, captureError } = this.props,
			activities_employment = cloneDeep(app.activities_employment);

		if (!app.date_submitted) {
			const { name, size } = file;

			if (size > FILE_UPLOAD.min_file_size) {
				this.setState({ uploading_files: true });

				this.getAPI()
					.saveFile(file, app_id, 'RESUME')
					.then(response => {
						if (this._mounted) {
							this.setState({ uploading_files: false });

							if (response.data.includes('UPLOAD FAILED')) {
								const err_msg = response.data.replace('UPLOAD FAILED:', '').trim();
								this.setState({ upload_error: `Upload failed: ${name} (${err_msg})` });

								activities_employment.employment_resume_file = '';
								activities_employment.employment_resume_file_name = '';
								updateApp({ activities_employment: activities_employment });
							} else {
								activities_employment.employment_resume_file = response.data;
								activities_employment.employment_resume_file_name = name;

								addDocument(name, response.data);
								updateApp({ activities_employment: activities_employment });
								this.setState({ upload_error: '' });
							}
						}
					})
					.catch(ex => captureError(ex));
			} else {
				confirmAlert(FILE_UPLOAD.err_msg);
			}
		}
	};

	onChange = (val, prop) => {
		let { app, updateApp } = this.props,
			activities_employment = cloneDeep(app.activities_employment);

		if (!app.date_submitted) {
			if (prop === 'employment_to_report') activities_employment.no_employment = false;

			if (prop === 'no_employment' && val) {
				activities_employment.employment = [];
				activities_employment.employment_resume_file = '';
				activities_employment.employment_resume_file_name = '';
				activities_employment.employment_to_report = '';
				activities_employment.employment_use_previous_file = '';
			}

			if (prop === 'employment_use_previous_file' && val === 'No') {
				activities_employment.employment_resume_file = '';
				activities_employment.employment_resume_file_name = '';
			}

			activities_employment[prop] = val;
			updateApp({ activities_employment: activities_employment });
		}
	};

	onChangeEmployment = (val, prop, isValid) => {
		let { app } = this.props,
			objEmployment = cloneDeep(this.state.objEmployment);

		if (!app.date_submitted) {
			objEmployment[prop] = val;

			if (objEmployment.current_job === 'Yes') objEmployment.employment_ended = str_current_month();

			if (prop === 'employment_started') this.setState({ valid_start_date: isValid });
			if (prop === 'employment_ended') this.setState({ valid_end_date: isValid });

			if (prop === 'self_employed' && val === 'No' && objEmployment.employer_company === 'Self Employed') {
				objEmployment.employer_company = '';
			}

			this.setState({ objEmployment: objEmployment });
		}
	};

	renderBtnDownload = (key, file_name) => {
		const { uploaded_files, captureError } = this.props;

		if (!file_name) {
			const uploaded_file = uploaded_files.find(file => file.key === key);
			file_name = uploaded_file ? uploaded_file.file_name : `${key}.pdf`;
		}

		return (
			<Button
				bsStyle='info'
				aria-label='Download uploaded resume'
				onClick={() => {
					this.getAPI()
						.downloadDocument(key, file_name)
						.catch(ex => {
							captureError(ex);

							confirmAlert({
								title: "Hmm, that didn't work",
								message:
									'Your resume could not be downloaded.  Please try again and let us know if the problem continues.',
								buttons: [{ label: 'OK' }]
							});
						});
				}}
				className='btn-download'>
				<img alt='downloadIcon' className='imgDashboard' height='18px' width='18px' src={_svg.Download} />
				<p className='pDashboard'>Download</p>
			</Button>
		);
	};

	renderEmploymentRow = () => {
		let { app } = this.props,
			activity_rows = app.activities_employment.employment.length
				? app.activities_employment.employment.map((element, i) => {
						return (
							<tr key={`activity${i}`}>
								<td data-label='Employer'>{element.employer_company}</td>
								<td data-label='Title'>{element.job_title}</td>
								<td data-label='Started'>{element.employment_started}</td>
								<td data-label='Actions'>
									<Button
										bsStyle='info'
										onClick={this.onEditEmployment(i)}
										className='editBtn btn-xs'>
										<img
											alt='editIcon'
											className='imgDashboard'
											height='18px'
											width='18px'
											src={_svg.Edit}
										/>
										<p className='pDashboard'>Edit</p>
									</Button>

									<Button
										bsStyle='info'
										onClick={() => this.onDeleteEmployment(i)}
										className='deleteBtn btn-xs'>
										<img
											alt='deleteIcon'
											className='imgDashboard'
											height='18px'
											width='18px'
											src={_svg.Delete}
										/>
										<p className='pDashboard'>Remove</p>
									</Button>
								</td>
							</tr>
						);
				  })
				: [
						<tr key='none'>
							<td colSpan={4}>You have no employment added.</td>
						</tr>
				  ];

		activity_rows.push(
			<tr key='add' className='addColumn'>
				<td colSpan={3}></td>
				<td data-label='Actions'>
					<Button bsStyle='info' onClick={this.onAddEmployment} className='editBtn btn-xs'>
						<img
							alt='editIcon'
							className='imgDashboard'
							height='18px'
							width='18px'
							src={_svg.GreenCirclePlus}
						/>
						<p className='pDashboard'>Add Employment</p>
					</Button>
				</td>
			</tr>
		);

		return activity_rows;
	};

	renderEmploymentForm = () => {
		let { objEmployment, valid_start_date } = this.state,
			{
				self_employed,
				employer_company,
				employer_country,
				employer_country_other,
				employer_state,
				employer_city,
				job_title,
				current_job,
				employment_started,
				employment_ended,
				hours_per_week,
				summer_only
			} = objEmployment,
			invalid_end_date = false;

		if (!!employment_started && valid_start_date) {
			const months_passed_start = months_passed(employment_started),
				months_passed_end = months_passed(employment_ended);

			invalid_end_date = months_passed_end > months_passed_start;
		}

		return (
			<>
				<Row>
					<RadioList
						value={self_employed}
						opts={['Yes', 'No']}
						onChange={val => this.onChangeEmployment(val, 'self_employed')}
						name='self_employed'
						label='Are/were you self-employed?'
						required={true}
						cols={12}
						oneLine={true}
					/>
				</Row>
				{self_employed === 'No' && (
					<Row>
						<Textbox
							value={employer_company}
							onChange={val => this.onChangeEmployment(val, 'employer_company')}
							name='employer_company'
							label='Employer / Company'
							placeholder='Employer / Company'
							required={true}
							maxLength={100}
							cols={12}
						/>
					</Row>
				)}
				{objEmployment.self_employed !== 'Yes' && (
					<>
						<Row>
							<Select
								value={employer_country}
								opts={countries}
								onChange={val => this.onChangeEmployment(val, 'employer_country')}
								name='employer_country'
								label='Employer Country'
								placeholder='Select Country'
								required={true}
								cols={6}
							/>

							{employer_country === 'Other' && (
								<Textbox
									value={employer_country_other}
									onChange={val => this.onChangeEmployment(val, 'employer_country_other')}
									name='employer_country_other'
									label='Specify Country'
									placeholder='Specify Country'
									required={true}
									maxLength={75}
									cols={6}
								/>
							)}

							{employer_country === 'USA' && (
								<Select
									value={employer_state}
									opts={states}
									onChange={val => this.onChangeEmployment(val, 'employer_state')}
									name='employer_state'
									label='Employer State'
									placeholder='Select State'
									required={true}
									cols={6}
								/>
							)}
						</Row>
						<Row>
							<Textbox
								value={employer_city}
								onChange={val => this.onChangeEmployment(val, 'employer_city')}
								name='employer_city'
								label='Employer City'
								placeholder='Employer City'
								required={true}
								maxLength={80}
								cols={12}
							/>
						</Row>
					</>
				)}
				<Row>
					<Textbox
						value={job_title}
						onChange={val => this.onChangeEmployment(val, 'job_title')}
						name='job_title'
						label='Job Title'
						placeholder='Job Title'
						required={true}
						maxLength={80}
						cols={12}
					/>
				</Row>
				<Row>
					<RadioList
						value={current_job}
						opts={['Yes', 'No']}
						onChange={val => this.onChangeEmployment(val, 'current_job')}
						name='current_job'
						label='Are you currently employed here?'
						required={true}
						cols={12}
						oneLine={true}
					/>
				</Row>
				<Row>
					<DateInput
						value={employment_started}
						onChange={(val, isValid) => this.onChangeEmployment(val, 'employment_started', isValid)}
						label='Date Employment Started'
						required={true}
						cols={6}
						fullDate={false}
					/>

					{current_job === 'No' && (
						<DateInput
							value={employment_ended}
							onChange={(val, isValid) => this.onChangeEmployment(val, 'employment_ended', isValid)}
							label='Date Employment Ended'
							required={true}
							isInvalid={invalid_end_date}
							cols={6}
							fullDate={false}
						/>
					)}
				</Row>
				<Row>
					<Textbox
						type='number'
						value={hours_per_week}
						onChange={val => this.onChangeEmployment(val, 'hours_per_week')}
						name='hours_per_week'
						label='Hours Per Week'
						placeholder='Hours Per Week'
						step={0.1}
						min={0}
						max={100}
						cols={6}
					/>
				</Row>
				<Row>
					<RadioList
						value={summer_only}
						opts={['Yes', 'No']}
						onChange={val => this.onChangeEmployment(val, 'summer_only')}
						name='summer_only'
						label='Did you work this job only over summers?'
						required={true}
						cols={12}
						oneLine={true}
					/>
				</Row>
			</>
		);
	};

	renderFileUploadBlock = () => {
		let { app, uploaded_files } = this.props,
			{ upload_error, uploading_files } = this.state,
			{ employment_resume_file, employment_resume_file_name, employment_use_previous_file } =
				app.activities_employment,
			already_uploaded_file;

		if (upload_error) {
			already_uploaded_file = <p className='uwRedText'>{upload_error}</p>;
		} else if (employment_resume_file_name) {
			already_uploaded_file = (
				<p>
					<strong>File Successfully Uploaded: </strong>
					{employment_resume_file_name}
					<Button
						bsStyle='info'
						aria-label='Clear or remove uploaded resume'
						onClick={this.onClearUploadedFile}
						className='deleteBtn deleteUploadedFile'>
						<img alt='deleteIcon' className='imgDashboard' height='18px' width='18px' src={_svg.Delete} />
						<p className='pDashboard'>Remove</p>
					</Button>
					{this.renderBtnDownload(employment_resume_file, employment_resume_file_name)}
				</p>
			);
		}

		const showUploadControl =
			employment_use_previous_file === 'No' ||
			!this.orig_has_files ||
			(!!already_uploaded_file && !employment_use_previous_file);

		return (
			<>
				{(!!employment_use_previous_file || (this.orig_has_files && !employment_resume_file)) && (
					<Row>
						<RadioList
							value={employment_use_previous_file}
							opts={['Yes', 'No']}
							onChange={val => this.onChange(val, 'employment_use_previous_file')}
							name='employment_use_previous_file'
							label='Would you like to use a previous file?'
							required={true}
							cols={12}
							oneLine={true}
						/>
					</Row>
				)}

				{employment_use_previous_file === 'Yes' && (
					<Row>
						<Select
							value={employment_resume_file}
							opts={uploaded_files.map(file => ({ val: file.key, label: file.file_name }))}
							onChange={val => this.onChange(val, 'employment_resume_file')}
							name='employment_resume_file'
							label='Use previous file:'
							placeholder='Select File'
							required={true}
							cols={6}
						/>

						{employment_resume_file && (
							<div className='col-md-6 prev-download'>
								{this.renderBtnDownload(employment_resume_file)}
							</div>
						)}
					</Row>
				)}

				{showUploadControl && (
					<>
						{(!employment_resume_file_name || !!upload_error) && (
							<Row>
								<FileUpload
									name='employment_resume_file'
									displayName='resume'
									onChange={file => this.onUploadResume(file)}
									cols={12}
									hide_optional={true}
								/>
							</Row>
						)}

						{uploading_files && (
							<p>
								<strong>Uploading...</strong>
							</p>
						)}

						{!!(employment_resume_file_name || upload_error) && already_uploaded_file}
					</>
				)}
			</>
		);
	};

	render() {
		let { app, navigate, location } = this.props,
			{ uploading_files, objEmployment, index } = this.state,
			{ employment_to_report, no_employment, employment, employment_resume_file } = app.activities_employment,
			display_skip_checkbox =
				employment_to_report === '' ||
				(employment_to_report === 'Manually Enter Employment' && employment.length === 0) ||
				(employment_to_report === 'Upload Resume' && employment_resume_file === ''),
			skip_employment = display_skip_checkbox ? (
				<Row>
					<CheckboxList
						values={no_employment ? ['I have no work experience to report.'] : []}
						opts={['I have no work experience to report.']}
						onChange={val => this.onChange(!!val.length, 'no_employment')}
						name='no_employment'
						label=''
						cols={12}
						hide_optional={true}
					/>
				</Row>
			) : null,
			employment_method = (
				<>
					<p>
						Like activities, listing your work experience can help us understand your life outside the
						classroom better. Your work experience can include summer jobs, part or full-time jobs, and
						internships (paid or unpaid).
					</p>

					<Row>
						<RadioList
							value={app.activities_employment.employment_to_report}
							opts={['Upload Resume', 'Manually Enter Employment']}
							onChange={val => this.onChange(val, 'employment_to_report')}
							name='employment_to_report'
							label='Please select the method you wish to provide employment'
							forceNewLines={true}
							required={true}
						/>
					</Row>
				</>
			),
			main_page,
			showingEmploymentForm = index > -1 || !!objEmployment,
			buttons = (
				<>
					<Button
						bsStyle='info'
						className='back-btn'
						fill
						onClick={() => {
							navigate(prevAppPage(app, location.pathname));
						}}
						disabled={uploading_files}>
						Back
					</Button>
					<Button
						form='form'
						bsStyle='info'
						className='save-btn'
						fill
						disabled={uploading_files || !this.isValidForm()}
						onClick={this.onSubmit}>
						Save and Continue
					</Button>
				</>
			);

		//Resume or manually enter
		if (app.activities_employment.employment_to_report === 'Upload Resume') {
			main_page = (
				<>
					{employment_method}
					{this.renderFileUploadBlock()}
					{skip_employment}
				</>
			);
		} else if (app.activities_employment.employment_to_report === 'Manually Enter Employment') {
			if (showingEmploymentForm) {
				main_page = this.renderEmploymentForm();

				buttons = (
					<>
						<Button
							bsStyle='info'
							className='back-btn'
							fill
							onClick={() => {
								this.setState({ objEmployment: null, index: -1 });
							}}>
							Back
						</Button>
						<Button
							bsStyle='info'
							className='save-btn'
							fill
							onClick={this.onSaveEmployment}
							disabled={!this.isValidForm()}>
							Save Employment
						</Button>
					</>
				);
			} else {
				main_page = (
					<>
						{employment_method}

						<p>
							<strong>Please provide work experience for the past two years.</strong>
						</p>

						<hr />

						{app.activities_employment.employment.length > 0 ? (
							<Table striped bordered hover>
								<thead>
									<tr>
										<th scope='col'>
											<strong>Employer</strong>
										</th>
										<th scope='col'>
											<strong>Job Title</strong>
										</th>
										<th scope='col'>
											<strong>Employment Started</strong>
										</th>
										<th scope='col'>
											<strong>Actions</strong>
										</th>
									</tr>
								</thead>
								<tbody>{this.renderEmploymentRow()}</tbody>
							</Table>
						) : (
							<Button bsStyle='info' className='save-btn add-content' fill onClick={this.onAddEmployment}>
								Add Employment
							</Button>
						)}
						{skip_employment}
					</>
				);
			}
		} else {
			main_page = (
				<>
					{employment_method}
					{skip_employment}
				</>
			);
		}

		return (
			<Card
				title='WORK EXPERIENCE'
				content={
					<form id='form'>
						{main_page}
						<div className='clearfix' />
					</form>
				}
				buttons={buttons}
			/>
		);
	}
}

const mapStateToProps = state => {
		const { app_id, apps, support_student_id, documents, campus_admin } = state.user;

		return {
			app: apps[app_id].json_obj,
			app_id: app_id,
			support_student_id: support_student_id,
			uploaded_files: documents.filter(file => file.document_type === 'RESUME'),
			campus_admin: campus_admin
		};
	},
	mapDispatchToProps = dispatch => ({
		updateApp: obj => dispatch(UserActions.updateApp(obj)),
		saveApp: val => dispatch(UserActions.saveApp({ employmentCompleted: val })),
		setSubmenu: () => dispatch(UserActions.setSubmenus('holistic_background_open', true)),
		addDocument: (file_name, key) => dispatch(UserActions.addDocument('RESUME', file_name, key)),
		captureError: err => dispatch(GlobalActions.captureError(err))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Employment));
