import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import { UserActions } from 'reducers/user';
import { routeEnabledForModifier } from 'components/modules/app';
import { nextAppPage, prevAppPage } from 'components/modules/nav';

class HolisticBackground extends Component {
	componentDidMount() {
		this.props.setSubmenu(true);
	}

	saveAndContinue = e => {
		e.preventDefault();
		e.stopPropagation();

		const { navigate, app, location } = this.props;
		navigate(nextAppPage(app, location.pathname));
	};

	onBack = e => {
		const { navigate, location, app, setSubmenu } = this.props;

		setSubmenu(false);
		navigate(prevAppPage(app, location.pathname));
	};

	render() {
		let { app } = this.props,
			academic_page,
			transfer_warning,
			essayEnabled = routeEnabledForModifier('/essay', app),
			activityEnabled = routeEnabledForModifier('/activities', app),
			employmentEnabled = routeEnabledForModifier('/employment', app),
			showLetterOfRecommendationNote =
				app.application_modifier.includes('TR_') || app.application_modifier.includes('FM_');

		//Haven't filled out HE yet?
		if (app.application_modifier.includes('TR_') && !app.academic_background.colleges.length) {
			transfer_warning = (
				<div className='alert-msg'>
					<span>
						<img src={require('assets/img/exclamation-triangle-red.png')} alt='UW Logo' />
					</span>
					<p>
						Since you are a transfer student, you should fill out the academic background section before
						advancing to determine if you must provide an essay.
					</p>
				</div>
			);
		}

		if (essayEnabled && activityEnabled && employmentEnabled) {
			//ESSAY, ACT, EMPLOY
			academic_page = (
				<div>
					<p>
						Applicants to the University of Wisconsin System will be given a comprehensive review based upon
						academics, activities, employment, leadership, and essay response (if applicable).
					</p>

					{showLetterOfRecommendationNote ? (
						<>
							<ul>
								<li>
									A letter of recommendation is <strong>not</strong> required for admission to a UW
									campus except UW-Madison. A single letter from an academic source such as a teacher,
									school counselor, or faculty member must be submitted to UW-Madison to receive an
									admission decision.
								</li>
								<li>
									You can find more information on how to submit a letter of recommendation in the
									Next Steps PDF provided upon submitting your application.
								</li>
							</ul>
						</>
					) : (
						<p>Please note the activities and employment sections are optional.</p>
					)}

					{transfer_warning}
				</div>
			);
		} else if (essayEnabled && employmentEnabled) {
			//ESSAY. EMPLOY
			academic_page = (
				<div>
					<p>
						The holistic section will ask you to provide your employment information and write an
						essay/statement. A few things to know about the holistic area of the application:
					</p>

					<ul>
						<li className={'transitionLI'}>
							You can choose to upload a resume or manually enter your employment information.
						</li>
						<li className={'transitionLI'}>
							The employment section is optional on the application. Check with your program requirements
							if they require employment information from you.
						</li>
						<li className={'transitionLI'}>
							Your essay/statement prompt will be on the page. You will also receive guidance on what to
							include.
						</li>
					</ul>
				</div>
			);
		} else {
			//ESSAY
			academic_page = (
				<div>
					<p>
						Your holistic section asks that you write a statement for the campus that you are applying. On
						the statement page there will be a prompt to respond to along with some guidance for what to
						include in your statement.
					</p>
				</div>
			);
		}

		return (
			<Card
				title='HOLISTIC BACKGROUND'
				content={
					<form id={'form'} onSubmit={this.saveAndContinue}>
						{academic_page}

						<div className='clearfix' />
					</form>
				}
				buttons={
					<>
						<Button bsStyle='info' className='back-btn' fill onClick={this.onBack}>
							Back
						</Button>
						<Button form={'form'} autoFocus bsStyle='info' className='save-btn' fill type={'submit'}>
							Continue
						</Button>
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => {
		const { app_id, apps } = state.user;

		return {
			app: apps[app_id].json_obj,
			app_id: app_id
		};
	},
	mapDispatchToProps = dispatch => ({
		setSubmenu: val => dispatch(UserActions.setSubmenus('holistic_background_open', val))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HolisticBackground));
