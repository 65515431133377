import { SENTRY } from 'components/modules/_misc';

/*
	tms:
		don't pass exceptions up to global error-handling; if Ada won't load,
		there's nothing we can do about it, and the app will still work
*/

export const create_proactive = (msg, responseID) => {
		if (window.adaEmbed) {
			window.adaEmbed.getInfo().then(resp => {
				try {
					if (!(resp?.isChatOpen || resp?.isDrawerOpen)) {
						if (resp?.hasActiveChatter || resp?.hasClosedChat) {
							window.adaEmbed.reset({ greeting: responseID });
						}

						window.adaEmbed.createProactive(msg, 10, responseID);
					}
				} catch (ex) {
					SENTRY.log(ex);
				}
			});
		}
	},
	set_identity = (email, name) => {
		try {
			let obj = { email: email, name: name };
			if (!email) obj.app_id = '';

			if (window.adaEmbed) window.adaEmbed.setMetaFields(obj);
		} catch (ex) {
			SENTRY.log(ex);
		}
	},
	set_app_id = id => {
		try {
			if (window.adaEmbed) window.adaEmbed.setMetaFields({ app_id: id });
		} catch (ex) {
			SENTRY.log(ex);
		}
	};
