import * as Sentry from '@sentry/react';

let timezone_diff = 99;

export const setTimezoneDiff = val => {
		timezone_diff = val;
	},
	formatDateTime = (obj_date, include_date, include_time, is_UTC) => {
		// tms:  don't manipulate the date argument by ref (in case of UTC)
		let d = typeof obj_date === 'string' ? new Date(obj_date.replace(/-/g, '/')) : new Date(obj_date.getTime());

		if (is_UTC) d = new Date(d.getTime() - d.getTimezoneOffset() * 60000);

		let _month = d.getMonth() + 1,
			_date = d.getDate(),
			_year = d.getFullYear(),
			_hour = d.getHours(),
			_minute = d.getMinutes(),
			am_pm = 'AM';

		if (_minute < 10) _minute = `0${_minute}`;

		if (!_hour) {
			_hour = 12;
		} else if (_hour >= 12) {
			am_pm = 'PM';
		}

		if (_hour > 12) _hour = _hour - 12;

		if (include_date) {
			return include_time
				? `${_month}/${_date}/${_year} ${_hour}:${_minute} ${am_pm}`
				: `${_month}/${_date}/${_year}`;
		}

		return `${_hour}:${_minute} ${am_pm}`;
	},
	formatDateControl = (control, oldVal) => {
		let val = control.value || '',
			pattern = control.placeholder || 'mm/yyyy';

		oldVal = oldVal || '';

		if (val.length > oldVal.length) {
			val = val.replace(/[^0-9.]/g, '');

			if (val.length >= 2) val = `${val.substring(0, 2)}/${val.substring(2)}`;
			if (pattern.length > 7 && val.length >= 5) val = `${val.substring(0, 5)}/${val.substring(5)}`;
			if (val.length > pattern.length) val = val.substring(0, pattern.length);
		}

		return val;
	},
	withinDateRange = (strStart, strEnd) => {
		if (strStart.includes('T')) strStart = strStart.split('T')[0];
		if (strEnd.includes('T')) strEnd = strEnd.split('T')[0];

		let now = new Date(),
			_d = Number(strStart.split('-')[2]),
			_m = Number(strStart.split('-')[1]),
			_y = Number(strStart.split('-')[0]),
			dStart = new Date(_y, _m - 1, _d);

		_d = Number(strEnd.split('-')[2]);
		_m = Number(strEnd.split('-')[1]);
		_y = Number(strEnd.split('-')[0]);

		let dEnd = new Date(_y, _m - 1, _d);

		dStart.setTime(dStart.getTime() - timezone_diff * 60 * 60 * 1000);
		dEnd.setTime(dEnd.getTime() - timezone_diff * 60 * 60 * 1000);

		return now >= dStart && now < dEnd;
	},
	withinDateTimeRange = (strStart, strEnd) => {
		// expected format is YYYY-MM-DD HH:mm:ss

		let now = new Date(),
			dStart = parseUTCDateTime(strStart),
			dEnd = parseUTCDateTime(strEnd);

		return now >= dStart && now < dEnd;
	},
	str_current_month = () => {
		const now = new Date();
		return `${now.getMonth() + 1}/${now.getFullYear()}`;
	},
	date_reached = str_date => {
		// accepted formats for str_date:  YYYY-MM-DD

		if (str_date.includes('T')) str_date = str_date.split('T')[0];

		let now = new Date(),
			_d = Number(str_date.split('-')[2]),
			_m = Number(str_date.split('-')[1]),
			_y = Number(str_date.split('-')[0]),
			_date = new Date(_y, _m - 1, _d);

		_date.setTime(_date.getTime() - timezone_diff * 60 * 60 * 1000);

		return now >= _date;
	},
	months_passed = (str_date, str_date_compare) => {
		// accepted formats for str_date:  MM-YYYY , MM/YYYY , YYYY-MM-DD , YYYY/MM/DD

		const now = new Date();

		str_date = str_date.replace(/-/g, '/');
		if (str_date_compare) str_date_compare = str_date_compare.replace(/-/g, '/');

		if (str_date.length === 7) {
			let _m = Number(str_date.split('/')[0]),
				_y = Number(str_date.split('/')[1]),
				curr_month = str_date_compare ? Number(str_date_compare.split('/')[0]) : now.getMonth() + 1,
				curr_year = str_date_compare ? Number(str_date_compare.split('/')[1]) : now.getFullYear();

			return (curr_year - _y) * 12 + (curr_month - _m);
		} else {
			let _d = Number(str_date.split('/')[2]),
				_m = Number(str_date.split('/')[1]),
				_y = Number(str_date.split('/')[0]),
				curr_date = str_date_compare ? Number(str_date_compare.split('/')[2]) : now.getDate(),
				curr_month = str_date_compare ? Number(str_date_compare.split('/')[1]) : now.getMonth() + 1,
				curr_year = str_date_compare ? Number(str_date_compare.split('/')[0]) : now.getFullYear(),
				val = (curr_year - _y) * 12 + (curr_month - _m);

			if (curr_year === _y && curr_month === _m) return 0;

			if (curr_year > _y || (curr_year === _y && curr_month > _m)) {
				if (_d > curr_date) val--;
			} else if (_d < curr_date) {
				val++;
			}

			return val;
		}
	},
	years_passed = (str_date, date_compare) => {
		// str_date expected to be in MM/DD/YYYY format
		// date_compare (optional) should be an actual date

		if (!date_compare) date_compare = new Date();

		let _d = Number(str_date.split('/')[1]),
			_m = Number(str_date.split('/')[0]),
			_y = Number(str_date.split('/')[2]),
			curr_year = date_compare.getFullYear(),
			compare_date = new Date(curr_year, _m - 1, _d, 0, 0, 0),
			val = curr_year - _y;

		if (compare_date > date_compare) val--;

		return val;
	},
	strNow = () => {
		let d = new Date(),
			timezoneOffset = d.getTimezoneOffset();

		d = new Date(d.getTime() + timezoneOffset * 60000); // save the UTC value; the formatting module will handle time zones

		let month = d.getMonth() + 1,
			day = d.getDate(),
			year = d.getFullYear(),
			hours = d.getHours(),
			minutes = d.getMinutes(),
			seconds = d.getSeconds();

		if (month < 10) month = '0' + month;
		if (day < 10) day = '0' + day;
		if (hours < 10) hours = '0' + hours;
		if (minutes < 10) minutes = '0' + minutes;
		if (seconds < 10) seconds = '0' + seconds;

		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	},
	parseUTCDateTime = str => {
		// expected format is YYYY-MM-DD HH:mm:ss

		try {
			let now = new Date(),
				timezoneOffset = now.getTimezoneOffset(),
				strDate = str.split(' ')[0],
				strTime = str.split(' ')[1],
				_d = Number(strDate.split('-')[2]),
				_m = Number(strDate.split('-')[1]),
				_y = Number(strDate.split('-')[0]),
				_h = Number(strTime.split(':')[0]),
				_min = Number(strTime.split(':')[1]),
				_s = Number(strTime.split(':')[2]),
				_date = new Date(_y, _m - 1, _d, _h, _min, _s);

			return new Date(_date.getTime() - timezoneOffset * 60000);
		} catch (ex) {
			Sentry.captureException(new Error('Invalid Datetime'), {
				tags: { date_str: str }
			});

			return new Date(1970, 0, 1);
		}
	},
	sort_by_date = (str1, str2, desc) => {
		// expected format: YYYY-MM-YY OR YYYY-MM

		if (str1?.length >= 7 && str2?.length >= 7) {
			const strs1 = str1.split('-'),
				strs2 = str2.split('-'),
				gt_val = desc ? -1 : 1,
				lt_val = desc ? 1 : -1;

			if (Number(strs1[0]) > Number(strs2[0])) return gt_val;
			if (Number(strs1[0]) < Number(strs2[0])) return lt_val;

			if (Number(strs1[1]) > Number(strs2[1])) return gt_val;
			if (Number(strs1[1]) < Number(strs2[1])) return lt_val;

			if (strs1.length > 2 && strs2.length > 2) {
				if (Number(strs1[2]) > Number(strs2[2])) return gt_val;
				if (Number(strs1[2]) < Number(strs2[2])) return lt_val;
			}
		}

		return 0;
	};
