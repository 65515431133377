import React, { Component } from 'react';
import { connect } from 'react-redux';

import API from 'components/api';
import Textbox from 'components/controls/textbox';
import { scrollTo, MSG_DIRECT_ADMIT } from 'components/modules/_misc';
import _svg from 'components/modules/svg';
import BasicNavMenu from 'components/Navbars/Basic';
import withRouter from 'components/Wrappers/withRouter';
import { confirmAlert } from 'react-confirm-alert';
import { GlobalActions } from 'reducers/global';
import { UserActions } from 'reducers/user';
import ShowNotifications from 'views/Public/shared-content/show-notifications';

import 'assets/css/form-controls.css';
import 'assets/css/normalize.css';
import 'assets/css/webflow.css';
import 'assets/css/uw-help.webflow.css';
import 'assets/css/uw-help-custom.css';

const main = require('assets/homepageImages/UWs-logo/white.png'),
	image500 = require('assets/homepageImages/UWs-logo/white-500.png'),
	image800 = require('assets/homepageImages/UWs-logo/white-800.png'),
	image1080 = require('assets/homepageImages/UWs-logo/white-1080.png'),
	image1600 = require('assets/homepageImages/UWs-logo/white-1600.png');

class LinkDirectAdmit extends Component {
	constructor(props) {
		super(props);

		const { prev_page, direct_admit } = this.props;

		this.state = {
			email: '',
			code: '',
			working: false,
			show_success: false,
			resent: false
		};

		this.new_account = prev_page === '/verify' && !!direct_admit?.email;
	}

	componentDidMount() {
		const { direct_admit } = this.props;

		if (this.new_account) this.getAPI().sendDirectAdmitVerifyEmail(direct_admit.email);

		scrollTo();
	}

	componentDidUpdate(prevProps, prevState) {
		const { direct_admit, navigate } = this.props,
			{ show_success } = this.state;

		if (direct_admit.user_id && !prevProps.direct_admit.user_id) this.setState({ show_success: true });

		if (!show_success && prevState.show_success) navigate('/my-account');
	}

	getAPI = () => {
		const { support_student_id, campus_admin } = this.props;
		return new API('', support_student_id, campus_admin);
	};

	onVerifyEmail = () => {
		const { captureError, setDirectAdmit } = this.props,
			{ email } = this.state;

		this.setState({ working: true });

		this.getAPI()
			.sendDirectAdmitVerifyEmail(email)
			.then(resp => {
				this.setState({ working: false });

				if (resp.result === 'success') {
					setDirectAdmit({ email: email });
				} else {
					confirmAlert({
						customUI: ({ onClose }) => {
							return (
								<div className='react-confirm-alert'>
									<div className='react-confirm-alert-body'>
										{MSG_DIRECT_ADMIT.send_email(resp.error, email)}

										<div className='react-confirm-alert-button-group'>
											<button onClick={onClose}>OK</button>
										</div>
									</div>
								</div>
							);
						}
					});
				}
			})
			.catch(ex => captureError(ex));
	};

	onVerifyCode = () => {
		let { captureError, setDirectAdmit, direct_admit } = this.props,
			{ code, working } = this.state;

		this.setState({ working: true });

		this.getAPI()
			.verifyDirectAdmitEmail(direct_admit.email, code)
			.then(resp => {
				if (resp.result === 'success') {
					this.setState({ working: false });
					setDirectAdmit(resp.record);
				} else {
					confirmAlert({
						customUI: ({ onClose }) => {
							return (
								<div className='react-confirm-alert'>
									<div className='react-confirm-alert-body'>
										{MSG_DIRECT_ADMIT.verify_record(resp.error, direct_admit.email)}

										<div className='react-confirm-alert-button-group'>
											<button
												disabled={working}
												onClick={() => {
													this.setState({ working: true, code: '' });

													this.getAPI()
														.sendDirectAdmitVerifyEmail(direct_admit.email)
														.then(resp => {
															this.setState({ working: false });
															onClose();
														});
												}}>
												Send me a new code
											</button>
											<button disabled={working} onClick={onClose}>
												OK
											</button>
										</div>
									</div>
								</div>
							);
						}
					});
				}
			})
			.catch(ex => captureError(ex));
	};

	onResendCode = () => {
		const { direct_admit } = this.props;

		this.setState({ code: '', resent: true });
		this.getAPI().sendDirectAdmitVerifyEmail(direct_admit.email);

		setTimeout(() => {
			this.setState({ resent: false });
		}, 3000);
	};

	renderEmailForm = () => {
		const { email, working } = this.state;

		return (
			<>
				<h1 className='column-heading'>Let's find your Direct Admit offers</h1>

				<p className='last-p'>
					Enter your <strong>high school email address</strong> included on your Direct Admit letter to verify
					your identity and access your Direct Admit offers.
				</p>

				<Textbox
					value={email}
					onChange={val => this.setState({ email: val })}
					name='email'
					label='Email'
					required={true}
					maxLength={70}
				/>

				<input
					type='button'
					onClick={() => this.onVerifyEmail()}
					disabled={working}
					className='btn-general primary'
					value='Verify'
				/>
			</>
		);
	};

	renderCodeForm = () => {
		const { direct_admit } = this.props,
			{ code, resent, working } = this.state;

		return (
			<>
				{this.new_account ? (
					<>
						<h1 className='column-heading'>Looking Good!</h1>

						<p>
							Thanks! Your account has been verified and is ready to go. To access your Direct Admit
							admission offers, we need to verify your high school email too.
						</p>
						<p className='last-p'>
							We have sent a code to <strong>{direct_admit.email}</strong>.
						</p>
					</>
				) : (
					<>
						<h1>Let's find your Direct Admit offers</h1>

						<p className='last-p'>
							We've sent a code to <strong>{direct_admit.email}</strong>.
						</p>
					</>
				)}

				<Textbox
					value={code}
					onChange={val => this.setState({ code: val })}
					name='code'
					label='Code'
					required={true}
					maxLength={10}
					classVals={['floating-short']}
				/>

				<div className='btn-line-wrapper'>
					<input
						type='button'
						onClick={() => this.onVerifyCode()}
						disabled={resent}
						className='btn-general primary'
						value='Verify'
					/>
					<input
						type='button'
						onClick={() => this.onResendCode()}
						disabled={resent || working}
						className={`btn-general ${resent ? 'triggered' : ''}`}
						value={resent ? 'Resent!' : 'Resend Code'}
					/>
				</div>

				<p className='link-da-no-code'>Not receiving your code? Please give us a call at 800-442-6459.</p>
			</>
		);
	};

	renderSuccess = () => {
		const { navigate } = this.props;

		return (
			<>
				<h1 className='column-heading'>Email Verified</h1>

				{this.new_account ? (
					<p>That&apos;s what we needed!</p>
				) : (
					<p>Great! Now you can access your Direct Admit offers.</p>
				)}

				<div className='success-img-wrapper'>
					<img alt='Success' src={_svg.InitInfoYes} />
				</div>

				<input
					type='button'
					className='btn-general btn-da-linked'
					onClick={() => navigate('/my-account')}
					value={"OK, Let's go!"}
				/>
			</>
		);
	};

	render() {
		let { direct_admit } = this.props,
			content = direct_admit?.email ? this.renderCodeForm() : this.renderEmailForm();

		if (direct_admit.user_id) content = this.renderSuccess();

		return (
			<div className='body-2' role='main'>
				<div
					data-collapse='medium'
					data-animation='default'
					data-duration='400'
					data-easing='ease-out'
					data-easing2='ease-out'
					className='nav-2-columns w-nav'>
					<div className='navigation-container'>
						<a href='/' className='logo w-inline-block'>
							<img
								src={main}
								width='100'
								srcSet={`${image500} 500w, ${image800} 800w, ${image1080} 1080w, ${image1600} 1600w, ${main} 1647w`}
								sizes='(max-width: 479px) 55vw, (max-width: 991px) 200px, 100vw'
								alt=''
								className='image-3'
							/>
						</a>
						<div className='menu-button w-nav-button'>
							<div className='icon-2 w-icon-nav-menu' onClick={this.mobileSidebarToggle} />
						</div>
					</div>
				</div>

				<div className='two-column-view' role='navigation'>
					<BasicNavMenu />

					<div className='right-column'>
						<ShowNotifications />

						<div className='right-column-wrap direct-admit-form'>
							<div className='form-block w-form'>{content}</div>
						</div>
					</div>
				</div>
				<script
					src='https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.4.1.min.220afd743d.js?site=5ea1ccd99c38e78f25db182e'
					type='text/javascript'
					integrity='sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo='
					crossOrigin='anonymous'></script>
				<script src='assets/webflowJS/webflow.js' type='text/javascript'></script>
			</div>
		);
	}
}

const mapStateToProps = state => ({
		support_student_id: state.user.support_student_id,
		campus_admin: state.user.campus_admin,
		direct_admit: state.user.direct_admit,
		prev_page: state.global.prev_page
	}),
	mapDispatchToProps = dispatch => ({
		setDirectAdmit: obj => dispatch(UserActions.setDirectAdmit(obj)),
		captureError: err => dispatch(GlobalActions.captureError(err))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LinkDirectAdmit));
