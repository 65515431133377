import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';

import routes from 'components/routes';
import withRouter from 'components/Wrappers/withRouter';
import MobileNavbar from 'components/Navbars/MobileNavbar';
import Sidebar from 'components/Sidebar/Sidebar';
import MobileWarningModal from 'views/Public/Modals/MobileWarning';
import LoadingPage from 'views/NotAvailable/Loading';

class AppLayout extends Component {
	constructor(props) {
		super(props);

		this.state = {
			current_route: routes.find(r => r.path === props.location.pathname) || {}
		};
	}

	componentDidMount() {
		const { current_route } = this.state;
		this.setPageAttributes(current_route);
	}

	componentDidUpdate(prevProps) {
		const { location, app_id } = this.props;

		if (location.pathname !== prevProps.location.pathname || app_id !== prevProps.app_id) {
			const route = routes.find(r => r.path === location.pathname) || {};

			this.setState({ current_route: route });
			this.setPageAttributes(route);
		}
	}

	setPageAttributes(route) {
		let { app_id } = this.props,
			path = `${route.path || ''}`,
			layout = '';

		if (path.startsWith('/')) path = path.substring(1);
		if (!path && !!route.path) path = 'index';

		if (['index', 'direct-admit'].includes(path)) layout = 'home';
		if (['sign-in', 'create-account', 'reset-password', 'link-direct-admit'].includes(path)) layout = 'onboard';

		if (!layout) {
			if (route.navMenu === 'none') layout = 'single-card';
			if (route.path) layout = 'primary';
		}

		document.getElementById('root').setAttribute('data-page', path);
		document.getElementById('root').setAttribute('data-layout', layout);
		document.getElementById('root').setAttribute('data-app', !!app_id);
	}

	renderLayout() {
		const { location, page_loading, current_page, loading_local_user } = this.props,
			{ current_route } = this.state;

		if (location.pathname !== current_page || page_loading || loading_local_user) {
			return <LoadingPage />;
		}

		if (
			['/', '/direct-admit', '/sign-in', '/create-account', '/reset-password', '/link-direct-admit'].includes(
				location.pathname
			)
		) {
			return (
				<>
					<Sidebar />
					<Outlet />
				</>
			);
		}

		if (current_route.navMenu === 'none') {
			return (
				<div className='wrapper noSidebarWrapper' role='main'>
					<div id='main-panel' className='main-panel noSidebarPanel'>
						<Outlet />
					</div>
				</div>
			);
		}

		return (
			<div className='wrapper' role='main'>
				<Sidebar />

				<div id='main-panel' className='main-panel'>
					<MobileNavbar />
					<Outlet />
				</div>
			</div>
		);
	}

	render() {
		return (
			<>
				<div className='constellation-bg'></div>
				<MobileWarningModal />
				{this.renderLayout()}
			</>
		);
	}
}

const mapStateToProps = state => {
	const { current_page, loading_data } = state.global,
		{ loading_local_user, app_id } = state.user,
		route = routes.find(r => r.path === current_page),
		missing_fields = (route?.dataDependencies || []).filter(key => loading_data.includes(key));

	return {
		page_loading: !!missing_fields.length,
		loading_data: loading_data,
		loading_local_user: loading_local_user,
		current_page: current_page,
		app_id: app_id
	};
};

export default withRouter(connect(mapStateToProps)(AppLayout));
