import React from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

export default function withRouter(Component) {
	function ComponentWithRouterProp(_props) {
		let props = {
			..._props,
			location: useLocation(),
			navigate: useNavigate(),
			params: useParams()
		};

		return <Component {...props} />;
	}

	return ComponentWithRouterProp;
}
