import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';

import { Card } from 'components/Card/Card';
import Select from 'react-select';
import Button from 'components/CustomButton';
import { onlyUnique } from 'components/modules/_misc';
import { ACAD_LEVELS, MATCHES_SEARCH } from 'components/modules/programs';
import { find_a_program as styles } from 'components/modules/styles';
import { CAMPUS_TITLES, DEFUNCT_CAMPUS_CODES, getCampusProgramsPage } from 'components/modules/campus';

const getDistanceLearningFlag = prog => {
		if (prog.field_flags?.includes('Online 100%')) return 'Online';
		if (prog.field_flags?.includes('Blended')) return 'Online/In-Person';

		return 'In-Person';
	},
	setOptsDegreeType = acad_level =>
		ACAD_LEVELS.base[acad_level]
			.map(code => ACAD_LEVELS.names[code])
			.filter(onlyUnique)
			.map(str => ({ label: str, value: `DEGREE-TYPE|${str}` })),
	setOptsProgramType = (strAcadLevel, majors) => {
		let arr = [],
			majors_at_current_acad_level = majors.filter(major =>
				ACAD_LEVELS.base[strAcadLevel].includes(major.field_academic_level)
			);

		majors_at_current_acad_level.forEach(major => {
			const { field_cmc_program_type: _type } = major;
			if (_type && !arr.includes(_type)) arr.push(_type);

			major.sub_programs.forEach(sub_program => {
				const { field_cmc_program_type: _type } = sub_program;
				if (_type && !arr.includes(_type)) arr.push(_type);
			});
		});

		arr.sort((x, y) => {
			if (x < y) return -1;
			if (x > y) return 1;
			return 0;
		});

		return arr.map(str => ({ label: str, value: `PROG-TYPE|${str}` }));
	};

class FindAProgram extends Component {
	constructor(props) {
		super(props);

		this.state = {
			_string: '',
			_acad_level: 'Undergraduate',
			_career_clusters: [],
			_campuses: [],
			_filters: [],
			results: []
		};

		this.majors = props.majors
			.filter(maj => !DEFUNCT_CAMPUS_CODES.includes(maj.field_campus_id))
			.map(maj => {
				DEFUNCT_CAMPUS_CODES.forEach(code => {
					const i = maj.field_override_campuses.indexOf(code);
					if (i > -1) maj.field_override_campuses.splice(i, 1);
				});

				return maj;
			});

		this.opts = {
			career_clusters: props.career_clusters.map(str => ({ label: str, value: str })),
			campuses: props.campuses.map(c => ({
				label: c.title,
				value: `${c.field_abbreviation}|${c.id}`
			})),
			degree_type: {
				Undergraduate: setOptsDegreeType('Undergraduate'),
				Graduate: setOptsDegreeType('Graduate')
			},
			program_type: {
				Undergraduate: setOptsProgramType('Undergraduate', this.majors),
				Graduate: setOptsProgramType('Graduate', this.majors)
			},
			delivery: [
				{ label: 'In-Person', value: 'DELIVERY|In-Person' },
				{ label: 'Online', value: 'DELIVERY|Online' }
			]
		};
	}

	componentDidUpdate(prevProps, prevState) {
		const { _string, _acad_level, _career_clusters, _campuses, _filters } = this.state,
			search_changed =
				_string !== prevState._string ||
				_career_clusters !== prevState._career_clusters ||
				_campuses !== prevState._campuses ||
				_filters.join('*') !== prevState._filters.join('*');

		if (_acad_level !== prevState._acad_level) {
			this.setState({ _filters: [..._filters].filter(str => !str.value.includes('DEGREE-TYPE|')) });
		}

		if (_acad_level !== prevState._acad_level || search_changed) this.onSearch();
	}

	onSearch = () => {
		const { _string, _acad_level, _career_clusters, _campuses, _filters } = this.state,
			_matchProgram = prog => {
				const { field_academic_level } = prog,
					campuses = _campuses.map(opt => ({
						id: opt.value.split('|')[1],
						code: opt.value.split('|')[0],
						title: opt.label
					}));

				return (
					ACAD_LEVELS.base[_acad_level].includes(field_academic_level) &&
					MATCHES_SEARCH._string(prog, _string) &&
					MATCHES_SEARCH._career_cluster(prog, _career_clusters) &&
					MATCHES_SEARCH._campus(prog, campuses) &&
					MATCHES_SEARCH._degree_type(prog, _filters) &&
					MATCHES_SEARCH._delivery(prog, _filters) &&
					MATCHES_SEARCH._program_type(prog, _filters)
				);
			},
			results = this.majors.filter(
				rec => _matchProgram(rec) || rec.sub_programs.some(subProg => _matchProgram(subProg))
			);

		// the secondary sort is campus, which is already done within mapStateToProps
		results.sort((x, y) => {
			if (x.title < y.title) return -1;
			if (x.title > y.title) return 1;
			return 0;
		});

		this.setState({ results: results });
	};

	highlightMatch = _value => {
		let { _string } = this.state,
			value = `${_value}`;

		if (_string) {
			const i = value.toLowerCase().indexOf(_string.toLowerCase());

			if (i > -1) {
				const str_1 = value.substr(0, i),
					str_2 = value.substr(i, _string.length),
					str_3 = value.substr(i + _string.length);

				value = (
					<>
						{str_1}
						<span className='str-match'>{str_2}</span>
						{str_3}
					</>
				);
			}
		}

		return <>{value}</>;
	};

	renderMajor = prog => {
		let { _string } = this.state,
			url = prog.field_program_url || getCampusProgramsPage(prog.field_campus_id),
			simple_distance_learning = !prog.sub_programs.some(
				subProg => getDistanceLearningFlag(subProg) !== getDistanceLearningFlag(prog)
			),
			keywords = prog.field_keywords || [],
			matching_keywords = [],
			matches_text_search = value => !!_string.length && value.toLowerCase().indexOf(_string.toLowerCase()) > -1,
			show_matching_keywords =
				!!_string.length &&
				!matches_text_search(prog.title) &&
				!prog.sub_programs.some(sub_prog => matches_text_search(sub_prog.title));

		prog.sub_programs.forEach(subProg => {
			subProg.field_keywords.forEach(keyword => {
				if (!keywords.includes(keyword)) keywords.push(keyword);
			});
		});

		if (show_matching_keywords) {
			matching_keywords = keywords
				.sort()
				.map(keyword => {
					return matches_text_search(keyword) ? this.highlightMatch(keyword) : null;
				})
				.filter(str => !!str);
		}

		return (
			<section key={prog.id} className='find-prog'>
				<h3>
					{CAMPUS_TITLES[prog.field_campus_id]}
					<span>{ACAD_LEVELS.names[prog.field_academic_level]}</span>
				</h3>

				<a data-value={prog.id} className='parent-line' href={url} target='_blank' rel='noopener noreferrer'>
					<span>
						{this.highlightMatch(prog.title)}

						{prog.field_cmc_program_type !== 'Major' && (
							<span className='prog-type'>| {prog.field_cmc_program_type}</span>
						)}
					</span>

					<span className='dist-learning'>{getDistanceLearningFlag(prog)}</span>
				</a>

				{prog.sub_programs.map(sub_program => (
					<a
						data-value={sub_program.id}
						className='child-line'
						key={`${prog.id}|${sub_program.id}`}
						href={sub_program.field_program_url || ''}
						target='_blank'
						rel='noopener noreferrer'>
						<span className={simple_distance_learning ? '' : 'cut-off'}>
							{this.highlightMatch(sub_program.title)}

							{sub_program.field_cmc_program_type !== 'Concentration' && (
								<span className='prog-type'>| {sub_program.field_cmc_program_type}</span>
							)}
						</span>

						{!simple_distance_learning && (
							<span className='dist-learning'>{getDistanceLearningFlag(sub_program)}</span>
						)}
					</a>
				))}

				{!!matching_keywords.length && (
					<p className='keywords'>
						matched keywords:{' '}
						{matching_keywords.map((obj, i) => (
							<React.Fragment key={i}>
								{!!i && ' | '}
								{obj}
							</React.Fragment>
						))}
					</p>
				)}
			</section>
		);
	};

	render = () => {
		let { results, _string, _acad_level, _campuses, _career_clusters, _filters } = this.state,
			show_results = _string.length >= 3 || !!_career_clusters.length || !!_campuses.length || !!_filters.length;

		return (
			<Card
				title='FIND A PROGRAM'
				noFooter={true}
				content={
					<div className='find-major-card'>
						<p className='intro-copy'>
							There are thousands of majors and academic programs available within the Universities of
							Wisconsin. Use any or all of the filters available to find the one for you! Your
							major/program can always be changed later by emailing the campus or at orientation.
						</p>

						<div className='acad-level-picker'>
							<Button
								className={_acad_level === 'Undergraduate' ? 'selected' : ''}
								onClick={() => this.setState({ _acad_level: 'Undergraduate' })}>
								Undergraduate
							</Button>
							<Button
								className={_acad_level === 'Graduate' ? 'selected' : ''}
								onClick={() => this.setState({ _acad_level: 'Graduate' })}>
								Graduate
							</Button>
						</div>

						<div className='major-search-controls'>
							<input
								type='text'
								name='majorValue'
								placeholder='What do you want to study...?'
								aria-label='Search for a major'
								value={_string}
								onChange={e => this.setState({ _string: e.target.value })}
							/>
							{!!_string && (
								<button
									className='major-clear'
									aria-label='Clear major search'
									onClick={e => this.setState({ _string: '' })}>
									&times;
								</button>
							)}

							<Select
								isMulti={true}
								placeholder='Select a Career Cluster...'
								name='careerCluster'
								aria-label='Select a Career Cluster'
								options={this.opts.career_clusters}
								classNamePrefix='select'
								onChange={arr => this.setState({ _career_clusters: arr || [] })}
								value={_career_clusters}
								styles={styles.control_styles.career_cluster}
								components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
							/>
							<Select
								isMulti={true}
								placeholder='Select a UW Campus...'
								name='campus'
								aria-label='Select a UW Campus'
								options={this.opts.campuses}
								classNamePrefix='select'
								onChange={arr => this.setState({ _campuses: arr || [] })}
								value={_campuses}
								styles={styles.control_styles.campus}
								components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
							/>
							<Select
								isMulti={true}
								placeholder='Filter'
								name='filter'
								aria-label='Filter'
								options={[
									{
										label: 'Degree Type',
										options: this.opts.degree_type[_acad_level]
									},
									{
										label: 'Course Delivery',
										options: this.opts.delivery
									},
									{ label: 'Program Type', options: this.opts.program_type[_acad_level] }
								]}
								classNamePrefix='select'
								onChange={arr => this.setState({ _filters: arr || [] })}
								value={_filters}
								styles={styles.control_styles.filters}
								components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
							/>
						</div>

						{show_results &&
							(results.length ? (
								<>
									<p className='major-results-counter'>Total Results: {results.length}</p>
									{results.map(prog => this.renderMajor(prog))}
								</>
							) : (
								<p className='no-progs'>Sorry, no available programs meet those criteria</p>
							))}
					</div>
				}
			/>
		);
	};
}

const mapStateToProps = state => {
	const { campuses, cdrMajors, career_clusters } = state.global;

	return {
		campuses: campuses.filter(
			c => !DEFUNCT_CAMPUS_CODES.includes(c.field_abbreviation) && c.field_abbreviation !== 'XPK' // tms:  hide one of the Flex campuses for the drop down; the search method will match either
		),
		majors: cdrMajors,
		career_clusters: career_clusters
			.sort((a, b) => {
				if (a.title < b.title) return -1;
				if (a.title > b.title) return 1;
				return 0;
			})
			.map(cc => cc.title)
	};
};

export default withRouter(connect(mapStateToProps)(FindAProgram));
