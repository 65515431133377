import constellationMap from 'assets/img/constellation-map.svg';

import AppSummary from 'assets/img/AppSummary.svg';
import Delete from 'assets/img/Delete.svg';
import Download from 'assets/img/Download.svg';
import DownloadLink from 'assets/img/Download-link.svg';
import Edit from 'assets/img/Edit.svg';
import GreenCirclePlus from 'assets/img/GreenCirclePlus.svg';
import Pause from 'assets/img/Pause.svg';

import HomepagePlus from 'assets/img/Homepage/Plus.svg';

import InitInfoYes from 'assets/img/InitialInformation/Yes.svg';
import InitInfoNo from 'assets/img/InitialInformation/No.svg';
import InitInfoUS from 'assets/img/InitialInformation/US.svg';
import InitInfoGlobe from 'assets/img/InitialInformation/Globe.svg';

import AppFeePaid from 'assets/img/MyAccount/app_fee_paid.svg';
import AppFeeNotPaid from 'assets/img/MyAccount/app_fee_not_paid.svg';
import Pay from 'assets/img/MyAccount/Pay.svg';
import View from 'assets/img/MyAccount/View.svg';

import MyAccount from 'assets/img/Sidebar/My_Account.svg';
import MyDocuments from 'assets/img/Sidebar/My_Documents.svg';
import AccountInformation from 'assets/img/Sidebar/Account_Information.svg';
import CircleCheck from 'assets/img/Sidebar/CircleCheck.svg';
import CircleX from 'assets/img/Sidebar/CircleX.svg';
import ChevronDown from 'assets/img/Sidebar/ChevronDown.svg';
import ContactUs from 'assets/img/Sidebar/ContactUs.svg';
import CreateAccount from 'assets/img/Sidebar/CreateAccountIcon.svg';
import FindAProgram from 'assets/img/Sidebar/Find_a_Program.svg';
import FindYourTerm from 'assets/img/Sidebar/Find_Your_Term.svg';
import Homepage from 'assets/img/Sidebar/Homepage.svg';
import HowToApply from 'assets/img/Sidebar/How_to_Apply.svg';
import RedCircleX from 'assets/img/Sidebar/RedCircleX.svg';
import SaveAndReturn from 'assets/img/Sidebar/Save.svg';
import SignIn from 'assets/img/Sidebar/SignInIcon.svg';
import SignOut from 'assets/img/Sidebar/Sign_Out.svg';

import UWS_std from 'assets/img/UWs-logo/standard.svg';
import UWS_white from 'assets/img/UWs-logo/white.svg';

const _svg = {
	constellationMap: constellationMap,

	AppSummary: AppSummary,
	Delete: Delete,
	Download: Download,
	DownloadLink: DownloadLink,
	Edit: Edit,
	GreenCirclePlus: GreenCirclePlus,
	Pause: Pause,

	// Homepage
	HomepagePlus: HomepagePlus,

	// InitialInformation
	InitInfoYes: InitInfoYes,
	InitInfoNo: InitInfoNo,
	InitInfoUS: InitInfoUS,
	InitInfoGlobe: InitInfoGlobe,

	// MyAccount
	AppFeePaid: AppFeePaid,
	AppFeeNotPaid: AppFeeNotPaid,
	Pay: Pay,
	View: View,

	// Sidebar
	MyAccount: MyAccount,
	MyDocuments: MyDocuments,
	AccountInformation: AccountInformation,
	CircleCheck: CircleCheck,
	CircleX: CircleX,
	ChevronDown: ChevronDown,
	ContactUs: ContactUs,
	CreateAccount: CreateAccount,
	FindAProgram: FindAProgram,
	FindYourTerm: FindYourTerm,
	Homepage: Homepage,
	HowToApply: HowToApply,
	RedCircleX: RedCircleX,
	SaveAndReturn: SaveAndReturn,
	SignIn: SignIn,
	SignOut: SignOut,

	UWS_std: UWS_std,
	UWS_white: UWS_white
};

export default _svg;
