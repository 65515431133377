import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import { create_proactive } from 'components/modules/ada';
import { nextAppPage, prevAppPage } from 'components/modules/nav';
import { is_app_open } from 'components/modules/term';
import ShoppingCart from 'components/ShoppingCart';
import API from 'components/api';
import { GlobalActions } from 'reducers/global';
import { UserActions } from 'reducers/user';

class AdditionalApplications extends Component {
	componentDidMount() {
		const { app } = this.props;

		this._mounted = true;

		this.addAssociatedAppsToCart();

		setTimeout(() => {
			if (this._mounted) {
				create_proactive(
					`Yes, ${
						app.application_modifier.includes('FM') ? 'freshmen' : 'transfer applicants'
					} can apply to multiple campuses at once. After you complete one application you will be asked if you would like to apply to more campuses.`,
					'5eb495f0025020f7d0a44ceb'
				);
			}
		}, 2000);
	}

	componentDidUpdate(prevProps) {
		const { apps_in_cart } = this.props;
		if (apps_in_cart.length !== prevProps.apps_in_cart.length) this.checkAppFee();
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	getAPI = () => {
		const { support_student_id, campus_admin } = this.props;
		return new API('', support_student_id, campus_admin);
	};

	checkAppFee = () => {
		const { setAppFees, captureError } = this.props;

		this.getAPI()
			.getAllApplications()
			.then(result => {
				let obj = {};

				(result.rows || []).forEach(rec => {
					obj[rec.application_id] = rec.application_fee;
				});

				setAppFees(obj);
			})
			.catch(ex => captureError(ex));
	};

	addAssociatedAppsToCart = () => {
		const { addToCart, terms, app_id, app, apps } = this.props,
			parent_app = apps[app.parent_application_id],
			_app_id = parent_app ? app.parent_application_id : app_id,
			{ title: current_term } = app.initial_information?.chosen_semester || {},
			parent_id = parent_app ? parent_app.parent_application_id : app.parent_application_id;

		Object.keys(apps).forEach(key => {
			let { campus, term, app_type, paid, percentage_complete, date_submitted, json_obj } = apps[key];

			const id_match =
					key === _app_id ||
					json_obj.parent_application_id === _app_id ||
					json_obj.parent_application_id === parent_id,
				ignore =
					(key !== _app_id && json_obj.parent_application_id === -1 && parent_id === -1) ||
					percentage_complete < 100 ||
					!campus ||
					!term ||
					!app_type ||
					parseInt(paid) === 1 ||
					!!date_submitted ||
					term !== current_term ||
					!is_app_open(json_obj, terms);

			if (id_match && !ignore) addToCart(key);
		});
	};

	saveAndContinue = e => {
		e.preventDefault();
		e.stopPropagation();

		let { navigate, setScreensCompleted, app, location } = this.props;

		setScreensCompleted();
		navigate(nextAppPage(app, location.pathname));
	};

	applyToMoreCampuses = () => {
		let { navigate, createAdditionalApp } = this.props;
		createAdditionalApp();
		navigate('/select-campus');
	};

	render() {
		const { app, apps_in_cart, navigate, location } = this.props,
			strApplyingTo =
				apps_in_cart.split('|').length > 1 ? 'the above campuses' : app.initial_information.chosen_campus.title;

		return (
			<Card
				title='SUBMIT APPLICATION'
				content={
					<form id={'form'} onSubmit={this.saveAndContinue}>
						<p>Would you like to apply to more campuses?</p>

						<h3 className='uwred'>Applications to Submit</h3>
						<ShoppingCart role='ADD-APPS' />

						<p>You have completed but not submitted your application to {strApplyingTo}.</p>

						<p>What to know about applying to multiple campuses:</p>
						<ul>
							<li className={'transitionLI'}>
								You can apply to multiple campuses for the same term and application type (i.e. freshman
								or transfer)
							</li>
							<li className={'transitionLI'}>
								You will have the opportunity to review and update your essay for your new campus(es)
							</li>
						</ul>

						{(app.parent_application_id || '-1') !== '-1' && (
							<div>
								<p className={'text'}>
									Please double check your information and essay prompt before continuing.
								</p>
							</div>
						)}

						<div className='clearfix' />
					</form>
				}
				buttons={
					<>
						<Button
							bsStyle='info'
							className='back-btn'
							fill
							onClick={() => navigate(prevAppPage(app, location.pathname))}>
							Back
						</Button>
						<Button bsStyle='info' className='save-btn' fill onClick={() => this.applyToMoreCampuses()}>
							Apply to more campuses
						</Button>
						<Button form={'form'} autoFocus bsStyle='info' className='save-btn' fill type={'submit'}>
							Continue
						</Button>
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => {
		const { apps, app_id, apps_in_cart, support_student_id, campus_admin } = state.user;

		return {
			apps: apps,
			app: apps[app_id].json_obj,
			app_id: app_id,
			apps_in_cart: apps_in_cart,
			support_student_id: support_student_id,
			campus_admin: campus_admin,
			terms: state.global.terms
		};
	},
	mapDispatchToProps = dispatch => ({
		createAdditionalApp: () => dispatch(UserActions.createAdditionalApp()),
		addToCart: id => dispatch(UserActions.addToCart(id)),
		setScreensCompleted: () => dispatch(UserActions.setScreensCompleted({ additional: true })),
		setAppFees: obj => dispatch(UserActions.setAppFees(obj)),
		captureError: err => dispatch(GlobalActions.captureError(err))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdditionalApplications));
