import React from 'react';

export const formatNotificationBody = objNotification => {
	let { field_notification_body, field_link_url } = objNotification,
		content = `${field_notification_body}`,
		strs = field_notification_body.split('|');

	if (strs.length > 1) {
		if (!field_link_url || strs.length !== 3) {
			content = content.split('|').join('');
		} else {
			const external_url = !field_link_url.indexOf('http'),
				link = external_url ? (
					<a href={field_link_url} target='_blank' rel='noopener noreferrer'>
						{strs[1]}
					</a>
				) : (
					<a href={field_link_url}>{strs[1]}</a>
				);

			content = (
				<>
					{strs[0]}
					{link}
					{strs[2]}
				</>
			);
		}
	}

	return <span>{content}</span>;
};
