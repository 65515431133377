import React, { Component } from 'react';
import { NoSidebarCard } from 'components/Card/NoSidebarCard';

class IEDisabled extends Component {
	render() {
		return (
			<NoSidebarCard
				header={'UNSUPPORTED BROWSER'}
				content={
					<form>
						<div className='constellation-bg'></div>
						<div className='col-md-10 col-centered'>
							<p>
								You are attempting to access this site with an unsupported browser. For best results,
								use the latest version of one of these browsers:
							</p>
						</div>
						<div className='col-md-2 col-centered'>
							<ul>
								<li>Firefox</li>
								<li>Chrome</li>
								<li>Edge</li>
								<li>Safari</li>
							</ul>
						</div>
						<div className='clearfix' />
					</form>
				}
			/>
		);
	}
}

export default IEDisabled;
