import React, { Component } from 'react';
import { connect } from 'react-redux';

import withRouter from 'components/Wrappers/withRouter';
import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';

class HowToApply extends Component {
	onNavigate = () => {
		const { email, navigate } = this.props;
		navigate(email ? '/my-account' : '/sign-in');
	};

	render() {
		return (
			<Card
				title='How to Apply'
				buttons={
					<Button bsStyle='info' className='tertiaryButton' fill onClick={() => this.onNavigate()}>
						My Account
					</Button>
				}
				content={
					<>
						<p>
							Welcome to the next chapter of your life. Your life is about to change. We don't know how,
							only that it will. You will be challenged to learn more deeply, think more critically, and
							push beyond boundaries. This time in your life will be filled not only with learning and
							growth, but also new relationships, new experiences, and a range of emotions.
						</p>
						<p>We're here for it all &mdash; with you and for you. We know you can and will succeed.</p>

						<hr />

						<h2>Student Types (see content below)</h2>
						<ul>
							<li>
								<a href='#freshman'>Future Freshman/First-Year Student</a>
							</li>
							<li>
								<a href='#transfer'>Transfer Student</a>
							</li>
							<li>
								<a href='#re-enroll'>Re-Enrolling Student</a>
							</li>
							<li>
								<a href='#grad'>Graduate Student</a>
							</li>
							<li>
								<a href='#online'>Online Student</a>
							</li>
							<li>
								<a href='#dual-enroll'>Dual Enrollment/Non-Degree Student</a>
							</li>
							<li>
								<a href='#international'>International Student</a>
							</li>
						</ul>

						<h2 id='freshman'>Future Freshman/First-Year Student</h2>
						<p>
							You are a future freshman or first-year student if you have not yet graduated from high
							school, or if you have graduated from high school but have not yet attended college. If you
							took college classes while you were enrolled in high school, you are still considered a
							freshman or first-year student applicant.
						</p>
						<p>
							The Universities of Wisconsin offers{' '}
							<a href='https://www.wisconsin.edu/students/wipathways/' target='__blank'>
								three paths to first-year student/freshman undergraduate admission
							</a>{' '}
							for Wisconsin high school students.
						</p>

						<h3>New Admission Programs</h3>
						<ul>
							<li>
								<a href='https://www.wisconsin.edu/direct-admissions' target='__blank'>
									<strong>Direct Admit Wisconsin</strong>
								</a>
								: Universities of Wisconsin to proactively offer admission to Wisconsin high school
								students participating in Direct Admit Wisconsin. To view your Direct Admit offers and
								to submit a Direct Admit form, visit{' '}
								<a href='/direct-admit'>apply.wisconsin.edu/direct-admit</a>.
							</li>
							<li>
								<a href='https://wisconsin.edu/wisconsin-guarantee' target='__blank'>
									<strong>Wisconsin Guarantee</strong>
								</a>
								: Wisconsin high school students in the top 5% to 10% of their graduating class and who
								meet eligibility requirements will be guaranteed admission to the Universities of
								Wisconsin through the Wisconsin Guarantee. Submit your Universities of Wisconsin
								Application to be considered for the Wisconsin Guarantee.
							</li>
						</ul>

						<h2 id='transfer'>Transfer Student</h2>
						<p>
							A transfer student is anyone who has attended a college or university after graduating from
							high school and wishes to enroll in an undergraduate degree program at one of the
							Universities of Wisconsin. Transfer students are vital members of our UW universities. Our
							campuses are ready to help ease your transfer and support your success!
						</p>

						<h2 id='re-enroll'>Re-Enrolling Student</h2>
						<p>
							Whether you want to return to pursue your undergraduate or graduate/professional degree,
							continue your educational journey by returning to the Universities of Wisconsin.
							Re-Enrolling students have previously attended a UW as degree-seeking students in the past
							and wish to take classes again at that university.
						</p>

						<h2 id='grad'>Graduate Student</h2>
						<p>
							Your academic and personal growth continues when you decide to apply to graduate school.
							Whether you're looking to advance within your field of study or wanting to explore one of
							your other interests, the Universities of Wisconsin is ready to help you succeed. You may
							apply to many, but not all, graduate and professional programs using the Universities of
							Wisconsin Application
						</p>

						<h2 id='online'>Online Student</h2>
						<p>
							If you are interested in fully online undergraduate, graduate, or professional degree
							options, see{' '}
							<a target='__blank' href='https://online.wisconsin.edu/'>
								Wisconsin Online
							</a>{' '}
							for available programs.
						</p>

						<h2 id='dual-enroll'>Dual Enrollment/Non-Degree Student</h2>
						<p>
							Are you a current high school student who wants to earn college credit before you graduate?
							The Universities of Wisconsin offer a variety of opportunities for students attending high
							school to complete college courses. You can earn college credit by taking a course at your
							high school or on a UW university campus.
						</p>
						<p>
							If you are interested in continuing your education, seeking professional development, to
							enhance your skills, or to pursue a new passion, you can apply as a non-degree applicant.
						</p>

						<h2 id='international'>International Student</h2>
						<p>
							You are an international student if you require any type of visa to attend a UW university.
							This includes students living inside and outside the United States. All 13 Universities of
							Wisconsin welcome applications from international students who hope to pursue an
							undergraduate or graduate degree.
						</p>
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => ({
	email: state.user.email
});

export default withRouter(connect(mapStateToProps)(HowToApply));
