import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import Button from 'components/CustomButton';

class MobileWarningModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			mobile_acknowledged: false
		};
	}

	render() {
		const { mobile_acknowledged } = this.state;

		return (
			<Modal show={isMobile && !mobile_acknowledged}>
				<Modal.Header>
					<Modal.Title className='h2'>This looks like a mobile device</Modal.Title>
				</Modal.Header>
				<Modal.Body className='modalMinHeight'>
					<p>
						There can be a lot that goes into your college application. You might have a better experience
						applying on a desktop or laptop.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						bsStyle='info'
						className='back-btn'
						fill={true}
						onClick={() => this.setState({ mobile_acknowledged: true })}>
						No thanks, this is fine
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default MobileWarningModal;
