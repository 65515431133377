import React, { Component } from 'react';
import { Nav } from 'react-bootstrap';

class MobileNavbarLinks extends Component {
	render() {
		return (
			<div>
				<Nav pullRight>
					{/*<NavItem eventKey={1} href="my-account">*/}
					{/*	Account*/}
					{/*</NavItem>*/}
					{/*<NavItem eventKey={3} href="sign-out">*/}
					{/*	Log Out*/}
					{/*</NavItem>*/}
				</Nav>
			</div>
		);
	}
}

export default MobileNavbarLinks;
