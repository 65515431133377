import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import { cloneDeep } from 'components/modules/_misc';
import RadioList from 'components/controls/radio_list';
import { nextAppPage, prevAppPage } from 'components/modules/nav';
import { UserActions } from 'reducers/user';

class AcademicBackgroundHigherEducationCheck extends Component {
	componentDidMount() {
		this.props.setSubmenu();
	}

	componentWillUnmount() {
		const { saveApp, app, updateApp } = this.props,
			academic_background = cloneDeep(app.academic_background);

		if (academic_background.any_dual_enrollment === 'No') {
			academic_background.colleges = [];
			updateApp({ academic_background: academic_background });
		}

		saveApp(!!app.academic_background.any_dual_enrollment);
	}

	onSubmit = e => {
		e.preventDefault();
		e.stopPropagation();

		const { navigate, app, location } = this.props;
		navigate(nextAppPage(app, location.pathname));
	};

	onChange = val => {
		let { app, updateApp } = this.props,
			academic_background = cloneDeep(app.academic_background);

		if (!app.date_submitted) {
			academic_background.any_dual_enrollment = val;
			updateApp({ academic_background: academic_background });
		}
	};

	render() {
		const { navigate, app, location } = this.props;

		return (
			<Card
				title='Academic Background: College/Post-Secondary Check'
				content={
					<form id='form' onSubmit={this.onSubmit}>
						<p>
							It's important for your potential campus to know if you are TAKING or have previously taken
							college courses. Please select yes below if:
						</p>
						<ul>
							<li className='transitionLI'>
								You are currently taking college courses while in high school.
							</li>
							<li className='transitionLI'>
								You took at least one course at the college level, while in high school, that you could
								request a transcript for.
							</li>
							<li className='transitionLI'>You are currently a college student.</li>
						</ul>

						<Row>
							<RadioList
								name='any_dual_enrollment'
								value={app.academic_background.any_dual_enrollment}
								onChange={val => this.onChange(val)}
								opts={['Yes', 'No']}
								label={<strong>Have you ever taken any college level courses?</strong>}
								cols={12}
								required={true}
							/>
						</Row>

						<p>
							Do <strong>NOT</strong> select Yes if you have taken or will be taking Advanced Placement
							(AP), International Baccalaureate (IB), or Project Lead the Way Courses.
						</p>
						<div className='clearfix' />
					</form>
				}
				buttons={
					<>
						<Button
							bsStyle='info'
							className='back-btn'
							fill
							onClick={() => navigate(prevAppPage(app, location.pathname))}>
							Back
						</Button>
						<Button
							form='form'
							bsStyle='info'
							className='save-btn'
							disabled={!app.academic_background.any_dual_enrollment}
							fill
							type='submit'>
							Save and Continue
						</Button>
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => {
		const { app_id, apps, support_student_id } = state.user;

		return {
			app: apps[app_id].json_obj,
			app_id: app_id,
			support_student_id: support_student_id
		};
	},
	mapDispatchToProps = dispatch => ({
		updateApp: obj => dispatch(UserActions.updateApp(obj)),
		saveApp: val => dispatch(UserActions.saveApp({ academicBackgroundHigherEdCheckCompleted: val })),
		setSubmenu: () => dispatch(UserActions.setSubmenus('academic_background_open', true))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AcademicBackgroundHigherEducationCheck));
