function getAddressComponentValue(addressComponentType, returnField, addressComponentArray, initialValue) {
	for (let i = 0; i < addressComponentArray.length; i++) {
		let row = addressComponentArray[i];
		if (row.types.includes(addressComponentType)) return row[returnField];
	}

	return initialValue;
}

export const parseAddressFromGoogleResponse = (addr_resp, orig_addr) => {
	let addr = { ...orig_addr },
		streetNumber = getAddressComponentValue('street_number', 'long_name', addr_resp.address_components, ''),
		streetName = getAddressComponentValue('route', 'long_name', addr_resp.address_components, ''),
		subpremise = getAddressComponentValue('subpremise', 'long_name', addr_resp.address_components, '') || '';

	if (streetNumber && streetName) addr.address_1 = `${streetNumber} ${streetName}`;

	addr.address_2 = subpremise.match(/^\d+/) ? `#${subpremise}` : subpremise;

	addr.city = getAddressComponentValue('locality', 'long_name', addr_resp.address_components, addr.city);

	addr.state = getAddressComponentValue(
		'administrative_area_level_1',
		'short_name',
		addr_resp.address_components,
		addr.state
	);

	addr.county = getAddressComponentValue(
		'administrative_area_level_2',
		'long_name',
		addr_resp.address_components,
		addr.county
	);

	addr.zip = getAddressComponentValue('postal_code', 'long_name', addr_resp.address_components, addr.zip);
	addr.error = '';

	addr.formatted_address = addr_resp.formatted_address;

	return addr;
};
