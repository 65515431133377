import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import API from 'components/api';
import { Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { GlobalActions } from 'reducers/global';
import { UserActions } from 'reducers/user';
import BasicNavMenu from 'components/Navbars/Basic';
import CheckboxList from 'components/controls/checkbox_list';
import PhoneControl from 'components/controls/phone';
import RadioList from 'components/controls/radio_list';
import Textbox from 'components/controls/textbox';
import { cleanString, cloneDeep, MSG_DIRECT_ADMIT } from 'components/modules/_misc';
import { REGEXES } from 'components/modules/validation';
import { formatNotificationBody } from 'components/modules/react_content';
import { withinDateTimeRange } from 'components/modules/date';
import AccountCreationTermsModal from 'views/Public/Modals/AccountCreationTerms';
import PracticeWarningModal from 'views/Public/Modals/PracticeWarning';

const { REACT_APP_ENV } = process.env,
	main = require('assets/homepageImages/UWs-logo/white.png'),
	image500 = require('assets/homepageImages/UWs-logo/white-500.png'),
	image800 = require('assets/homepageImages/UWs-logo/white-800.png'),
	image1080 = require('assets/homepageImages/UWs-logo/white-1080.png'),
	image1600 = require('assets/homepageImages/UWs-logo/white-1600.png');

class CreateAccount extends Component {
	constructor(props) {
		super(props);

		this.state = {
			captchaValue: '',
			termsModal: false,
			practiceWarningModal: REACT_APP_ENV === 'practice',
			sidebarExists: false,
			notification_index: 0,
			interval_id: -1,

			hs_email: '',
			email: '',
			confirm_email: '',
			password: '',
			confirm_password: '',
			first_name: '',
			last_name: '',
			phone_number: '',
			no_phone: false,
			permission_to_send_texts: '',
			valid_phone: false,
			working: false,
			direct_admit_email_failed: false
		};

		this.notifications = props.system_notifications.filter(
			n =>
				!n?.field_start_date ||
				!n?.field_end_date ||
				(n.field_display_on_login_screen === '1' && withinDateTimeRange(n.field_start_date, n.field_end_date))
		);
	}

	componentDidMount() {
		this._mounted = true;

		if (this.notifications.length > 1) {
			let funcSetInterval = setInterval(() => {
				const { notification_index } = this.state,
					new_index = notification_index === this.notifications.length - 1 ? 0 : notification_index + 1;

				this.setState({ notification_index: new_index });
			}, 5000);

			this.setState({ interval_id: funcSetInterval });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { email, direct_admit, setDirectAdmit, navigate } = this.props,
			{ direct_admit_email_failed } = this.state;

		if (email && !prevProps.email) {
			if (direct_admit?.id && !prevProps.direct_admit?.id) {
				this.getAPI()
					.sendDirectAdmitVerifyEmail(direct_admit.email)
					.then(resp => {
						if (resp.result === 'success') {
							navigate('/my-account');
						} else {
							confirmAlert({
								customUI: ({ onClose }) => {
									return (
										<div className='react-confirm-alert'>
											<div className='react-confirm-alert-body'>
												<h1>Oops!</h1>
												<p>
													Looks like something went wrong, and we couldn't send a verification
													email to <strong>{direct_admit.email}</strong>. Have you already
													tried to link that Direct Admit record to an account?
												</p>

												<div className='react-confirm-alert-button-group'>
													<button
														onClick={() => {
															setDirectAdmit({ email: direct_admit.email });
															this.setState({ direct_admit_email_failed: true });

															onClose();
														}}>
														OK
													</button>
												</div>
											</div>
										</div>
									);
								}
							});
						}
					});
			} else {
				navigate('/my-account');
			}
		}

		if (direct_admit_email_failed && !prevState.direct_admit_email_failed) navigate('/my-account');
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	getAPI = () => {
		const { support_student_id, campus_admin } = this.props;
		return new API('', support_student_id, campus_admin);
	};

	showMessage = str => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className='react-confirm-alert'>
						<div className='react-confirm-alert-body'>
							<p>{str}</p>
							<div className='react-confirm-alert-button-group'>
								<button onClick={onClose}>OK</button>
							</div>
						</div>
					</div>
				);
			}
		});
	};

	createAccount() {
		let _state = cloneDeep(this.state);

		if (_state.no_phone) {
			_state.phone_number = '';
			_state.permission_to_send_texts = 'No';
		}

		let { login, captureError, direct_admit } = this.props,
			{ hs_email, email, password, first_name, last_name, phone_number, no_phone, permission_to_send_texts } =
				_state;

		permission_to_send_texts = permission_to_send_texts.includes('Yes') ? 1 : 0;

		if ([email, password, first_name, last_name, `${permission_to_send_texts}`].some(val => !val)) {
			this.setState({ termsModal: false });
			this.showMessage("Oops!  Something's gone wrong.  Please refresh the page and try again.");
		} else {
			this.setState({ working: true });

			this.getAPI()
				.createAccount(
					email,
					password,
					email,
					first_name,
					last_name,
					phone_number,
					no_phone,
					permission_to_send_texts
				)
				.then(result => {
					if (this._mounted) {
						if (result.result === 'success') {
							if (direct_admit.need_to_link) {
								login(result.user, { email: hs_email });
							} else {
								login(result.user);
							}
						} else {
							let msg =
								result.result === 'Error. There already exists an account with this email/username.'
									? 'Oops!  Looks like an account with that email address already exists.'
									: result.result;

							if (!msg) {
								msg =
									"Oops!  Looks like something went wrong, but we can't tell what it was.  Please refresh the page and try again.";
							}

							this.setState({ termsModal: false, working: false });
							this.showMessage(msg);
						}
					}
				})
				.catch(ex => captureError(ex));
		}
	}

	onCheckHighSchoolEmail = () => {
		const { setDirectAdmit } = this.props,
			{ hs_email } = this.state;

		this.setState({ working: true });

		this.getAPI()
			.checkForDirectAdmitEmail(hs_email)
			.then(resp => {
				this.setState({ working: false });

				if (['unclaimed', 'verification outstanding'].includes(resp.status)) {
					this.setState({ termsModal: true });
				} else {
					this.setState({ working: false });

					confirmAlert({
						customUI: ({ onClose }) => {
							return (
								<div className='react-confirm-alert'>
									<div className='react-confirm-alert-body'>
										{MSG_DIRECT_ADMIT.check_for_record(resp.status, hs_email)}

										<div className='react-confirm-alert-button-group'>
											<button onClick={onClose}>No</button>
											<button
												onClick={() => {
													setDirectAdmit({});
													this.setState({ hs_email: '' });

													onClose();
												}}>
												Yes, Create Account
											</button>
										</div>
									</div>
								</div>
							);
						}
					});
				}
			})
			.catch(ex => captureError(ex));
	};

	mobileSidebarToggle = e => {
		const { sidebarExists } = this.state;

		if (!sidebarExists) this.setState({ sidebarExists: true });

		e.preventDefault();

		if (document.documentElement.classList.contains('nav-open')) {
			document.documentElement.classList.toggle('nav-open');
			document.querySelectorAll('#bodyClick').forEach(e => e.remove());
		} else {
			document.documentElement.classList.toggle('nav-open');

			let node = document.createElement('div');
			node.id = 'bodyClick';
			node.onclick = function () {
				if (this.parentElement) this.parentElement.removeChild(this);

				document.querySelectorAll('#bodyClick').forEach(e => e.remove());
				document.documentElement.classList.toggle('nav-open');
			};

			document.body.appendChild(node);
		}
	};

	onSetName = (value, data_name) => {
		const strCleaningResult = cleanString(value);

		value = strCleaningResult.clean.replace(/\d/g, '');
		if (strCleaningResult.dirty) {
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<div className='react-confirm-alert'>
							<div className='react-confirm-alert-body'>
								Invalid characters were removed from "{data_name}."
								<div className='react-confirm-alert-button-group'>
									<button onClick={onClose}>OK</button>
								</div>
							</div>
						</div>
					);
				}
			});
		}

		if (value.includes('@')) {
			value = ''; // tms:  if this symbol is in a name field, it's most likely from auto-complete
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<div className='react-confirm-alert'>
							<div className='react-confirm-alert-body'>
								The '@' symbol was removed from "{data_name}."
								<div className='react-confirm-alert-button-group'>
									<button onClick={onClose}>OK</button>
								</div>
							</div>
						</div>
					);
				}
			});
		}

		return value;
	};

	onSetEmail = () => {
		const { hs_email, email } = this.state;

		if (REGEXES.email.test(email)) {
			const is_school_email =
				email === hs_email ||
				email.endsWith('.edu') ||
				email.split('@')[1].includes('k12') ||
				email.split('@')[1].includes('school');

			if (is_school_email && !this.email_warning_shown) {
				this.email_warning_shown = true;

				confirmAlert({
					customUI: ({ onClose }) => {
						return (
							<div className='react-confirm-alert'>
								<div className='react-confirm-alert-body'>
									<p>
										Is <strong>{email}</strong> a school email address? If so, consider using a
										different one.
									</p>
									<p>Your email should be one you will have access to indefinitely.</p>
									<div className='react-confirm-alert-button-group'>
										<button onClick={onClose}>OK</button>
									</div>
								</div>
							</div>
						);
					}
				});
			}
		}
	};

	isValidForm = () => {
		const { direct_admit } = this.props,
			{
				hs_email,
				email,
				confirm_email,
				first_name,
				last_name,
				password,
				confirm_password,
				valid_phone,
				no_phone,
				permission_to_send_texts
			} = this.state;

		return (
			(!direct_admit.need_to_link || (!!hs_email && REGEXES.email.test(hs_email))) &&
			!!email &&
			REGEXES.email.test(email) &&
			confirm_email === email &&
			!!first_name &&
			!!last_name &&
			password.length >= 12 &&
			confirm_password === password &&
			(no_phone || (valid_phone && !!permission_to_send_texts))
		);
	};

	renderNotifications = () => {
		const { notification_index } = this.state,
			content = formatNotificationBody(this.notifications[notification_index]);

		return (
			<div className='announcement-modal'>
				<img src={require('assets/homepageImages/Alert.png')} alt='' className='announcement-icon' />
				<div>{content}</div>
			</div>
		);
	};

	render() {
		const { direct_admit } = this.props,
			{
				hs_email,
				email,
				confirm_email,
				password,
				first_name,
				last_name,
				phone_number,
				no_phone,
				permission_to_send_texts,
				confirm_password,
				practiceWarningModal,
				termsModal,
				working
			} = this.state,
			allow_submit = this.isValidForm();

		return (
			<div className='body-2' role='main'>
				<Modal show={termsModal} onHide={() => this.setState({ termsModal: false })}>
					<AccountCreationTermsModal working={working} acceptTerms={() => this.createAccount()} />
				</Modal>

				<Modal show={practiceWarningModal}>
					<PracticeWarningModal closeModal={() => this.setState({ practiceWarningModal: false })} />
				</Modal>

				<div
					data-collapse='medium'
					data-animation='default'
					data-duration='400'
					data-easing='ease-out'
					data-easing2='ease-out'
					className='nav-2-columns w-nav'>
					<div className='navigation-container'>
						<a href='/' className='logo w-inline-block'>
							<img
								src={main}
								width='100'
								srcSet={`${image500} 500w, ${image800} 800w, ${image1080} 1080w, ${image1600} 1600w, ${main} 1647w`}
								sizes='(max-width: 479px) 55vw, (max-width: 991px) 200px, 100vw'
								alt=''
								className='image-3'
							/>
						</a>
						<div className='menu-button w-nav-button'>
							<div className='icon-2 w-icon-nav-menu' onClick={this.mobileSidebarToggle} />
						</div>
					</div>
				</div>
				<div className='two-column-view' role='navigation'>
					<BasicNavMenu />

					<div className='right-column'>
						{!!this.notifications.length && this.renderNotifications()}

						<div className='right-column-wrap'>
							<div className='form-block w-form'>
								<h1 className='column-heading'>create your account</h1>

								<form
									id='email-form'
									name='email-form'
									data-name='Email Form'
									className='form create-acct'
									autoComplete='off'
									onSubmit={e => {
										e.preventDefault();
										e.stopPropagation();

										if (allow_submit) {
											if (direct_admit.need_to_link) {
												this.onCheckHighSchoolEmail();
											} else {
												this.setState({ termsModal: true });
											}
										}
									}}>
									{direct_admit.need_to_link && (
										<Textbox
											value={hs_email}
											type='email'
											onChange={val => this.setState({ hs_email: val })}
											name='hs_email'
											label={'High School Email Address'}
											note_long='This is your high school assigned  email address. We will use this to verify your identity so you can access your Direct Admit Wisconsin admission offers.'
											placeholder='Enter your high school email address'
											required={true}
											maxLength={70}
											isInvalid={!REGEXES.email.test(hs_email)}
										/>
									)}

									<Textbox
										value={email}
										type='email'
										onChange={val => this.setState({ email: val })}
										name='email'
										label={direct_admit.need_to_link ? 'Personal Email Address' : 'Email Address'}
										note_long={
											direct_admit.need_to_link
												? 'This will be your log in email. Make sure it is an email address you can access after high school.'
												: 'The email you choose to use should be an email you will have access to indefinitely. Please avoid using school or work email addresses.'
										}
										placeholder='Enter your email address'
										required={true}
										maxLength={70}
										isInvalid={!REGEXES.email.test(email)}
										onBlur={() => this.onSetEmail()}
									/>

									<Textbox
										value={confirm_email}
										type='email'
										onChange={val => this.setState({ confirm_email: val })}
										name='confirm_email'
										label={
											direct_admit.need_to_link
												? 'Confirm Personal Email Address'
												: 'Confirm Email Address'
										}
										placeholder='Enter your email address'
										required={true}
										maxLength={70}
										isInvalid={confirm_email !== email}
									/>

									<Textbox
										value={first_name}
										onChange={val => {
											this.setState({ first_name: this.onSetName(val, 'First Name') });
										}}
										name='first_name'
										label='Legal First Name'
										placeholder='Enter your legal first name'
										required={true}
										maxLength={40}
									/>

									<Textbox
										value={last_name}
										onChange={val => {
											this.setState({ last_name: this.onSetName(val, 'Last Name') });
										}}
										name='last_name'
										label='Last Name/Family Name'
										placeholder='Enter your last name/family name'
										required={true}
										maxLength={40}
									/>

									<Textbox
										value={password}
										type='password'
										onChange={val => this.setState({ password: val })}
										name='password'
										label='Password'
										placeholder='Enter your password'
										note_short='at least 12 characters'
										required={true}
										maxLength={256}
										minLength={12}
									/>

									<Textbox
										value={confirm_password}
										type='password'
										onChange={val => this.setState({ confirm_password: val })}
										name='confirm_password'
										label='Confirm Password'
										placeholder='Confirm your password'
										required={true}
										maxLength={256}
										isInvalid={confirm_password !== password}
									/>

									{!no_phone && (
										<PhoneControl
											value={phone_number}
											onChange={(val, status) =>
												this.setState({ phone_number: val, valid_phone: status === 'valid' })
											}
											label='Cell Phone'
											name='cell_phone'
											required={true}
										/>
									)}

									<CheckboxList
										values={no_phone ? ["I don't have a cell phone"] : []}
										label=''
										opts={["I don't have a cell phone"]}
										onChange={val => this.setState({ no_phone: !!val.length })}
										name='no_phone'
										classVals={['no-phone']}
									/>

									{!no_phone && (
										<>
											<div className='text-block-3'>
												Occasionally, the Universities of Wisconsin will call or send a reminder
												text to confirm information related to your admission. Please accept or
												decline these updates below (standard text and data rates apply).
											</div>

											<RadioList
												name='permission_to_send_texts'
												value={permission_to_send_texts}
												opts={[
													'Yes, I give UW permission to call or send texts',
													'No, I do not give UW permission to call or send texts'
												]}
												onChange={val => this.setState({ permission_to_send_texts: val })}
												classVals={['permission-to-text']}
												forceNewLines={true}
											/>
										</>
									)}

									<input
										aria-label='Create account submit'
										type='submit'
										value={`create account${allow_submit ? '' : ' ...'}`}
										data-wait='Just a moment . . .'
										className='submit-button w-button'
										disabled={!allow_submit}
									/>
								</form>
							</div>
						</div>
					</div>
				</div>
				<script
					src='https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.4.1.min.220afd743d.js?site=5ea1ccd99c38e78f25db182e'
					type='text/javascript'
					integrity='sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo='
					crossOrigin='anonymous'></script>
				<script src='assets/webflowJS/webflow.js' type='text/javascript'></script>
			</div>
		);
	}
}

const mapStateToProps = state => ({
		support_student_id: state.user.support_student_id,
		email: state.user.email,
		campus_admin: state.user.campus_admin,
		system_notifications: state.global.system_notifications,
		direct_admit: state.user.direct_admit
	}),
	mapDispatchToProps = dispatch => ({
		login: (obj, direct_admit) => dispatch(UserActions.login(obj, direct_admit)),
		captureError: err => dispatch(GlobalActions.captureError(err)),
		setDirectAdmit: obj => dispatch(UserActions.setDirectAdmit(obj))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateAccount));
