import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';
import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import CheckboxList from 'components/controls/checkbox_list';
import Textbox from 'components/controls/textbox';
import PhoneControl from 'components/controls/phone';
import RadioList from 'components/controls/radio_list';
import { cloneDeep } from 'components/modules/_misc';
import { REGEXES } from 'components/modules/validation';
import API from 'components/api';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { GlobalActions } from 'reducers/global';
import { UserActions } from 'reducers/user';

class AccountInformation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			first_name: props.first_name,
			last_name: props.last_name,
			email: props.email,
			phone_number: props.phone_number,
			no_phone: props.no_phone,
			permission_to_send_texts: props.permission_to_send_texts,
			valid_phone: true,
			updating_pwd: false,
			password: '',
			confirm_password: '',
			working: false
		};

		this.orig = {
			first_name: props.first_name,
			last_name: props.last_name,
			email: props.email,
			phone_number: props.phone_number,
			no_phone: props.no_phone,
			permission_to_send_texts: props.permission_to_send_texts
		};
	}

	componentDidMount() {
		this._mounted = true;
	}

	componentDidUpdate(prevProps, prevState) {
		const { first_name, last_name } = this.state;

		if (first_name !== prevState.first_name && first_name.includes('@')) {
			this.setState({ first_name: first_name.replace(/@/g, '') });
			alert(`The '@' symbol was removed from "First Name."`);
		}

		if (last_name !== prevState.last_name && last_name.includes('@')) {
			this.setState({ last_name: last_name.replace(/@/g, '') });
			alert(`The '@' symbol was removed from "Last Name."`);
		}
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	getAPI = () => {
		const { support_student_id, campus_admin } = this.props;
		return new API('', support_student_id, campus_admin);
	};

	isValidForm = () => {
		const { email, first_name, last_name, permission_to_send_texts, phone_number, valid_phone, no_phone } =
			this.state;

		return (
			!!first_name &&
			!!last_name &&
			!!email &&
			REGEXES.email.test(email) &&
			(no_phone || (!!phone_number && valid_phone && !!permission_to_send_texts))
		);
	};

	onChangeEmail = () => {
		const { support_student_id, campus_admin, login, captureError } = this.props,
			{ email } = this.state;

		this.getAPI()
			.resendVerifyEmail()
			.then(resp => {
				if (this._mounted) {
					this.setState({ working: false });

					if (resp?.result === 'success' && resp.user) {
						const objLogin = {
							...resp.user,
							support_student_id: support_student_id,
							campus_admin: campus_admin
						};

						this.setState({ change_email: false, new_email: '' });

						alert(`OK!  We've sent another code to ${email}.  This will invalidate any previous codes.`);

						login(objLogin);
					} else {
						alert(
							`That's weird.  We changed your email to ${email}, but had some trouble trouble sending you a new code.`
						);
					}
				}
			})
			.catch(ex => captureError(ex));
	};

	onChangePassword = () => {
		let { captureError } = this.props,
			{ password } = this.state;

		this.setState({ working: true });

		this.getAPI()
			.updateAccount(
				this.orig.email,
				this.orig.first_name,
				this.orig.last_name,
				this.orig.phone_number,
				this.orig.no_phone,
				this.orig.permission_to_send_texts,
				password
			)
			.then(resp => {
				if (this._mounted) {
					if (resp.result === 'success' && resp.user) {
						this.setState({
							working: false,
							updating_pwd: false,
							password: '',
							confirm_password: ''
						});

						alert("OK!  We've updated your password.");
					} else {
						alert("Sorry, something went wrong, and your account wasn't updated.");
					}
				}
			})
			.catch(ex => captureError(ex));
	};

	onSave() {
		let _state = cloneDeep(this.state);

		if (_state.no_phone) {
			_state.phone_number = '';
			_state.permission_to_send_texts = 'No';
		}

		let { navigate, setUserProperty, email: old_email, captureError } = this.props,
			{ email, first_name, last_name, phone_number, no_phone, permission_to_send_texts } = _state,
			required_fields = [email, first_name, last_name],
			changed_fields = [
				'first_name',
				'last_name',
				'email',
				'phone_number',
				'no_phone',
				'permission_to_send_texts'
			].filter(key => _state[key] !== this.props[key]);

		this.setState({ working: true });

		if (!no_phone) required_fields = required_fields.concat([phone_number, permission_to_send_texts]);

		if (changed_fields.length) {
			if (required_fields.some(val => !val)) {
				alert("Sorry, something went wrong, and your account wasn't updated.");
			} else {
				this.getAPI()
					.updateAccount(email, first_name, last_name, phone_number, no_phone, permission_to_send_texts)
					.then(resp => {
						if (this._mounted) {
							if (resp.result === 'success' && resp.user) {
								(changed_fields || []).forEach(key => setUserProperty(key, cloneDeep(_state[key])));

								if (email !== old_email) {
									this.onChangeEmail();
								} else {
									navigate('/my-account');
								}
							} else {
								this.setState({ working: false });

								alert(
									email !== old_email
										? 'Oops!  Looks like a different account with that email address already exists.'
										: "Sorry, something went wrong, and your account wasn't updated."
								);
							}
						}
					})
					.catch(ex => captureError(ex));
			}
		} else {
			navigate('/my-account');
		}
	}

	deactivateAndLogout = () => {
		const { navigate, captureError } = this.props;

		this.setState({ working: true });

		this.getAPI()
			.deactivateAccount()
			.then(deactivate_result => {
				this.getAPI()
					.logout()
					.then(logout_result => {
						navigate('/', { state: { logging_out: true } });
					})
					.catch(ex => captureError(ex));
			})
			.catch(ex => captureError(ex));
	};

	deactivateAccount = () => {
		const { email } = this.props;

		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className='react-confirm-alert'>
						<div className='react-confirm-alert-body'>
							<h1>Deactivate Account</h1>
							<p>
								This will delete in-progress applications and render your account and personal
								information inaccessible. It will <strong>NOT</strong> recall or delete applications
								that have been submitted.
							</p>
							<p>You will not be able to create a new account under {email}.</p>
							<p>Are you sure you want to deactivate your account?</p>
							<div className='react-confirm-alert-button-group'>
								<button
									onClick={() => {
										this.deactivateAndLogout();
										onClose();
									}}>
									Yes
								</button>
								<button onClick={onClose}>No</button>
							</div>
						</div>
					</div>
				);
			}
		});
	};

	render() {
		let {
				email,
				first_name,
				last_name,
				phone_number,
				no_phone,
				permission_to_send_texts,
				updating_pwd,
				password,
				confirm_password,
				working
			} = this.state,
			allow_submit = this.isValidForm(),
			allow_updating_pwd = password.length >= 12 && confirm_password === password,
			str_pwd_link = updating_pwd ? "Never mind, my current password's fine" : 'Update my password';

		return (
			<Card
				title='ACCOUNT INFORMATION'
				content={
					<form
						id='form'
						onSubmit={e => {
							e.preventDefault();
							e.stopPropagation();

							if (allow_submit) this.onSave();
						}}>
						<Row>
							<Textbox
								value={first_name}
								name={'first_name'}
								onChange={val => this.setState({ first_name: val })}
								label='First Name'
								placeholder='First Name'
								required={true}
								maxLength={256}
								cols={6}
							/>
							<Textbox
								value={last_name}
								name={'last_name'}
								onChange={val => this.setState({ last_name: val })}
								label='Last Name/Family Name'
								placeholder='Last Name/Family Name'
								required={true}
								maxLength={256}
								cols={6}
							/>
						</Row>
						<Row>
							<Textbox
								value={email}
								type='email'
								name={'email'}
								onChange={val => this.setState({ email: val })}
								label='Email Address'
								placeholder='Email Address'
								required={true}
								maxLength={256}
								isInvalid={!REGEXES.email.test(email)}
								cols={6}
							/>

							{!no_phone && (
								<PhoneControl
									value={phone_number}
									onChange={(val, status) =>
										this.setState({ phone_number: val, valid_phone: status === 'valid' })
									}
									label='Cell Phone'
									name='cell_phone'
									required={true}
									cols={6}
								/>
							)}
						</Row>
						<Row>
							<CheckboxList
								values={no_phone ? ["I don't have a cell phone"] : []}
								label=''
								opts={["I don't have a cell phone"]}
								onChange={val => this.setState({ no_phone: !!val.length })}
								name='no_phone'
								cols={6}
							/>

							{!no_phone && (
								<RadioList
									value={permission_to_send_texts}
									opts={['Yes', 'No']}
									onChange={val => this.setState({ permission_to_send_texts: val })}
									name='permission_to_send_texts'
									label='Permission to send texts?'
									required={true}
									cols={6}
								/>
							)}
						</Row>

						<button
							type='button'
							className='link-button update-pwd'
							onClick={e => {
								e.preventDefault();
								e.stopPropagation();

								this.setState({ updating_pwd: !updating_pwd });
							}}>
							{str_pwd_link}
						</button>

						{updating_pwd && (
							<>
								<Row>
									<Textbox
										value={password}
										type='password'
										onChange={val => this.setState({ password: val })}
										name='password'
										label='Password'
										note_short='at least 12 characters'
										placeholder='Enter your password'
										required={true}
										maxLength={256}
										minLength={12}
										cols={6}
									/>

									<Textbox
										value={confirm_password}
										type='password'
										onChange={val => this.setState({ confirm_password: val })}
										name='confirm_password'
										label='Confirm Password'
										placeholder='Confirm your password'
										required={true}
										maxLength={256}
										isInvalid={confirm_password !== password}
										cols={6}
									/>
								</Row>
								<Row>
									<Button
										bsStyle='info'
										className='mid-form'
										fill
										disabled={!allow_updating_pwd}
										onClick={this.onChangePassword}>
										Update Password
									</Button>
								</Row>
							</>
						)}

						<button
							type='button'
							className='link-button offset-btn'
							onClick={e => {
								e.preventDefault();
								e.stopPropagation();

								this.deactivateAccount();
							}}>
							Deactivate Account
						</button>
					</form>
				}
				buttons={
					<Button form='form' className='save-btn' fill type='submit' disabled={working || !allow_submit}>
						Save
					</Button>
				}
			/>
		);
	}
}

const mapStateToProps = state => ({
		email: state.user.email,
		first_name: state.user.first_name,
		last_name: state.user.last_name,
		phone_number: state.user.phone_number,
		no_phone: state.user.no_phone,
		permission_to_send_texts: state.user.permission_to_send_texts,
		support_student_id: state.user.support_student_id,
		campus_admin: state.user.campus_admin
	}),
	mapDispatchToProps = dispatch => ({
		login: obj => dispatch(UserActions.login(obj)),
		setUserProperty: (str_prop, val) => dispatch(UserActions.setUserProperty(str_prop, val)),
		captureError: err => dispatch(GlobalActions.captureError(err))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountInformation));
