import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Card } from 'components/Card/Card';
import _svg from 'components/modules/svg';

class LoadingPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modal: false
		};
	}

	componentDidMount = () => {
		this._mounted = true;

		setTimeout(() => {
			if (this._mounted) this.setState({ modal: true });
		}, 15000);
	};

	componentWillUnmount = () => {
		this._mounted = false;
	};

	render = () => {
		const { modal } = this.state;

		return (
			<div className='wrapper noSidebarWrapper' role='main'>
				<div id='main-panel' className='main-panel noSidebarPanel' ref='mainPanel'>
					<Modal show={modal}>
						<Modal.Header>
							<h2 className={'uwRedText'}>Something&apos;s wrong</h2>
						</Modal.Header>
						<Modal.Body className={'modalMinHeight'}>
							<p>
								Things should be ready by now. Maybe check your internet connection and give it another
								shot?
							</p>
						</Modal.Body>
					</Modal>

					<Card
						title='ONE MOMENT . . .'
						content={
							<div className='loading-page'>
								<img
									className='image-2'
									src={_svg.constellationMap}
									alt='Map of the Universities of Wisconsin'
								/>
							</div>
						}
					/>
				</div>
			</div>
		);
	};
}

export default LoadingPage;
