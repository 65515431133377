import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Row, Table } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';

import { Card } from 'components/Card/Card';
import CheckboxList from 'components/controls/checkbox_list';
import Select from 'components/controls/select';
import RadioList from 'components/controls/radio_list';
import Textbox from 'components/controls/textbox';
import Button from 'components/CustomButton';
import { cloneDeep } from 'components/modules/_misc';
import { DEFAULT } from 'components/modules/app';
import { nextAppPage, prevAppPage } from 'components/modules/nav';
import _svg from 'components/modules/svg';
import { UserActions } from 'reducers/user';
import 'react-confirm-alert/src/react-confirm-alert.css';

class Activities extends Component {
	constructor(props) {
		super(props);

		this.state = {
			index: -1,
			objActivity: null
		};
	}

	componentDidMount() {
		this.props.setSubmenu();
	}

	componentWillUnmount() {
		this.props.saveApp(this.isValidForm(true));
	}

	isValidForm = unmounting => {
		const { app } = this.props,
			{ objActivity, index } = this.state,
			editing_activity = index > -1 || !!objActivity,
			{
				activity_category,
				activity_name,
				participation_grade_level,
				level_of_participation,
				intend_to_participate
			} = objActivity || {};

		return editing_activity && !unmounting
			? activity_category &&
					activity_name &&
					participation_grade_level.length &&
					level_of_participation &&
					intend_to_participate
			: app.activities_employment.no_activities || !!app.activities_employment.activities.length;
	};

	onSave = e => {
		const { navigate, app, location, updateApp } = this.props,
			{ objActivity, index } = this.state,
			activities_employment = cloneDeep(app.activities_employment),
			editing_activity = index > -1 || !!objActivity;

		e.preventDefault();
		e.stopPropagation();

		if (this.isValidForm()) {
			if (editing_activity) {
				if (index < 0) {
					activities_employment.activities.push(objActivity);
				} else {
					activities_employment.activities[index] = objActivity;
				}

				if (activities_employment.activities.length) activities_employment.no_activities = false;

				updateApp({ activities_employment: activities_employment });
				this.setState({ objActivity: null, index: -1 });
			} else {
				navigate(nextAppPage(app, location.pathname));
			}
		}
	};

	onAddActivity = e => {
		this.setState({ index: -1, objActivity: cloneDeep(DEFAULT.ACTIVITY) });
	};

	onEditActivity = i => e => {
		const { app } = this.props;

		this.setState({
			index: i,
			objActivity: cloneDeep(app.activities_employment.activities[i])
		});
	};

	onChangeActivity = (val, prop) => {
		let { app } = this.props,
			objActivity = cloneDeep(this.state.objActivity);

		if (!app.date_submitted) {
			objActivity[prop] = val;
			this.setState({ objActivity: objActivity });
		}
	};

	onDeleteActivity = (i, e) => {
		let { app, updateApp } = this.props;

		if (!app.date_submitted) {
			confirmAlert({
				title: 'Delete Entry',
				message: 'Are you sure you want to delete this activity?',
				buttons: [
					{
						label: 'Yes',
						onClick: () => {
							let activities_employment = cloneDeep(app.activities_employment);
							if (activities_employment.activities.length === 1) {
								activities_employment.activities = [];
							} else {
								activities_employment.activities.splice(i, 1);
							}

							updateApp({ activities_employment: activities_employment });
						}
					},
					{
						label: 'No'
					}
				]
			});
		}
	};

	onChangeNoActivities = val => {
		let { app, updateApp } = this.props,
			activities_employment = cloneDeep(app.activities_employment);

		if (!app.date_submitted) {
			activities_employment.no_activities = val;
			updateApp({ activities_employment: activities_employment });
		}
	};

	onBack = e => {
		const { navigate, app, location } = this.props,
			{ index, objActivity } = this.state;

		if (index < 0 && !objActivity) {
			navigate(prevAppPage(app, location.pathname));
		} else {
			this.setState({ objActivity: null, index: -1 });
		}
	};

	renderActivities() {
		let { app } = this.props,
			activity_rows = app.activities_employment.activities.length
				? app.activities_employment.activities.map((element, i) => {
						return (
							<tr key={`activity${i}`}>
								<td data-label='Category'>{element.activity_category}</td>
								<td data-label='Name'>{element.activity_name}</td>
								<td data-label='College Participate'>{element.intend_to_participate}</td>
								<td data-label='Actions'>
									<Button bsStyle='info' onClick={this.onEditActivity(i)} className='editBtn btn-xs'>
										<img
											alt='editIcon'
											className={'imgDashboard'}
											height={'18px'}
											width={'18px'}
											src={_svg.Edit}
										/>
										<p className={'pDashboard'}>Edit</p>
									</Button>

									<Button
										bsStyle='info'
										onClick={() => this.onDeleteActivity(i)}
										className='deleteBtn btn-xs'>
										<img
											alt='deleteIcon'
											className={'imgDashboard'}
											height={'18px'}
											width={'18px'}
											src={_svg.Delete}
										/>
										<p className={'pDashboard'}>Delete</p>
									</Button>
								</td>
							</tr>
						);
				  })
				: [
						<tr key={'none'}>
							<td colSpan={4}>You have no activities added.</td>
						</tr>
				  ];

		activity_rows.push(
			<tr key={'add'} className={'addColumn'}>
				<td colSpan={3}></td>
				<td data-label='Actions'>
					<Button bsStyle='info' onClick={this.onAddActivity} className='editBtn btn-xs'>
						<img
							alt='editIcon'
							className={'imgDashboard'}
							height={'18px'}
							width={'18px'}
							src={_svg.GreenCirclePlus}
						/>
						<p className={'pDashboard'}>Add Activity</p>
					</Button>
				</td>
			</tr>
		);

		return activity_rows;
	}

	renderTable = () => {
		let { app } = this.props,
			{ activities, no_activities } = app.activities_employment;

		return (
			<>
				<p>
					Listing your activities and interests can help us understand your life outside the classroom.
					Activities can include the arts, athletics, clubs, personal commitments, and other pursuits.
				</p>

				<hr />

				{activities.length ? (
					<Table striped bordered hover>
						<thead>
							<tr>
								<th scope='col'>
									<b>Activity Category</b>
								</th>
								<th scope='col'>
									<b>Activity Name</b>
								</th>
								<th scope='col'>
									<b>Intend To Participate</b>
								</th>
								<th scope='col'>
									<b>Actions</b>
								</th>
							</tr>
						</thead>
						<tbody>{this.renderActivities()}</tbody>
					</Table>
				) : (
					<Button bsStyle='info' className='save-btn add-content' fill onClick={this.onAddActivity}>
						Add Activity
					</Button>
				)}

				{!activities.length && (
					<>
						<br />
						<p>
							It is highly encouraged to provide activities on your application. Your involvement and
							leadership is factored into your admissions decision.
						</p>

						<Row>
							<CheckboxList
								values={no_activities ? ['I have no activities to report.'] : []}
								opts={['I have no activities to report.']}
								onChange={val => this.onChangeNoActivities(!!val.length)}
								name='no_activities'
								label=''
								cols={12}
								hide_optional={true}
							/>
						</Row>
					</>
				)}
			</>
		);
	};

	renderEditForm = () => {
		let { all_activities } = this.props,
			{ objActivity } = this.state,
			{
				activity_category,
				activity_name,
				participation_grade_level,
				level_of_participation,
				position_or_leadership_role,
				intend_to_participate
			} = objActivity;

		return (
			<>
				<Row>
					<Select
						value={activity_category}
						opts={all_activities.map(a => a.title)}
						name='activity_category'
						label='Activity Category'
						placeholder='Select Category'
						onChange={val => this.onChangeActivity(val, 'activity_category')}
						required={true}
						cols={6}
					/>
					<Textbox
						value={activity_name}
						name='activity_name'
						label='Activity Name'
						placeholder='Activity Name'
						onChange={val => this.onChangeActivity(val, 'activity_name')}
						required={true}
						maxLength={50}
						cols={6}
					/>
				</Row>
				<Row>
					<CheckboxList
						values={participation_grade_level}
						opts={['9', '10', '11', '12', 'After High School']}
						onChange={val => this.onChangeActivity(val, 'participation_grade_level')}
						name='participation_grade_level'
						label='Participation grade levels:'
						required={true}
						cols={12}
					/>
				</Row>
				<Row>
					<RadioList
						value={level_of_participation}
						opts={[
							'Low (Up to 2 hours per week)',
							'Medium (3-4 hours per week)',
							'High (5 or more hours per week)'
						]}
						onChange={val => this.onChangeActivity(val, 'level_of_participation')}
						name='level_of_participation'
						label='Level of participation'
						forceNewLines={true}
						required={true}
						cols={12}
					/>
				</Row>
				<Row>
					<Textbox
						value={position_or_leadership_role}
						name='position_or_leadership_role'
						label='Position / Leadership Role and/or Awards Received, if applicable'
						onChange={val => this.onChangeActivity(val, 'position_or_leadership_role')}
						cols={12}
						maxLength={80}
					/>
				</Row>
				<Row>
					<RadioList
						value={intend_to_participate}
						opts={['Yes', 'No']}
						onChange={val => this.onChangeActivity(val, 'intend_to_participate')}
						name='intend_to_participate'
						label='I intend to participate in a similar activity in college:'
						required={true}
						cols={12}
					/>
				</Row>
			</>
		);
	};

	render() {
		let { objActivity, index } = this.state,
			editing_activity = index > -1 || !!objActivity;

		return (
			<Card
				title='ACTIVITIES'
				content={
					<form id='form' onSubmit={this.onSave}>
						{editing_activity ? this.renderEditForm() : this.renderTable()}
						<div className='clearfix' />
					</form>
				}
				buttons={
					<>
						<Button bsStyle='info' className='back-btn' fill onClick={this.onBack}>
							Back
						</Button>
						<Button
							form='form'
							bsStyle='info'
							className='save-btn'
							fill
							type='submit'
							disabled={!this.isValidForm()}>
							{editing_activity ? 'Save Activity' : 'Save and Continue'}
						</Button>
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => {
		const { app_id, apps } = state.user;

		return {
			app: apps[app_id].json_obj,
			app_id: app_id,
			all_activities: state.global.activities
		};
	},
	mapDispatchToProps = dispatch => ({
		updateApp: obj => dispatch(UserActions.updateApp(obj)),
		saveApp: val => dispatch(UserActions.saveApp({ activitiesCompleted: val })),
		setSubmenu: () => dispatch(UserActions.setSubmenus('holistic_background_open', true))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Activities));
