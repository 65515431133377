export const OS = () => {
		let operatingSystem = 'Not known';

		if (window.navigator.appVersion.indexOf('Win') > -1) operatingSystem = 'Windows';
		if (window.navigator.appVersion.indexOf('Mac') > -1) operatingSystem = 'MacOS';
		if (window.navigator.userAgent.indexOf('iPhone') > -1) operatingSystem = 'iPhone';
		if (window.navigator.userAgent.indexOf('iPad') > -1) operatingSystem = 'iPad';
		if (window.navigator.userAgent.indexOf('Android') > -1) operatingSystem = 'Android';
		if (window.navigator.userAgent.indexOf('Blackberry') > -1) operatingSystem = 'Blackberry';

		return operatingSystem;
	},
	currentBrowser = () => {
		let currentBrowser = 'Not known';

		if (window.navigator.userAgent.indexOf('Chrome') > -1) {
			currentBrowser = 'Chrome';
		} else if (window.navigator.userAgent.indexOf('Firefox') > -1) {
			currentBrowser = 'Firefox';
		} else if (window.navigator.userAgent.indexOf('MSIE') > -1) {
			currentBrowser = 'Internet Explorer';
		} else if (window.navigator.userAgent.indexOf('Edge') > -1) {
			currentBrowser = 'Edge';
		} else if (window.navigator.userAgent.indexOf('Safari') > -1) {
			currentBrowser = 'Safari';
		} else if (window.navigator.userAgent.indexOf('Opera') > -1) {
			currentBrowser = 'Opera';
		} else {
			console.log('Browser not tracked');
		}

		return currentBrowser;
	},
	userAgent = () => {
		let userAgent = 'Not known';

		if (window.navigator.userAgent) {
			userAgent = window.navigator.userAgent;
		} else {
			console.log('UserAgent not tracked');
		}
		
		return userAgent;
	};


