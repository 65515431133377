import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

class AccountCreationTermsModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			accepted: false
		};
	}

	componentDidUpdate(prevProps, prevState) {
		const { acceptTerms } = this.props,
			{ accepted } = this.state;

		if (accepted && !prevState.accepted) acceptTerms();
	}

	render() {
		const { working } = this.props,
			{ accepted } = this.state;

		return (
			<>
				<Modal.Header closeButton>
					<h2 className='uwRedText'>Terms &mdash; Personal Information</h2>
				</Modal.Header>
				<Modal.Body className='modalMinHeight'>
					{working ? (
						<p>Creating your account . . .</p>
					) : (
						<p>
							Personal information provided in your in-progress application(s) may be used by the UW
							System and its campuses to contact you about submitting your application. Upon submission of
							an application, the personal data collected in the Universities of Wisconsin Admissions
							Portal will be shared with the UW System campus(es) that you are applying to. This data will
							be used to set up your record at the campus to be considered for admission. Submitted
							applications are property of the UW System.
						</p>
					)}
				</Modal.Body>

				{!working && (
					<Modal.Footer>
						<input
							type='checkbox'
							id='confirm'
							name='confirm'
							checked={accepted}
							onChange={() => this.setState({ accepted: !accepted })}
						/>
						<label htmlFor='confirm'>I have read and agree to the above terms.</label>
					</Modal.Footer>
				)}
			</>
		);
	}
}

export default AccountCreationTermsModal;
