import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/lib/Button';

import ContactUsInfo from 'views/Public/shared-content/contact-us-info';

class ContactUsModal extends Component {
	render() {
		const { closeModal } = this.props;

		return (
			<div>
				<Modal.Header>
					<Modal.Title className='h2'>Contact Us</Modal.Title>
				</Modal.Header>
				<Modal.Body className='modalMinHeight'>
					<ContactUsInfo />
				</Modal.Body>
				<Modal.Footer>
					<Button bsStyle='info' className='back-btn' fill='true' onClick={closeModal}>
						Close
					</Button>
				</Modal.Footer>
			</div>
		);
	}
}

export default ContactUsModal;
