import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import { Tooltip } from 'react-tooltip';
import { Table, Row } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-tooltip/dist/react-tooltip.css';

import API from 'components/api';
import { FILE_UPLOAD, cloneDeep } from 'components/modules/_misc';
import { DEFAULT } from 'components/modules/app';
import { nextAppPage, prevAppPage } from 'components/modules/nav';
import _svg from 'components/modules/svg';
import { GlobalActions } from 'reducers/global';
import { UserActions } from 'reducers/user';
import { create_proactive } from 'components/modules/ada';
import FileUpload from 'components/controls/file_upload';
import RadioList from 'components/controls/radio_list';
import Select from 'components/controls/select';
import Textbox from 'components/controls/textbox';

class AcademicBackgroundHSCourses extends Component {
	constructor(props) {
		super(props);

		this.state = {
			index: -1,
			course: null,
			upload_error: '',
			uploading_files: false
		};

		this.orig_has_files = !!props.uploaded_files.length;
	}

	componentDidMount() {
		this._mounted = true;

		let { app, setSubmenu, updateApp } = this.props,
			academic_background = cloneDeep(app.academic_background);

		setSubmenu();

		if (academic_background.show_courses_option) {
			academic_background.hs_show_courses_option = academic_background.show_courses_option;
			delete academic_background.show_courses_option;
			updateApp({ academic_background: academic_background });
		}

		setTimeout(() => {
			if (this._mounted) {
				create_proactive(
					'You can choose to upload a class schedule (PDF) or manually enter your courses. The document you upload (PDF) should be your course list for the whole academic year.',
					'5ebaedc7ec7964a1a0894570'
				);
			}
		}, 2000);
	}

	componentWillUnmount() {
		this._mounted = false;

		const { app, updateApp } = this.props,
			academic_background = cloneDeep(app.academic_background),
			_valid = this.isValidForm();

		if (academic_background.hs_show_courses_option === 'Upload file') academic_background.courses = [];

		if (academic_background.hs_show_courses_option === 'Manually Enter') {
			academic_background.hs_course_use_previous_file = '';
			academic_background.hs_course_schedule_file = '';
			academic_background.hs_course_schedule_file_name = '';
		}

		updateApp({ academic_background: academic_background });
		this.props.saveApp(_valid);
	}

	getAPI = () => {
		const { support_student_id, campus_admin } = this.props;
		return new API('', support_student_id, campus_admin);
	};

	isValidForm = () => {
		let { app } = this.props,
			{ course } = this.state,
			{ courses, hs_show_courses_option, hs_course_schedule_file } = app.academic_background;

		if (course) {
			const { course_title, credits } = course;
			return !!course_title && !!credits && !isNaN(credits) && Number(credits) >= 0 && Number(credits) <= 50;
		}

		return (
			!!hs_show_courses_option &&
			((hs_show_courses_option === 'Manually Enter' && !!courses.length) ||
				(hs_show_courses_option === 'Upload file' && !!hs_course_schedule_file))
		);
	};

	onAddCourse = () => {
		this.setState({ course: cloneDeep(DEFAULT.HS_COURSE) });
	};

	onEditCourse = i => {
		const { app } = this.props;
		this.setState({ index: i, course: cloneDeep(app.academic_background.courses[i]) });
	};

	onDeleteCourse = i => {
		let { app, updateApp } = this.props;

		if (!app.date_submitted) {
			confirmAlert({
				title: 'Remove Entry',
				message: 'Are you sure you want to remove this?',
				buttons: [
					{
						label: 'Yes',
						onClick: () => {
							let academic_background = cloneDeep(app.academic_background);
							academic_background.courses.splice(i, 1);
							updateApp({ academic_background: academic_background });
						}
					},
					{
						label: 'No'
					}
				]
			});
		}
	};

	onSaveCourse = e => {
		e.preventDefault();
		e.stopPropagation();

		let { app, updateApp } = this.props,
			{ course, index } = this.state,
			academic_background = cloneDeep(app.academic_background);

		if (!app.date_submitted) {
			if (index < 0) {
				academic_background.courses.push(course);
			} else {
				academic_background.courses[index] = course;
			}

			updateApp({ academic_background: academic_background });
			this.setState({ course: null, index: -1 });
		}
	};

	clearUploadedFile = e => {
		const { app, updateApp } = this.props;

		if (!app.date_submitted) {
			confirmAlert({
				title: 'Remove File',
				message: 'Are you sure you want to remove this file from your application?',
				buttons: [
					{
						label: 'Yes',
						onClick: () => {
							let academic_background = cloneDeep(app.academic_background);
							academic_background.hs_course_schedule_file = '';
							academic_background.hs_course_schedule_file_name = '';

							updateApp({ academic_background: academic_background });
						}
					},
					{
						label: 'No'
					}
				]
			});
		}
	};

	onUploadSchedule = file => {
		let { app, app_id, updateApp, addDocument, captureError } = this.props,
			academic_background = cloneDeep(app.academic_background);

		if (!app.date_submitted) {
			const { name, size } = file;

			if (size > FILE_UPLOAD.min_file_size) {
				this.setState({ uploading_files: true });

				this.getAPI()
					.saveFile(file, app_id, 'HSCourses')
					.then(response => {
						if (this._mounted) {
							this.setState({ uploading_files: false });

							if (response.data.includes('UPLOAD FAILED')) {
								const err_msg = response.data.replace('UPLOAD FAILED:', '').trim();
								this.setState({ upload_error: `Upload failed: ${name} (${err_msg})` });

								academic_background.hs_course_schedule_file = '';
								academic_background.hs_course_schedule_file_name = '';
							} else {
								academic_background.hs_course_schedule_file = response.data;
								academic_background.hs_course_schedule_file_name = name;

								addDocument(name, response.data);
								this.setState({ upload_error: '' });
							}

							updateApp({ academic_background: academic_background });
						}
					})
					.catch(ex => captureError(ex));
			} else {
				confirmAlert(FILE_UPLOAD.err_msg);
			}

			updateApp({ academic_background: academic_background });
		}
	};

	onChange = (val, prop) => {
		let { app, updateApp } = this.props,
			academic_background = cloneDeep(app.academic_background);

		if (!app.date_submitted) {
			academic_background[prop] = val;

			if (prop === 'hs_course_use_previous_file' && val === 'No') {
				academic_background.hs_course_schedule_file = '';
				academic_background.hs_course_schedule_file_name = '';
			}

			updateApp({ academic_background: academic_background });
		}
	};

	onChangeCourse = (val, prop) => {
		let { app } = this.props,
			course = cloneDeep(this.state.course);

		if (!app.date_submitted) {
			course[prop] = val;
			this.setState({ course: course });
		}
	};

	renderBtnDownload = (key, file_name) => {
		const { uploaded_files, captureError } = this.props;

		if (!file_name) {
			const uploaded_file = uploaded_files.find(file => file.key === key);
			file_name = uploaded_file ? uploaded_file.file_name : `${key}.pdf`;
		}

		return (
			<Button
				bsStyle='info'
				aria-label='Download uploaded schedule'
				onClick={() => {
					this.getAPI()
						.downloadDocument(key, file_name)
						.catch(ex => {
							captureError(ex);

							confirmAlert({
								title: "Hmm, that didn't work",
								message:
									'Your schedule could not be downloaded.  Please try again and let us know if the problem continues.',
								buttons: [{ label: 'OK' }]
							});
						});
				}}
				className='btn-download'>
				<img alt='downloadIcon' className='imgDashboard' height='18px' width='18px' src={_svg.Download} />
				<p className='pDashboard'>Download</p>
			</Button>
		);
	};

	renderFileUploadBlock = () => {
		let { app, uploaded_files } = this.props,
			{ upload_error, uploading_files } = this.state,
			{ hs_course_schedule_file, hs_course_schedule_file_name, hs_course_use_previous_file } =
				app.academic_background,
			showUploadControl =
				hs_course_use_previous_file === 'No' ||
				!this.orig_has_files ||
				(!!hs_course_schedule_file && !hs_course_use_previous_file),
			already_uploaded_file;

		if (upload_error) {
			already_uploaded_file = <p className='uwRedText'>{upload_error}</p>;
		} else if (hs_course_schedule_file_name) {
			already_uploaded_file = (
				<p>
					<strong>File Successfully Uploaded: </strong>
					{hs_course_schedule_file_name}
					<Button bsStyle='info' onClick={this.clearUploadedFile} className='deleteBtn deleteUploadedFile'>
						<img alt='deleteIcon' className='imgDashboard' height='18px' width='18px' src={_svg.Delete} />
						<p className='pDashboard'>Remove</p>
					</Button>
					{this.renderBtnDownload(hs_course_schedule_file, hs_course_schedule_file_name)}
				</p>
			);
		}

		return (
			<>
				{(!!hs_course_use_previous_file || (this.orig_has_files && !hs_course_schedule_file)) && (
					<Row>
						<RadioList
							name='hs_course_use_previous_file'
							value={hs_course_use_previous_file}
							onChange={val => this.onChange(val, 'hs_course_use_previous_file')}
							opts={['Yes', 'No']}
							label='Would you like to use a previous file?'
							cols={12}
							required={true}
						/>
					</Row>
				)}

				{hs_course_use_previous_file === 'Yes' && (
					<Row>
						<Select
							value={hs_course_schedule_file}
							opts={uploaded_files.map(file => ({ val: file.key, label: file.file_name }))}
							onChange={val => this.onChange(val, 'hs_course_schedule_file')}
							name='hs_course_schedule_file'
							label='Use previous file:'
							placeholder='Select File'
							required={true}
							cols={6}
						/>

						{hs_course_schedule_file && (
							<div className='col-md-6 prev-download'>
								{this.renderBtnDownload(hs_course_schedule_file)}
							</div>
						)}
					</Row>
				)}

				{showUploadControl && (
					<>
						{(!hs_course_schedule_file_name || !!upload_error) && (
							<Row>
								<FileUpload
									name='hs_course_schedule_file'
									displayName='schedule'
									onChange={file => this.onUploadSchedule(file)}
									cols={12}
									hide_optional={true}
								/>
							</Row>
						)}

						{uploading_files && (
							<p>
								<strong>Uploading...</strong>
							</p>
						)}

						{!!(hs_course_schedule_file_name || upload_error) && already_uploaded_file}
					</>
				)}
			</>
		);
	};

	renderCoursesTable() {
		const { app } = this.props;

		let object_rows = app.academic_background.courses.length
			? app.academic_background.courses.map((_course, i) => {
					return (
						<tr key={`course-${i}`}>
							<td data-label='Course Title'>{_course.course_title}</td>
							<td data-label='Credits'>{_course.credits}</td>
							<td data-label='Actions'>
								<Button bsStyle='info' onClick={() => this.onEditCourse(i)} className='editBtn btn-xs'>
									<img
										alt='editIcon'
										className='imgDashboard'
										height='18px'
										width='18px'
										src={_svg.Edit}
									/>
									<p className='pDashboard'>Edit</p>
								</Button>
								<Button
									bsStyle='info'
									onClick={() => this.onDeleteCourse(i)}
									className='deleteBtn btn-xs'>
									<img
										alt='deleteIcon'
										className='imgDashboard'
										height='18px'
										width='18px'
										src={_svg.Delete}
									/>
									<p className='pDashboard'>Remove</p>
								</Button>
							</td>
						</tr>
					);
			  })
			: [
					<tr key='none'>
						<td colSpan={3}>You have no courses added.</td>
					</tr>
			  ];

		object_rows.push(
			<tr key='add' className='addColumn'>
				<td colSpan={2}></td>
				<td data-label='Actions'>
					<Button bsStyle='info' onClick={() => this.onAddCourse()} className='editBtn btn-xs'>
						<img
							alt='editIcon'
							className='imgDashboard'
							height='18px'
							width='18px'
							src={_svg.GreenCirclePlus}
						/>
						<p className='pDashboard'>Add Course</p>
					</Button>
				</td>
			</tr>
		);

		return object_rows;
	}

	renderMainPage = () => {
		const { app } = this.props,
			{ hs_show_courses_option } = app.academic_background;

		return (
			<>
				<p>
					{app.application_modifier === 'DA'
						? "We've worked with your high school to share your in-progress transcript so far. Please list the courses you are taking in your senior year."
						: 'Please list or upload all high school/secondary school courses you are taking in your senior year.'}
				</p>
				<p>
					<a id='tooltip'>
						How do I get my schedule?{' '}
						<img
							className='informationTooltip'
							src={require('assets/img/Information.png')}
							alt='Information'
						/>
					</a>
				</p>

				<Tooltip
					anchorId='tooltip'
					className='tooltipContainer'
					delayHide={1000}
					effect='solid'
					content={
						<>
							<p>Sign into your high school portal. Go to the area where you can pull your schedule.</p>
							<p>Export a pdf of your schedule or convert an image of your schedule to a pdf.</p>
						</>
					}
				/>

				<Row>
					<RadioList
						name='hs_show_courses_option'
						value={hs_show_courses_option}
						onChange={val => this.onChange(val, 'hs_show_courses_option')}
						opts={['Manually Enter', 'Upload file']}
						label='How do you wish to provide your high school courses?'
						cols={12}
						required={true}
					/>
				</Row>

				{hs_show_courses_option === 'Upload file' && this.renderFileUploadBlock()}
			</>
		);
	};

	renderBtnBack = () => {
		const { app, navigate, location } = this.props,
			{ uploading_files, course } = this.state;

		return course ? (
			<Button bsStyle='info' className='back-btn' fill onClick={() => this.setState({ course: null, index: -1 })}>
				Back
			</Button>
		) : (
			<Button
				bsStyle='info'
				className='back-btn'
				fill
				onClick={() => navigate(prevAppPage(app, location.pathname))}
				disabled={uploading_files}>
				Back
			</Button>
		);
	};

	renderBtnSubmit = () => {
		const { uploading_files, course } = this.state,
			_valid = this.isValidForm();

		return course ? (
			<Button bsStyle='info' className='save-btn' fill disabled={!_valid} onClick={this.onSaveCourse}>
				Save Course
			</Button>
		) : (
			<Button
				form='form'
				bsStyle='info'
				className='save-btn'
				disabled={uploading_files || !_valid}
				fill
				type='submit'>
				Save and Continue
			</Button>
		);
	};

	render() {
		let { app, navigate, location } = this.props,
			{ course } = this.state,
			{ courses, hs_show_courses_option } = app.academic_background,
			content = this.renderMainPage();

		if (hs_show_courses_option === 'Manually Enter') {
			content = course ? (
				<>
					<Row>
						<Textbox
							name='course_title'
							value={course.course_title}
							label='Course Title'
							placeholder='Example:  Physics I or AP U.S. History'
							maxLength={100}
							onChange={val => this.onChangeCourse(val, 'course_title')}
							required={true}
							cols={8}
						/>
						<Textbox
							type='number'
							value={course.credits}
							onChange={val => this.onChangeCourse(val, 'credits')}
							name='credits'
							label='Credits'
							placeholder='Example:  0.5 or 1'
							required={true}
							step={0.01}
							min={0}
							max={50}
							cols={4}
						/>
					</Row>
				</>
			) : (
				<>
					{content}

					{courses.length ? (
						<>
							<hr />
							<Table striped bordered hover>
								<thead>
									<tr>
										<th scope='col'>
											<strong>Course Title</strong>
										</th>
										<th scope='col'>
											<strong>Credits</strong>
										</th>
										<th scope='col'>
											<strong>Actions</strong>
										</th>
									</tr>
								</thead>
								<tbody>{this.renderCoursesTable()}</tbody>
							</Table>
						</>
					) : (
						<Button bsStyle='info' className='save-btn add-content' fill onClick={() => this.onAddCourse()}>
							Add Course
						</Button>
					)}
				</>
			);
		}

		return (
			<Card
				title='Academic Background: High School/Secondary Courses'
				content={
					<form id='form' onSubmit={() => navigate(nextAppPage(app, location.pathname))}>
						{content}
						<div className='clearfix' />
					</form>
				}
				buttons={
					<>
						{this.renderBtnBack()}
						{this.renderBtnSubmit()}
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => {
		const { app_id, apps, support_student_id, documents, campus_admin } = state.user;

		return {
			app: apps[app_id].json_obj,
			app_id: app_id,
			support_student_id: support_student_id,
			uploaded_files: documents.filter(file => file.document_type === 'HSCourses'),
			campus_admin: campus_admin
		};
	},
	mapDispatchToProps = dispatch => ({
		updateApp: obj => dispatch(UserActions.updateApp(obj)),
		saveApp: val => dispatch(UserActions.saveApp({ academicBackgroundHSCoursesCompleted: val })),
		setSubmenu: () => dispatch(UserActions.setSubmenus('academic_background_open', true)),
		addDocument: (file_name, key) => dispatch(UserActions.addDocument('HSCourses', file_name, key)),
		captureError: err => dispatch(GlobalActions.captureError(err))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AcademicBackgroundHSCourses));
