import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/lib/Button';

class PracticeWarningModal extends Component {
	render() {
		return (
			<div>
				<Modal.Header>
					<h2 className={'uwRedText'}>Practice Application</h2>
				</Modal.Header>
				<Modal.Body className={'modalMinHeight'}>
					<p>
						This is a practice application intended for demonstration purposes. Applications submitted here
						will not be received by any campus.
					</p>
					<p>
						Please use the{' '}
						<a href='https://apply.wisconsin.edu' target='_blank' rel='noopener noreferrer'>
							live site
						</a>{' '}
						to submit an application.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button bsStyle='info' className='back-btn' fill onClick={this.props.closeModal}>
						Close
					</Button>
				</Modal.Footer>
			</div>
		);
	}
}

export default PracticeWarningModal;
