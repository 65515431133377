import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';

import API from 'components/api';
import { Card } from 'components/Card/Card';
import Textbox from 'components/controls/textbox';
import Button from 'components/CustomButton';
import { REGEXES } from 'components/modules/validation';
import { GlobalActions } from 'reducers/global';
import { UserActions } from 'reducers/user';

class ResetPasswordForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			code: '',
			password: '',
			confirm_password: '',
			commitError: '',
			working: false
		};
	}

	componentDidMount() {
		this._mounted = true;

		const { location } = this.props;
		if (location.search.includes('code')) this.setState({ code: location.search.split('code=')[1] });
	}

	componentDidUpdate(prevProps) {
		const { force_pwd_reset, navigate } = this.props;

		if (this._mounted && !force_pwd_reset && prevProps.force_pwd_reset) navigate('/my-account');
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	getAPI = () => {
		const { support_student_id, campus_admin } = this.props;
		return new API('', support_student_id, campus_admin);
	};

	isValidForm = () => {
		const { password, confirm_password } = this.state;

		return password.length >= 12 && confirm_password === password;
	};

	onSubmit = () => {
		let {
				navigate,
				force_pwd_reset,
				email,
				first_name,
				last_name,
				phone_number,
				no_phone,
				permission_to_send_texts,
				unsetForcePwdReset,
				captureError
			} = this.props,
			{ code, password } = this.state;

		if (force_pwd_reset) {
			const allow_submit =
				!!first_name &&
				!!last_name &&
				!!email &&
				REGEXES.email.test(email) &&
				(no_phone || (!!phone_number && !!permission_to_send_texts));

			if (!allow_submit) {
				this.setState({ commitError: 'Failed to reset your password.' });
			} else {
				this.setState({ working: true });

				this.getAPI()
					.updateAccount(
						email,
						first_name,
						last_name,
						phone_number,
						no_phone,
						permission_to_send_texts,
						password
					)
					.then(result => {
						if (this._mounted) {
							if (result.result === 'success') {
								unsetForcePwdReset();
							} else {
								this.setState({ commitError: 'Failed to reset your password.' });
							}
						}
					})
					.catch(ex => captureError(ex));
			}
		} else {
			this.getAPI()
				.commitReset(code, password)
				.then(result => {
					if (this._mounted) {
						if (result.result === 'success') {
							this.setState({
								commitError: 'Successfully reset your password.',
								password: '',
								confirm_password: ''
							});

							navigate('/sign-in');
						} else {
							this.setState({ commitError: 'Failed to reset your password.' });
						}
					}
				})
				.catch(ex => captureError(ex));
		}
	};

	render() {
		const { navigate, force_pwd_reset } = this.props,
			{ password, confirm_password, commitError, working } = this.state,
			allow_submit = this.isValidForm();

		return (
			<Card
				content={
					<form
						id='form'
						onSubmit={e => {
							e.preventDefault();
							e.stopPropagation();

							if (allow_submit) this.onSubmit();
						}}>
						<Row className={'myAccountRow'}>
							<h3 className={'myAccountH3'}>RESET YOUR PASSWORD</h3>
						</Row>
						<div>
							{!!force_pwd_reset && (
								<p className='intro-copy'>
									Sorry for the inconvenience, but our password policy has changed. Please set a
									password of at least 12 characters before proceeding to your account.
								</p>
							)}

							<Row>
								<Textbox
									type='password'
									value={password}
									name='password'
									label='Password'
									placeholder='Password'
									note_short='at least 12 characters'
									required={true}
									cols={6}
									minLength={12}
									maxLength={256}
									onChange={val => this.setState({ password: val })}
								/>

								<Textbox
									type='password'
									value={confirm_password}
									name='confirm_password'
									label='Confirm Password'
									placeholder='Confirm Password'
									required={true}
									cols={6}
									maxLength={256}
									onChange={val => this.setState({ confirm_password: val })}
									isInvalid={confirm_password !== password}
								/>
							</Row>

							{!!commitError && <h4 className={'uwBlueText'}>{commitError}</h4>}
						</div>
					</form>
				}
				buttons={
					<>
						{!force_pwd_reset && (
							<Button bsStyle='info' className='back-btn col-centered' fill onClick={() => navigate('/')}>
								Home page
							</Button>
						)}

						<Button
							form={'form'}
							bsStyle='info'
							type='submit'
							className='col-md-2 col-centered save-btn'
							disabled={working}
							fill>
							Reset Password
						</Button>
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => ({
		support_student_id: state.user.support_student_id,
		email: state.user.email,
		first_name: state.user.first_name,
		last_name: state.user.last_name,
		phone_number: state.user.phone_number,
		no_phone: state.user.no_phone,
		permission_to_send_texts: state.user.permission_to_send_texts,
		force_pwd_reset: state.user.force_pwd_reset,
		campus_admin: state.user.campus_admin
	}),
	mapDispatchToProps = dispatch => ({
		unsetForcePwdReset: () => dispatch(UserActions.setForcePwdReset(false)),
		captureError: err => dispatch(GlobalActions.captureError(err))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm));
