import React, { Component } from 'react';
import { connect } from 'react-redux';
import API from 'components/api';
import BasicNavMenu from 'components/Navbars/Basic';
import Textbox from 'components/controls/textbox';
import { REGEXES } from 'components/modules/validation';
import { GlobalActions } from 'reducers/global';

const main = require('assets/homepageImages/UWs-logo/white.png'),
	image500 = require('assets/homepageImages/UWs-logo/white-500.png'),
	image800 = require('assets/homepageImages/UWs-logo/white-800.png'),
	image1080 = require('assets/homepageImages/UWs-logo/white-1080.png'),
	image1600 = require('assets/homepageImages/UWs-logo/white-1600.png');

class ResetPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sidebarExists: false,
			email: '',
			reset_state: '',
			error: '',
			working: false
		};
	}

	getAPI = () => {
		const { support_student_id, campus_admin } = this.props;
		return new API('', support_student_id, campus_admin);
	};

	onSubmit = () => {
		const { captureError } = this.props,
			{ email } = this.state;

		this.setState({ working: true });

		this.getAPI()
			.resetPassword(encodeURIComponent(email))
			.then(result => {
				if (result.result === 'success') {
					this.setState({
						working: false,
						reset_state: 'completed',
						error: 'Reset information will be sent to the email you provided if an account with that email is in our system.'
					});
				} else {
					this.setState({
						working: false,
						reset_state: 'notCompleted',
						error: `No account associated with this email: ${email}`
					});
				}
			})
			.catch(ex => captureError(ex));
	};

	mobileSidebarToggle = e => {
		const { sidebarExists } = this.state;

		if (!sidebarExists) this.setState({ sidebarExists: true });

		e.preventDefault();

		if (document.documentElement.classList.contains('nav-open')) {
			document.documentElement.classList.toggle('nav-open'); //Toggle and remove all bodyClicks
			document.querySelectorAll('#bodyClick').forEach(e => e.remove()); //REMOVE ALL bodyClick elements
		} else {
			document.documentElement.classList.toggle('nav-open'); //Toggle and add bodyClick element

			let node = document.createElement('div');
			node.id = 'bodyClick';
			node.onclick = function () {
				if (this.parentElement) this.parentElement.removeChild(this);
				document.querySelectorAll('#bodyClick').forEach(e => e.remove()); //REMOVE ALL bodyClick elements
				document.documentElement.classList.toggle('nav-open');
			};

			document.body.appendChild(node);
		}
	};

	render() {
		const { reset_state, email, error, working } = this.state,
			allow_submit = !!email && REGEXES.email.test(email);

		return (
			<div className='body-2' role='main'>
				<div
					data-collapse='medium'
					data-animation='default'
					data-duration='400'
					data-easing='ease-out'
					data-easing2='ease-out'
					className='nav-2-columns w-nav'>
					<div className='navigation-container'>
						<a href='/' className='logo w-inline-block'>
							<img
								src={main}
								width='100'
								srcSet={`${image500} 500w, ${image800} 800w, ${image1080} 1080w, ${image1600} 1600w, ${main} 1647w`}
								sizes='(max-width: 479px) 55vw, (max-width: 991px) 200px, 100vw'
								alt=''
								className='image-3'
							/>
						</a>
						<div className='menu-button w-nav-button'>
							<div className='icon-2 w-icon-nav-menu' onClick={this.mobileSidebarToggle} />
						</div>
					</div>
				</div>

				<div className='two-column-view' role='navigation'>
					<BasicNavMenu />

					<div className='right-column'>
						<div className='right-column-wrap'>
							<div className='form-block w-form'>
								<h1 className='column-heading'>Reset Password</h1>

								<form
									id='email-form'
									name='email-form'
									data-name='Email Form'
									className='form'
									onSubmit={e => {
										e.preventDefault();
										e.stopPropagation();

										if (allow_submit) this.onSubmit();
									}}>
									<p className='intro-copy'>
										Enter the email address associated with your Universities of Wisconsin account
										to have the password reset instructions emailed to you.
									</p>

									<Textbox
										type='email'
										name='email'
										value={email}
										maxLength={256}
										label='Email'
										placeholder='Enter your email or username'
										required={true}
										onChange={val => this.setState({ email: val })}
										isInvalid={!REGEXES.email.test(email)}
									/>

									{!!error && <h4 className={`text ${reset_state}`}>{error}</h4>}

									<input
										aria-label='Reset password submit'
										type='submit'
										value='Reset Password'
										data-wait='Please wait...'
										className='submit-button w-button'
										disabled={working || !allow_submit}
									/>
								</form>
							</div>
						</div>
					</div>
				</div>
				<script
					src='https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.4.1.min.220afd743d.js?site=5ea1ccd99c38e78f25db182e'
					type='text/javascript'
					integrity='sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo='
					crossOrigin='anonymous'></script>
				<script src='assets/webflowJS/webflow.js' type='text/javascript'></script>
			</div>
		);
	}
}

const mapStateToProps = state => ({
		support_student_id: state.user.support_student_id,
		campus_admin: state.user.campus_admin
	}),
	mapDispatchToProps = dispatch => ({
		captureError: err => dispatch(GlobalActions.captureError(err))
	});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
