/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

class ProgressBarFull extends React.Component {
	render() {
		return (
			<div>
				<ProgressBar percentage={this.props.percentage} />

				<p className={'progressText'}>Progress</p>
				<p className={'progressPercent'}>{this.props.percentage}%</p>
			</div>
		);
	}
}

const ProgressBar = props => {
	return (
		<div className='progress-bar'>
			<Filler percentage={props.percentage} />
		</div>
	);
};

const Filler = props => {
	return <div className='filler' style={{ width: `${props.percentage}%` }} />;
};

export default ProgressBarFull;
