import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import { ControlLabel, FormGroup, Modal, Row, Table } from 'react-bootstrap';
import API from 'components/api';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { cloneDeep } from 'components/modules/_misc';
import { DEFAULT } from 'components/modules/app';
import { nextAppPage, prevAppPage } from 'components/modules/nav';
import _svg from 'components/modules/svg';
import { months_passed } from 'components/modules/date';
import DateInput from 'components/controls/date';
import RadioList from 'components/controls/radio_list';
import Select from 'components/controls/select';
import Textbox from 'components/controls/textbox';
import { states, countries } from 'components/modules/opts';
import { UserActions } from 'reducers/user';

const optsDegrees = [
	'Associate Degree',
	'Bachelor Degree',
	"Master's Degree",
	'Doctorate',
	'Certificate',
	'Other',
	'None'
];

class AcademicBackgroundHigherEducation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			index: -1,
			show_search: false,
			arrSearch: [],
			search: {
				name: '',
				location: ''
			},
			modalShow: false,
			college: null
		};

		this.countries = countries.map(rec => ({ val: rec.label, label: rec.label })); // 12/12/22, tms:  our school data uses a country's full name rather than code
	}

	componentDidMount() {
		this._mounted = true;
		this.props.setSubmenu();
	}

	componentWillUnmount() {
		this._mounted = false;

		const { saveApp, app } = this.props;
		saveApp(!!app.academic_background.colleges.length);
	}

	getAPI = () => {
		const { support_student_id, campus_admin } = this.props;
		return new API('', support_student_id, campus_admin);
	};

	isValidForm = () => {
		const { app } = this.props,
			{ college } = this.state;

		if (!college) return !!app.academic_background.colleges.length;

		let { currently_attend, did_you_earn_degree, graduation_date, degree_earned, degree_freetext, major } = college,
			valid =
				!!currently_attend &&
				this.checkDateValidity('college_start') &&
				this.checkDateValidity('college_end') &&
				!!did_you_earn_degree;

		if (valid && did_you_earn_degree === 'Yes') {
			valid =
				graduation_date?.length === 7 &&
				this.checkDateValidity('graduation_date') &&
				!!degree_earned &&
				!(degree_earned === 'Other' && !degree_freetext) &&
				!!major;
		}

		return valid;
	};

	checkDateValidity = str => {
		const { college } = this.state,
			{ currently_attend, college_start, college_end, graduation_date } = college;

		if (college[str].length < 7) return false;

		let start_months = college_start.length === 7 ? months_passed(college_start) : 1200,
			end_months = college_end.length === 7 ? months_passed(college_end) : -1200,
			grad_months = graduation_date.length === 7 ? months_passed(graduation_date) : -1200,
			inRange = months => months >= -4 * 12 && months <= 82 * 12;

		if (str === 'college_start') {
			return (
				inRange(start_months) &&
				!(currently_attend === 'Yes' && start_months < 0) &&
				!(currently_attend === 'No' && start_months > 0 && end_months < 0)
			);
		}

		if (str === 'college_end') {
			return (
				inRange(end_months) &&
				end_months <= start_months &&
				!(currently_attend === 'Yes' && end_months > 0) &&
				!(currently_attend === 'No' && start_months > 0 && end_months < 0)
			);
		}

		return inRange(grad_months) && grad_months <= start_months && !(currently_attend === 'Yes' && grad_months > 0);
	};

	onAddCollege = () => {
		this.setState({ index: -1, college: cloneDeep(DEFAULT.COLLEGE), show_search: true });
	};

	onEditCollege = i => {
		const { app } = this.props;
		this.setState({ index: i, college: cloneDeep(app.academic_background.colleges[i]) });
	};

	onDeleteCollege = i => {
		let { app, updateApp } = this.props;

		if (!app.date_submitted) {
			confirmAlert({
				title: 'Delete Entry',
				message: 'Are you sure you want to delete this?',
				buttons: [
					{
						label: 'Yes',
						onClick: () => {
							let academic_background = cloneDeep(app.academic_background);
							academic_background.colleges.splice(i, 1);
							updateApp({ academic_background: academic_background });
						}
					},
					{
						label: 'No'
					}
				]
			});
		}
	};

	onSaveCollege = e => {
		e.preventDefault();
		e.stopPropagation();

		let { app, updateApp } = this.props,
			{ index } = this.state,
			academic_background = cloneDeep(app.academic_background),
			college = cloneDeep(this.state.college);

		if (!app.date_submitted) {
			if (!['United States', 'USA'].includes(college.selected_college.field_country)) {
				college.selected_college.field_state = '';
			}

			if (college.selected_college.field_country !== 'Other') {
				college.selected_college.field_other_country = '';
			}

			if (college.did_you_earn_degree === 'No') {
				college.graduation_date = '';
				college.degree_earned = '';
				college.degree_freetext = '';
				college.major = '';
			}

			if (index < 0) {
				academic_background.colleges.push(college);
			} else {
				academic_background.colleges[index] = college;
			}

			updateApp({ academic_background: academic_background });
			this.setState({ college: null, index: -1 });
		}
	};

	onChangeSearch = (value, prop) => {
		const { captureError } = this.props,
			{ search } = this.state,
			setList = resp => {
				if (this._mounted) this.setState({ arrSearch: resp });
			};

		search[prop] = value;

		if (value) {
			if (prop === 'location') {
				this.getAPI()
					.getCollegesByLocation(value)
					.then(setList)
					.catch(ex => captureError(ex));
			} else {
				this.getAPI()
					.getCollegesBySearch(value)
					.then(setList)
					.catch(ex => captureError(ex));
			}
		} else {
			this.setState({ arrSearch: [] });
		}

		this.setState({ search: search });
	};

	onSelectCollegeBySearch = _college => {
		let { app } = this.props,
			college = cloneDeep(this.state.college);

		if (!app.date_submitted) {
			college.selected_college = cloneDeep(_college);

			this.setState({
				show_search: false,
				college: college,
				arrSearch: [],
				search: {
					name: '',
					location: ''
				}
			});
		}
	};

	onChange = (val, prop) => {
		let { app } = this.props,
			college = cloneDeep(this.state.college);

		if (!app.date_submitted) {
			college[prop] = val;
			this.setState({ college: college });
		}
	};

	onChangeSelectedCollege = (val, prop) => {
		let { app } = this.props,
			college = cloneDeep(this.state.college);

		if (!app.date_submitted) {
			college.selected_college[prop] = val;
			this.setState({ college: college });
		}
	};

	renderCustomCollegeModal = () => {
		const { modalShow, college } = this.state,
			{ title, field_country, field_state, field_city, field_other_country } = college.selected_college,
			valid_form =
				!!title &&
				!!field_country &&
				!!field_city &&
				!(['United States', 'USA'].includes(field_country) && !field_state) &&
				!(field_country === 'Other' && !field_other_country);

		return (
			<Modal show={modalShow}>
				<form
					onSubmit={e => {
						e.preventDefault();
						e.stopPropagation();

						this.setState({ show_search: false, modalShow: false });
					}}>
					<Modal.Header>
						<Modal.Title className='h2'>College</Modal.Title>
					</Modal.Header>
					<Modal.Body className='modalMinHeight'>
						<Row>
							<Textbox
								name='title'
								value={title}
								onChange={val => this.onChangeSelectedCollege(val, 'title')}
								label='College/Post-Secondary School Name'
								placeholder='College/Post-Secondary School Name'
								required={true}
								maxLength={50}
								cols={12}
							/>
						</Row>
						<Row>
							<Select
								name='field_country'
								value={field_country}
								onChange={val => this.onChangeSelectedCollege(val, 'field_country')}
								opts={this.countries}
								label='Country'
								placeholder='Select Country'
								required={true}
								cols={6}
							/>

							{['United States', 'USA'].includes(field_country) && (
								<Select
									name='field_state'
									value={field_state}
									onChange={val => this.onChangeSelectedCollege(val, 'field_state')}
									opts={states}
									label='State'
									placeholder='Select State'
									required={true}
									cols={6}
								/>
							)}

							{field_country === 'Other' && (
								<Textbox
									name='field_other_country'
									value={field_other_country}
									onChange={val => this.onChangeSelectedCollege(val, 'field_other_country')}
									label='Specify Country'
									placeholder='Specify Country'
									required={true}
									maxLength={40}
									cols={6}
								/>
							)}
						</Row>
						<Row>
							<Textbox
								name='field_city'
								value={field_city}
								onChange={val => this.onChangeSelectedCollege(val, 'field_city')}
								label='City'
								placeholder='City'
								required={true}
								maxLength={50}
								cols={6}
							/>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							bsStyle='info'
							className='back-btn'
							fill
							onClick={() => this.setState({ modalShow: false })}>
							Cancel
						</Button>
						<Button bsStyle='info' className='save-btn' fill type='submit' disabled={!valid_form}>
							Confirm
						</Button>
					</Modal.Footer>
				</form>
			</Modal>
		);
	};

	renderEditForm = () => {
		let { college } = this.state,
			{
				currently_attend,
				selected_college,
				college_start,
				college_end,
				did_you_earn_degree,
				graduation_date,
				degree_earned,
				degree_freetext,
				major
			} = college,
			{ title, field_city, field_state, field_country } = selected_college;

		return (
			<>
				<p>
					<strong>{title}</strong>
					<br />
					{field_city}, {field_state === 'N/A' ? '' : `${field_state} `}
					{!['United States', 'USA'].includes(field_country) && field_country?.toUpperCase()}
					<br />
				</p>

				<Row>
					<RadioList
						name='currently_attend'
						value={currently_attend}
						onChange={val => this.onChange(val, 'currently_attend')}
						opts={['Yes', 'No']}
						label='Do you currently attend this school?'
						cols={12}
						required={true}
					/>
				</Row>
				<Row>
					<DateInput
						value={college_start}
						onChange={(val, isValid) => this.onChange(val, 'college_start', isValid)}
						label='Attended from'
						required={true}
						cols={6}
						fullDate={false}
						isInvalid={!this.checkDateValidity('college_start')}
					/>

					<DateInput
						value={college_end}
						onChange={(val, isValid) => this.onChange(val, 'college_end', isValid)}
						label={currently_attend === 'Yes' ? 'Anticipated Finish ' : 'Attended to'}
						required={true}
						cols={6}
						fullDate={false}
						isInvalid={!this.checkDateValidity('college_end')}
					/>
				</Row>
				<Row>
					<RadioList
						name='did_you_earn_degree'
						value={did_you_earn_degree}
						onChange={val => this.onChange(val, 'did_you_earn_degree')}
						opts={['Yes', 'No']}
						label='Did you or will you earn a degree at this institution?'
						cols={12}
						required={true}
					/>
				</Row>

				{did_you_earn_degree === 'Yes' && (
					<>
						<Row>
							<DateInput
								value={graduation_date}
								onChange={(val, isValid) => this.onChange(val, 'graduation_date', isValid)}
								label={currently_attend === 'Yes' ? 'Anticipated Graduation Date' : 'Graduation Date'}
								required={true}
								cols={6}
								fullDate={false}
								isInvalid={!this.checkDateValidity('graduation_date')}
							/>
						</Row>
						<Row>
							<Select
								name='degree_earned'
								value={degree_earned}
								onChange={val => this.onChange(val, 'degree_earned')}
								opts={optsDegrees}
								label='Degree Earned'
								placeholder='Degree Earned'
								required={true}
								cols={6}
							/>

							{degree_earned === 'Other' && (
								<Textbox
									name='degree_freetext'
									value={degree_freetext}
									onChange={val => this.onChange(val, 'degree_freetext')}
									label='What degree?'
									placeholder='What degree?'
									maxLength={100}
									required={true}
									cols={6}
								/>
							)}
						</Row>

						<Row>
							<Textbox
								name='major'
								value={major}
								onChange={val => this.onChange(val, 'major')}
								label='Major/Program'
								placeholder='Major/Program'
								maxLength={40}
								required={true}
								cols={6}
							/>
						</Row>
					</>
				)}
			</>
		);
	};

	renderSearchForm = () => {
		let { search, arrSearch } = this.state;

		return (
			<>
				{this.renderCustomCollegeModal()}

				<Row>
					<Textbox
						name='name'
						value={search.name}
						onChange={val => this.onChangeSearch(val, 'name')}
						label='Search By Name'
						placeholder='Search By Name'
						maxLength={100}
						cols={6}
						hide_optional={true}
					/>
					<Textbox
						name='location'
						value={search.location}
						onChange={val => this.onChangeSearch(val, 'location')}
						label='Search By City/Location'
						placeholder='Search By City/Location'
						maxLength={100}
						cols={6}
						hide_optional={true}
					/>
				</Row>

				<FormGroup>
					<ControlLabel>Choose School</ControlLabel>
					<br />

					{arrSearch.map((_college, i) => {
						const { title, field_city, field_state, field_country } = _college;

						return (
							<button
								tabIndex='0'
								key={i}
								className='school_select'
								onClick={() => this.onSelectCollegeBySearch(_college)}>
								<strong>{title}</strong>
								<br />
								{field_city}, {field_state === 'N/A' ? '' : `${field_state} `}
								{!['United States', 'USA'].includes(field_country) && field_country?.toUpperCase()}
							</button>
						);
					})}

					<button
						tabIndex='0'
						className='school_select school_not_found'
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();

							this.setState({ modalShow: true });
						}}>
						<strong>I did not find my college</strong>
					</button>
				</FormGroup>
			</>
		);
	};

	renderCollegeRow() {
		const { app } = this.props;

		let objectRows = app.academic_background.colleges.length
			? app.academic_background.colleges.map((_college, i) => {
					return (
						<tr key={`college_${i}`}>
							<td data-label='College'>{_college.selected_college.title}</td>
							<td data-label='Actions'>
								<Button bsStyle='info' onClick={() => this.onEditCollege(i)} className='editBtn btn-xs'>
									<img
										alt='editIcon'
										className='imgDashboard'
										height='18px'
										width='18px'
										src={_svg.Edit}
									/>
									<p className='pDashboard'>Edit</p>
								</Button>
								<Button
									bsStyle='info'
									onClick={() => this.onDeleteCollege(i)}
									className='deleteBtn btn-xs'>
									<img
										alt='deleteIcon'
										className='imgDashboard'
										height='18px'
										width='18px'
										src={_svg.Delete}
									/>
									<p className='pDashboard'>Delete</p>
								</Button>
							</td>
						</tr>
					);
			  })
			: [
					<tr key='none'>
						<td colSpan={2}>You have no colleges added.</td>
					</tr>
			  ];

		objectRows.push(
			<tr key='add' className='addColumn'>
				<td></td>
				<td data-label='Actions'>
					<Button bsStyle='info' onClick={() => this.onAddCollege()} className='editBtn btn-xs'>
						<img
							alt='editIcon'
							className='imgDashboard'
							height='18px'
							width='18px'
							src={_svg.GreenCirclePlus}
						/>
						<p className='pDashboard'>Add College</p>
					</Button>
				</td>
			</tr>
		);

		return objectRows;
	}

	renderMainPage = () => {
		const { app } = this.props;

		return (
			<>
				<h4>
					In this next section you will need to supply the following pieces of information about your
					College/Post-Secondary history:
					<a id='tooltip'>
						<img
							className='informationTooltip'
							src={require('assets/img/Information.png')}
							alt='Information'
						/>
					</a>
					<Tooltip
						anchorId='tooltip'
						className='tooltipContainer'
						delayHide={1000}
						effect='solid'
						content={
							<>
								<p>
									All institutions of higher education currently or previously attended must be
									listed. Institutions of higher education include: post-secondary colleges,
									universities, academies, online campuses, vocational schools.
								</p>
								<p>Dates of enrollment are important!</p>
							</>
						}
					/>
				</h4>
				<ul>
					<li className='transitionLI'>
						Please list all college/post-secondary schools you've attended and the following information
						about each one:
						<ul>
							<li className='transitionLI'>Full name of college/post-secondary institution</li>
							<li className='transitionLI'>Attendance dates</li>
							<li className='transitionLI'>Degree earned</li>
							<li className='transitionLI'>Major/program earned</li>
						</ul>
					</li>
				</ul>

				<hr />

				{app.academic_background.colleges.length > 0 ? (
					<Table striped bordered hover>
						<thead>
							<tr>
								<th scope='col'>
									<b>College</b>
								</th>
								<th scope='col'>
									<b>Actions</b>
								</th>
							</tr>
						</thead>
						<tbody>{this.renderCollegeRow()}</tbody>
					</Table>
				) : (
					<Button bsStyle='info' className='save-btn add-content' fill onClick={() => this.onAddCollege()}>
						Add College
					</Button>
				)}
			</>
		);
	};

	renderBtnSubmit = () => {
		const { college, uploading_files, show_search } = this.state;

		if (show_search) return null;

		const _valid = this.isValidForm();

		return college ? (
			<Button
				bsStyle='info'
				className='tertiaryButton'
				fill
				onClick={this.onSaveCollege}
				disabled={uploading_files || !_valid}>
				Save College
			</Button>
		) : (
			<Button form='form' bsStyle='info' className='save-btn' disabled={!_valid} fill type='submit'>
				Save and Continue
			</Button>
		);
	};

	render() {
		let { app, navigate, location } = this.props,
			{ college, show_search } = this.state,
			content;

		if (college) {
			content = show_search ? this.renderSearchForm() : this.renderEditForm();
		} else {
			content = this.renderMainPage();
		}

		return (
			<Card
				title='Academic Background: College/Post-Secondary'
				content={
					<form id='form' onSubmit={e => navigate(nextAppPage(app, location.pathname))}>
						{content}
						<div className='clearfix' />
					</form>
				}
				buttons={
					<>
						<Button
							bsStyle='info'
							className='back-btn'
							fill
							onClick={() => {
								if (college) {
									this.setState({ college: null, show_search: false });
								} else {
									navigate(prevAppPage(app, location.pathname));
								}
							}}>
							Back
						</Button>
						{this.renderBtnSubmit()}
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => {
		const { app_id, apps, support_student_id } = state.user;

		return {
			app: apps[app_id].json_obj,
			app_id: app_id,
			support_student_id: support_student_id
		};
	},
	mapDispatchToProps = dispatch => ({
		updateApp: obj => dispatch(UserActions.updateApp(obj)),
		saveApp: val => dispatch(UserActions.saveApp({ academicBackgroundCollegeCompleted: val })),
		setSubmenu: () => dispatch(UserActions.setSubmenus('academic_background_open', true)),
		unsetAcademicBackgroundCollegeCompleted: () =>
			dispatch(UserActions.setScreensCompleted({ academicBackgroundCollegeCompleted: false }))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AcademicBackgroundHigherEducation));
