import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import { FormGroup } from 'react-bootstrap';
import 'react-phone-input-2/lib/style.css';

export default class PhoneControl extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hide_invalid: !props.value?.length // don't show invalid status while it's being inputted for the first time
		};
	}

	isValid = (str, country) => {
		return (
			(country?.name || 'United States') !== 'United States' ||
			str.length === country.format.replace(/[^.]/g, '').length
		);
	};

	render() {
		let { value, onChange, label, name, required, cols, disabled, tooltip } = this.props,
			{ hide_invalid } = this.state,
			content = (
				<>
					{!!label && (
						<label htmlFor={name} className='field-label'>
							{label}
							{!required && !disabled && <small>(Optional)</small>}
							{tooltip}
						</label>
					)}

					<PhoneInput
						containerClass={`${hide_invalid ? 'hide-invalid-phone' : ''}`}
						name='name'
						country={'us'}
						preferredCountries={['us']}
						value={value}
						countryCodeEditable={false}
						onBlur={() => this.setState({ hide_invalid: false })}
						isValid={(str, country) =>
							(!required && str === country.dialCode) || this.isValid(str, country)
						}
						disabled={disabled}
						onChange={(str, country) => {
							let status = this.isValid(str, country) ? 'valid' : 'invalid';
							if (str.length <= country.dialCode.length) status = 'empty';

							onChange(`${country.dialCode}-${str.substr(country.dialCode.length)}`, status);
						}}
					/>
				</>
			);

		return cols ? (
			<div className={`col-md-${cols}`}>
				<FormGroup>{content}</FormGroup>
			</div>
		) : (
			content
		);
	}
}

PhoneControl.propTypes = {
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	label: PropTypes.string,
	name: PropTypes.string,
	required: PropTypes.bool,
	cols: PropTypes.number,
	disabled: PropTypes.bool,
	tooltip: PropTypes.object
};
