export const REGEXES = {
		email: new RegExp(
			/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
		),
		zip: country => {
			const international_zip_regex = '^[A-Za-z\\d\\.\\- ]{0,10}$',
				zip_regexes = {
					USA: '^\\d{5}(?:-\\d{4})?$',
					CAN: '[A-Za-z]\\d[A-Za-z] \\d[A-Za-z]\\d'
				};

			if (!country) return international_zip_regex;

			return zip_regexes[country] || international_zip_regex;
		}
	},
	addrIsPOBox = addr => {
		// checks whether or not the input begins with some variation of "P.O. Box"

		if ((addr.country || 'USA') === 'USA') {
			if (
				!(addr.address_1 || '')
					.replace(/[^a-zA-Z\d]+/g, '')
					.toLowerCase()
					.indexOf('pobox')
			) {
				return 'address_1';
			}

			if (
				!(addr.address_2 || '')
					.replace(/[^a-zA-Z\d]+/g, '')
					.toLowerCase()
					.indexOf('pobox')
			) {
				return 'address_2';
			}
		}

		return '';
	},
	addrIsValid = addr => {
		if (!addr) return false;

		const { address_1, city, country, state, other_country, zip } = addr;

		return (
			!!address_1 &&
			!!country &&
			(country !== 'USA' || !!state) &&
			(country !== 'Other' || !!other_country) &&
			!!city &&
			(!['USA', 'CAN'].includes(country) || !!zip) &&
			!!zip.match(REGEXES.zip(country))
		);
	};
