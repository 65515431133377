import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Table, Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import withRouter from 'components/Wrappers/withRouter';
import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import { cloneDeep, scrollTo } from 'components/modules/_misc';
import { DEFAULT } from 'components/modules/app';
import { nextAppPage, prevAppPage } from 'components/modules/nav';
import { years_passed, months_passed } from 'components/modules/date';
import { create_proactive } from 'components/modules/ada';
import DateInput from 'components/controls/date';
import RadioList from 'components/controls/radio_list';
import Select from 'components/controls/select';
import Textbox from 'components/controls/textbox';
import { UserActions } from 'reducers/user';

class Residency extends Component {
	constructor(props) {
		super(props);

		const { app } = props;

		this.state = {
			editing: '',
			show_modal: false
		};

		let age = years_passed(app.personal_information.date_of_birth);
		if (isNaN(age)) age = 0;

		this.is_adult = age >= 25;
		this.hs_grad = app.initial_information.app_type_question_4 === 'Yes';
		this.applicant_label = app.application_modifier === 'DA' ? 'Student' : 'Applicant';
	}

	componentDidMount() {
		this._mounted = true;

		setTimeout(() => {
			if (this._mounted) {
				create_proactive('Have some questions about how we determine residency?', '5ebaea25b578cf68ad3cba32');
			}
		}, 2000);

		this.props.setSubmenu(true);
	}

	componentWillUnmount() {
		this._mounted = false;

		let { app, saveApp, updateApp } = this.props,
			residency = cloneDeep(app.residency),
			{
				intend_claim_residency,
				relationship_us_citizen,
				relationship_visa_type,
				relationship_lived_wi_12_months,
				relationship_employed,
				relationship_income_taxes,
				self_live_in_wi,
				relationship_unable_to_provide
			} = residency,
			_valid = this.isValidForm();

		saveApp(_valid);

		if (intend_claim_residency === 'No') {
			residency = cloneDeep(DEFAULT.APP_JSON.residency);
			residency.intend_claim_residency = 'No';
		} else {
			if (relationship_us_citizen !== 'No') residency.relationship_visa_type = '';

			if (relationship_us_citizen !== 'No' || relationship_visa_type !== 'Other') {
				residency.relationship_visa_type_other = '';
			}

			if (
				!(
					relationship_lived_wi_12_months === 'No' ||
					relationship_employed !== 'Wisconsin' ||
					relationship_income_taxes !== 'Yes'
				)
			) {
				residency.relationship_registered_vote = '';
				residency.relationship_valid_license = '';
			}

			if (self_live_in_wi !== "Yes, but I haven't always") residency.self_lived_wi_date = '';

			if (relationship_unable_to_provide) residency.self_claim_dependent = '';
		}

		updateApp({ residency: residency });
	}

	isValidForm = () => {
		let { app } = this.props,
			{ editing } = this.state,
			{
				intend_claim_residency,
				self_provided,
				parent_provided,
				provide_parent_info,
				residency_relationship,
				relationship_us_citizen,
				relationship_visa_type,
				relationship_visa_type_other,
				relationship_lived_wi_12_months,
				relationship_employed,
				relationship_income_taxes,
				relationship_registered_vote,
				relationship_valid_license,
				self_live_in_wi,
				self_lived_wi_date,
				self_employed,
				self_income_taxes,
				self_registered_vote,
				self_valid_license,
				relationship_unable_to_provide,
				self_claim_dependent,
				self_lived_wi_12_months,
				self_he_enrolled
			} = app.residency;

		if (editing === 'parent') {
			const show_more_fields =
				relationship_lived_wi_12_months === 'No' ||
				relationship_employed !== 'Wisconsin' ||
				relationship_income_taxes !== 'Yes';

			return (
				!!residency_relationship &&
				!!relationship_us_citizen &&
				!(relationship_us_citizen === 'No' && !relationship_visa_type) &&
				!(
					relationship_us_citizen === 'No' &&
					relationship_visa_type === 'Other' &&
					!relationship_visa_type_other
				) &&
				!!relationship_lived_wi_12_months &&
				!!relationship_employed &&
				!!relationship_income_taxes &&
				!(show_more_fields && !relationship_registered_vote) &&
				!(show_more_fields && !relationship_valid_license)
			);
		}

		if (editing === 'self') {
			const invalid_date = !!self_lived_wi_date && months_passed(self_lived_wi_date) < 0;

			return (
				!!self_live_in_wi &&
				!(self_live_in_wi === "Yes, but I haven't always" && !self_lived_wi_date) &&
				!(self_live_in_wi === "Yes, but I haven't always" && invalid_date) &&
				!!self_employed &&
				!!self_income_taxes &&
				!!self_registered_vote &&
				!!self_valid_license &&
				!(!relationship_unable_to_provide && !self_claim_dependent) &&
				!!self_lived_wi_12_months &&
				(!this.hs_grad || !!self_he_enrolled)
			);
		}

		return (
			!(this.appQuestionsNeeded() && !self_provided) &&
			!(this.parentQuestionsNeeded() && !parent_provided) &&
			!!intend_claim_residency &&
			!(intend_claim_residency === 'Yes' && this.is_adult && !provide_parent_info)
		);
	};

	getParentOptions() {
		let { app } = this.props,
			default_opts = ['Mother', 'Father', 'Stepmother', 'Stepfather', 'Legal Guardian/Caretaker'],
			opts = app.parent_info.parents.map(parent => ({
				label: `${parent.first_name} ${parent.last_name} (${parent.relationship_to_applicant})`,
				val: parent.relationship_to_applicant
			}));

		default_opts
			.filter(default_opt => !opts.some(opt => opt.val === default_opt))
			.forEach(default_opt => {
				opts.push({ label: default_opt, val: default_opt });
			});

		return opts;
	}

	appQuestionsNeeded = e => {
		const { intend_claim_residency, residency_status, provide_parent_info } = this.props.app.residency;
		return intend_claim_residency === 'Yes' && (residency_status === 'U' || provide_parent_info === 'No');
	};

	parentQuestionsNeeded = event => {
		const { intend_claim_residency, provide_parent_info, relationship_unable_to_provide } =
			this.props.app.residency;

		return intend_claim_residency === 'Yes' && provide_parent_info !== 'No' && !relationship_unable_to_provide;
	};

	onUnableToProvide = e => {
		const { app, updateApp } = this.props,
			residency = cloneDeep(app.residency),
			{ checked } = e.target;

		if (!app.date_submitted) {
			const changeAnswer = () => {
					residency.relationship_unable_to_provide = checked;
					if (checked) residency.self_claim_dependent = '';
					updateApp({ residency: residency });
				},
				parent_res_fields = [
					'residency_relationship',
					'relationship_us_citizen',
					'relationship_lived_wi_12_months',
					'relationship_employed',
					'relationship_income_taxes',
					'relationship_registered_vote',
					'relationship_valid_license'
				],
				changed_field = parent_res_fields.find(str => residency[str] !== '');

			if (checked) {
				if (changed_field) {
					confirmAlert({
						title: 'Delete Parent Info?',
						message: "Are you sure you want to discard the parent residency information you've provided?",
						buttons: [
							{
								label: 'Yes',
								onClick: () => {
									if (residency.provide_parent_info === 'Yes') residency.provide_parent_info = 'No';

									parent_res_fields.forEach(str => {
										residency[str] = '';
									});

									changeAnswer();
								}
							},
							{
								label: 'No'
							}
						]
					});
				} else {
					if (residency.provide_parent_info === 'Yes') residency.provide_parent_info = 'No';
					changeAnswer();
				}
			} else {
				changeAnswer();
			}
		}
	};

	onSubmit = e => {
		e.preventDefault();
		e.stopPropagation();

		let { app, updateApp, setSubmenu, navigate, location } = this.props,
			{ editing } = this.state,
			residency = cloneDeep(app.residency);

		if (!editing) {
			setSubmenu(false);
			navigate(nextAppPage(app, location.pathname));
		} else {
			if (editing === 'parent') {
				residency.parent_provided = true;
			} else {
				residency.self_provided = true;
			}

			this.setState({ editing: '' });
			updateApp({ residency: residency });

			scrollTo();
		}
	};

	onBack = e => {
		let { app, navigate, location } = this.props,
			{ editing } = this.state;

		if (editing) {
			this.setState({ editing: '' });
			scrollTo();
		} else {
			navigate(prevAppPage(app, location.pathname));
		}
	};

	onChange = (prop, val) => {
		const { app, updateApp } = this.props,
			residency = cloneDeep(app.residency);

		if (!app.date_submitted) {
			residency[prop] = val;

			if (prop === 'provide_parent_info' && val === 'Yes') residency.relationship_unable_to_provide = false;

			if (prop === 'intend_claim_residency' && val === 'No') {
				const has_in_state_addr =
					app.contact_information.permanent_address.state === 'WI' ||
					app.parent_info.parents.some(p => p.current_address.state === 'WI');

				if (has_in_state_addr) this.setState({ show_modal: true });
			}

			updateApp({ residency: residency });
		}
	};

	renderModal = () => {
		const { app, updateApp } = this.props,
			{ show_modal } = this.state,
			residency = cloneDeep(app.residency);

		return (
			<Modal show={show_modal}>
				<Modal.Header>
					<Modal.Title className='h2'>Residency Status</Modal.Title>
					<Modal.Body className={'modalMinHeight'}>
						<p>
							<strong>
								Based on your selection, you will be classified as a nonresident (out-of-state) student
								for tuition purposes.
							</strong>
						</p>
						<p>
							In most cases, nonresident students are charged a higher tuition rate than resident
							students. In many (but not all) circumstances, students may be eligible for resident
							(in-state) tuition if they claim resident status and they or their parents reside in
							Wisconsin.
						</p>
						<p>
							Students who claim nonresident status will be considered nonresidents for tuition purposes,
							regardless of the state of their home address.
						</p>
					</Modal.Body>
					<Modal.Footer>
						<Button
							bsStyle='info'
							className='back-btn'
							fill
							onClick={() => {
								residency.intend_claim_residency = '';
								updateApp({ residency: residency });
								this.setState({ show_modal: false });
							}}>
							Change my selection
						</Button>

						<Button
							bsStyle='info'
							className='save-btn'
							fill
							onClick={() => this.setState({ show_modal: false })}>
							Confirm
						</Button>
					</Modal.Footer>
				</Modal.Header>
			</Modal>
		);
	};

	renderIntroForm = () => {
		let { app } = this.props,
			{ intend_claim_residency, provide_parent_info, relationship_unable_to_provide, parent_provided } =
				app.residency,
			show_sub_forms = intend_claim_residency === 'Yes' && (!this.is_adult || !!provide_parent_info);

		return (
			<>
				<p>
					The next section will ask questions to determine if you qualify for Wisconsin resident tuition under
					state law. The information collected in the following sections is only used to determine your
					tuition rate. Learn more about qualifying for
					<span
						onClick={() => window.open('https://www.wisconsin.edu/student-resources/residency/', '_blank')}
						className={'underlined uwBlueText'}>
						{' '}
						Wisconsin residency
					</span>
					.
				</p>
				<p>
					<strong className='uwRedText'>
						Failure to accurately and completely answer the following questions may result in an incorrect
						tuition rate.
					</strong>
				</p>
				<Row>
					<RadioList
						name='intend_claim_residency'
						value={intend_claim_residency}
						label='Do you believe you may qualify for in-state tuition rate based upon Wisconsin residency?'
						opts={['Yes', 'No']}
						onChange={val => this.onChange('intend_claim_residency', val)}
						required={true}
						cols={12}
					/>
				</Row>

				{intend_claim_residency === 'Yes' && this.is_adult && (
					<>
						<p>
							Typically, the easiest route to qualify for the Wisconsin resident tuition rate is through
							providing information about your parents.
						</p>
						<Row>
							<RadioList
								name='provide_parent_info'
								value={provide_parent_info}
								label="Do you want to provide parent's information for residency purposes?"
								opts={['Yes', 'No']}
								onChange={val => this.onChange('provide_parent_info', val)}
								required={true}
								cols={12}
							/>
						</Row>
					</>
				)}

				{show_sub_forms && (
					<>
						{this.renderTable()}

						{!app.parent_info.parents.length && !parent_provided && (
							<div className='chkbox-container'>
								<input
									type='checkbox'
									id='parent-opt-out'
									name='parent-opt-out'
									checked={!!relationship_unable_to_provide}
									onChange={this.onUnableToProvide}
								/>
								<label htmlFor='parent-opt-out'>
									I&apos;m not able to provide parent or legal guardian information because I&apos;m
									homeless, in foster care, do not know my parents, or do not have a legal guardian.
								</label>
							</div>
						)}
					</>
				)}

				<p className={'mnRecip'}>
					<strong>Minnesota Reciprocity</strong>
				</p>
				<p className={'mnRecip'}>
					If you believe you qualify for Minnesota tuition reciprocity, that is determined through a separate
					process. Learn more about qualifying for
					<span
						onClick={() => window.open('https://www.ohe.state.mn.us/mPg.cfm?PageID=120', '_blank')}
						className={'underlined uwBlueText'}>
						{' '}
						Minnesota Reciprocity
					</span>
					.
				</p>
			</>
		);
	};

	renderParentInfoForm = () => {
		let { app, visa_types } = this.props,
			{
				residency_relationship,
				relationship_us_citizen,
				relationship_visa_type,
				relationship_visa_type_other,
				relationship_lived_wi_12_months,
				relationship_employed,
				relationship_income_taxes,
				relationship_registered_vote,
				relationship_valid_license
			} = app.residency,
			strResRel = residency_relationship.toLowerCase(),
			optsVisa = [
				'U.S. Refugee/Granted Political Asylum',
				'U.S. Permanent Resident',
				'Applied/Applying for U.S. Permanent Resident Status'
			];

		optsVisa = optsVisa.concat(visa_types.map(rec => rec.title.trim()));
		optsVisa.push('Other');

		return (
			<>
				<p>
					Please select the parent/guardian who best qualifies you for the Wisconsin resident tuition rate. If
					neither parent resides in Wisconsin, please list the parent whose information you are most familiar
					with. Note: Use the question mark in the lower right to help determine which parent you should
					select.
				</p>

				<Row>
					{app.parent_info.parents.length ? (
						<Select
							name='residency_relationship'
							value={residency_relationship}
							label='Choose your parent to use for residency'
							placeholder='Select Parent'
							opts={this.getParentOptions()}
							required={true}
							cols={6}
							onChange={val => this.onChange('residency_relationship', val)}
							tooltip={
								<a id='relationshipTooltip'>
									<img
										className='informationTooltip'
										src={require('assets/img/Information.png')}
										alt='Information'
									/>
								</a>
							}
						/>
					) : (
						<Select
							name='residency_relationship'
							value={residency_relationship}
							label='Chosen relationship'
							placeholder='Select Relationship'
							opts={['Mother', 'Father', 'Stepmother', 'Stepfather', 'Legal Guardian/Caretaker']}
							required={true}
							cols={6}
							onChange={val => this.onChange('residency_relationship', val)}
							tooltip={
								<a id='relationshipTooltip'>
									<img
										className='informationTooltip'
										src={require('assets/img/Information.png')}
										alt='Information'
									/>
								</a>
							}
						/>
					)}
				</Row>

				<Tooltip
					anchorId='relationshipTooltip'
					className='tooltipContainer'
					delayHide={1000}
					effect='solid'
					content={
						<>
							<ul>
								<li>
									<strong>Parent (Mother or Father)</strong>: The student's biological or adoptive
									parent.
								</li>
								<li>
									<strong>Stepparent (Stepmother or Stepfather)</strong>: The legal spouse of the
									student's biological or adoptive parent. A stepparent must be currently married to
									the student's parent.
								</li>
								<li>
									<strong>Legal Guardian/Caretaker</strong>: A legal guardian is a person designated
									by the court to assume care of and make decisions for the student. A caretaker is a
									person designated by an individual (such as the student's parent) or an entity other
									than the court to assume care of and make decisions for the student.
								</li>
								<li>
									<strong>Other</strong>: Students may use the “other” category when completing the
									parent information section if they do not have a parent, legal guardian, or
									caretaker whose information they can provide. The “other” category may include
									individuals who do not fit one of the other categories listed above.
								</li>
							</ul>
						</>
					}
				/>

				{!!residency_relationship && (
					<>
						<Row>
							<RadioList
								name='relationship_us_citizen'
								value={relationship_us_citizen}
								label={`Is your ${strResRel} a U.S. citizen?`}
								opts={['Yes', 'No', 'Prefer not to respond at this time']}
								onChange={val => this.onChange('relationship_us_citizen', val)}
								required={true}
								cols={12}
							/>
						</Row>

						{relationship_us_citizen === 'No' && (
							<>
								<Row>
									<Select
										name='relationship_visa_type'
										value={relationship_visa_type}
										label={`${residency_relationship}'s visa type`}
										placeholder='Select Visa Type'
										opts={optsVisa}
										onChange={val => this.onChange('relationship_visa_type', val)}
										required={true}
										cols={6}
									/>

									{relationship_visa_type === 'Other' && (
										<Textbox
											name='relationship_visa_type_other'
											value={relationship_visa_type_other}
											label="Please explain 'Other' status"
											placeholder="Please explain 'Other' status"
											onChange={val => this.onChange('relationship_visa_type_other', val)}
											required={true}
											cols={6}
											maxLength={100}
										/>
									)}
								</Row>
							</>
						)}

						<Row>
							<RadioList
								name='relationship_lived_wi_12_months'
								value={relationship_lived_wi_12_months}
								label={`Has your ${strResRel} physically resided full-time in Wisconsin for the past 12 months?`}
								opts={['Yes', 'No']}
								onChange={val => this.onChange('relationship_lived_wi_12_months', val)}
								required={true}
								cols={12}
							/>
						</Row>
						<Row>
							<RadioList
								name='relationship_employed'
								value={relationship_employed}
								label={`Where is your ${strResRel} employed?`}
								opts={['Wisconsin', 'Outside of Wisconsin', 'Not currently working']}
								onChange={val => this.onChange('relationship_employed', val)}
								required={true}
								cols={12}
							/>
						</Row>
						<Row>
							<RadioList
								name='relationship_income_taxes'
								value={relationship_income_taxes}
								label={`Has your ${strResRel} filed a Wisconsin resident income tax return for the most recent tax year?`}
								opts={['Yes', 'No, did not file', 'No, filed in a different state']}
								onChange={val => this.onChange('relationship_income_taxes', val)}
								required={true}
								cols={12}
							/>
						</Row>

						{(relationship_lived_wi_12_months === 'No' ||
							relationship_employed !== 'Wisconsin' ||
							relationship_income_taxes !== 'Yes') && (
							<>
								<Row>
									<RadioList
										name='relationship_registered_vote'
										value={relationship_registered_vote}
										label={`Was Wisconsin the last place your ${strResRel} registered to vote or voted?`}
										opts={['Yes', 'No']}
										onChange={val => this.onChange('relationship_registered_vote', val)}
										required={true}
										cols={12}
									/>
								</Row>
								<Row>
									<RadioList
										name='relationship_valid_license'
										value={relationship_valid_license}
										label={`Does your ${strResRel} hold a valid Wisconsin driver’s license?`}
										opts={['Yes', 'No']}
										onChange={val => this.onChange('relationship_valid_license', val)}
										required={true}
										cols={12}
									/>
								</Row>
							</>
						)}
					</>
				)}
			</>
		);
	};

	renderSelfInfoForm = () => {
		let { app } = this.props,
			{
				self_live_in_wi,
				self_lived_wi_date,
				self_employed,
				self_income_taxes,
				self_registered_vote,
				self_valid_license,
				relationship_unable_to_provide,
				self_claim_dependent,
				self_lived_wi_12_months,
				self_he_enrolled
			} = app.residency,
			invalid_date = !!self_lived_wi_date && months_passed(self_lived_wi_date) < 0;

		return (
			<>
				<Row>
					<Select
						name='self_live_in_wi'
						value={self_live_in_wi}
						label='Do you live in Wisconsin?'
						placeholder='Select Option'
						opts={['No', "Yes, but I haven't always", 'Yes, my entire life']}
						required={true}
						cols={6}
						onChange={val => this.onChange('self_live_in_wi', val)}
					/>

					{self_live_in_wi === "Yes, but I haven't always" && (
						<DateInput
							value={self_lived_wi_date}
							label='When did you start living in Wisconsin?'
							onChange={(val, isValid) => this.onChange('self_lived_wi_date', val)}
							fullDate={false}
							required={true}
							cols={6}
							isInvalid={invalid_date}
						/>
					)}
				</Row>
				<Row>
					<RadioList
						name='self_employed'
						value={self_employed}
						label='Where are you employed?'
						opts={['Wisconsin', 'Outside of Wisconsin', 'Not currently working']}
						onChange={val => this.onChange('self_employed', val)}
						required={true}
						cols={12}
					/>
				</Row>
				<Row>
					<RadioList
						name='self_income_taxes'
						value={self_income_taxes}
						label='Have you filed a Wisconsin resident income tax return for the most recent tax year?'
						opts={['Yes', 'No, did not file', 'No, filed in a different state']}
						onChange={val => this.onChange('self_income_taxes', val)}
						required={true}
						cols={12}
					/>
				</Row>
				<Row>
					<RadioList
						name='self_registered_vote'
						value={self_registered_vote}
						label='Was Wisconsin the last place you registered to vote or voted?'
						opts={['Yes', 'No']}
						onChange={val => this.onChange('self_registered_vote', val)}
						required={true}
						cols={12}
						tooltip={
							<a id='voteTooltip'>
								<img
									className='informationTooltip'
									src={require('assets/img/Information.png')}
									alt='Information'
								/>
							</a>
						}
					/>

					<Tooltip
						anchorId='voteTooltip'
						className='tooltipContainer'
						delayHide={1000}
						effect='solid'
						content={<p>If you are under the age of 18, please select no.</p>}
					/>
				</Row>
				<Row>
					<RadioList
						name='self_valid_license'
						value={self_valid_license}
						label='Do you hold a valid Wisconsin driver’s license?'
						opts={['Yes', 'No']}
						onChange={val => this.onChange('self_valid_license', val)}
						required={true}
						cols={12}
					/>
				</Row>

				{!relationship_unable_to_provide && (
					<Row>
						<RadioList
							name='self_claim_dependent'
							value={self_claim_dependent}
							label='Did your parent(s)/guardian(s) claim you as a dependent for the most recent tax year?'
							opts={['Yes', 'No']}
							onChange={val => this.onChange('self_claim_dependent', val)}
							required={true}
							cols={12}
						/>
					</Row>
				)}

				<Row>
					<RadioList
						name='self_lived_wi_12_months'
						value={self_lived_wi_12_months}
						label='Have you physically resided full-time in Wisconsin for the past 12 months?'
						opts={['Yes', 'No']}
						onChange={val => this.onChange('self_lived_wi_12_months', val)}
						required={true}
						cols={12}
					/>
				</Row>

				{this.hs_grad && (
					<Row>
						<RadioList
							name='self_he_enrolled'
							value={self_he_enrolled}
							label="Have you been enrolled at any institutions of higher education during the twelve months prior to today's date?"
							opts={['Yes', 'No']}
							onChange={val => this.onChange('self_he_enrolled', val)}
							required={true}
							cols={12}
						/>
					</Row>
				)}
			</>
		);
	};

	renderTable = () => {
		let { app } = this.props,
			{
				parent_provided,
				residency_relationship,
				self_provided,
				self_lived_wi_12_months,
				provide_parent_info,
				relationship_unable_to_provide,
				residency_status
			} = app.residency,
			parent_button_text = 'Add Parent',
			parent_button_class = 'save-btn',
			self_button_text = `Add ${this.applicant_label}`,
			self_button_class = 'save-btn';

		if (!parent_provided && !!residency_relationship) {
			// relationship data for parent but parent_provided = false - info copied over and hasn't been reviewed
			parent_button_text = 'Review Parent';
		} else if (parent_provided) {
			parent_button_text = 'Edit Parent';
			parent_button_class = 'tertiaryButton';
		}

		if (!self_provided && !!self_lived_wi_12_months) {
			// relationship data but self but self_provided = false - info copied over and hasn't been reviewed
			self_button_text = `Review ${this.applicant_label}`;
		} else if (self_provided) {
			self_button_text = `Edit ${this.applicant_label}`;
			self_button_class = 'tertiaryButton';
		}

		return (
			<Table striped bordered hover>
				<thead>
					<tr>
						<th scope='col'>
							<strong>Section</strong>
						</th>
						<th scope='col'>
							<strong>Completed</strong>
						</th>
						<th scope='col'>
							<strong>Actions</strong>
						</th>
					</tr>
				</thead>
				<tbody>
					{provide_parent_info !== 'No' && !relationship_unable_to_provide && (
						<tr key={'parent'}>
							<td data-label='Question Type'>Parent Questions</td>
							<td data-label='Completed'>
								{parent_provided ? (
									<span className={'completed'}>Completed</span>
								) : (
									<span className={'notCompleted'}>Not Completed</span>
								)}
							</td>
							<td data-label='Actions'>
								<Button
									bsStyle='info'
									onClick={() => this.setState({ editing: 'parent' })}
									className={`${parent_button_class} btn-xs`}>
									{parent_button_text}
								</Button>
							</td>
						</tr>
					)}

					{(this.appQuestionsNeeded() || self_provided || residency_status === 'Y_APPLICANT_CHECK') && (
						<tr key='applicant'>
							<td data-label='Question Type'>{this.applicant_label} Questions</td>
							<td data-label='Completed'>
								{self_provided ? (
									<span className={'completed'}>Completed</span>
								) : (
									<span className={'notCompleted'}>Not Completed</span>
								)}
							</td>
							<td data-label='Actions'>
								<Button
									bsStyle='info'
									onClick={() => this.setState({ editing: 'self' })}
									className={`${self_button_class} btn-xs`}>
									{self_button_text}
								</Button>
							</td>
						</tr>
					)}
				</tbody>
			</Table>
		);
	};

	render() {
		let { editing } = this.state,
			header = 'RESIDENCY',
			residency_page,
			txtSave = editing ? 'Save Questions' : 'Save and Continue',
			_valid = this.isValidForm();

		if (!editing) {
			residency_page = this.renderIntroForm();
		} else if (editing === 'parent') {
			header = 'Parent Residency';
			residency_page = this.renderParentInfoForm();
		} else if (editing === 'self') {
			header = `${this.applicant_label} Residency`;
			residency_page = this.renderSelfInfoForm();
		}

		return (
			<Card
				title={header}
				content={
					<form id='form' onSubmit={this.onSubmit}>
						{this.renderModal()}
						{residency_page}

						<div className='clearfix' />
					</form>
				}
				buttons={
					<>
						<Button bsStyle='info' className='back-btn' fill onClick={this.onBack}>
							Back
						</Button>
						<Button
							form={'form'}
							type={'submit'}
							autoFocus
							bsStyle='info'
							className='save-btn'
							fill
							disabled={!_valid}>
							{txtSave}
						</Button>
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => {
		const { app_id, apps } = state.user;

		return {
			visa_types: state.global.visa_types,
			app: apps[app_id].json_obj,
			app_id: app_id
		};
	},
	mapDispatchToProps = dispatch => ({
		updateApp: obj => dispatch(UserActions.updateApp(obj)),
		saveApp: val => dispatch(UserActions.saveApp({ residencyCompleted: val })),
		setSubmenu: val => dispatch(UserActions.setSubmenus('personal_info_open', val))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Residency));
