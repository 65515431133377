import React, { Component } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import _ from 'underscore';

import API from 'components/api';
import withRouter from 'components/Wrappers/withRouter';
import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import ShoppingCart from 'components/ShoppingCart';
import { prevAppPage } from 'components/modules/nav';
import { SENTRY, cloneDeep, findReferrer } from 'components/modules/_misc';
import { has_fee } from 'components/modules/app';
import { CAMPUS_TITLES } from 'components/modules/campus';
import RadioList from 'components/controls/radio_list';
import Textbox from 'components/controls/textbox';
import ContactUsModal from 'views/Public/Modals/ContactUs';
import { GlobalActions } from 'reducers/global';
import { UserActions } from 'reducers/user';

class SignApplication extends Component {
	constructor(props) {
		super(props);

		this.state = {
			error: '',
			conditions_modal: false,
			failed_submit_modal: false,
			contact_modal: false,
			submitting: false
		};

		this.record_type = props.app.application_modifier === 'DA' ? 'Direct Admit form' : 'application';
	}

	componentDidMount = () => {
		const { majors, apps, apps_in_cart, updateApp } = this.props;

		apps_in_cart.split('|').forEach(_app_id => {
			let _app = cloneDeep(apps[_app_id].json_obj),
				{ receive_mail_at_permanent_address, mailing_address } = _app.contact_information,
				updated = false;

			['selected_primary_1', 'selected_primary_2'].forEach(progKey => {
				if ((_app.area_of_study[progKey].id || '-1') !== '-1') {
					const _major = (majors || []).find(prog => prog.id === _app.area_of_study[progKey].id);
					if (_major) {
						['field_plan_code', 'field_subplan_code', 'field_program_code'].forEach(strCode => {
							if (_major[strCode] !== _app.area_of_study[progKey][strCode]) {
								_app.area_of_study[progKey][strCode] = _major[strCode];
								updated = true;
							}
						});
					}
				}
			});

			_app.parent_info.parents
				.filter(parent => parent.same_current_address === 'Yes')
				.forEach(parent => {
					const addr_to_copy =
							receive_mail_at_permanent_address === 'No' &&
							mailing_address.formatted_address === parent.which_address
								? 'mailing_address'
								: 'permanent_address',
						_current_address = cloneDeep(_app.contact_information[addr_to_copy]);

					if (!_.isEqual(parent.current_address, _current_address)) {
						parent.current_address = _current_address;
						updated = true;
					}
				});

			if (updated) {
				updateApp({ area_of_study: _app.area_of_study, parent_info: _app.parent_info }, _app_id);

				this.getAPI()
					.saveApplication(_app_id, JSON.stringify(_app))
					.then(resp => {
						if (resp?.status !== 'success') throw new Error(resp?.message || '');
					})
					.catch(ex => {
						captureError(`error calling saveApplication on pre-submit checks (${_app_id}): ${ex.message}`);
					});
			}
		});
	};

	componentDidUpdate(prevProps) {
		let { app, apps, apps_in_cart, navigate } = this.props,
			submit_finished = Object.keys(apps).some(
				_app_id => apps[_app_id].date_submitted !== prevProps.apps[_app_id]?.date_submitted
			);

		if (app.application_modifier === 'DA') submit_finished = apps_in_cart !== prevProps.apps_in_cart;

		if (submit_finished) {
			const next_page = apps_in_cart.split('|').find(_app_id => has_fee(apps[_app_id]))
				? '/payment'
				: '/next-steps';

			navigate(next_page);
		}
	}

	getAPI = () => {
		const { support_student_id, campus_admin } = this.props;
		return new API('', support_student_id, campus_admin);
	};

	onChange = (val, prop) => {
		let { app, updateApp } = this.props,
			submit = cloneDeep(app.submit);

		if (!app.date_submitted) {
			submit[prop] = val;
			updateApp({ submit: submit });
		}
	};

	isProbableBot = () => {
		const { apps_in_cart, apps, app_id } = this.props;

		let d = new Date(),
			time_diff = d - new Date(apps[app_id].date_created),
			mins_since_created = time_diff / (1000 * 60) + d.getTimezoneOffset();

		return apps_in_cart.split('|').length === 1 && Object.keys(apps).length === 1 && mins_since_created <= 3;
	};

	onSubmitDirectAdmit = () => {
		let { app, app_id, captureError, setApps, campuses } = this.props,
			campus_codes = Object.keys(app.initial_information.direct_admit),
			created_app_ids = [],
			reset_apps = () => {
				this.getAPI()
					.deleteApplication(app_id)
					.then(resp => {
						if (resp?.result === 'success') {
							this.getAPI()
								.getAllApplications()
								.then(resp_apps => {
									setApps(resp_apps.rows, created_app_ids);
								})
								.catch(ex => captureError(ex));
						} else {
							this.setState({ failed_submit_modal: true, submitting: false });
							throw new Error('error calling deleteApplication for direct admit');
						}
					})
					.catch(ex => captureError(ex));
			};

		campus_codes.forEach((campus_code, i) => {
			let campus = campuses.find(c => c.field_abbreviation === campus_code),
				program = app.initial_information.direct_admit[campus_code],
				_app = cloneDeep(app);

			_app.initial_information.chosen_campus = campus;
			_app.area_of_study = program;
			_app.parent_application_id = app_id;
			delete _app.initial_information.direct_admit;

			setTimeout(() => {
				this.getAPI()
					.saveApplication('-1', JSON.stringify(_app))
					.then(saveResp => {
						const msg = saveResp?.message || '';

						if (saveResp?.status !== 'success') {
							this.setState({ failed_submit_modal: true, submitting: false });
							throw new Error(msg);
						} else {
							created_app_ids.push(saveResp.app_id);

							if (created_app_ids.length === campus_codes.length) {
								const newCart = created_app_ids.join('|');

								this.getAPI()
									.submitApplications(newCart)
									.then(resp => {
										const msg = resp?.message || '';

										if (resp?.result === 'success') {
											reset_apps();
										} else {
											this.setState({ failed_submit_modal: true, submitting: false });
											throw new Error(
												`error calling submitApplications for direct admit: ${msg}`
											);
										}
									})
									.catch(ex => captureError(ex));
							}
						}
					})
					.catch(ex => captureError(`error calling saveApplication for direct admit: ${ex.message}`));
			}, i * 50);
		});
	};

	onSubmitCart = () => {
		let { app, apps_in_cart, setApps, captureError } = this.props;

		if (this.isProbableBot()) {
			this.setState({ failed_submit_modal: true });
		} else {
			this.setState({ submitting: true });

			if (app.application_modifier === 'DA') {
				this.onSubmitDirectAdmit();
			} else {
				this.getAPI()
					.submitApplications(apps_in_cart)
					.then(resp => {
						const msg = resp?.message || '';

						if (resp?.result === 'success') {
							this.getAPI()
								.getAllApplications()
								.then(resp_apps => setApps(resp_apps.rows))
								.catch(ex => captureError(ex));
						} else {
							this.setState({ failed_submit_modal: true, submitting: false });

							SENTRY.set_tag('attempted_cart', apps_in_cart);
							throw new Error(`error calling submitApplications: ${msg}`);
						}
					})
					.catch(ex => captureError(ex));
			}
		}
	};

	onSubmit = e => {
		e.preventDefault();
		e.stopPropagation();

		let { app, apps_in_cart, setScreensCompleted, apps, captureError, referrer } = this.props,
			{ application_modifier, initial_information } = app,
			_record_type = app.application_modifier === 'DA' ? 'DA' : 'TRAD',
			multiApp = apps_in_cart.split('|').length > 1,
			campus_names = apps_in_cart.split('|').map(_app_id => apps[_app_id].campus);

		apps_in_cart.split('|').forEach(_app_id => {
			const app_rec = apps[_app_id];
			app_rec.json_obj.submit = cloneDeep(app.submit);

			if (referrer) {
				const i = app_rec.json_obj.referrers.findIndex(rec => findReferrer(rec, referrer));
				if (i > -1) {
					app_rec.json_obj.referrers[i]._submitted = true;
				} else {
					let _referrer = cloneDeep(referrer);
					_referrer._submitted = true;

					app_rec.json_obj.referrers.push(_referrer);
				}
			}

			this.getAPI()
				.saveApplication(_app_id, JSON.stringify(app_rec.json_obj))
				.catch(ex => captureError(ex));
		});

		if (application_modifier === 'DA') {
			campus_names = Object.keys(initial_information.direct_admit).map(code => CAMPUS_TITLES[code]);
			multiApp = campus_names.length > 1;
		}

		const COPY = {
			DA: [
				`You are about to submit a Direct Admit form expressing interest in ${
					multiApp ? 'the following UW universities' : campus_names[0]
				}. ${multiApp ? 'These universities' : campus_names[0]} will be given your Direct Admit form${
					multiApp ? 's' : ''
				} and will begin the process of reaching out to you to help you enroll.`,
				`Please note:  after submitting your form${
					multiApp ? 's' : ''
				} you are no longer able to make changes.`,
				`Ready to submit your form${multiApp ? 's' : ''} and to be admitted?`
			],
			TRAD: [
				`You are about to submit your application${multiApp ? 's' : ''} to ${
					multiApp ? 'the following campuses:' : `${campus_names[0]}.`
				}`,
				`Please note:  after submitting the application${multiApp ? 's' : ''}, you are no longer able to make
					changes.`,
				`Ready to submit your application${multiApp ? 's' : ''}?`
			]
		};

		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className='react-confirm-alert'>
						<div className='react-confirm-alert-body'>
							<h1>submit {this.record_type}</h1>
							<p>{COPY[_record_type][0]}</p>

							{multiApp && (
								<ul>
									{campus_names.map(campus_name => (
										<li key={campus_name}>{campus_name}</li>
									))}
								</ul>
							)}
							<p>{COPY[_record_type][1]}</p>
							<p>{COPY[_record_type][2]}</p>
							<div className='react-confirm-alert-button-group'>
								<button
									onClick={() => {
										setScreensCompleted();
										this.setState({ error: '' });

										this.onSubmitCart();

										onClose();
									}}>
									Yes, Submit
								</button>
								<button onClick={onClose}>No, Make Changes</button>
							</div>
						</div>
					</div>
				);
			}
		});
	};

	renderConditionsModal = () => {
		const { conditions_modal } = this.state;

		return (
			<Modal show={conditions_modal}>
				<Modal.Header>
					<Modal.Title className='h2'>
						By signing your {this.record_type} you agree to the following conditions:
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className='modalMinHeight'>
					<ul>
						<li className='transitionLI'>
							I understand that inaccurate information may affect my enrollment, tuition or financial aid
							status.
						</li>
						<li className='transitionLI'>
							I agree to notify the admissions office, in writing, if there is a change to any of the
							information, including my permanent home address.
						</li>
						<li className='transitionLI'>
							I also understand that if I have applied for financial assistance, information concerning
							the amount of financial aid I may be offered may be released to other agencies that may also
							be considering me for assistance.
						</li>
						<li className='transitionLI'>
							I authorize my secondary school to release a transcript of my secondary school record and
							any other pertinent information to the University of Wisconsin System.
						</li>
						<li className='transitionLI'>
							I acknowledge that I have listed all institutions of higher education currently or
							previously attended and understand that failure to list all institutions may result in
							disciplinary action, recision of admissions, and/or invalidation of credits or degrees
							earned.
						</li>
						<li className='transitionLI'>
							I authorize the University of Wisconsin System to release my Social Security Number (SSN) or
							other Taxpayer Identification Number (TIN) and date of birth to any UW Institution.
						</li>
						<li className='transitionLI'>
							If I enroll at this university, I will abide by its rules and regulations.
						</li>
						<li className='transitionLI'>
							The personal data collected in your {this.record_type}(s) will be shared with Universities
							of Wisconsin campus(es) that you are applying to. This data will be used to set up your
							record at the campus to be considered for admission.
						</li>
						<li className='transitionLI'>
							Submitted applications are property of the Universities of Wisconsin.
						</li>
					</ul>
				</Modal.Body>
				<Modal.Footer>
					<Button
						bsStyle='info'
						className='save-btn'
						fill
						onClick={() => this.setState({ conditions_modal: false })}>
						Close and Accept
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	renderFailedSubmitModal = () => {
		const { apps_in_cart, navigate } = this.props,
			{ contact_modal, failed_submit_modal } = this.state;

		if (contact_modal) {
			return (
				<Modal show={contact_modal}>
					<ContactUsModal closeModal={() => this.setState({ contact_modal: false })} />
				</Modal>
			);
		}

		return (
			<Modal show={failed_submit_modal}>
				<Modal.Header>
					<Modal.Title className='h2'>That&apos;s not right</Modal.Title>
				</Modal.Header>
				<Modal.Body className='modalMinHeight'>
					<p>
						Sorry, something went wrong, and{' '}
						{apps_in_cart.split('|').length > 1 ? 'one or more of your apps' : 'your app'} was not
						submitted. Please try again and{' '}
						<a href='#' onClick={() => this.setState({ contact_modal: true })}>
							let us know
						</a>{' '}
						if you have any more trouble.
					</p>
					<p>You&apos;ll be redirected to your account page.</p>
				</Modal.Body>
				<Modal.Footer>
					<Button bsStyle='info' className='save-btn' fill onClick={() => navigate('/my-account')}>
						Okay
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	render() {
		const { app, apps_in_cart, navigate, location } = this.props,
			{ error, submitting } = this.state,
			header = `${app.application_modifier === 'DA' ? 'Direct Admit Forms' : 'Applications'} to Submit`,
			{ agree_checkbox, signature } = app.submit,
			allow_submit = !submitting && !!agree_checkbox && signature.length >= 3 && !!apps_in_cart,
			{ REACT_APP_ENV } = process.env;

		return (
			<Card
				title={`sign your ${this.record_type}(S)`}
				content={
					<form id='form' onSubmit={this.onSubmit}>
						{this.renderConditionsModal()}
						{this.renderFailedSubmitModal()}

						<ShoppingCart role='STASHED' />

						<h3 className='uwred'>{header}</h3>
						<ShoppingCart role='TO-SUBMIT' />

						<h4>
							<strong>CONFIRMATION SIGNATURE</strong>
						</h4>
						<p>
							You are ready to sign your {this.record_type}. Once you sign and submit your{' '}
							{this.record_type} you are not able to make changes to your submitted {this.record_type}(s).
						</p>
						<p>
							I acknowledge that by providing an electronic signature, I attest to the accuracy and
							truthfulness of the {this.record_type}(s) I'm submitting. I also agree to the{' '}
							<span
								onClick={() => this.setState({ conditions_modal: true })}
								className='underlined pointer'>
								<strong>conditions</strong>
							</span>{' '}
							of the Universities of Wisconsin.
						</p>

						<Row>
							<RadioList
								name='agree_checkbox'
								value={agree_checkbox ? 'I Agree' : ''}
								onChange={val => this.onChange(!!val.length, 'agree_checkbox')}
								opts={['I Agree']}
								label='Do you agree to all of the above?'
								cols={12}
								required={true}
							/>
						</Row>
						<Row>
							<Textbox
								name='signature'
								value={signature}
								onChange={val => this.onChange(val, 'signature')}
								label='Signature'
								placeholder='Signature'
								required={true}
								maxLength={100}
								cols={6}
							/>
						</Row>

						{REACT_APP_ENV !== 'production' && (
							<p className='uwRedText bold'>
								NOTICE: This is a practice {this.record_type}. Your {this.record_type} will not be
								submitted to the campus. Go to{' '}
								<a target='_blank' href='https://apply.wisconsin.edu' rel='noopener noreferrer'>
									https://apply.wisconsin.edu
								</a>{' '}
								to submit a real {this.record_type}.
							</p>
						)}

						<h3 className='uwred'>{error}</h3>

						<div className='clearfix' />
					</form>
				}
				buttons={
					<>
						<Button
							bsStyle='info'
							className='back-btn'
							fill
							onClick={() => navigate(prevAppPage(app, location.pathname))}>
							Back
						</Button>
						<Button
							form='form'
							autoFocus
							bsStyle='info'
							className='save-btn'
							fill
							type='submit'
							disabled={!allow_submit}>
							{app.application_modifier === 'DA' ? 'Submit' : 'Submit Application(s)'}
						</Button>
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => {
		const { apps, app_id, support_student_id, apps_in_cart, campus_admin, referrer } = state.user;

		return {
			support_student_id: support_student_id,
			apps: apps,
			app: apps[app_id].json_obj,
			app_id: app_id,
			apps_in_cart: apps_in_cart,
			majors: state.global.cdrMajors,
			campus_admin: campus_admin,
			referrer: referrer,
			campuses: state.global.campuses
		};
	},
	mapDispatchToProps = dispatch => ({
		updateApp: (obj, _app_id) => dispatch(UserActions.updateApp(obj, _app_id)),
		setScreensCompleted: () => dispatch(UserActions.setScreensCompleted({ sign: true })),
		setApps: (arr, _app_id) => dispatch(UserActions.setApps(arr, _app_id)),
		captureError: err => dispatch(GlobalActions.captureError(err))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignApplication));
