import chroma from 'chroma-js';

const styleSettings = {
		hoverColor: '#808080',
		textColor: '#000'
	},
	general_find_a_program = {
		multiValue: (styles, { data }) => ({
			...styles,
			backgroundColor: chroma('#990134').alpha(0.1).css()
		}),
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: '#990134',
			fontWeight: 'bold'
		}),
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: '#990134',
			':hover': {
				backgroundColor: '#990134',
				color: 'white'
			}
		})
	};

export const find_a_program = {
	control_styles: {
		search: {
			control: styles => ({
				...styles,
				backgroundColor: 'white',
				width: '100%',
				borderRadius: 0,
				borderTop: 'none',
				borderRight: 'none',
				borderLeft: 'none',
				marginBottom: '26px',
				fontSize: '26px',
				lineHeight: '32px',
				fontWeight: 300
			}),
			option: (styles, { data, isDisabled, isFocused, isSelected }) => {
				let color = chroma(styleSettings.hoverColor),
					val = {
						...styles,
						backgroundColor: isDisabled
							? null
							: isSelected
							? data.color
							: isFocused
							? color.alpha(0.1).css()
							: null,
						textAlign: 'left',
						color: isDisabled ? '#ccc' : styleSettings.textColor,
						cursor: isDisabled ? 'not-allowed' : 'default',

						':active': {
							...styles[':active'],
							backgroundColor: !isDisabled && (isSelected ? '#808080' : '') //color.alpha(0.3).css()
						}
					};

				if (data.field_parent_program_id) {
					val.marginLeft = '24px';
					val.padingLeft = '12px';
					val.borderLeft = '6px solid #eee';
				}

				return val;
			},
			multiValue: general_find_a_program.multiValue,
			multiValueLabel: general_find_a_program.multiValueLabel,
			multiValueRemove: general_find_a_program.multiValueRemove
		},
		career_cluster: {
			container: base => ({
				...base,
				display: 'inline-block',
				width: 'calc(100% / 3)'
			}),
			control: styles => ({
				...styles,
				marginRight: '5px'
			}),
			multiValue: general_find_a_program.multiValue,
			multiValueLabel: general_find_a_program.multiValueLabel,
			multiValueRemove: general_find_a_program.multiValueRemove
		},
		campus: {
			container: base => ({
				...base,
				display: 'inline-block',
				width: 'calc(100% / 3)'
			}),
			control: styles => ({
				...styles,
				marginLeft: '5px',
				marginRight: '5px'
			}),
			multiValue: general_find_a_program.multiValue,
			multiValueLabel: general_find_a_program.multiValueLabel,
			multiValueRemove: general_find_a_program.multiValueRemove
		},
		filters: {
			container: base => ({
				...base,
				display: 'inline-block',
				width: 'calc(100% / 3)'
			}),
			control: styles => ({
				...styles,
				marginLeft: '5px'
			}),
			multiValue: general_find_a_program.multiValue,
			multiValueLabel: general_find_a_program.multiValueLabel,
			multiValueRemove: general_find_a_program.multiValueRemove
		}
	}
};
