import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Checkbox, ControlLabel } from 'react-bootstrap';
import { toggleArray } from 'components/modules/_misc';

export default class CheckboxList extends Component {
	render() {
		let {
			values,
			opts,
			onChange,
			name,
			forceNewLines,
			classVals,
			tooltip,
			required,
			label,
			cols,
			hide_optional,
			oneLine
		} = this.props;

		if (!classVals) classVals = [];
		if (cols) classVals.push(`col-md-${cols}`);

		opts = opts.map(opt => {
			return typeof opt === 'string' ? { val: opt, label: opt, disabled: false } : opt;
		});

		return (
			<div className={classVals.join(' ')}>
				<FormGroup className='checkbox-list' aria-label={label}>
					{label && (
						<ControlLabel className={oneLine ? 'one-line' : ''}>
							{label}
							{!required && !hide_optional && <small>(Optional)</small>}
							{tooltip}
						</ControlLabel>
					)}

					{opts.map((opt, i) => (
						<React.Fragment key={i}>
							{!!forceNewLines && !!i && <br />}

							<Checkbox
								disabled={opt.disabled}
								id={name}
								key={i}
								aria-label={opt.label}
								value={opt.val}
								name={name}
								onChange={() => {
									const new_values = toggleArray(values, opt.val);
									onChange(new_values);
								}}
								checked={values.includes(opt.val)}>
								{opt.label}
							</Checkbox>
						</React.Fragment>
					))}
				</FormGroup>
			</div>
		);
	}
}

CheckboxList.propTypes = {
	values: PropTypes.array.isRequired,
	opts: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	forceNewLines: PropTypes.bool,
	classVals: PropTypes.array,
	cols: PropTypes.number,
	required: PropTypes.bool,
	oneLine: PropTypes.bool,
	tooltip: PropTypes.object,
	hide_optional: PropTypes.bool
};
