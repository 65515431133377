import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withinDateTimeRange } from 'components/modules/date';
import { formatNotificationBody } from 'components/modules/react_content';
import withRouter from 'components/Wrappers/withRouter';

const alert = require('assets/homepageImages/Alert.png'),
	alert500 = require('assets/homepageImages/Alert-p-500.png');

class ShowNotifications extends Component {
	constructor(props) {
		super(props);

		this.state = {
			notification_index: 0,
			interval_id: -1
		};

		this.notifications = props.system_notifications.filter(
			n =>
				!n?.field_start_date ||
				!n?.field_end_date ||
				(n.field_display_on_login_screen !== '1' && withinDateTimeRange(n.field_start_date, n.field_end_date))
		);
	}

	componentDidMount() {
		if (this.notifications.length > 1) {
			let funcSetInterval = setInterval(() => {
				const { notification_index } = this.state,
					new_index = notification_index === this.notifications.length - 1 ? 0 : notification_index + 1;

				this.setState({ notification_index: new_index });
			}, 5000);

			this.setState({ interval_id: funcSetInterval });
		}
	}

	componentWillUnmount() {
		clearInterval(this.state.interval_id);
	}

	render = () => {
		if (!this.notifications.length) return <></>;

		const { notification_index } = this.state,
			content = formatNotificationBody(this.notifications[notification_index]),
			{ REACT_APP_ENV } = process.env;

		return (
			<div className={`announcement-bar${REACT_APP_ENV === 'production' ? '' : ' practice'}`}>
				<div className='announcement-wrap'>
					<img
						src={alert}
						srcSet={`${alert500} 500w, ${alert} 800w`}
						sizes='75px'
						alt=''
						className='announcement-icon'
					/>
					<div className='announcement'>{content}</div>
				</div>
			</div>
		);
	};
}

const mapStateToProps = state => ({
	system_notifications: state.global.system_notifications
});

export default withRouter(connect(mapStateToProps)(ShowNotifications));
