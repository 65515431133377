import axios from 'axios';
import queryString from 'query-string';
import fileDownload from 'js-file-download';

import { adminUrl } from 'components/modules/_misc';

axios.defaults.withCredentials = true;

const { REACT_APP_GOOGLE_API_KEY } = process.env,
	timeout_val = 30; // in seconds

// eslint-disable-next-line
const enabledStudentIDEndpoints = [
	'save-application',
	'documents',
	'save-file',
	'current_user',
	'update_profile',
	'applications',
	'application',
	'delete_application',
	'submit_application',
	'reset_password',
	'commit_reset',
	'next_steps_pdf',
	'student_pdf',
	'clear_coupon',
	'redeem_coupon',
	'redeem_bypass_term_code',
	'application_pdf'
];

class API {
	constructor(url, support_student_id, campus_admin) {
		this.url = url || adminUrl();
		this.block_changes = campus_admin;

		if (support_student_id && support_student_id > 0) {
			this.support_student_id = support_student_id;
		}
	}

	//GOOGLE API
	async getAllValidAddresses(addressInput) {
		return this.sendGet('json', { address: addressInput, key: REACT_APP_GOOGLE_API_KEY }, false);
	}

	//FROM CDN
	async getAllCampuses() {
		return this.sendGetCDN('campuses');
	}

	async getAllCampusExceptions() {
		return this.sendGetCDN('exceptions');
	}

	async getMajorsByThirds(mod) {
		return this.sendGetCDN(`majors-by-thirds/${mod}`);
	}

	async getSubMajorsByThirds(mod) {
		return this.sendGetCDN(`submajors-by-thirds/${mod}`);
	}

	async getMajorsByCampusGroup(group) {
		return this.sendGetCDN(`cdr-majors-by-campus-group/${group}`);
	}

	async getSubMajorsByCampusGroup(group) {
		return this.sendGetCDN(`submajors-by-campus-group/${group}`);
	}

	async getMajorsByCampusID(campusID) {
		return this.sendGetCDN('majors', { campus: campusID });
	}

	async getAllSpecials() {
		return this.sendGetCDN('specials');
	}

	async getPaperApplicationURL() {
		return this.sendGetCDN('paper-application');
	}

	async getAllActivities() {
		return this.sendGetCDN('activities');
	}

	async getTestTypes() {
		return this.sendGetCDN('test-score-types');
	}

	async getVisas() {
		return this.sendGetCDN('visas');
	}

	async getAllCareerClusters() {
		return this.sendGetCDN('career-clusters');
	}

	async getHighschoolsBySearch(search) {
		return this.sendGetCDN('high-schools', { search: search });
	}

	async getCollegesBySearch(search) {
		return this.sendGetCDN('colleges', { search: search });
	}

	async getHighschoolsByLocation(search) {
		return this.sendGetCDN('high-schools', { location: search });
	}

	async getCollegesByLocation(search) {
		return this.sendGetCDN('colleges', { location: search });
	}

	async getAllWaivers() {
		return this.sendGetCDN('waivers');
	}

	//NON CDN
	async getAllSystemNotifications() {
		return this.sendGet('system-notifications');
	}

	async getDefaultUndergradTerms() {
		return this.sendGet('open-close/undergrad');
	}

	async getCampusUndergradTerms() {
		return this.sendGet('open-close/undergrad/campus');
	}

	async getCampusSpecialTerms() {
		return this.sendGet('open-close/special/campus');
	}

	async getCampusGradTerms() {
		return this.sendGet('open-close/grad/campus');
	}

	async getGlobalEssayPrompts() {
		return this.sendGetCDN('essay-prompts/global');
	}

	async getCampusEssayPrompts() {
		return this.sendGetCDN('essay-prompts/campus');
	}

	async getCampusNextSteps() {
		return this.sendGetCDN('next-steps/campus');
	}

	async getServerTime() {
		return this.sendGet('current_datetime');
	}

	async getAllApplications() {
		return this.sendGet('applications');
	}

	async getApplication(application_id) {
		return this.sendGet('application/' + application_id);
	}

	async redeemBypassCode(code) {
		return this.sendGet('redeem_bypass_term_code/' + code);
	}

	async getBypassCode(code) {
		return this.sendGet('fetch_bypass_term_code/' + code);
	}

	async logout() {
		return await axios
			.get(this.url.split('/api')[0] + '/user/logout', {
				withCredentials: true
			})
			.then(response => {
				return 'Logged out';
			})
			.catch(error => {});
	}

	async deactivateAccount() {
		return this.block_changes ? {} : this.sendGet('deactivate-account');
	}

	async login(username, password) {
		return this.sendPost('login', { username: username, password: password });
	}

	async commitReset(code, password) {
		return this.block_changes ? {} : this.sendPost('commit_reset', { code: code, password: password });
	}

	async resetPassword(email) {
		return this.block_changes ? {} : this.sendGet('reset_password/' + email);
	}

	async saveApplication(application_id, application_json) {
		return this.block_changes
			? {}
			: this.sendPost('save-application/' + application_id, { application: application_json });
	}

	async deleteApplication(application_id) {
		return this.block_changes ? {} : this.sendGet('delete_application/' + application_id);
	}

	async submitApplications(str_app_ids) {
		return this.block_changes ? {} : this.sendGet('submit_applications/' + str_app_ids);
	}

	async submitApplication(application_id) {
		return this.block_changes ? {} : this.sendGet('submit_application/' + application_id);
	}

	async redeemCoupon(application_id, code) {
		return this.sendGet('redeem_coupon/' + application_id + '/' + code);
	}

	async clearCoupon(application_id) {
		return this.sendGet('clear_coupon/' + application_id);
	}

	async verifyEmail(code) {
		return this.sendGet('verify_email', { code: code });
	}

	async resendVerifyEmail(code) {
		return this.sendGet('resend_verify_email');
	}

	async checkForDirectAdmitEmail(email) {
		return this.sendPost('direct-admit-check-for-email', { email: email });
	}

	async sendDirectAdmitVerifyEmail(email) {
		return this.sendPost('direct-admit-send-verify-email', { email: email });
	}

	async verifyDirectAdmitEmail(email, code) {
		return this.sendPost('direct-admit-verify-email', { email: email, code: code });
	}

	async getAllDocuments() {
		return this.sendGet('documents');
	}

	async downloadDocument(key, file_name) {
		this.sendGet(`download-document/${key}`, null, true, file_name);
	}

	async createAccount(
		username,
		password,
		email,
		first_name,
		last_name,
		phone_number,
		no_phone,
		permission_to_send_texts
	) {
		return this.sendPost('register_verify', {
			username: username,
			password: password,
			email: email,
			first_name: first_name,
			last_name: last_name,
			phone_number: phone_number,
			no_phone: no_phone ? 1 : 0,
			permission_to_send_texts: permission_to_send_texts
		});
	}

	async updateAccount(email, first_name, last_name, phone_number, no_phone, permission_to_send_texts, password) {
		if (this.block_changes) return {};

		let parameters = {
			email: email,
			first_name: first_name,
			last_name: last_name,
			phone_number: phone_number,
			no_phone: no_phone ? 1 : 0,
			permission_to_send_texts:
				permission_to_send_texts.includes('Yes') || permission_to_send_texts === '1' ? 1 : 0
		};

		if (no_phone) {
			parameters.phone_number = '';
			parameters.permission_to_send_texts = 0;
		}

		if (password) parameters.password = password;

		return this.sendPost('update_profile', parameters);
	}

	async saveFile(file, application_id, document_type) {
		if (this.block_changes) return {};

		var data = new FormData();
		data.append('file', file);
		data.append('document_type', document_type);

		return axios.post(this.url + 'save-file/' + application_id, data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			timeout: timeout_val * 1000
		});
	}

	async sendPost(endpoint, parameters) {
		//Valid student ID?
		if (this.support_student_id) {
			parameters.student_user_id = this.support_student_id;
		}

		const postReturn = await axios
			.post(this.url + endpoint, queryString.stringify(parameters))
			.then(response => {
				return response.data;
			})
			.catch(error => {
				console.log(error);

				const str = '<h1>Site under maintenance</h1>';

				if (error.response?.data?.includes(str)) {
					let i = error.response.data.indexOf(str),
						msg = error.response.data.substr(i + str.length).trim();

					msg = msg.substr(0, msg.indexOf('</main>')).trim();

					return `MAINTENANCE|${msg}`;
				}

				return `unknown fetch error: ${endpoint}`;
			});
		return postReturn;
	}

	async sendGet(endpoint, urlParameters = null, credentials = true, downloadFileName = '') {
		//Valid student ID?
		if (this.support_student_id) {
			if (urlParameters) {
				urlParameters.support_student_id = this.support_student_id;
			} else {
				urlParameters = { student_user_id: this.support_student_id };
			}
		}

		const getReturn = await axios
			.get(this.url + endpoint, {
				withCredentials: credentials,
				params: urlParameters,
				responseType: downloadFileName ? 'blob' : 'json'
			})
			.then(response => {
				if (downloadFileName) {
					fileDownload(response.data, downloadFileName);
				} else {
					return response.data;
				}
			})
			.catch(error => {
				console.log(error);

				const str = '<h1>Site under maintenance</h1>';

				if (error.response?.data?.includes(str)) {
					let i = error.response.data.indexOf(str),
						msg = error.response.data.substr(i + str.length).trim();

					msg = msg.substr(0, msg.indexOf('</main>')).trim();

					return `MAINTENANCE|${msg}`;
				}

				return `unknown fetch error: ${endpoint}`;
			});

		return getReturn;
	}

	async sendGetCDN(endpoint, urlParameters = null, credentials = true) {
		const getReturn = await axios
			.get(adminUrl(true) + endpoint, {
				withCredentials: credentials,
				params: urlParameters
			})
			.then(response => {
				return response.data;
			})
			.catch(error => {
				console.log(error);

				const str = '<h1>Site under maintenance</h1>';

				if (error.response?.data?.includes(str)) {
					let i = error.response.data.indexOf(str),
						msg = error.response.data.substr(i + str.length).trim();

					msg = msg.substr(0, msg.indexOf('</main>')).trim();

					return `MAINTENANCE|${msg}`;
				}

				return `unknown fetch error: ${endpoint}`;
			});
		return getReturn;
	}
}

export default API;
