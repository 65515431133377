import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';

import API from 'components/api';
import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';

class MyDocuments extends Component {
	constructor(props) {
		super(props);

		this.doc_types = [
			{
				key: 'HSCourses',
				label: 'High School Course Schedule'
			},
			{
				key: 'HSTranscript',
				label: 'High School Transcript'
			},
			{
				key: 'CollegeTranscript',
				label: 'College Transcript'
			},
			{
				key: 'RESUME',
				label: 'Resume'
			}
		];
	}

	getAPI = () => {
		const { support_student_id, campus_admin } = this.props;
		return new API('', support_student_id, campus_admin);
	};

	renderPage = () => {
		const { documents, captureError } = this.props;

		if (!documents.length) return <p>Looks like you haven't provided us with any documents yet.</p>;

		return (
			<>
				<p>Here are all the documents you've uploaded while applying so far.</p>

				{this.doc_types.map(doc_type => {
					const docs = documents.filter(doc => doc.document_type === doc_type.key);

					if (docs.length) {
						let label = doc_type.label;
						if (docs.length > 1) label = `${label}s`;

						return (
							<React.Fragment key={doc_type.key}>
								<h3>{label}</h3>

								<ul className='my-docs'>
									{docs.map(doc => (
										<li key={doc.key}>
											<a
												onClick={() => {
													this.getAPI()
														.downloadDocument(doc.key, doc.file_name)
														.catch(ex => {
															captureError(ex);

															confirmAlert({
																title: "Hmm, that didn't work",
																message:
																	'Your file could not be downloaded.  Please try again and let us know if the problem continues.',
																buttons: [{ label: 'OK' }]
															});
														});
												}}>
												{doc.file_name}
											</a>
										</li>
									))}
								</ul>
							</React.Fragment>
						);
					}
				})}
			</>
		);
	};

	render() {
		const { navigate } = this.props;

		return (
			<Card
				title='MY DOCUMENTS'
				content={this.renderPage()}
				buttons={
					<Button bsStyle='info' className='tertiaryButton' fill onClick={() => navigate('/my-account')}>
						My Account
					</Button>
				}
			/>
		);
	}
}

const mapStateToProps = state => ({
		documents: state.user.documents,
		support_student_id: state.user.support_student_id,
		campus_admin: state.user.campus_admin
	}),
	mapDispatchToProps = dispatch => ({
		captureError: err => dispatch(GlobalActions.captureError(err))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyDocuments));
