import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { Tooltip } from 'react-tooltip';
import { connect } from 'react-redux';
import 'react-tooltip/dist/react-tooltip.css';
import _ from 'underscore';

import API from 'components/api';
import { GlobalActions } from 'reducers/global';
import { UserActions } from 'reducers/user';
import { SENTRY, cloneDeep, cleanString } from 'components/modules/_misc';
import { formalizeAppType, has_fee } from 'components/modules/app';
import { FIND_TERM } from 'components/modules/term';
import _svg from 'components/modules/svg';
import Button from 'components/CustomButton';

class ShoppingCart extends Component {
	constructor(props) {
		super(props);

		const { role, apps_in_cart, apps, app_ids_to_pay, app_id, later } = props;

		this.state = {
			coupons: {},
			app_recs: [],
			show_action: role !== 'ADD-APPS' && !(role === 'TO-SUBMIT' && !apps_in_cart?.includes('|'))
		};

		this.orig_app_ids_to_pay = app_ids_to_pay || [];

		if (role === 'PAY' && !later) this.filter_by_term = apps[app_id].term;
	}

	componentDidMount() {
		this._mounted = true;
		this.organizeApps();
	}

	componentDidUpdate(prevProps) {
		const { role, apps_in_cart } = this.props,
			cart_updated = apps_in_cart.length !== prevProps.apps_in_cart.length;

		if (cart_updated) {
			this.organizeApps();

			this.setState({
				show_action: role !== 'ADD-APPS' && !(role === 'TO-SUBMIT' && !apps_in_cart?.includes('|'))
			});
		}
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	getAPI = () => {
		const { support_student_id, campus_admin } = this.props;
		return new API('', support_student_id, campus_admin);
	};

	organizeApps = () => {
		let { apps_in_cart, apps, role, apps_to_submit_later } = this.props,
			list_to_show = role === 'STASHED' ? apps_to_submit_later : apps_in_cart;

		this.setState({
			app_recs: Object.keys(apps)
				.filter(_app_id => {
					if (!this.filter_by_term) return true;

					const _app = apps[_app_id];
					return _app.term === this.filter_by_term;
				})
				.map(_app_id => {
					const _app = apps[_app_id],
						{ app_type, campus, term, paid } = _app;

					return {
						id: _app_id,
						app_type: app_type,
						campus: campus,
						term: term,
						paid: paid !== '0'
					};
				})
				.filter(rec => list_to_show.includes(rec.id) && !(role === 'PAY' && rec.paid))
		});
	};

	clearCoupon = _app_id => {
		const { clearCoupon, captureError } = this.props,
			coupons = cloneDeep(this.state.coupons);

		this.getAPI()
			.clearCoupon(_app_id)
			.then(resp => {
				const { code } = coupons[_app_id];

				if (resp?.result !== 'success') {
					SENTRY.set_tag('triggering_app_id', _app_id);
					throw new Error('error calling clearCoupon');
				}

				this.getAPI()
					.getApplication(_app_id)
					.then(respGetApp => {
						if (respGetApp.result === 'error' || !respGetApp.rows.length) {
							SENTRY.set_tag('triggering_app_id', _app_id);
							throw new Error('error calling getApplication');
						} else {
							clearCoupon(_app_id, respGetApp.rows[0].application_fee);
						}
					})
					.catch(ex => captureError(ex));

				if (this._mounted) {
					delete coupons[_app_id];
					this.setState({ coupons: coupons });

					confirmAlert({
						title: 'Cleared Coupon',
						message: `The coupon "${code}" has been removed from your application.`,
						buttons: [{ label: 'OK' }]
					});
				}
			})
			.catch(ex => captureError(ex));
	};

	redeemCoupon = _app_id => {
		const { redeemCoupon, captureError } = this.props,
			coupons = cloneDeep(this.state.coupons);

		this.getAPI()
			.redeemCoupon(_app_id, coupons[_app_id].code)
			.then(resp => {
				if (resp.result === 'success') {
					redeemCoupon(_app_id, resp.coupon_id);

					if (this._mounted) {
						coupons[_app_id].id = resp.coupon_id;
						this.setState({ coupons: coupons });

						confirmAlert({
							title: 'Applied Coupon',
							message: `The coupon "${coupons[_app_id].code}" has been applied to your application.`,
							buttons: [{ label: 'OK' }]
						});
					}
				} else if (resp.result === 'error') {
					const error_messages = {
							'App not found': {
								title: 'App Not Found',
								message: "Hmm, we can't seem to find your application."
							},
							'No coupon with that code found': {
								title: 'Coupon Not Found',
								message: "Hmm, we can't seem to find that coupon code."
							},
							'Coupon used or expired': {
								title: 'Coupon Already Redeemed',
								message: 'Hmm, it seems as if that coupon has already been redeemed.'
							},
							'Coupon not yet valid': {
								title: 'Coupon Not Valid',
								message: "Sorry, it looks like that coupon isn't valid yet."
							},
							'Coupon no longer valid': {
								title: 'Coupon Expired',
								message: 'Sorry, it looks like that coupon has expired.'
							},
							'Invalid app type for coupon': {
								title: 'Wrong App Type',
								message: `It looks like that coupon can't be applied to an application of that type.`
							},
							'Invalid term for coupon': {
								title: 'Wrong Term',
								message: `It looks like that coupon can't be applied to an application with that term.`
							},
							'Invalid program for coupon': {
								title: 'Wrong Program',
								message:
									"It looks like that coupon can't be applied to an application with the program you've selected."
							}
						},
						objConfirm = error_messages[resp.error] || {
							title: 'Coupon Failed',
							message: "Hmm, that coupon code doesn't seem to work."
						};

					confirmAlert({ ...objConfirm, buttons: [{ label: 'OK' }] });
				} else {
					SENTRY.set_tag('triggering_app_id', _app_id);
					SENTRY.set_tag('coupon_code', coupons[_app_id].code);
					throw new Error('error calling redeemCoupon');
				}
			})
			.catch(ex => captureError(ex));
	};

	onChangeCoupon = e => {
		let coupons = cloneDeep(this.state.coupons),
			{ name, value } = e.target,
			valClean = cleanString(value);

		value = valClean.clean;
		if (valClean.dirty) {
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<div className='react-confirm-alert'>
							<div className='react-confirm-alert-body'>
								Invalid characters were removed from your coupon code.
								<div className='react-confirm-alert-button-group'>
									<button onClick={onClose}>OK</button>
								</div>
							</div>
						</div>
					);
				}
			});
		}

		if (coupons[name]) {
			coupons[name].code = value || '';
		} else {
			coupons[name] = {
				code: value || '',
				id: ''
			};
		}

		this.setState({ coupons: coupons });
	};

	renderActionElement = _app_id => {
		const { role, apps, submitLater, addToCart } = this.props,
			{ coupons } = this.state,
			coupon_code = coupons[_app_id]?.code || '',
			coupon_id = coupons[_app_id]?.id || '';

		if (role === 'TO-SUBMIT') {
			return (
				<td data-label='Remove Application'>
					<div className='actionsColumn'>
						<Button bsStyle='info' onClick={() => submitLater(_app_id)} className='deleteBtn btn-xs'>
							<img
								alt='deleteIcon'
								className='imgDashboard'
								height='18px'
								width='18px'
								src={_svg.Pause}
							/>
							<p className='pDashboard'>Submit Later</p>
						</Button>
					</div>
				</td>
			);
		} else if (role === 'STASHED') {
			return (
				<td data-label='Add Application'>
					<div className='actionsColumn'>
						<Button
							bsStyle='info'
							aria-label='Add Application'
							onClick={() => addToCart(_app_id)}
							className='couponButton change-btn btn-xs'>
							<p>Add</p>
						</Button>
					</div>
				</td>
			);
		} else if (role === 'PAY') {
			if (coupon_id) {
				return (
					<td data-label='Remove Coupon' className='not-padded'>
						<div className='actionsColumn'>
							<Button
								bsStyle='info'
								onClick={() => this.clearCoupon(_app_id)}
								className='couponButton btn-xs btn-remove'>
								<p>Remove Coupon</p>
							</Button>
						</div>
					</td>
				);
			} else {
				return has_fee(apps[_app_id]) ? (
					<td data-label='Redeem Coupon' className='not-padded'>
						<div className='actionsColumn'>
							<input
								className='form-inline maxWidthCoupon'
								type='text'
								aria-label='coupon input'
								placeholder='Enter coupon'
								value={coupon_code}
								name={_app_id}
								onChange={this.onChangeCoupon}
							/>

							<Button
								bsStyle='info'
								aria-label='Redeem Coupon'
								onClick={() => this.redeemCoupon(_app_id)}
								disabled={!coupon_code}
								className='couponButton btn-xs'>
								<p>Redeem</p>
							</Button>
						</div>
					</td>
				) : (
					<td>-</td>
				);
			}
		}
	};

	renderStashedRows = () => {
		let { terms, apps, apps_to_submit_later } = this.props;

		return apps_to_submit_later.split('|').map(_app_id => {
			const _app = apps[_app_id],
				term = FIND_TERM.by_app(_app.json_obj, terms);

			return (
				<tr key={`STASHED|${_app_id}`}>
					{this.renderActionElement(_app_id)}
					<td data-label='Campus'>{_app.json_obj.initial_information.chosen_campus.title}</td>
					<td data-label='App Type'>{formalizeAppType(_app.json_obj)}</td>
					<td data-label='Semester'>{term?.title}</td>
					<td data-label='App Fee'>
						<strong>{has_fee(_app) ? `$${_app.application_fee}` : 'No Charge'} </strong>
					</td>
				</tr>
			);
		});
	};

	renderRows = headers => {
		let { terms, role, apps, apps_in_cart, app_ids_to_pay, onChangePay, campuses } = this.props,
			{ coupons, app_recs } = this.state,
			str_app_ids = this.orig_app_ids_to_pay.length ? this.orig_app_ids_to_pay.join('|') : apps_in_cart;

		if (!str_app_ids.length) return <></>;

		return app_recs.map(rec => {
			const _app = apps[rec.id],
				coupon_code = coupons[rec.id]?.code || '',
				coupon_id = coupons[rec.id]?.id || '',
				term = FIND_TERM.by_app(_app.json_obj, terms),
				strTerm = _app.app_type === 'DA' ? term?.title.replace('Direct Admit', 'Fall') : term?.title,
				strCampusHeader = _app.app_type === 'DA' ? 'Campuses' : 'Campus',
				strCampus =
					_app.app_type === 'DA'
						? Object.keys(_app.json_obj.initial_information.direct_admit)
								.map(campus_code => campuses.find(c => c.field_abbreviation === campus_code).title)
								.join(', ')
						: rec.campus;

			return (
				<tr key={`${role}|${rec.id}`}>
					{headers.some(h => h.id === 'coupon') && (
						<td data-label='Coupon'>{coupon_id ? coupon_code : '-'}</td>
					)}
					{headers.some(h => h.id === 'action') && this.renderActionElement(rec.id)}
					<td data-label={strCampusHeader}>{strCampus}</td>
					<td data-label='App Type'>{formalizeAppType(_app.json_obj)}</td>
					<td data-label='Semester'>{strTerm}</td>
					{headers.some(h => h.id === 'pay') && (
						<td
							data-label='Pay Fee?'
							onClick={() => {
								if (this.orig_app_ids_to_pay.includes(rec.id)) onChangePay([rec.id]);
							}}>
							{has_fee(apps[rec.id]) && (
								<input
									id={`pay|${rec.id}`}
									type='checkbox'
									checked={app_ids_to_pay.includes(rec.id) ? 'checked' : ''}
									readOnly={true}
								/>
							)}
						</td>
					)}
					{headers.some(h => h.id === 'cost') && (
						<td data-label='App Fee'>
							<strong>{has_fee(_app) ? `$${_app.application_fee}` : 'No Charge'} </strong>
						</td>
					)}
				</tr>
			);
		});
	};

	renderTable() {
		let { role, app } = this.props,
			{ show_action } = this.state,
			headers = [],
			strCampus = app.application_modifier === 'DA' ? 'Campuses' : 'Campus';

		if (role === 'PAY') {
			headers.push({
				id: 'coupon',
				elem: (
					<div className='couponColumn'>
						<strong>Coupon</strong>{' '}
						<a id='couponTooltip' className='couponIButton'>
							<img
								className='informationTooltip'
								src={require('assets/img/InformationWhite.png')}
								alt='Information'
							/>
						</a>
						<Tooltip
							anchorId='couponTooltip'
							className='tooltipContainer'
							delayHide={1000}
							effect='solid'
							content={
								<p>
									You may have received a coupon code for your application. Coupon codes are uncommon.
								</p>
							}
						/>
					</div>
				)
			});
		}

		if (show_action) headers.push({ id: 'action', elem: <strong>Actions</strong> });

		headers.push({ id: 'campus', elem: <strong>{strCampus}</strong> });
		headers.push({ id: 'app-type', elem: <strong>App Type</strong> });
		headers.push({ id: 'term', elem: <strong>Term</strong> });

		if (this.orig_app_ids_to_pay.length) headers.push({ id: 'pay', elem: <strong>Pay Fee?</strong> });
		headers.push({ id: 'cost', elem: <strong>Cost</strong> });

		return (
			<Table striped bordered hover>
				<thead>
					<tr>
						{headers.map((cell, i) => (
							<th key={i} scope='col'>
								{cell.elem}
							</th>
						))}
					</tr>
				</thead>
				<tbody>{role === 'STASHED' ? this.renderStashedRows() : this.renderRows(headers)}</tbody>
			</Table>
		);
	}

	render() {
		let { apps_to_submit_later, role } = this.props,
			header;

		if (role === 'STASHED') {
			header = <h3 className='uwred'>In Progress Applications-Not Ready to Submit</h3>;

			if (_.isEmpty(apps_to_submit_later)) return <></>;
		}

		return (
			<>
				{header}
				{this.renderTable()}
			</>
		);
	}
}

const mapStateToProps = state => {
		const { apps, app_id, apps_in_cart, apps_to_submit_later, support_student_id, campus_admin } = state.user;

		return {
			terms: state.global.terms,
			campuses: state.global.campuses,
			apps: apps,
			app: apps[app_id].json_obj,
			app_id: app_id,
			apps_in_cart: apps_in_cart,
			apps_to_submit_later: apps_to_submit_later,
			support_student_id: support_student_id,
			campus_admin: campus_admin
		};
	},
	mapDispatchToProps = dispatch => ({
		submitLater: id => dispatch(UserActions.submitLater(id)),
		addToCart: id => dispatch(UserActions.addToCart(id)),
		redeemCoupon: (_app_id, coupon_id) => dispatch(UserActions.redeemCoupon(_app_id, coupon_id)),
		clearCoupon: (_app_id, app_fee) => dispatch(UserActions.clearCoupon(_app_id, app_fee)),
		captureError: err => dispatch(GlobalActions.captureError(err))
	});

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);
