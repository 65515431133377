import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { ControlLabel, FormGroup, Modal, Row, Table } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import { connect } from 'react-redux';
import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-tooltip/dist/react-tooltip.css';
import _ from 'underscore';

import API from 'components/api';
import { FILE_UPLOAD, cloneDeep } from 'components/modules/_misc';
import { create_proactive } from 'components/modules/ada';
import { DEFAULT } from 'components/modules/app';
import { nextAppPage, prevAppPage } from 'components/modules/nav';
import _svg from 'components/modules/svg';
import { months_passed } from 'components/modules/date';
import DateInput from 'components/controls/date';
import FileUpload from 'components/controls/file_upload';
import RadioList from 'components/controls/radio_list';
import Select from 'components/controls/select';
import Textbox from 'components/controls/textbox';
import { states, countries } from 'components/modules/opts';
import { GlobalActions } from 'reducers/global';
import { UserActions } from 'reducers/user';

const isGraduatedHS = rec =>
	rec?.currently_attend === 'Yes' ||
	rec?.did_graduate === 'Yes' ||
	['GED', 'HSED'].includes(rec?.selected_highschool?.title || '');

class AcademicBackgroundHS extends Component {
	constructor(props) {
		super(props);

		this.state = {
			index: -1,
			show_search: false,
			arrSearch: [],
			search: {
				name: '',
				location: ''
			},
			modalShow: false,
			selected_closed_hs: null,
			high_school: null,
			uploading_files: false
		};

		this.orig_has_files = !!props.uploaded_files.length;
		this.countries = countries.map(rec => ({ val: rec.label, label: rec.label })); // 12/12/22, tms:  our school data uses a country's full name rather than code
	}

	componentDidMount() {
		const { app, setSubmenu } = this.props,
			{ highschools } = app.academic_background;

		this._mounted = true;

		setSubmenu();

		setTimeout(() => {
			if (this._mounted) {
				create_proactive(
					'You have the option to upload an unofficial high school transcript. Please note the app only accepts PDFs. Campuses will let you know if they need an official high school transcript from you to make an admissions decision.',
					'5ebaed8883b16dea626d1725'
				);
			}
		}, 2000);

		const idxInvalid = highschools.findIndex(hs => !this.isValidHighSchool(hs));
		if (app.application_modifier === 'DA' && idxInvalid > -1) {
			this.setState({ index: idxInvalid, high_school: highschools[idxInvalid] });
		}
	}

	componentWillUnmount() {
		this._mounted = false;

		const { saveApp, app } = this.props,
			{ highschools } = app.academic_background,
			is_complete = highschools.some(hs => this.isValidHighSchool(hs) && isGraduatedHS(hs));

		saveApp(is_complete);
	}

	getAPI = () => {
		const { support_student_id, campus_admin } = this.props;
		return new API('', support_student_id, campus_admin);
	};

	gradHSIndex = () => {
		const { app } = this.props;
		return app.academic_background.highschools.findIndex(isGraduatedHS);
	};

	isValidHighSchool = hs => {
		let { app } = this.props,
			{ index } = this.state,
			{
				selected_highschool,
				currently_attend,
				did_graduate,
				graduation_date,
				use_previous_file,
				high_school_transcript
			} = hs,
			{ title, field_state } = selected_highschool,
			gradHSIndex = app.academic_background.highschools.findIndex(isGraduatedHS),
			includeGradQuestions = [-1, index].includes(gradHSIndex) || isGraduatedHS(hs);

		if (['GED', 'HSED'].includes(selected_highschool.title)) {
			return !!field_state && !!graduation_date && this.checkDateValidity('graduation_date', hs);
		}

		let valid = !(title === 'Homeschooled' && !field_state) && !(includeGradQuestions && !currently_attend);

		if (valid && currently_attend === 'No') {
			valid = !(
				(includeGradQuestions && !did_graduate) ||
				(did_graduate === 'Yes' && !this.checkDateValidity('graduation_date', hs)) ||
				(did_graduate === 'No' &&
					!(
						this.checkDateValidity('hs_attendance_start', hs) &&
						this.checkDateValidity('hs_attendance_end', hs)
					))
			);
		}

		if (valid && currently_attend === 'Yes') {
			valid =
				this.checkDateValidity('hs_attendance_start', hs) &&
				this.checkDateValidity('graduation_date', hs) &&
				!(use_previous_file === 'Yes' && !high_school_transcript);
		}

		return valid;
	};

	isValidForm = () => {
		const { app } = this.props,
			{ high_school } = this.state,
			{ highschools } = app.academic_background;

		return high_school
			? this.isValidHighSchool(high_school)
			: !!highschools.length && !highschools.some(hs => !this.isValidHighSchool(hs));
	};

	checkDateValidity = (str, _hs) => {
		const { high_school } = this.state,
			hs = _hs || high_school,
			{ currently_attend, hs_attendance_start, hs_attendance_end, graduation_date, did_graduate } = hs;

		if (hs[str].length < 7) return false;

		let start_months = months_passed(hs_attendance_start),
			end_months = months_passed(hs_attendance_end),
			grad_months = months_passed(graduation_date),
			inRange = months => months >= -4 * 12 && months <= 82 * 12;

		if (str === 'hs_attendance_start') {
			return (
				inRange(start_months) &&
				!(currently_attend === 'Yes' && start_months < 0) &&
				!(currently_attend === 'No' && start_months > 0 && end_months < 0)
			);
		}

		if (str === 'hs_attendance_end') {
			return (
				inRange(end_months) &&
				end_months <= start_months &&
				!(currently_attend === 'Yes' && end_months > 0) &&
				!(currently_attend === 'No' && start_months > 0 && end_months < 0)
			);
		}

		return (
			inRange(grad_months) &&
			!(currently_attend === 'Yes' && grad_months > 0) &&
			!(did_graduate === 'Yes' && grad_months < 0)
		);
	};

	onAddHighSchool = e => {
		let high_school = cloneDeep(DEFAULT.HIGH_SCHOOL);

		if (this.gradHSIndex() > -1) {
			high_school.currently_attend = 'No';
			high_school.did_graduate = 'No';
		}

		this.setState({ index: -1, high_school: high_school, show_search: true });
	};

	onEditHighSchool = i => {
		const { highschools } = this.props.app.academic_background;
		this.setState({ index: i, high_school: cloneDeep(highschools[i]) });
	};

	onDeleteHighSchool = i => {
		let { app, updateApp } = this.props;

		if (!app.date_submitted) {
			confirmAlert({
				title: 'Remove Entry',
				message: 'Are you sure you want to remove this?',
				buttons: [
					{
						label: 'Yes',
						onClick: () => {
							let academic_background = cloneDeep(app.academic_background);
							academic_background.highschools.splice(i, 1);
							updateApp({ academic_background: academic_background });
						}
					},
					{
						label: 'No'
					}
				]
			});
		}
	};

	onSaveHighSchool = e => {
		e.preventDefault();
		e.stopPropagation();

		let { app, updateApp } = this.props,
			{ index } = this.state,
			high_school = cloneDeep(this.state.high_school),
			academic_background = cloneDeep(app.academic_background);

		if (!app.date_submitted) {
			if (!['United States', 'USA'].includes(high_school.selected_highschool.field_country)) {
				high_school.selected_highschool.field_state = '';
			}

			if (high_school.selected_highschool.field_country !== 'Other') {
				high_school.selected_highschool.field_other_country = '';
			}

			if (high_school.currently_attend === 'Yes') high_school.hs_attendance_end = '';

			if (high_school.currently_attend === 'No') {
				high_school.use_previous_file = '';
				high_school.high_school_transcript = '';
				high_school.high_school_transcript_name = '';
				high_school.upload_error = '';

				if (high_school.did_graduate === 'No') high_school.graduation_date = '';

				if (high_school.did_graduate === 'Yes') {
					high_school.hs_attendance_start = '';
					high_school.hs_attendance_end = '';
				}
			}

			if (!isGraduatedHS(high_school)) high_school.graduation_date = '';

			if (index < 0) {
				if (isGraduatedHS(high_school)) {
					academic_background.highschools.unshift(high_school);
				} else {
					academic_background.highschools.push(high_school);
				}
			} else if (index > 0 && isGraduatedHS(high_school)) {
				// newly updated to graduated HS - need to move to beginning of list
				academic_background.highschools.splice(index, index);
				academic_background.highschools.unshift(high_school);
			} else {
				academic_background.highschools[index] = high_school;
			}

			updateApp({ academic_background: academic_background });
			this.setState({ high_school: null, index: -1 });
		}
	};

	onChangeSearch = (value, prop) => {
		const { captureError } = this.props,
			{ search } = this.state,
			setList = resp => {
				if (this._mounted) {
					this.setState({
						arrSearch: _.sortBy(resp, x => (x.title === 'Homeschooled' && x.field_city === '-' ? -1 : 0))
					});
				}
			};

		search[prop] = value;

		if (value) {
			if (prop === 'location') {
				this.getAPI()
					.getHighschoolsByLocation(value)
					.then(setList)
					.catch(ex => captureError(ex));
			} else {
				this.getAPI()
					.getHighschoolsBySearch(value)
					.then(setList)
					.catch(ex => captureError(ex));
			}
		} else {
			this.setState({ arrSearch: [] });
		}

		this.setState({ search: search });
	};

	onSelectHighschoolBySearch = hs => {
		let high_school = cloneDeep(DEFAULT.HIGH_SCHOOL);
		high_school.selected_highschool = cloneDeep(hs);

		if (this.gradHSIndex() > -1) {
			high_school.currently_attend = 'No';
			high_school.did_graduate = 'No';
		}

		this.setState({
			show_search: false,
			high_school: high_school,
			arrSearch: [],
			search: {
				name: '',
				location: ''
			}
		});
	};

	onUploadTranscript = file => {
		let { app, app_id, addDocument, captureError } = this.props,
			high_school = cloneDeep(this.state.high_school);

		if (!app.date_submitted) {
			const { name, size } = file;

			if (size > FILE_UPLOAD.min_file_size) {
				this.setState({ uploading_files: true });

				this.getAPI()
					.saveFile(file, app_id, 'HSTranscript')
					.then(response => {
						if (this._mounted) {
							this.setState({ uploading_files: false });

							if (response.data.includes('UPLOAD FAILED')) {
								const err_msg = response.data.replace('UPLOAD FAILED:', '').trim();

								high_school.high_school_transcript = '';
								high_school.high_school_transcript_name = '';

								this.setState({
									high_school: high_school,
									upload_error: `Upload failed: ${name} (${err_msg})`
								});
							} else {
								high_school.high_school_transcript = response.data;
								high_school.high_school_transcript_name = name;

								addDocument(name, response.data);
								this.setState({ high_school: high_school, upload_error: '' });
							}
						}
					})
					.catch(ex => captureError(ex));
			} else {
				confirmAlert(FILE_UPLOAD.err_msg);
			}
		}
	};

	onChange = (val, prop) => {
		let { app } = this.props,
			high_school = cloneDeep(this.state.high_school);

		if (!app.date_submitted) {
			high_school[prop] = val;

			if (prop === 'did_graduate' && val === 'Yes') high_school.currently_attend = 'No';

			if (prop === 'currently_attend' && val === 'Yes') high_school.did_graduate = '';

			this.setState({ high_school: high_school });
		}
	};

	onChangeSelectedHS = (val, prop) => {
		let { app } = this.props,
			high_school = cloneDeep(this.state.high_school);

		if (!app.date_submitted) {
			high_school.selected_highschool[prop] = val;
			this.setState({ high_school: high_school });
		}
	};

	onClearUploadedFile = e => {
		let { app } = this.props,
			high_school = cloneDeep(this.state.high_school);

		if (!app.date_submitted) {
			confirmAlert({
				title: 'Remove File',
				message: 'Are you sure you want to remove this file from your application?',
				buttons: [
					{
						label: 'Yes',
						onClick: () => {
							high_school.high_school_transcript = '';
							high_school.high_school_transcript_name = '';

							this.setState({ high_school: high_school });
						}
					},
					{
						label: 'No'
					}
				]
			});
		}
	};

	renderBtnDownload = (key, file_name) => {
		const { uploaded_files, captureError } = this.props;

		if (!file_name) {
			const uploaded_file = uploaded_files.find(file => file.key === key);
			file_name = uploaded_file ? uploaded_file.file_name : `${key}.pdf`;
		}

		return (
			<Button
				bsStyle='info'
				aria-label='Download uploaded transcript'
				onClick={() => {
					this.getAPI()
						.downloadDocument(key, file_name)
						.catch(ex => {
							captureError(ex);

							confirmAlert({
								title: "Hmm, that didn't work",
								message:
									'Your transcript could not be downloaded.  Please try again and let us know if the problem continues.',
								buttons: [{ label: 'OK' }]
							});
						});
				}}
				className='btn-download'>
				<img alt='downloadIcon' className='imgDashboard' height='18px' width='18px' src={_svg.Download} />
				<p className='pDashboard'>Download</p>
			</Button>
		);
	};

	renderFileUploadBlock = () => {
		let { uploaded_files } = this.props,
			{ high_school, uploading_files, upload_error } = this.state,
			{ use_previous_file, high_school_transcript, high_school_transcript_name } = high_school,
			showUploadControl =
				use_previous_file === 'No' || !this.orig_has_files || (!!high_school_transcript && !use_previous_file),
			already_uploaded_file;

		if (upload_error) {
			already_uploaded_file = <p className='uwRedText'>{upload_error}</p>;
		} else if (high_school_transcript_name) {
			already_uploaded_file = (
				<p>
					<strong>File Successfully Uploaded: </strong>
					{high_school_transcript_name}
					<Button
						bsStyle='info'
						aria-label='Clear or remove uploaded transcript'
						onClick={this.onClearUploadedFile}
						className='deleteBtn deleteUploadedFile'>
						<img alt='deleteIcon' className='imgDashboard' height='18px' width='18px' src={_svg.Delete} />
						<p className='pDashboard'>Remove</p>
					</Button>
					{this.renderBtnDownload(high_school_transcript, high_school_transcript_name)}
				</p>
			);
		}

		return (
			<>
				<h3>HIGH SCHOOL TRANSCRIPT</h3>
				<p>
					Providing an unofficial transcript is optional to include in the application. An unofficial
					transcript may reduce the time to receive an admission decision. An official transcript may be
					required by some campuses.
				</p>

				{(!!use_previous_file || (this.orig_has_files && !high_school_transcript)) && (
					<Row>
						<RadioList
							name='use_previous_file'
							value={use_previous_file}
							onChange={val => this.onChange(val, 'use_previous_file')}
							opts={['Yes', 'No']}
							label='Would you like to use a previous file?'
							cols={12}
							hide_optional={true}
						/>
					</Row>
				)}

				{use_previous_file === 'Yes' && (
					<Row>
						<Select
							value={high_school_transcript}
							opts={uploaded_files.map(file => ({ val: file.key, label: file.file_name }))}
							onChange={val => this.onChange(val, 'high_school_transcript')}
							name='high_school_transcript'
							label='Use previous file:'
							placeholder='Select File'
							required={true}
							cols={6}
						/>

						{high_school_transcript && (
							<div className='col-md-6 prev-download'>
								{this.renderBtnDownload(high_school_transcript)}
							</div>
						)}
					</Row>
				)}

				{showUploadControl && (
					<>
						{(!high_school_transcript_name || !!upload_error) && (
							<>
								<Row>
									<FileUpload
										name='high_school_transcript'
										displayName='H.S. transcript'
										onChange={file => this.onUploadTranscript(file)}
										cols={12}
										hide_optional={true}
										toolTip={
											<a id='residentTooltip'>
												<img
													className='informationTooltip'
													src={require('assets/img/Information.png')}
													alt='Information'
												/>
											</a>
										}
									/>
								</Row>

								<Tooltip
									anchorId='residentTooltip'
									className='tooltipContainer'
									delayHide={1000}
									effect='solid'
									content={
										<p>
											Export a pdf of your unofficial high school transcript from either your high
											school&apos;s student portal or an e-transcript vendor your high school
											uses.
										</p>
									}
								/>
							</>
						)}

						{uploading_files && (
							<p>
								<strong>Uploading...</strong>
							</p>
						)}

						{!!(high_school_transcript_name || upload_error) && already_uploaded_file}
					</>
				)}
			</>
		);
	};

	renderClosedHSModal = () => {
		let { selected_closed_hs } = this.state;

		return (
			<Modal show={!!selected_closed_hs} onHide={() => this.setState({ selected_closed_hs: null })}>
				<Modal.Header closeButton>
					<h2 className={'uwRedText'}>Closed High School</h2>
				</Modal.Header>
				<Modal.Body className={'modalMinHeight'}>
					<p>
						Our records indicate that <strong>{selected_closed_hs?.title}</strong> has been closed, renamed,
						or is an otherwise defunct high school.
					</p>
					<p>Please confirm that you meant to select it.</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						bsStyle='info'
						className='back-btn'
						fill
						onClick={() => this.setState({ selected_closed_hs: null })}>
						No, I&apos;ll Select Something Else
					</Button>
					<Button
						bsStyle='info'
						className='save-btn'
						fill
						onClick={() => {
							this.setState({ selected_closed_hs: null });
							this.onSelectHighschoolBySearch(selected_closed_hs);
						}}>
						Yes, That&apos;s My High School
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	renderCustomHighSchoolModal = () => {
		let { modalShow, high_school } = this.state,
			{ title, field_country, field_other_country, field_state, field_city } = high_school.selected_highschool,
			valid_form =
				!!title &&
				!!field_country &&
				!!field_city &&
				!(['United States', 'USA'].includes(field_country) && !field_state) &&
				!(field_country === 'Other' && !field_other_country);

		return (
			<Modal show={modalShow}>
				<form
					onSubmit={e => {
						e.preventDefault();
						e.stopPropagation();

						this.setState({ show_search: false, modalShow: false });
					}}>
					<Modal.Header>
						<Modal.Title className='h2'>High School</Modal.Title>
					</Modal.Header>
					<Modal.Body className='modalMinHeight'>
						<Row>
							<Textbox
								name='title'
								value={title}
								onChange={val => this.onChangeSelectedHS(val, 'title')}
								label='High School Name'
								placeholder='High School Name'
								required={true}
								maxLength={50}
								cols={12}
							/>
						</Row>
						<Row>
							<Select
								name='field_country'
								value={field_country}
								onChange={val => this.onChangeSelectedHS(val, 'field_country')}
								opts={this.countries}
								label='Country'
								placeholder='Select Country'
								required={true}
								cols={6}
							/>

							{['United States', 'USA'].includes(field_country) && (
								<Select
									name='field_state'
									value={field_state}
									onChange={val => this.onChangeSelectedHS(val, 'field_state')}
									opts={states}
									label='State'
									placeholder='Select State'
									required={true}
									cols={6}
								/>
							)}

							{field_country === 'Other' && (
								<Textbox
									name='field_other_country'
									value={field_other_country}
									onChange={val => this.onChangeSelectedHS(val, 'field_other_country')}
									label='Specify Country'
									placeholder='Specify Country'
									required={true}
									maxLength={40}
									cols={6}
								/>
							)}
						</Row>
						<Row>
							<Textbox
								name='field_city'
								value={field_city}
								onChange={val => this.onChangeSelectedHS(val, 'field_city')}
								label='City'
								placeholder='City'
								required={true}
								maxLength={50}
								cols={6}
							/>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							bsStyle='info'
							className='back-btn'
							fill
							onClick={() => this.setState({ modalShow: false })}>
							Cancel
						</Button>
						<Button bsStyle='info' className='save-btn' fill type='submit' disabled={!valid_form}>
							Confirm
						</Button>
					</Modal.Footer>
				</form>
			</Modal>
		);
	};

	renderNonTraditionalEditForm = () => {
		let { high_school } = this.state,
			{ selected_highschool, graduation_date } = high_school,
			{ title, field_state, field_country } = selected_highschool;

		return (
			<>
				<p>
					<strong>{title}</strong>
					<br />
					{field_state === 'N/A' ? '' : `${field_state} `}
					{!['United States', 'USA'].includes(field_country) && field_country?.toUpperCase()}
				</p>

				<Row>
					<Select
						name='field_state'
						value={field_state}
						onChange={val => this.onChangeSelectedHS(val, 'field_state')}
						opts={states}
						label='What state?'
						placeholder='Select State'
						required={true}
						cols={6}
					/>

					<DateInput
						value={graduation_date}
						onChange={(val, isValid) => this.onChange(val, 'graduation_date', isValid)}
						label='When was this earned?'
						required={true}
						cols={6}
						fullDate={false}
						isInvalid={!this.checkDateValidity('graduation_date')}
					/>
				</Row>
			</>
		);
	};

	renderEditForm = () => {
		let { app } = this.props,
			{ high_school, index } = this.state,
			{
				selected_highschool,
				currently_attend,
				hs_attendance_start,
				hs_attendance_end,
				graduation_date,
				did_graduate
			} = high_school,
			{ title, field_state, field_city, field_country } = selected_highschool,
			gradHSIndex = app.academic_background.highschools.findIndex(isGraduatedHS),
			includeGradQuestions = [-1, index].includes(gradHSIndex) || isGraduatedHS(high_school);

		return (
			<>
				<p>
					<strong>{title}</strong>
					<br />
					{field_city === '-' ? '' : `${field_city}, `}
					{field_state === 'N/A' ? '' : `${field_state} `}
					{!['United States', 'USA'].includes(field_country) && field_country?.toUpperCase()}
					<br />
				</p>

				<>
					{title === 'Homeschooled' && (
						<Row>
							<Select
								name='field_state'
								value={field_state}
								onChange={val => this.onChange(val, 'field_state')}
								opts={states}
								label='What state?'
								placeholder='Select State'
								required={true}
								cols={6}
							/>
						</Row>
					)}

					{includeGradQuestions && (
						<Row>
							<RadioList
								name='currently_attend'
								value={currently_attend}
								onChange={val => this.onChange(val, 'currently_attend')}
								opts={['Yes', 'No']}
								label='Do you currently attend this school?'
								cols={12}
								required={true}
							/>
						</Row>
					)}

					{currently_attend === 'Yes' && (
						<>
							<Row>
								<DateInput
									value={hs_attendance_start}
									onChange={(val, isValid) => this.onChange(val, 'hs_attendance_start', isValid)}
									label='Attended from'
									required={true}
									cols={6}
									fullDate={false}
									isInvalid={!this.checkDateValidity('hs_attendance_start')}
								/>
								<DateInput
									value={graduation_date}
									onChange={(val, isValid) => this.onChange(val, 'graduation_date', isValid)}
									label='Anticipated graduation date?'
									required={true}
									cols={6}
									fullDate={false}
									isInvalid={!this.checkDateValidity('graduation_date')}
								/>
							</Row>
						</>
					)}

					{currently_attend === 'No' && (
						<>
							{includeGradQuestions && (
								<Row>
									<RadioList
										name='did_graduate'
										value={did_graduate}
										onChange={val => this.onChange(val, 'did_graduate')}
										opts={['Yes', 'No']}
										label='Did you graduate from this high school/secondary school?'
										cols={6}
										required={true}
									/>
								</Row>
							)}

							{did_graduate === 'Yes' && (
								<Row>
									<DateInput
										value={graduation_date}
										onChange={(val, isValid) => this.onChange(val, 'graduation_date', isValid)}
										label='Graduation Date'
										required={true}
										cols={6}
										fullDate={false}
										isInvalid={!this.checkDateValidity('graduation_date')}
									/>
								</Row>
							)}

							{did_graduate === 'No' && (
								<Row>
									<DateInput
										value={hs_attendance_start}
										onChange={(val, isValid) => this.onChange(val, 'hs_attendance_start', isValid)}
										label='Attended from'
										required={true}
										cols={6}
										fullDate={false}
										isInvalid={!this.checkDateValidity('hs_attendance_start')}
									/>

									<DateInput
										value={hs_attendance_end}
										onChange={(val, isValid) => this.onChange(val, 'hs_attendance_end', isValid)}
										label='Attended to'
										required={true}
										cols={6}
										fullDate={false}
										isInvalid={!this.checkDateValidity('hs_attendance_end')}
									/>
								</Row>
							)}
						</>
					)}

					{app.application_modifier !== 'DA' && currently_attend === 'Yes' && this.renderFileUploadBlock()}
				</>
			</>
		);
	};

	renderSearchForm = () => {
		let { app } = this.props,
			{ search, arrSearch } = this.state;

		return (
			<>
				{this.renderCustomHighSchoolModal()}
				{this.renderClosedHSModal()}

				<Row>
					<Textbox
						name='name'
						value={search.name}
						onChange={val => this.onChangeSearch(val, 'name')}
						label='Search By Name'
						placeholder='Search By Name'
						maxLength={100}
						cols={6}
						hide_optional={true}
					/>
					<Textbox
						name='location'
						value={search.location}
						onChange={val => this.onChangeSearch(val, 'location')}
						label='Search By City/Location'
						placeholder='Search By City/Location'
						maxLength={100}
						cols={6}
						hide_optional={true}
					/>
				</Row>

				<FormGroup>
					<ControlLabel>Choose School</ControlLabel>
					<br />

					{arrSearch.map((hs, i) => (
						<button
							tabIndex='0'
							key={i}
							className='school_select'
							onClick={e => {
								e.preventDefault();
								e.stopPropagation();

								if (!app.date_submitted) {
									if (hs.field_closed === '1') {
										this.setState({ selected_closed_hs: hs });
									} else {
										this.onSelectHighschoolBySearch(hs);
									}
								}
							}}>
							<strong>{hs.title}</strong>
							<br />
							{hs.field_city === '-' ? '' : `${hs.field_city}, `}
							{hs.field_state === 'N/A' ? '' : `${hs.field_state} `}
							{!['United States', 'USA'].includes(hs.field_country) && hs.field_country?.toUpperCase()}
						</button>
					))}

					<button
						tabIndex='0'
						className='school_select school_not_found'
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();

							this.setState({ modalShow: true });
						}}>
						<strong>I did not find my high school</strong>
					</button>
				</FormGroup>
			</>
		);
	};

	renderHighSchoolRow() {
		const { app } = this.props;

		let objectRows = app.academic_background.highschools.length
			? app.academic_background.highschools.map((hs, i) => (
					<tr key={`highschool_${i}`}>
						<td data-label='Title'>{hs.selected_highschool.title}</td>
						<td data-label='Graduation Date'>{hs.graduation_date}</td>
						<td data-label='Transcript'>{hs.high_school_transcript !== '' ? 'Yes' : 'No'}</td>
						<td data-label='Actions'>
							<Button bsStyle='info' onClick={() => this.onEditHighSchool(i)} className='editBtn btn-xs'>
								<img
									alt='editIcon'
									className='imgDashboard'
									height='18px'
									width='18px'
									src={_svg.Edit}
								/>
								<p className='pDashboard'>Edit</p>
							</Button>

							<Button
								bsStyle='info'
								onClick={() => this.onDeleteHighSchool(i)}
								className='deleteBtn btn-xs'>
								<img
									alt='deleteIcon'
									className='imgDashboard'
									height='18px'
									width='18px'
									src={_svg.Delete}
								/>
								<p className='pDashboard'>Remove</p>
							</Button>
						</td>
					</tr>
			  ))
			: [
					<tr key='none'>
						<td colSpan={4}>You have no high school/secondary schools added.</td>
					</tr>
			  ];

		objectRows.push(
			<tr key='add' className='addColumn'>
				<td colSpan={3}></td>
				<td data-label='Actions'>
					<Button bsStyle='info' onClick={this.onAddHighSchool} className='editBtn btn-xs'>
						<img
							alt='editIcon'
							className='imgDashboard'
							height='18px'
							width='18px'
							src={_svg.GreenCirclePlus}
						/>
						<p className='pDashboard'>Add High School</p>
					</Button>
				</td>
			</tr>
		);

		return objectRows;
	}

	renderMainPage = () => {
		const { app } = this.props;

		return (
			<>
				<h4>
					In this next section you will need to supply the following pieces of information about all of your
					High School/Secondary Schools attended:
				</h4>
				<ul>
					<li className='transitionLI'>Full name of your high school/secondary school</li>
					<li className='transitionLI'>High school/secondary school attendance dates</li>
					<li className='transitionLI'>High school/secondary school graduation date</li>
					<li className='transitionLI'>
						Optional: unofficial high school/secondary school transcript, if still currently in high school
					</li>
				</ul>

				<hr />

				{app.academic_background.highschools.length ? (
					<Table striped bordered hover>
						<thead>
							<tr>
								<th scope='col'>
									<strong>Title</strong>
								</th>
								<th scope='col'>
									<strong>Graduation date</strong>
								</th>
								<th scope='col'>
									<strong>Transcript</strong>
								</th>
								<th scope='col'>
									<strong>Actions</strong>
								</th>
							</tr>
						</thead>
						<tbody>{this.renderHighSchoolRow()}</tbody>
					</Table>
				) : (
					<Button bsStyle='info' className='save-btn add-content' fill onClick={this.onAddHighSchool}>
						Add High School
					</Button>
				)}
			</>
		);
	};

	renderBtnBack = () => {
		const { navigate, app, location } = this.props,
			{ high_school, uploading_files } = this.state;

		return high_school ? (
			<Button
				bsStyle='info'
				className='back-btn'
				fill
				onClick={() => this.setState({ high_school: null, show_search: false })}
				disabled={uploading_files}>
				Back
			</Button>
		) : (
			<Button
				bsStyle='info'
				className='back-btn'
				fill
				onClick={() => navigate(prevAppPage(app, location.pathname))}>
				Back
			</Button>
		);
	};

	renderBtnSubmit = () => {
		const { high_school, uploading_files, show_search } = this.state;

		if (show_search) return null;

		const _valid = this.isValidForm();

		return high_school ? (
			<Button
				bsStyle='info'
				className='tertiaryButton'
				fill
				onClick={this.onSaveHighSchool}
				disabled={uploading_files || !_valid}>
				Save High School
			</Button>
		) : (
			<Button form='hsForm' bsStyle='info' className='save-btn' disabled={!_valid} fill type='submit'>
				Save and Continue
			</Button>
		);
	};

	render() {
		let { app, navigate, location } = this.props,
			{ high_school, show_search } = this.state,
			content;

		if (high_school) {
			if (show_search) {
				content = this.renderSearchForm();
			} else if (['GED', 'HSED'].includes(high_school.selected_highschool.title)) {
				content = this.renderNonTraditionalEditForm();
			} else {
				content = this.renderEditForm();
			}
		} else {
			content = this.renderMainPage();
		}

		return (
			<Card
				title='Academic Background: High School/Secondary School'
				content={
					<form id='hsForm' onSubmit={e => navigate(nextAppPage(app, location.pathname))}>
						{content}
						<div className='clearfix' />
					</form>
				}
				buttons={
					<>
						{this.renderBtnBack()}
						{this.renderBtnSubmit()}
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => {
		const { app_id, apps, support_student_id, campus_admin, documents } = state.user;

		return {
			app: apps[app_id].json_obj,
			app_id: app_id,
			support_student_id,
			campus_admin: campus_admin,
			uploaded_files: documents.filter(file => file.document_type === 'HSTranscript')
		};
	},
	mapDispatchToProps = dispatch => ({
		updateApp: obj => dispatch(UserActions.updateApp(obj)),
		saveApp: val => dispatch(UserActions.saveApp({ academicBackgroundHSCompleted: val })),
		setSubmenu: () => dispatch(UserActions.setSubmenus('academic_background_open', true)),
		addDocument: (file_name, key) => dispatch(UserActions.addDocument('HSTranscript', file_name, key)),
		captureError: err => dispatch(GlobalActions.captureError(err))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AcademicBackgroundHS));
