import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import { heritages, tribes } from 'components/modules/opts';
import { Row, Modal } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import CheckboxList from 'components/controls/checkbox_list';
import { nextAppPage, prevAppPage } from 'components/modules/nav';
import RadioList from 'components/controls/radio_list';
import Select from 'components/controls/select';
import Textbox from 'components/controls/textbox';
import { UserActions } from 'reducers/user';
import { cloneDeep } from 'components/modules/_misc';

class PersonalInformationAdditional extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show_ethnicity_modal: false
		};

		this.show_waiver = ['FM_D', 'TR_D'].includes(props.app.application_modifier);
	}

	componentDidMount() {
		this.props.setSubmenu();
	}

	componentWillUnmount() {
		const { saveApp, app, updateApp } = this.props,
			personal_information = cloneDeep(app.personal_information),
			{ heritage_hispanic, heritage_race, waive_fee } = personal_information,
			_valid = this.isValidForm();

		saveApp(_valid);

		if (heritage_hispanic !== 'Y') personal_information.selected_ethnicities = [];

		if (!heritage_race.includes('As')) {
			['Cm', 'Hm', 'La', 'Vi', 'Ai'].forEach(str => {
				const i = heritage_race.indexOf(str);
				if (i > -1) heritage_race.splice(i, 1);
			});
		}

		if (!heritage_race.includes('Am')) personal_information.heritage_tribe_code = '';

		if (['', 'NOT-ENROLLED', 'OTHER'].includes(personal_information.heritage_tribe_code)) {
			personal_information.tribal_enrollment_no = '';
		}

		if (personal_information.heritage_tribe_code !== 'OTHER') personal_information.other_tribe = '';

		if (waive_fee !== 'Yes') personal_information.waive_fee_reason = '';

		updateApp({ personal_information: personal_information });
	}

	isValidForm = () => {
		let { app } = this.props,
			{ heritage_race, heritage_tribe_code, other_tribe, waive_fee, waive_fee_reason } = app.personal_information;

		return (
			!(heritage_race.includes('Am') && heritage_tribe_code === 'OTHER' && !other_tribe) &&
			!(this.show_waiver && waive_fee === 'Yes' && !waive_fee_reason)
		);
	};

	onChange = (prop, val) => {
		let { app, updateApp } = this.props,
			personal_information = cloneDeep(app.personal_information);

		if (!app.date_submitted) {
			if (['military_service', 'veteran_status'].includes(prop)) {
				if (val.includes('None') && !personal_information[prop].includes('None')) {
					personal_information[prop] = ['None'];
				} else if (personal_information[prop].includes('None') && val.length > 1) {
					personal_information[prop] = [val[1]];
				} else {
					personal_information[prop] = val;
				}
			} else {
				personal_information[prop] = val;
			}

			updateApp({ personal_information: personal_information });
		}
	};

	renderPage = () => {
		const { app, waivers } = this.props,
			{
				military_service,
				veteran_status,
				heritage_hispanic,
				selected_ethnicities,
				heritage_race,
				heritage_tribe_code,
				other_tribe,
				tribal_enrollment_no,
				waive_fee,
				waive_fee_reason
			} = app.personal_information;

		return (
			<>
				<h3>FAMILY BACKGROUND</h3>
				<Row>
					<CheckboxList
						name='military_service'
						values={military_service}
						opts={[
							{ val: 'Self', label: 'Self' },
							{ val: 'Parent', label: 'Parent' },
							{ val: 'Spouse', label: 'Spouse' },
							{ val: 'None', label: 'None serving' }
						]}
						onChange={val => this.onChange('military_service', val)}
						label='Are you, or is a parent or spouse, currently serving in the U.S. Military?'
						hide_optional={true}
						cols={12}
						tooltip={
							<a id='militaryTooltip'>
								<img
									className='informationTooltip'
									src={require('assets/img/Information.png')}
									alt='Information'
								/>
							</a>
						}
					/>

					<Tooltip
						anchorId='militaryTooltip'
						className='tooltipContainer'
						delayHide={1000}
						effect='solid'
						content={
							<p>
								Examples of service: Army, Navy, Air Force, Marine Corps and Coast Guard, including
								National Guard and Reserves.
							</p>
						}
					/>
				</Row>

				<Row>
					<CheckboxList
						name='veteran_status'
						values={veteran_status}
						opts={[
							{ val: 'Self', label: 'Self' },
							{ val: 'Parent', label: 'Parent' },
							{ val: 'Spouse', label: 'Spouse' },
							{ val: 'None', label: 'None served' }
						]}
						onChange={val => this.onChange('veteran_status', val)}
						hide_optional={true}
						label='Are you, or is a parent or spouse, a U.S. Military veteran?'
						cols={12}
						tooltip={
							<a id='veteranTooltip'>
								<img
									className='informationTooltip'
									src={require('assets/img/Information.png')}
									alt='Information'
								/>
							</a>
						}
					/>

					<Tooltip
						anchorId='veteranTooltip'
						className='tooltipContainer'
						delayHide={1000}
						effect='solid'
						content={
							<p>
								A person who served in the Army, Navy, Air Force, Marine Corps or Coast Guard, and was
								discharged or released under conditions other than dishonorable. [note - Title 38 Code
								of Federal Regulations (CFR) 3.1(d)]
							</p>
						}
					/>
				</Row>

				<h3>
					ETHNICITY AND RACE
					<a id='ethnicityTooltip'>
						<img
							className={'informationTooltip'}
							src={require('assets/img/Information.png')}
							alt='Information'
						/>
					</a>
				</h3>
				<Tooltip
					anchorId='ethnicityTooltip'
					className='tooltipContainer'
					delayHide={1000}
					effect='solid'
					content={
						<p>
							Federal guidelines require us to ask the following questions. This information is used in
							the aggregate for federal reporting and will not be used in the application review process.
						</p>
					}
				/>

				<Row>
					<RadioList
						name='heritage_hispanic'
						value={heritage_hispanic}
						label='Are you of Hispanic or Latino/a origin?'
						hide_optional={true}
						opts={[
							{ label: 'Yes', val: 'Y' },
							{ label: 'No', val: 'N' }
						]}
						onChange={val => this.onChange('heritage_hispanic', val)}
						cols={12}
					/>
				</Row>

				{heritage_hispanic === 'Y' && (
					<Row>
						<CheckboxList
							name='selected_ethnicities'
							values={selected_ethnicities}
							opts={[
								'Cuban',
								'Mexican, Mexican American, or Chicano/a',
								'Puerto Rican',
								'Another Hispanic or Latino/a Identity'
							]}
							onChange={val => this.onChange('selected_ethnicities', val)}
							label='Which of the option(s) below best describe how you identify yourself? (Check all that apply.)'
							hide_optional={true}
							cols={12}
							forceNewLines={true}
						/>
					</Row>
				)}

				<Row>
					<CheckboxList
						name='heritage_race'
						values={heritage_race}
						opts={heritages.filter(opt => !opt.asian)}
						onChange={val => this.onChange('heritage_race', val)}
						label='Which of the option(s) below best describe how you identify yourself? (Check all that apply.)'
						hide_optional={true}
						cols={12}
						forceNewLines={true}
					/>
				</Row>

				{heritage_race.includes('Am') && (
					<Row>
						<Select
							name='heritage_tribe_code'
							value={heritage_tribe_code}
							label='Please select your tribal enrollment'
							hide_optional={true}
							placeholder='Select Tribe'
							opts={tribes}
							cols={6}
							onChange={val => this.onChange('heritage_tribe_code', val)}
						/>

						{heritage_tribe_code === 'OTHER' && (
							<Textbox
								name='other_tribe'
								value={other_tribe}
								label='Other Tribe'
								hide_optional={true}
								placeholder='Other Tribe (Required)'
								maxLength={50}
								onChange={val => this.onChange('other_tribe', val)}
								required={true}
								cols={6}
							/>
						)}

						{!['', 'NOT-ENROLLED', 'OTHER'].includes(heritage_tribe_code) && (
							<>
								<Textbox
									name='tribal_enrollment_no'
									value={tribal_enrollment_no}
									label='Tribal Enrollment No.'
									hide_optional={true}
									onChange={val => this.onChange('tribal_enrollment_no', val)}
									placeholder='Tribal Enrollment No.'
									cols={5}
									tooltip={
										<a id='tribalEnrollmentNoTooltip'>
											<img
												className={'informationTooltip'}
												src={require('assets/img/Information.png')}
												alt='Information'
											/>
										</a>
									}
								/>
								<Tooltip
									anchorId='tribalEnrollmentNoTooltip'
									className='tooltipContainer'
									delayHide={1000}
									effect='solid'
									content={
										<p>
											This number may be used for verification purposes for programs on campus. It
											is not required and can be provided later if it is not available at this
											time.
										</p>
									}
								/>
							</>
						)}
					</Row>
				)}

				{heritage_race.includes('As') && (
					<Row>
						<CheckboxList
							name='heritage_race'
							values={heritage_race}
							opts={heritages.filter(opt => !!opt.asian)}
							onChange={val => this.onChange('heritage_race', val)}
							label='Which of the option(s) below best describe how you identify yourself? (Check all that apply.)'
							hide_optional={true}
							cols={12}
							forceNewLines={true}
							tooltip={
								<a id='asianEthnicityTooltip'>
									<img
										className={'informationTooltip'}
										src={require('assets/img/Information.png')}
										alt='Information'
									/>
								</a>
							}
						/>

						<Tooltip
							anchorId='asianEthnicityTooltip'
							className='tooltipContainer'
							delayHide={1000}
							effect='solid'
							content={
								<p>We ask about these categories based on criteria in State of Wisconsin statutes.</p>
							}
						/>
					</Row>
				)}

				{this.show_waiver && (
					<>
						<h3>FINANCIAL NEEDS BASED WAIVER</h3>
						<p>
							Do you believe you are eligible for a waived application fee and/or additional assistance
							because of financial need or other circumstances? Providing this information may waive your
							application fee and/or offer you additional assistance on campus.
						</p>

						<Row>
							<RadioList
								name='waive_fee'
								value={waive_fee}
								opts={['Yes', 'No']}
								onChange={val => this.onChange('waive_fee', val)}
								cols={12}
								hide_optional={true}
							/>
						</Row>

						{waive_fee === 'Yes' && (
							<>
								<Row>
									<RadioList
										name='waive_fee_reason'
										value={waive_fee_reason}
										label='If you chose “yes”, choose an option below that best describes your situation:'
										opts={waivers}
										onChange={val => this.onChange('waive_fee_reason', val)}
										required={true}
										cols={12}
										forceNewLines={true}
									/>
								</Row>

								<p>
									<strong>
										You may be eligible for a waiver of the application fee and/or additional
										assistance.
									</strong>
								</p>
								<p>
									Admissions offices may reach out to confirm your eligibility for an application fee
									waiver or additional assistance. Being dishonest on your application can lead to
									your application not being considered for admission.
								</p>
							</>
						)}
					</>
				)}
			</>
		);
	};

	renderEthnicityModal = () => {
		const { navigate, app, location } = this.props,
			{ show_ethnicity_modal } = this.state;

		return (
			<Modal show={show_ethnicity_modal}>
				<Modal.Header>
					<Modal.Title className='h2'>Race/Ethnicity Question</Modal.Title>
				</Modal.Header>
				<Modal.Body className={'modalMinHeight'}>
					<form id={'addNameForm'}>
						<p>
							You have not responded to the racial/ethnic heritage question but are strongly encouraged
							to. Your response to this question will help the Universities of Wisconsin meet federal
							reporting requirements.
						</p>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<Button
						bsStyle='info'
						className='back-btn'
						fill
						onClick={() => this.setState({ show_ethnicity_modal: false })}>
						Respond to Question
					</Button>
					<Button
						bsStyle='info'
						className='save-btn'
						fill
						onClick={() => navigate(nextAppPage(app, location.pathname))}>
						Close and Accept
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	render() {
		const { navigate, app, location } = this.props,
			{ heritage_hispanic } = app.personal_information,
			_valid = this.isValidForm();

		return (
			<Card
				title={'ADDITIONAL INFORMATION'}
				content={
					<form
						id='form'
						onSubmit={e => {
							e.preventDefault();
							e.stopPropagation();

							this.setState({ show_ethnicity_modal: !heritage_hispanic });
							if (heritage_hispanic) navigate(nextAppPage(app, location.pathname));
						}}>
						{this.renderPage()}
						{this.renderEthnicityModal()}

						<div className='clearfix' />
					</form>
				}
				buttons={
					<>
						<Button
							aria-label='Back'
							bsStyle='info'
							className='back-btn'
							fill
							onClick={() => navigate(prevAppPage(app, location.pathname))}>
							Back
						</Button>
						<Button
							form='form'
							aria-label='Save and Continue'
							bsStyle='info'
							className='save-btn'
							fill
							disabled={!_valid}
							type={'submit'}>
							Save and Continue
						</Button>
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => {
		const { app_id, apps } = state.user;

		return {
			app: apps[app_id].json_obj,
			app_id: app_id,
			waivers: state.global.waivers.map(rec => ({ val: rec.id, label: rec.title }))
		};
	},
	mapDispatchToProps = dispatch => ({
		updateApp: obj => dispatch(UserActions.updateApp(obj)),
		saveApp: val => dispatch(UserActions.saveApp({ personalInformationAdditionalCompleted: val })),
		setSubmenu: () => dispatch(UserActions.setSubmenus('personal_info_open', true))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PersonalInformationAdditional));
