import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Row, Table } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { Tooltip } from 'react-tooltip';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-tooltip/dist/react-tooltip.css';

import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import { countries, states } from 'components/modules/opts';
import { UserActions } from 'reducers/user';
import { cloneDeep } from 'components/modules/_misc';
import { DEFAULT } from 'components/modules/app';
import { nextAppPage, prevAppPage } from 'components/modules/nav';
import _svg from 'components/modules/svg';
import { REGEXES } from 'components/modules/validation';
import PhoneControl from 'components/controls/phone';
import RadioList from 'components/controls/radio_list';
import Select from 'components/controls/select';
import Textbox from 'components/controls/textbox';
import ZipInput from 'components/controls/zip';

class ParentInfo extends Component {
	constructor(props) {
		super(props);

		this.state = {
			_index: -1,
			current_parent: null,
			valid_phone: false,
			skipped: false
		};

		this._mounted = true;
		this.applicant_label = props.app.application_modifier === 'DA' ? 'Student' : 'Applicant';
	}

	componentDidUpdate(prevProps, prevState) {
		let { app, navigate, location } = this.props,
			{ skipped } = this.state,
			{ residency_status: status } = app.residency,
			{ residency_status: prev_status } = prevProps.app.residency;

		/* tms:  this change is done in onSubmit;
			wait for the change to take before navigating so the app can accurately read the next page
		*/
		if (this._mounted && status === 'U' && prev_status === 'Y_PARENT_CHECK') this.setState({ skipped: true });

		if (skipped && !prevState.skipped) navigate(nextAppPage(app, location.pathname));
	}

	componentWillUnmount() {
		this._mounted = false;

		let { saveApp, app, updateApp } = this.props,
			{ skipped, _index, valid_phone } = this.state,
			parent_info = cloneDeep(app.parent_info),
			{ receive_mail_at_permanent_address } = app.contact_information,
			arg = {};

		if (_index > -1 && !valid_phone) parent_info.parents[_index].parent_phone = '';

		parent_info.parents.forEach(parent => {
			if (!(parent.same_current_address === 'Yes' && receive_mail_at_permanent_address === 'No')) {
				parent.which_address = '';
			}
		});

		updateApp({ parent_info: parent_info });

		if (skipped || app.parent_info.parents.length) arg.parentInfoCompleted = true;

		if (app.residency.residency_status === 'U' && !app.residency.provide_parent_info) {
			arg.residencyCompleted = false;
		}

		saveApp(arg);
	}

	isValidForm = () => {
		let { app } = this.props,
			{ current_parent, valid_phone } = this.state,
			{ receive_mail_at_permanent_address } = app.contact_information;

		if (current_parent) {
			const {
					first_name,
					last_name,
					relationship_to_applicant,
					same_current_address,
					which_address,
					current_address,
					email_address
				} = current_parent,
				{ address_1, country, other_country, state, city, zip } = current_address,
				complete_address =
					!!address_1 &&
					!!country &&
					!(country === 'USA' && !state) &&
					!(country === 'Other' && !other_country) &&
					!!city &&
					!(['USA', 'CAN'].includes(country) && !zip);

			return (
				!!first_name &&
				!!last_name &&
				!!relationship_to_applicant &&
				valid_phone &&
				!!same_current_address &&
				!(same_current_address === 'Yes' && receive_mail_at_permanent_address === 'No' && !which_address) &&
				!(same_current_address === 'Yes' && receive_mail_at_permanent_address !== 'No' && !complete_address) &&
				(!email_address || REGEXES.email.test(email_address))
			);
		}

		return true;
	};

	onEditParent = i => {
		const { parents } = this.props.app.parent_info,
			{ parent_phone } = parents[i];

		this.setState({
			current_parent: cloneDeep(parents[i]),
			_index: i,
			valid_phone: parent_phone?.length > 5
		});
	};

	onAddParent = () => {
		this.setState({ current_parent: cloneDeep(DEFAULT.PARENT_INFO), _index: -1 });
	};

	onDeleteParent = index => {
		let { app, updateApp } = this.props;

		if (!app.date_submitted) {
			confirmAlert({
				title: 'Delete Entry',
				message: 'Are you sure you want to delete this?',
				buttons: [
					{
						label: 'Yes',
						onClick: () => {
							let parent_info = cloneDeep(app.parent_info);
							parent_info.parents.splice(index, 1);
							updateApp({ parent_info: parent_info });
						}
					},
					{
						label: 'No'
					}
				]
			});
		}
	};

	onSaveParent = e => {
		let { app, updateApp } = this.props,
			{ current_parent, _index } = this.state,
			parent_info = cloneDeep(app.parent_info);

		e.preventDefault();
		e.stopPropagation();

		if (_index < 0) {
			parent_info.parents.push(current_parent);
		} else {
			parent_info.parents[_index] = current_parent;
		}

		updateApp({ parent_info: parent_info });
		this.setState({ current_parent: null, _index: -1, valid_phone: false });
	};

	onSubmit = e => {
		e.preventDefault();
		e.stopPropagation();

		let { app, updateApp, navigate, location } = this.props,
			residency = cloneDeep(app.residency),
			undo_parent_res =
				residency.intend_claim_residency === 'Yes' && residency.residency_status === 'Y_PARENT_CHECK';

		if (app.parent_info.parents.length) {
			residency.relationship_unable_to_provide = false;
			updateApp({ residency: residency });

			navigate(nextAppPage(app, location.pathname));
		} else {
			if (undo_parent_res) {
				residency.residency_status = 'U';
				residency.provide_parent_info = '';
				updateApp({ residency: residency });
			}

			this.setState({ skipped: true });
		}
	};

	onChangeParent = (prop, val, phone_status) => {
		let { app } = this.props,
			current_parent = cloneDeep(this.state.current_parent),
			{ receive_mail_at_permanent_address, mailing_address } = app.contact_information;

		if (!app.date_submitted) {
			if (prop === 'parent_phone') this.setState({ valid_phone: phone_status === 'valid' });

			current_parent[prop] = val;

			if (prop === 'same_current_address' && val === 'Yes' && !current_parent.which_address) {
				current_parent.which_address = app.contact_information.permanent_address.formatted_address;
			}

			if (current_parent.same_current_address === 'Yes') {
				const str =
					receive_mail_at_permanent_address === 'No' &&
					mailing_address.formatted_address === current_parent.which_address
						? 'mailing_address' //Have diff mailing? Copy from permanent_address or mailing_address
						: 'permanent_address';

				current_parent.current_address = {
					...current_parent.current_address,
					address_1: app.contact_information[str].address_1,
					address_2: app.contact_information[str].address_2,
					city: app.contact_information[str].city,
					state: app.contact_information[str].state,
					other_state: app.contact_information[str].other_state,
					county: app.contact_information[str].county,
					zip: app.contact_information[str].zip,
					country: app.contact_information[str].country,
					other_country: app.contact_information[str].other_country,
					formatted_address: app.contact_information[str].formatted_address
				};
			}

			this.setState({ current_parent: current_parent });
		}
	};

	onChangeAddress = (prop, val) => {
		let { app } = this.props,
			current_parent = cloneDeep(this.state.current_parent);

		if (!app.date_submitted) {
			if (prop === 'zip' && current_parent.current_address.country === 'CAN') val = val.toUpperCase();

			current_parent.current_address[prop] = val;

			this.setState({ current_parent: current_parent });
		}
	};

	renderParents = () => {
		let { app } = this.props,
			parentRows = (app.parent_info.parents || []).map((parent, i) => (
				<tr key={`parent_${i}`}>
					<td data-label='First Name'>{parent.first_name}</td>
					<td data-label='Last Name'>{parent.last_name}</td>
					<td data-label='Relationship'>{parent.relationship_to_applicant}</td>
					<td data-label='Current Address'>{parent.current_address.address_1}</td>
					<td data-label='Actions'>
						<Button bsStyle='info' onClick={() => this.onEditParent(i)} className='editBtn btn-xs'>
							<img
								alt='editIcon'
								className={'imgDashboard'}
								height={'18px'}
								width={'18px'}
								src={_svg.Edit}
							/>
							<p className={'pDashboard'}>Edit</p>
						</Button>

						<Button bsStyle='info' onClick={() => this.onDeleteParent(i)} className='deleteBtn btn-xs'>
							<img
								alt='deleteIcon'
								className={'imgDashboard'}
								height={'18px'}
								width={'18px'}
								src={_svg.Delete}
							/>
							<p className={'pDashboard'}>Delete</p>
						</Button>
					</td>
				</tr>
			));

		if (!parentRows.length) {
			parentRows.push(
				<tr key={'none'}>
					<td colSpan={5}>You have no parents added.</td>
				</tr>
			);
		}

		if (parentRows.length < 4) {
			parentRows.push(
				<tr key={'add'} className={'addColumn'}>
					<td colSpan={4}></td>
					<td data-label='Actions'>
						<Button bsStyle='info' onClick={this.onAddParent} className='editBtn btn-xs'>
							<img
								alt='editIcon'
								className={'imgDashboard'}
								height={'18px'}
								width={'18px'}
								src={_svg.GreenCirclePlus}
							/>
							<p className={'pDashboard'}>Add Parent/Guardian</p>
						</Button>
					</td>
				</tr>
			);
		}

		return parentRows;
	};

	renderEditForm = () => {
		const { app } = this.props,
			{ current_parent } = this.state,
			{
				first_name,
				last_name,
				relationship_to_applicant,
				parent_phone,
				same_current_address,
				which_address,
				current_address,
				email_address
			} = current_parent,
			{ address_1, address_2, country, other_country, state, city, zip, other_state } = current_address,
			{ receive_mail_at_permanent_address, permanent_address, mailing_address } = app.contact_information,
			{ formatted_address: perm_addr } = permanent_address,
			{ formatted_address: mail_addr } = mailing_address;

		return (
			<>
				<Row>
					<Textbox
						name='first_name'
						value={first_name}
						label='First Name'
						required={true}
						cols={6}
						maxLength={75}
						onChange={val => this.onChangeParent('first_name', val)}
					/>
					<Textbox
						name='last_name'
						value={last_name}
						label='Last Name'
						required={true}
						cols={6}
						maxLength={75}
						onChange={val => this.onChangeParent('last_name', val)}
					/>
				</Row>
				<Row>
					<Select
						name='relationship_to_applicant'
						value={relationship_to_applicant}
						label={`Relationship to ${this.applicant_label}`}
						placeholder='Select Relationship'
						opts={['Mother', 'Father', 'Stepmother', 'Stepfather', 'Legal Guardian/Caretaker', 'Other']}
						required={true}
						cols={6}
						onChange={val => this.onChangeParent('relationship_to_applicant', val)}
						tooltip={
							<a id='relationshipTooltip'>
								<img
									className='informationTooltip'
									src={require('assets/img/Information.png')}
									alt='Information'
								/>
							</a>
						}
					/>

					<PhoneControl
						name='parent_phone'
						value={parent_phone}
						label='Phone Number'
						required={true}
						cols={6}
						onChange={(val, status) => this.onChangeParent('parent_phone', val, status)}
					/>
				</Row>

				<Tooltip
					anchorId='relationshipTooltip'
					className='tooltipContainer'
					delayHide={1000}
					effect='solid'
					content={
						<>
							<ul>
								<li>
									<strong>Parent (Mother or Father)</strong>: The student's biological or adoptive
									parent.
								</li>
								<li>
									<strong>Stepparent (Stepmother or Stepfather)</strong>: The legal spouse of the
									student's biological or adoptive parent. A stepparent must be currently married to
									the student's parent.
								</li>
								<li>
									<strong>Legal Guardian/Caretaker</strong>: A legal guardian is a person designated
									by the court to assume care of and make decisions for the student. A caretaker is a
									person designated by an individual (such as the student's parent) or an entity other
									than the court to assume care of and make decisions for the student.
								</li>
								<li>
									<strong>Other</strong>: Students may use the “other” category when completing the
									parent information section if they do not have a parent, legal guardian, or
									caretaker whose information they can provide. The “other” category may include
									individuals who do not fit one of the other categories listed above.
								</li>
							</ul>
						</>
					}
				/>

				<h3>
					{first_name && last_name ? `Current Address for ${first_name} ${last_name}` : 'Current Address'}
				</h3>
				<Row>
					<RadioList
						name='same_current_address'
						value={same_current_address}
						label={`Same as ${this.applicant_label.toLowerCase()}?`}
						opts={['Yes', 'No']}
						onChange={val => this.onChangeParent('same_current_address', val)}
						required={true}
						cols={12}
					/>
				</Row>

				{same_current_address === 'Yes' ? (
					receive_mail_at_permanent_address === 'No' && (
						<Row>
							<Select
								name='which_address'
								value={which_address}
								label='Which address?'
								opts={[perm_addr, mail_addr]}
								required={true}
								cols={12}
								onChange={val => this.onChangeParent('which_address', val)}
							/>
						</Row>
					)
				) : (
					<>
						<Row>
							<Textbox
								name='address_1'
								value={address_1}
								label='Address'
								placeholder='Street name and number'
								required={true}
								cols={12}
								maxLength={55}
								onChange={val => this.onChangeAddress('address_1', val)}
							/>
						</Row>
						<Row>
							<Textbox
								name='address_2'
								value={address_2}
								label='Address Line 2'
								placeholder='Apartment, building, or unit #'
								cols={12}
								maxLength={55}
								onChange={val => this.onChangeAddress('address_2', val)}
							/>
						</Row>
						<Row>
							<Select
								name='country'
								value={country}
								label='Country'
								placeholder='Select Country'
								opts={countries}
								required={true}
								cols={6}
								onChange={val => this.onChangeAddress('country', val)}
							/>

							{country === 'USA' && (
								<Select
									name='state'
									value={state}
									label='State'
									placeholder='Select State'
									opts={states}
									required={true}
									cols={6}
									onChange={val => this.onChangeAddress('state', val)}
								/>
							)}

							{country === 'Other' && (
								<Textbox
									name='other_country'
									value={other_country}
									label='Specify Country'
									placeholder='Other Country'
									required={true}
									cols={6}
									maxLength={40}
									onChange={val => this.onChangeAddress('other_country', val)}
								/>
							)}
						</Row>
						<Row>
							<Textbox
								name='city'
								value={city}
								label='City'
								placeholder='City'
								required={true}
								cols={6}
								maxLength={50}
								onChange={val => this.onChangeAddress('city', val)}
							/>
						</Row>
						<Row>
							<ZipInput
								name='zip'
								value={zip}
								label='Zip / Postal Code'
								placeholder='Zip / Postal Code'
								country={country}
								required={['USA', 'CAN'].includes(country)}
								cols={6}
								onChange={val => this.onChangeAddress('zip', val)}
							/>
							<Textbox
								name='other_state'
								value={other_state}
								label='Other State/Province'
								placeholder='Other State'
								cols={6}
								maxLength={30}
								onChange={val => this.onChangeAddress('other_state', val)}
							/>
						</Row>
					</>
				)}

				<Row>
					<Textbox
						type='email'
						name={'email_address'}
						value={email_address}
						onChange={val => this.onChangeParent('email_address', val)}
						label='Email Address'
						placeholder='Email Address'
						maxLength={70}
						isInvalid={!REGEXES.email.test(email_address)}
						cols={6}
					/>
				</Row>
			</>
		);
	};

	renderMainForm = () => {
		const { app } = this.props;

		return (
			<>
				<h4>
					The next section will ask you for information about your parent(s) or guardian(s). Here are some key
					points to know about this section:
				</h4>
				<ul>
					<li className={'transitionLI'}>
						It's optional. You can skip the section using the “Skip Parent Section” button below.
					</li>
					<li className={'transitionLI'}>
						You only need to enter the number of guardians that you have information for.
					</li>
					<li className={'transitionLI'}>It may be used for emergency contact information.</li>
				</ul>

				<hr />

				{!!app.parent_info.parents.length ? (
					<Table striped bordered hover>
						<thead>
							<tr>
								<th scope='col'>
									<b>First Name</b>
								</th>
								<th scope='col'>
									<b>Last Name</b>
								</th>
								<th scope='col'>
									<b>Relationship</b>
								</th>
								<th scope='col'>
									<b>Address</b>
								</th>
								<th scope='col'>
									<b>Actions</b>
								</th>
							</tr>
						</thead>
						<tbody>{this.renderParents()}</tbody>
					</Table>
				) : (
					<Button bsStyle='info' className='save-btn add-content' fill onClick={this.onAddParent}>
						Add Parent/Guardian
					</Button>
				)}
			</>
		);
	};

	render() {
		let { app, navigate, location } = this.props,
			{ current_parent } = this.state,
			btnSubmitText = current_parent ? 'Save Parent/Guardian' : 'Save and Continue',
			_valid = this.isValidForm();

		if (!current_parent && !app.parent_info.parents.length) btnSubmitText = 'Skip Parent Section';

		return (
			<Card
				title='PARENT/GUARDIAN INFORMATION'
				content={
					<form
						id='form'
						onSubmit={e => {
							if (current_parent) {
								this.onSaveParent(e);
							} else {
								this.onSubmit(e);
							}
						}}>
						{current_parent ? this.renderEditForm() : this.renderMainForm()}
						<div className='clearfix' />
					</form>
				}
				buttons={
					<>
						<Button
							bsStyle='info'
							className='back-btn'
							fill
							onClick={() => {
								if (current_parent) {
									this.setState({ current_parent: null, _index: -1 });
								} else {
									navigate(prevAppPage(app, location.pathname));
								}
							}}>
							Back
						</Button>
						<Button form='form' bsStyle='info' className='save-btn' type='submit' fill disabled={!_valid}>
							{btnSubmitText}
						</Button>
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => {
		const { app_id, apps } = state.user;

		return {
			app: apps[app_id].json_obj,
			app_id: app_id
		};
	},
	mapDispatchToProps = dispatch => ({
		updateApp: obj => dispatch(UserActions.updateApp(obj)),
		saveApp: arg => dispatch(UserActions.saveApp(arg))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ParentInfo));
