import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'components/CustomButton';

const IdleTimeOutModal = ({ showModal, onClose, onLogout, onLogin, onReset, type }) => {
	if (type === 'timed_out') {
		return (
			<Modal show={showModal} onHide={onClose} className='warning-modal'>
				<Modal.Header closeButton>
					<Modal.Title className='h2'>Session Expired</Modal.Title>
				</Modal.Header>
				<Modal.Body className='modalMinHeight'>
					<p>
						Your session has expired due to inactivity. Please log back in to continue with your
						application.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button bsStyle='info' className='back-btn' fill onClick={onLogin}>
						Login
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	return (
		<Modal className='warning-modal' show={showModal}>
			<Modal.Header>
				<Modal.Title className='h2'>2 Minute Warning</Modal.Title>
			</Modal.Header>
			<Modal.Body className='modalMinHeight'>
				<div className='col-md-1'>
					<img
						className='infoAlertImg'
						src={require('assets/img/exclamation-triangle-solid.png')}
						alt='Alert'
					/>
				</div>
				<div className='col-md-11'>
					<p>Your session will expire in 2 minutes.</p>
					<p>Please select an option below to either continue working or save your current progress.</p>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button bsStyle='info' className='back-btn' fill onClick={onReset}>
					I&apos;m still working
				</Button>
				<Button bsStyle='info' className='save-btn' fill onClick={onLogout}>
					Save and logout
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default IdleTimeOutModal;
