import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControl } from 'react-bootstrap';

export default class Select extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hide_invalid: true
		};
	}

	render() {
		let {
				value,
				opts,
				onChange,
				name,
				placeholder,
				required,
				autoFocus,
				classVals,
				cols,
				centered,
				selectClassVals,
				label,
				hide_optional,
				tooltip
			} = this.props,
			{ hide_invalid } = this.state;

		if (!classVals) classVals = [];
		if (cols) classVals.push(`col-md-${cols}`);
		if (centered) classVals.push('col-centered');

		opts = opts.map(opt => {
			return typeof opt === 'string' ? { val: opt, label: opt } : opt;
		});

		if (placeholder && !opts.find(opt => !opt.val)) opts.unshift({ val: '', label: placeholder });

		if (required && !value && !hide_invalid) selectClassVals = 'invalid';

		return (
			<div className={classVals.join(' ')}>
				<FormGroup aria-label={opts[0].label}>
					{!!label && (
						<label htmlFor={name}>
							{label}
							{!(required || hide_optional || !onChange) && <small>(Optional)</small>}
							{tooltip}
						</label>
					)}

					<FormControl
						componentClass='select'
						name={name}
						value={value}
						required={!!required}
						onChange={e => onChange(e.target.value)}
						className={selectClassVals || ''}
						autoFocus={!!autoFocus}
						onBlur={() => this.setState({ hide_invalid: false })}>
						{opts.map((opt, i) => (
							<option key={i} value={opt.val} disabled={opt.disabled}>
								{opt.label}
							</option>
						))}
					</FormControl>
				</FormGroup>
			</div>
		);
	}
}

Select.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	opts: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	autoFocus: PropTypes.bool,
	classVals: PropTypes.array,
	cols: PropTypes.number,
	centered: PropTypes.bool,
	selectClassVals: PropTypes.string,
	hide_optional: PropTypes.bool,
	tooltip: PropTypes.object
};
