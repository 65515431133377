export const CAMPUS_CODES = {
		EAU: ['EAU_BRN'],
		GBY: ['MAN', 'MNT', 'SHB'],
		LAC: [],
		MSN: [],
		MIL: ['WSH', 'WAK'],
		OSH: ['FDL', 'FOX'],
		PKS: [], // TODO: we have programs belonging to XPK
		PLT: ['BRB', 'RLN'],
		RVF: [],
		STP: ['MSF', 'MTH'],
		STO: [],
		SUP: [],
		WTW: ['RCK']
	},
	CAMPUS_TITLES = {
		EAU: 'UW-Eau Claire',
		EAU_BRN: 'UW-Eau Claire - Barron County',
		GBY: 'UW-Green Bay',
		MAN: 'UW-Green Bay - Manitowoc Campus',
		MNT: 'UW-Green Bay - Marinette Campus',
		SHB: 'UW-Green Bay - Sheboygan Campus',
		LAC: 'UW-La Crosse',
		MSN: 'UW-Madison',
		MIL: 'UW-Milwaukee',
		WSH: 'UW-Milwaukee at Washington County',
		WAK: 'UW-Milwaukee at Waukesha',
		OSH: 'UW-Oshkosh',
		FDL: 'UW-Oshkosh, Fond du Lac Campus',
		FOX: 'UW-Oshkosh, Fox Cities Campus',
		PKS: 'UW-Parkside',
		XPK: 'UW Flexible Option',
		PLT: 'UW-Platteville',
		BRB: 'UW-Platteville Baraboo Sauk County',
		RLN: 'UW-Platteville Richland',
		RVF: 'UW-River Falls',
		STP: 'UW-Stevens Point',
		MSF: 'UW-Stevens Point at Marshfield',
		MTH: 'UW-Stevens Point at Wausau',
		STO: 'UW-Stout',
		SUP: 'UW-Superior',
		WTW: 'UW-Whitewater',
		RCK: 'UW-Whitewater at Rock County'
	},
	DIRECT_ADMIT_CAMPUS_TIERS = [
		['GBY', 'MAN', 'SHB', 'BRB', 'MSF', 'MTH', 'STO', 'RCK'],
		['MIL', 'STP', 'SUP'],
		['PKS', 'PLT', 'RVF', 'WTW'],
		['OSH']
	],
	STATIC_APP_FEES = {
		codes: ['EAU', 'EAU_BRN', 'LAC', 'MSN'],
		titles: ['UW-Eau Claire', 'UW-Eau Claire - Barron County', 'UW-La Crosse', 'UW-Madison']
	},
	DEFUNCT_CAMPUS_CODES = ['RLN', 'FDL', 'WSH'],
	getParentCampus = campus_title => {
		const code = Object.keys(CAMPUS_TITLES).find(key => CAMPUS_TITLES[key] === campus_title),
			parent_code = Object.keys(CAMPUS_CODES).find(key => key === code || CAMPUS_CODES[key].includes(code));

		return CAMPUS_TITLES[parent_code];
	},
	blockByRegion = campus_id => {
		const singleSelectionParentCampuses = ['42288', '42294', '42297', '42301']; // GBY, MIL, OSH, PLT

		return singleSelectionParentCampuses.includes(campus_id);
	},
	getCampusProgramsPage = campus_code => {
		const region_code = Object.keys(CAMPUS_CODES).find(key => CAMPUS_CODES[key].includes(campus_code)),
			links = {
				EAU: 'https://www.uwec.edu/academics/majors-minors/',
				GBY: 'https://www.uwgb.edu/majors-minors/',
				LAC: 'https://www.uwlax.edu/info/academics/majors-and-minors/',
				MSN: 'https://www.wisc.edu/academics/',
				MIL: 'https://uwm.edu/academics/?fwp_per_page=24',
				OSH: 'https://uwosh.edu/academics/majors-minors-emphases/',
				PKS: 'https://www.uwp.edu/learn/programs/',
				PLT: 'https://www.uwplatt.edu/academics',
				RVF: 'https://www.uwrf.edu/Academics/Undergraduate/',
				STP: 'https://www.uwsp.edu/admissions/Pages/majors-and-minors.aspx',
				STO: 'https://www.uwstout.edu/programs',
				SUP: 'https://www.uwsuper.edu/admissions/majors-minors/index.cfm',
				WTW: 'https://www.uww.edu/academics/departments-and-majors'
			};

		return links[campus_code] || links[region_code];
	};
