import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Table, Row } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import { FILE_UPLOAD, cloneDeep } from 'components/modules/_misc';
import { DEFAULT } from 'components/modules/app';
import { nextAppPage, prevAppPage } from 'components/modules/nav';
import _svg from 'components/modules/svg';
import { GlobalActions } from 'reducers/global';
import { UserActions } from 'reducers/user';
import FileUpload from 'components/controls/file_upload';
import RadioList from 'components/controls/radio_list';
import Select from 'components/controls/select';
import Textbox from 'components/controls/textbox';
import { create_proactive } from 'components/modules/ada';
import API from 'components/api';

class AcademicBackgroundHigherEducationCourses extends Component {
	constructor(props) {
		super(props);

		this.state = {
			index: -1,
			course: null,
			upload_error: '',
			uploading_files: false
		};

		this.orig_has_files = !!props.uploaded_files.length;
	}

	componentDidMount() {
		this._mounted = true;

		let { app, setSubmenu, updateApp } = this.props,
			academic_background = cloneDeep(app.academic_background);

		setSubmenu();

		academic_background.college_show_courses_option = academic_background.college_show_courses_option || '';
		delete academic_background.college_course_provide_format;
		updateApp({ academic_background: academic_background });

		setTimeout(() => {
			if (this._mounted) {
				create_proactive(
					'You have the option to upload an unofficial college transcript. Please note the app only accepts PDFs. Campuses will let you know if they need an official college transcript from you to make an admissions decision.',
					'5ebc1eee326dc3afcd336d33'
				);
			}
		}, 2000);
	}

	componentWillUnmount() {
		this._mounted = false;

		const { saveApp, app, updateApp } = this.props,
			academic_background = cloneDeep(app.academic_background),
			_valid = this.isValidForm();

		if (academic_background.college_show_courses_option === 'Upload file') {
			academic_background.college_courses_in_progress = [];
		}

		if (academic_background.college_show_courses_option === 'Manually Enter') {
			academic_background.college_course_use_previous_file = '';
			academic_background.college_course_schedule_file = '';
			academic_background.college_course_schedule_file_name = '';
		}

		updateApp({ academic_background: academic_background });
		saveApp(_valid);
	}

	getAPI = () => {
		const { support_student_id, campus_admin } = this.props;
		return new API('', support_student_id, campus_admin);
	};

	isValidForm = () => {
		let { app } = this.props,
			{ course } = this.state,
			{ college_show_courses_option, college_courses_in_progress, college_course_schedule_file } =
				app.academic_background;

		if (course) {
			const { course_title, course_number, college_associated, credits } = course;
			return (
				!!course_title &&
				!!course_number &&
				!!college_associated &&
				!!credits &&
				!isNaN(credits) &&
				Number(credits) >= 0 &&
				Number(credits) <= 50
			);
		}

		return (
			!!college_show_courses_option &&
			((college_show_courses_option === 'Manually Enter' && !!college_courses_in_progress.length) ||
				(college_show_courses_option === 'Upload file' && !!college_course_schedule_file))
		);
	};

	onAddCourse = () => {
		this.setState({ course: cloneDeep(DEFAULT.COLLEGE_COURSE), index: -1 });
	};

	onEditCourse = i => {
		const { app } = this.props,
			{ college_courses_in_progress } = app.academic_background;

		this.setState({ index: i, course: cloneDeep(college_courses_in_progress[i]) });
	};

	onDeleteCourse = i => {
		let { app, updateApp } = this.props;

		if (!app.date_submitted) {
			confirmAlert({
				title: 'Remove Entry',
				message: 'Are you sure you want to remove this?',
				buttons: [
					{
						label: 'Yes',
						onClick: () => {
							let academic_background = cloneDeep(app.academic_background);
							academic_background.college_courses_in_progress.splice(i, 1);
							updateApp({ academic_background: academic_background });
						}
					},
					{
						label: 'No'
					}
				]
			});
		}
	};

	onSaveCourse = e => {
		e.preventDefault();
		e.stopPropagation();

		let { app, updateApp } = this.props,
			{ course, index } = this.state,
			academic_background = cloneDeep(app.academic_background);

		if (!app.date_submitted) {
			if (index < 0) {
				academic_background.college_courses_in_progress.push(course);
			} else {
				academic_background.college_courses_in_progress[index] = course;
			}

			updateApp({ academic_background: academic_background });
			this.setState({ course: null, index: -1 });
		}
	};

	clearUploadedFile = e => {
		let { app, updateApp, unsetScreensCompleted } = this.props,
			academic_background = cloneDeep(app.academic_background);

		if (!app.date_submitted) {
			confirmAlert({
				title: 'Remove File',
				message: 'Are you sure you want to remove this file from your application?',
				buttons: [
					{
						label: 'Yes',
						onClick: () => {
							academic_background.college_course_schedule_file = '';
							academic_background.college_course_schedule_file_name = '';

							updateApp({ academic_background: academic_background });
							unsetScreensCompleted();
						}
					},
					{
						label: 'No'
					}
				]
			});
		}
	};

	onUploadSchedule = file => {
		const { app, app_id, updateApp, addDocument, captureError } = this.props,
			academic_background = cloneDeep(app.academic_background);

		if (!app.date_submitted) {
			const { name, size } = file;

			if (size > FILE_UPLOAD.min_file_size) {
				this.setState({ uploading_files: true });

				this.getAPI()
					.saveFile(file, app_id, 'CollegeTranscript')
					.then(response => {
						this.setState({ uploading_files: false });

						if (response.data.includes('UPLOAD FAILED')) {
							const err_msg = response.data.replace('UPLOAD FAILED:', '').trim();
							this.setState({ upload_error: `Upload failed: ${name} (${err_msg})` });

							academic_background.college_course_schedule_file = '';
							academic_background.college_course_schedule_file_name = '';
						} else {
							academic_background.college_course_schedule_file = response.data;
							academic_background.college_course_schedule_file_name = name;

							addDocument(name, response.data);
							this.setState({ upload_error: '' });
						}

						updateApp({ academic_background: academic_background });
					})
					.catch(ex => captureError(ex));
			} else {
				confirmAlert(FILE_UPLOAD.err_msg);
			}
		}

		updateApp({ academic_background: academic_background });
	};

	onChange = (val, prop) => {
		const { app, updateApp } = this.props,
			academic_background = cloneDeep(app.academic_background);

		if (!app.date_submitted) {
			academic_background[prop] = val;

			if (prop === 'college_course_use_previous_file' && val === 'No') {
				academic_background.college_course_schedule_file = '';
				academic_background.college_course_schedule_file_name = '';
			}

			updateApp({ academic_background: academic_background });
		}
	};

	onChangeCourse = (val, prop) => {
		let { app } = this.props,
			course = cloneDeep(this.state.course);

		if (!app.date_submitted) {
			course[prop] = val;
			this.setState({ course: course });
		}
	};

	renderBtnDownload = (key, file_name) => {
		const { uploaded_files, captureError } = this.props;

		if (!file_name) {
			const uploaded_file = uploaded_files.find(file => file.key === key);
			file_name = uploaded_file ? uploaded_file.file_name : `${key}.pdf`;
		}

		return (
			<Button
				bsStyle='info'
				aria-label='Download uploaded transcript'
				onClick={() => {
					this.getAPI()
						.downloadDocument(key, file_name)
						.catch(ex => {
							captureError(ex);

							confirmAlert({
								title: "Hmm, that didn't work",
								message:
									'Your transcript could not be downloaded.  Please try again and let us know if the problem continues.',
								buttons: [{ label: 'OK' }]
							});
						});
				}}
				className='btn-download'>
				<img alt='downloadIcon' className='imgDashboard' height='18px' width='18px' src={_svg.Download} />
				<p className='pDashboard'>Download</p>
			</Button>
		);
	};

	renderFileUploadBlock = () => {
		let { app, uploaded_files } = this.props,
			{ uploading_files, upload_error } = this.state,
			{ college_course_use_previous_file, college_course_schedule_file, college_course_schedule_file_name } =
				app.academic_background,
			showUploadControl =
				college_course_use_previous_file === 'No' ||
				!this.orig_has_files ||
				(!!college_course_schedule_file && !college_course_use_previous_file),
			already_uploaded_file;

		if (upload_error) {
			already_uploaded_file = <p className='uwRedText'>{upload_error}</p>;
		} else if (college_course_schedule_file_name) {
			already_uploaded_file = (
				<p>
					<strong>File Successfully Uploaded: </strong>
					{college_course_schedule_file_name}
					<Button bsStyle='info' onClick={this.clearUploadedFile} className='deleteBtn deleteUploadedFile'>
						<img alt='deleteIcon' className='imgDashboard' height='18px' width='18px' src={_svg.Delete} />
						<p className='pDashboard'>Remove</p>
					</Button>
					{this.renderBtnDownload(college_course_schedule_file, college_course_schedule_file_name)}
				</p>
			);
		}

		return (
			<>
				{(!!college_course_use_previous_file || (this.orig_has_files && !college_course_schedule_file)) && (
					<Row>
						<RadioList
							name='college_course_use_previous_file'
							value={college_course_use_previous_file}
							onChange={val => this.onChange(val, 'college_course_use_previous_file')}
							opts={['Yes', 'No']}
							label='Would you like to use a previous file?'
							cols={12}
							required={true}
						/>
					</Row>
				)}

				{college_course_use_previous_file === 'Yes' && (
					<Row>
						<Select
							value={college_course_schedule_file}
							opts={uploaded_files.map(file => ({ val: file.key, label: file.file_name }))}
							onChange={val => this.onChange(val, 'college_course_schedule_file')}
							name='college_course_schedule_file'
							label='Use previous file:'
							placeholder='Select File'
							required={true}
							cols={6}
						/>

						{college_course_schedule_file && (
							<div className='col-md-6 prev-download'>
								{this.renderBtnDownload(college_course_schedule_file)}
							</div>
						)}
					</Row>
				)}

				{showUploadControl && (
					<>
						{(!college_course_schedule_file_name || !!upload_error) && (
							<Row>
								<FileUpload
									name='college_course_schedule_file'
									displayName='schedule'
									onChange={file => this.onUploadSchedule(file)}
									cols={12}
									required={true}
								/>
							</Row>
						)}

						{uploading_files && (
							<p>
								<strong>Uploading...</strong>
							</p>
						)}

						{!!(college_course_schedule_file_name || upload_error) && already_uploaded_file}
					</>
				)}
			</>
		);
	};

	renderCoursesTable() {
		const { app } = this.props,
			{ college_courses_in_progress } = app.academic_background;

		let object_rows = college_courses_in_progress.length
			? college_courses_in_progress.map((_course, i) => {
					return (
						<tr key={`course-${i}`}>
							<td data-label='College Course Title'>{_course.course_title}</td>
							<td data-label='College Course Number'>{_course.course_number}</td>
							<td data-label='Number of College Credits'>{_course.credits}</td>
							<td data-label='College'>{_course.college_associated}</td>
							<td data-label='Actions'>
								<Button bsStyle='info' onClick={() => this.onEditCourse(i)} className='editBtn btn-xs'>
									<img
										alt='editIcon'
										className='imgDashboard'
										height='18px'
										width='18px'
										src={_svg.Edit}
									/>
									<p className='pDashboard'>Edit</p>
								</Button>

								<Button
									bsStyle='info'
									onClick={() => this.onDeleteCourse(i)}
									className='deleteBtn btn-xs'>
									<img
										alt='deleteIcon'
										className='imgDashboard'
										height='18px'
										width='18px'
										src={_svg.Delete}
									/>
									<p className='pDashboard'>Remove</p>
								</Button>
							</td>
						</tr>
					);
			  })
			: [
					<tr key='none'>
						<td colSpan={4}>You have no courses added.</td>
					</tr>
			  ];

		object_rows.push(
			<tr key='add' className='addColumn'>
				<td colSpan={4}></td>
				<td data-label='Actions'>
					<Button bsStyle='info' onClick={() => this.onAddCourse()} className='editBtn btn-xs'>
						<img
							alt='editIcon'
							className='imgDashboard'
							height='18px'
							width='18px'
							src={_svg.GreenCirclePlus}
						/>
						<p className='pDashboard'>Add Course</p>
					</Button>
				</td>
			</tr>
		);

		return object_rows;
	}

	renderMainPage = () => {
		const { app } = this.props,
			{ college_show_courses_option, college_courses_in_progress } = app.academic_background;

		return (
			<>
				<p>Please list or upload all college courses you are taking this academic year.</p>

				<a id='tooltip'>
					How do I get my in-progress college courses?{' '}
					<img className='informationTooltip' src={require('assets/img/Information.png')} alt='Information' />
				</a>
				<Tooltip
					anchorId='tooltip'
					className='tooltipContainer'
					delayHide={1000}
					effect='solid'
					content={
						<p>
							You can either upload a college transcript with your in-progress courses or provide a pdf
							listing each course including its course name, course number, credits, and college course
							will be taken at.
						</p>
					}
				/>

				<Row>
					<RadioList
						name='college_show_courses_option'
						value={college_show_courses_option}
						onChange={val => this.onChange(val, 'college_show_courses_option')}
						opts={['Manually Enter', 'Upload file']}
						label='How do you wish to provide your college courses?'
						cols={12}
						required={true}
					/>
				</Row>

				{college_show_courses_option === 'Upload file' && this.renderFileUploadBlock()}

				{college_show_courses_option === 'Manually Enter' &&
					(college_courses_in_progress.length ? (
						<>
							<hr />
							<Table striped bordered hover>
								<thead>
									<tr>
										<th scope='col'>
											<strong>College Course Title</strong>
										</th>
										<th scope='col'>
											<strong>College Course Number</strong>
										</th>
										<th scope='col'>
											<strong>Number of College Credits</strong>
										</th>
										<th scope='col'>
											<strong>College</strong>
										</th>
										<th scope='col'>
											<strong>Actions</strong>
										</th>
									</tr>
								</thead>
								<tbody>{this.renderCoursesTable()}</tbody>
							</Table>
						</>
					) : (
						<Button bsStyle='info' className='save-btn add-content' fill onClick={() => this.onAddCourse()}>
							Add Course
						</Button>
					))}
			</>
		);
	};

	renderEditCourseForm = () => {
		const { app } = this.props,
			{ course } = this.state,
			{ colleges } = app.academic_background,
			{ course_title, course_number, college_associated, credits } = course;

		return (
			<>
				<Row>
					<Textbox
						name='course_title'
						value={course_title}
						onChange={val => this.onChangeCourse(val, 'course_title')}
						label='Course Title'
						placeholder='Example: General Psychology'
						required={true}
						maxLength={80}
						cols={7}
					/>

					<Textbox
						name='course_number'
						value={course_number}
						onChange={val => this.onChangeCourse(val, 'course_number')}
						label='Course Number'
						placeholder='Example: Psychology 101'
						required={true}
						maxLength={50}
						cols={5}
					/>
				</Row>
				<Row>
					<Select
						name='college_associated'
						value={college_associated}
						onChange={val => this.onChangeCourse(val, 'college_associated')}
						opts={colleges.map(c => c.selected_college.title)}
						label='College'
						placeholder='Select College'
						required={true}
						cols={7}
					/>

					<Textbox
						type='number'
						value={credits}
						onChange={val => this.onChangeCourse(val, 'credits')}
						name='credits'
						label='Credits'
						placeholder='Example:  3'
						required={true}
						step={0.01}
						min={0}
						max={50}
						cols={5}
					/>
				</Row>
			</>
		);
	};

	renderBtnBack = () => {
		const { app, navigate, location } = this.props,
			{ uploading_files, course } = this.state;

		return course ? (
			<Button bsStyle='info' className='back-btn' fill onClick={() => this.setState({ course: null, index: -1 })}>
				Back
			</Button>
		) : (
			<Button
				bsStyle='info'
				className='back-btn'
				fill
				onClick={() => navigate(prevAppPage(app, location.pathname))}
				disabled={uploading_files}>
				Back
			</Button>
		);
	};

	renderBtnSubmit = () => {
		const { uploading_files, course } = this.state,
			_valid = this.isValidForm();

		return course ? (
			<Button bsStyle='info' className='save-btn' fill disabled={!_valid} onClick={this.onSaveCourse}>
				Save Course
			</Button>
		) : (
			<Button
				form='form'
				type='submit'
				bsStyle='info'
				className='save-btn'
				fill
				disabled={uploading_files || !_valid}>
				Save and Continue
			</Button>
		);
	};

	render() {
		const { app, navigate, location } = this.props,
			{ course } = this.state;

		return (
			<Card
				title='Academic Background: College/Post-Secondary Courses'
				content={
					<form id='form' onSubmit={() => navigate(nextAppPage(app, location.pathname))}>
						{course ? this.renderEditCourseForm() : this.renderMainPage()}
						<div className='clearfix' />
					</form>
				}
				buttons={
					<>
						{this.renderBtnBack()}
						{this.renderBtnSubmit()}
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => {
		const { app_id, apps, documents, support_student_id, campus_admin } = state.user;

		return {
			app: apps[app_id].json_obj,
			app_id: app_id,
			uploaded_files: documents.filter(file => file.document_type === 'CollegeTranscript'),
			support_student_id: support_student_id,
			campus_admin: campus_admin
		};
	},
	mapDispatchToProps = dispatch => ({
		updateApp: obj => dispatch(UserActions.updateApp(obj)),
		saveApp: val => dispatch(UserActions.saveApp({ academicBackgroundCollegeCoursesCompleted: val })),
		setSubmenu: () => dispatch(UserActions.setSubmenus('academic_background_open', true)),
		unsetScreensCompleted: () =>
			dispatch(UserActions.setScreensCompleted({ academicBackgroundCollegeCoursesCompleted: false })),
		addDocument: (file_name, key) => dispatch(UserActions.addDocument('CollegeTranscript', file_name, key)),
		captureError: err => dispatch(GlobalActions.captureError(err))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AcademicBackgroundHigherEducationCourses));
