import { createActions, createReducer } from 'reduxsauce';

import { SENTRY, cloneDeep } from 'components/modules/_misc';
import { setTimezoneDiff } from 'components/modules/date';
import { combine_majors } from 'components/modules/programs';
import { DEFAULT_STATE, check_loading_fields } from 'components/modules/redux';

const { Types, Creators } = createActions({
		setCurrentPage: ['val'],
		captureError: ['err'],
		onGetTimezoneDiff: ['val'],
		onCheckForStaleData: ['current_page'],
		onGetData: ['str_prop', 'arr'],
		onGetTerms: ['str_prop', 'arr'],
		onGetMajors: ['majors', 'sub_majors'],
		onGetEssayPrompts: ['str_prop', 'arr'],
		onGetPaperAppUrl: ['str']
	}),
	finish_loading_data = (objState, key) => {
		let i = objState.loading_data.indexOf(key);
		if (i > -1) objState.loading_data.splice(i, 1);
	};

const setCurrentPage = (state, action) => {
		let objState = cloneDeep(state);

		if (objState.current_page) {
			objState.prev_page = `${objState.current_page}`;
			SENTRY.set_tag('previous_page', objState.prev_page);
		}

		objState.current_page = action.val;
		objState.loading_data = check_loading_fields(objState);

		return objState;
	},
	captureError = (state, action) => {
		let objState = cloneDeep(state),
			{ err } = action;

		if (err) {
			objState.err = typeof err === 'string' ? new Error(err) : err;
		} else {
			objState.err = null;

			// navigating away from on-error page
			SENTRY.set_tag('triggering_app_id', '');
			SENTRY.set_tag('bypass_code', '');
			SENTRY.set_tag('coupon_code', '');
			SENTRY.set_tag('address_string', '');
		}

		return objState;
	},
	onGetTimezoneDiff = (state, action) => {
		let objState = cloneDeep(state),
			{ val } = action;

		objState.timezone_diff = val;
		setTimezoneDiff(val);
		finish_loading_data(objState, 'timezone_diff');

		return objState;
	},
	onCheckForStaleData = (state, action) => {
		let objState = cloneDeep(state),
			{ current_page } = action,
			{ date_cached } = objState,
			d = new Date(date_cached),
			d_login = new Date(),
			date_string = str => `${str.getMonth()}-${str.getDate()}-${str.getFullYear()}`;

		if (date_string(d) !== date_string(d_login)) {
			localStorage.removeItem('global');

			objState = {
				...DEFAULT_STATE.global,
				date_cached: new Date(),
				timezone_diff: objState.timezone_diff,
				current_page: current_page || '',
				prev_page: objState.current_page
			};

			SENTRY.set_tag('previous_page', objState.prev_page);
		}

		return objState;
	},
	onGetData = (state, action) => {
		let objState = cloneDeep(state),
			{ str_prop, arr } = action;

		// tms: system_notifications might legitimately be an empty array
		if (Array.isArray(arr) && (!!arr.length || str_prop === 'system_notifications')) {
			objState[str_prop] = arr || [];
			finish_loading_data(objState, str_prop);
		}

		return objState;
	},
	onGetMajors = (state, action) => {
		let objState = cloneDeep(state),
			{ majors, sub_majors } = action,
			submajor_key = rec =>
				`${rec.field_campus_id}|${rec.title}|${rec.field_academic_level}|${rec.field_cmc_program_type}|${rec.field_parent_program_id}`,
			unique_submajors = sub_majors.filter(
				(x, i) => sub_majors.findIndex(y => submajor_key(y) === submajor_key(x)) === i
			);

		objState.cdrMajors = combine_majors(majors, unique_submajors);
		finish_loading_data(objState, 'cdrMajors');

		return objState;
	},
	onGetTerms = (state, action) => {
		let objState = cloneDeep(state),
			{ str_prop, arr } = action;

		if (Array.isArray(arr)) objState.terms[str_prop] = arr || [];

		const done_loading_terms = Object.keys(objState.terms).every(key => !!objState.terms[key].length);
		if (done_loading_terms) finish_loading_data(objState, 'terms');

		return objState;
	},
	onGetEssayPrompts = (state, action) => {
		let objState = cloneDeep(state),
			{ str_prop, arr } = action;

		if (Array.isArray(arr)) objState.essay_prompts[str_prop] = arr || [];

		if (!!objState.essay_prompts.global.length && !!objState.essay_prompts.campus_specific.length) {
			finish_loading_data(objState, 'essay_prompts');
		}

		return objState;
	},
	onGetPaperAppUrl = (state, action) => {
		let objState = cloneDeep(state);

		objState.paper_application_url = action.str;

		finish_loading_data(objState, 'paper_application_url');
		return objState;
	};

export default createReducer(DEFAULT_STATE.global, {
	[Types.SET_CURRENT_PAGE]: setCurrentPage,
	[Types.CAPTURE_ERROR]: captureError,
	[Types.ON_GET_TIMEZONE_DIFF]: onGetTimezoneDiff,
	[Types.ON_CHECK_FOR_STALE_DATA]: onCheckForStaleData,
	[Types.ON_GET_DATA]: onGetData,
	[Types.ON_GET_TERMS]: onGetTerms,
	[Types.ON_GET_MAJORS]: onGetMajors,
	[Types.ON_GET_ESSAY_PROMPTS]: onGetEssayPrompts,
	[Types.ON_GET_PAPER_APP_URL]: onGetPaperAppUrl
});

export const GlobalActions = Creators;
