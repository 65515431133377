import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { ControlLabel } from 'react-bootstrap';
import Select from 'components/controls/select';
import { cloneDeep, campusLogos } from 'components/modules/_misc';
import { DEFAULT } from 'components/modules/app';
import { UserActions } from 'reducers/user';

class CampusCard extends Component {
	onChange = val => {
		let { app, updateApp, campuses, setScreensCompleted, creating_additional_app } = this.props,
			application_modifier = `${app.application_modifier}`,
			initial_information = cloneDeep(app.initial_information);

		if (!app.date_submitted) {
			let screens_completed_arg = {};

			initial_information.chosen_campus = campuses.find(c => c.id === val);

			if (!creating_additional_app) {
				initial_information.chosen_semester = { id: '', title: '', global_term: '' };
				initial_information.re_entry_attended_campus = '';
				initial_information.re_entry_attended_other = '';

				screens_completed_arg.selectSemesterCompleted = false;
			}

			screens_completed_arg.selectAreaStudyCompleted = false;
			screens_completed_arg.selectSemesterCompleted = false;
			screens_completed_arg.summaryCompleted = false;

			setScreensCompleted(screens_completed_arg);

			updateApp({
				application_modifier: application_modifier,
				initial_information: initial_information,
				area_of_study: cloneDeep(DEFAULT.APP_JSON.area_of_study)
			});
		}
	};

	render() {
		let { campuses, value, columnWidth, showExceptionModal } = this.props,
			_disabled = campuses.every(c => c.disabled),
			campusBlock =
				campuses.length === 1 ? (
					<div
						onClick={() => {
							if (!campuses[0].disabled) this.onChange(campuses[0].id);
						}}
						className={'col-md-10 col-centered'}>
						<div className={'form-group '}>
							<div
								className={`form-control text-left ${_disabled ? ' disabledCampusCard ' : ''} ${
									campuses[0].id === value ? 'campusFill' : ''
								}`}>
								{campuses[0].title}
							</div>
						</div>
					</div>
				) : (
					<Select
						value={value}
						opts={campuses.map(c => ({ val: c.id, label: c.title, disabled: c.disabled }))}
						onChange={val => this.onChange(val)}
						name={'chosen_campus'}
						placeholder='Select Campus'
						cols={10}
						centered={true}
						selectClassVals={`${campuses.find(x => x.id === value) ? 'campusFill' : ''} ${
							_disabled ? ' disabledCampusCard ' : ''
						}`}
					/>
				);

		return (
			<div className={'campusCard col-md-' + columnWidth}>
				<ControlLabel>
					<div className='img-wrapper'>
						<img
							src={campusLogos[campuses[0].field_abbreviation]}
							width={121}
							alt={campuses[0].title + ' campus logo'}
						/>
					</div>
				</ControlLabel>
				{campuses[0].enabled ? (
					campusBlock
				) : (
					<div onClick={() => showExceptionModal(campuses[0].id)} className={'col-md-10 col-centered'}>
						<p className={'campusExceptionInfo'}>More info</p>
					</div>
				)}
				<div className={'clearfix'}></div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
		app: state.user.temp_app_rec.json_obj,
		creating_additional_app: state.user.creating_additional_app
	}),
	mapDispatchToProps = dispatch => ({
		updateApp: obj => dispatch(UserActions.updateApp(obj)),
		setScreensCompleted: arg => dispatch(UserActions.setScreensCompleted(arg))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampusCard));
