import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Modal, Row } from 'react-bootstrap';
import Masonry from 'react-masonry-component';

import RadioList from 'components/controls/radio_list';
import { cloneDeep } from 'components/modules/_misc';
import { blockByRegion, DEFUNCT_CAMPUS_CODES } from 'components/modules/campus';
import { create_proactive } from 'components/modules/ada';
import { resolveReEntry } from 'components/modules/app';
import { withinDateRange } from 'components/modules/date';
import { FIND_TERM } from 'components/modules/term';
import { NoSidebarCard } from 'components/Card/NoSidebarCard';
import Button from 'components/CustomButton';
import CampusCard from './CampusCard';
import { UserActions } from 'reducers/user';

const masonryOptions = {
	transitionDuration: 1000,
	fitWidth: true,
	isOriginLeft: true
};

class SelectCampus extends Component {
	re_entry_routes = ['RE_D', 'RE_I', 'RET_D', 'RET_I'];

	constructor(props) {
		super(props);

		this.state = {
			stage: 0,
			exception_modal: false,
			modal_text: ''
		};
	}

	componentDidMount() {
		this._mounted = true;

		setTimeout(() => {
			if (this._mounted) create_proactive('Not sure what campus to pick?', '5ebae1b067c1c5e986c86ef8');
		}, 2000);
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	findException = campus_id => {
		const { app, campus_exceptions } = this.props,
			second_degree =
				app.initial_information.app_type_question_5 === "I've earned a bachelor's degree or higher" &&
				app.initial_information.app_type_question_7 === "Bachelor's Degree";

		return campus_exceptions.find(
			exception =>
				exception.field_campuses.includes(campus_id) &&
				exception.field_application_types.includes(app.application_modifier) &&
				!(second_degree && exception.second_degree)
		);
	};

	blockSelection = campus_id => {
		let { app, apps, app_id, campuses, terms } = this.props,
			{ application_modifier: app_type } = app,
			campus = campuses.find(c => c.id === campus_id);

		if (!campus) return false; // don't block based on a defunct campus in the applicant's history

		let parent_app_campus = apps[app.parent_application_id].json_obj.initial_information.chosen_campus,
			term = FIND_TERM.by_campus(campus_id, app.initial_information.chosen_semester, terms),
			parent_campus_id = _campus_id => {
				return campus.field_parent_campus || _campus_id;
			};

		if (
			parent_app_campus.id === campus_id ||
			(blockByRegion(parent_campus_id(campus_id)) &&
				!parent_app_campus.title.includes('Flexible') &&
				parent_campus_id(campus_id) === parent_campus_id(parent_app_campus.id))
		) {
			return true;
		}

		const terms_for_campus = this.findTerms(campus_id);
		if (!terms_for_campus.length) return true;

		return !!Object.keys(apps).find(_app_id => {
			let { json_obj: _json_obj } = apps[_app_id],
				{ application_modifier: _app_type } = _json_obj,
				{
					chosen_semester,
					chosen_campus: _chosen_campus,
					direct_admit: _direct_admit
				} = _json_obj.initial_information,
				_term = FIND_TERM.by_campus(campus_id, chosen_semester, terms),
				_campuses = [];

			if (_app_id === app_id) return false;

			if (_chosen_campus?.id) {
				_campuses.push(_chosen_campus);
			} else if (Object.keys(_direct_admit).length) {
				_campuses = Object.keys(_direct_admit).map(code => campuses.find(c => c.field_abbreviation === code));
			}

			let campus_match = _campuses.some(_c => {
					const allow_one_selection_per_region =
						blockByRegion(parent_campus_id(campus_id)) && !_c.title.includes('Flexible');

					return allow_one_selection_per_region
						? parent_campus_id(_c.id) === parent_campus_id(campus_id)
						: _c.id === campus_id;
				}),
				term_match =
					term.field_global_term === _term?.field_global_term ||
					(_term?.title.includes('Direct Admit') &&
						term.title.includes(_term?.title.replace('Direct Admit', 'Fall'))),
				app_type_match = app_type === _app_type;

			if (!app_type_match) {
				app_type_match =
					app_type.replace('DA', 'FM_D').replace('FM', 'TR') ===
					_app_type.replace('DA', 'FM_D').replace('FM', 'TR');
			}

			return campus_match && term_match && app_type_match;
		});
	};

	findTerms = campus_id => {
		let { app, terms } = this.props,
			term = FIND_TERM.by_campus(campus_id, app.initial_information.chosen_semester, terms),
			international = app.application_modifier.split('_')[1] === 'I',
			_matches_domestic_status = t =>
				(international && t.field_type === 'International') || (!international && t.field_type === 'Domestic'),
			returnedTerms = [];

		if (!app.application_modifier) return returnedTerms;

		let app_type_name = '';
		if (app.application_modifier.includes('FM_')) {
			app_type_name = 'freshman';
		} else if (app.application_modifier.includes('TR_')) {
			app_type_name = 'transfer';
		} else if (app.application_modifier.includes('RE_') || app.application_modifier.includes('RET_')) {
			app_type_name = 're_entry';
		}

		if (term) {
			const { field_global_term: chosen_global_term } = term,
				global_term = terms.default_undergrad.find(t => t.field_global_term === chosen_global_term);

			if (global_term) {
				let app_type_terms = global_term[`field_${app_type_name}`],
					term_to_add = app_type_terms.find(_matches_domestic_status);

				terms.campus_undergrad
					.filter(
						_term =>
							_term.field_global_term === global_term.field_global_term &&
							_term.field_campuses.includes(campus_id) &&
							_term.field_program_specific !== '1'
					)
					.forEach(campusTerm => {
						app_type_terms = campusTerm[`field_${app_type_name}_campus`];
						term_to_add = app_type_terms.find(
							t => _matches_domestic_status(t) && withinDateRange(t.field_opens, t.field_closes)
						);
					});

				if (term_to_add) returnedTerms.push(term_to_add);
			}
		}

		return returnedTerms;
	};

	saveAndContinue = e => {
		let { app, navigate, setScreensCompleted } = this.props,
			{ stage } = this.state;

		e.preventDefault();
		e.stopPropagation();

		if (stage >= 1 || !this.re_entry_routes.includes(app.application_modifier)) {
			setScreensCompleted({ selectCampusCompleted: true });
			navigate('/course-of-study');
		} else {
			this.setState({ stage: stage + 1 });
		}
	};

	backToInitialInformation = e => {
		let { app, updateApp, navigate } = this.props,
			initial_information = cloneDeep(app.initial_information);

		initial_information.re_entry_attended_campus = '';
		initial_information.re_entry_attended_other = '';

		updateApp({ initial_information: initial_information });

		navigate('/select-modifier', { state: { page: 8 } });
	};

	onBack = e => {
		let { app, updateApp, navigate, setScreensCompleted } = this.props,
			{ stage } = this.state;

		//Done?
		if (stage <= 0 || !this.re_entry_routes.includes(app.application_modifier)) {
			let application_modifier = `${app.application_modifier}`,
				initial_information = cloneDeep(app.initial_information),
				select_modifier_page = 0;

			//Back to start of app selector
			if (!app.date_submitted) {
				this.setState({ stage: 0 });

				application_modifier = '';

				setScreensCompleted({ initialInformationCompleted: false });

				select_modifier_page = 1;
				initial_information.studentPlan = '';
				initial_information.us_citizenship_status = '';
				initial_information.alien_status = '';
				initial_information.visa_type = '';
				initial_information.need_f1_j1 = '';
				initial_information.app_type_question_4 = '';
				initial_information.app_type_question_5 = '';
				initial_information.app_type_question_6 = '';
				initial_information.app_type_question_7 = '';
			}

			updateApp({
				application_modifier: application_modifier,
				initial_information: initial_information
			});

			navigate('/select-modifier');
			navigate('/select-modifier', { state: { page: select_modifier_page } });
		} else {
			this.setState({ stage: stage - 1 });
		}
	};

	onChange = (name, val) => {
		let { app, updateApp } = this.props,
			initial_information = cloneDeep(app.initial_information);

		if (!app.date_submitted) {
			initial_information[name] = val;

			updateApp({
				initial_information: initial_information,
				application_modifier: resolveReEntry(initial_information) //Resolve modifier for re-entry logic,
			});
		}
	};

	renderExceptionModal = () => {
		const { exception_modal, modal_text } = this.state;

		return (
			<Modal show={exception_modal}>
				<form>
					<Modal.Header>
						<Modal.Title className='h2'>Campus Exception</Modal.Title>
					</Modal.Header>
					<Modal.Body className='modalMinHeight'>
						<div dangerouslySetInnerHTML={{ __html: modal_text }} />
					</Modal.Body>
					<Modal.Footer>
						<Button
							bsStyle='info'
							className='back-btn'
							fill
							onClick={() => this.setState({ exception_modal: false })}>
							Close
						</Button>
					</Modal.Footer>
				</form>
			</Modal>
		);
	};

	renderSelectionPage = () => {
		let { app, campuses, creating_additional_app } = this.props,
			columnWidth = 2,
			parentCards = [],
			instructions;

		campuses.forEach((_campus, i) => {
			let campus = cloneDeep(_campus);

			if (!campus.field_parent_campus) {
				let region_campuses = [];

				campus.enabled = !this.findException(campus.id);
				region_campuses.push(campus);

				const child_campuses = campuses.filter(
					c => c.field_parent_campus === campus.id && !this.findException(c.id)
				);

				region_campuses = region_campuses.concat(child_campuses);

				region_campuses.forEach(c => {
					c.disabled =
						creating_additional_app && (this.blockSelection(c.id) || c?.title?.includes('Flexible'));
				});

				const card = (
					<CampusCard
						key={i}
						value={app.initial_information.chosen_campus?.id || ''}
						showExceptionModal={() => {
							const exceptionObj = this.findException(campus.id);
							this.setState({
								exception_modal: true,
								modal_text: exceptionObj.field_exception_text_formatted
							});
						}}
						columnWidth={columnWidth}
						campuses={region_campuses}
					/>
				);

				parentCards.push(card);
			}
		});

		if (creating_additional_app) {
			instructions = (
				<>
					<p className='addtl-campus'>Please select the next campus you wish to apply to.</p>
					<ul className='addtl-campus'>
						<li>
							Note: You will not be able to apply to a campus that you have already applied to or to a
							term that is not open.
						</li>
					</ul>
				</>
			);
		} else if (app.application_modifier.includes('FM_')) {
			instructions = (
				<p className='textMargin'>
					Pick <strong>one</strong> campus now. To make the process quicker and easier, you will be able to
					reuse the information you provide for the first campus to apply to additional campuses before
					submitting your application.
				</p>
			);
		}

		return (
			<div className={'text-center campus-selector'}>
				{this.renderExceptionModal()}

				{instructions}

				<Masonry options={masonryOptions} className='col-centered'>
					{parentCards}
				</Masonry>
				<Row></Row>
			</div>
		);
	};

	renderPostSelectionQuestions = () => {
		let { app } = this.props;

		return (
			<>
				<RadioList
					label={`Have you attended ${app.initial_information.chosen_campus.title} as a degree-seeking student in the past?`}
					name='re_entry_attended_campus'
					opts={['Yes', 'No']}
					onChange={val => this.onChange('re_entry_attended_campus', val)}
					value={app.initial_information.re_entry_attended_campus}
					cols={8}
					centered={true}
					required={true}
				/>

				{app.initial_information.re_entry_attended_campus === 'Yes' && (
					<RadioList
						label='Have you gone to another college since you last attended your selected campus?'
						name='re_entry_attended_other'
						opts={['Yes', 'No']}
						onChange={val => this.onChange('re_entry_attended_other', val)}
						value={app.initial_information.re_entry_attended_other}
						cols={8}
						centered={true}
						required={true}
					/>
				)}

				{app.initial_information.re_entry_attended_campus === 'No' && (
					<div className={'col-md-8 col-centered'}>
						<p>
							You&apos;ve incorrectly chosen Re-Entry. Please use the button below to redo your initial
							information.
						</p>
					</div>
				)}
			</>
		);
	};

	render() {
		let { app, navigate, creating_additional_app } = this.props,
			{ stage } = this.state,
			allow_continue =
				!!app.initial_information.chosen_campus?.id &&
				!!app.application_modifier &&
				!(stage === 1 && !app.initial_information.re_entry_attended_campus),
			backButton = (
				<Button bsStyle='info' className='back-btn' fill onClick={this.onBack}>
					Back
				</Button>
			);

		if (!app.application_modifier) {
			backButton = (
				<Button bsStyle='info' className='back-btn' fill onClick={this.backToInitialInformation}>
					Back To Initial Information
				</Button>
			);
		}

		if (creating_additional_app) {
			backButton = (
				<Button bsStyle='info' className='back-btn' fill onClick={() => navigate('/additional-applications')}>
					Return To Submit
				</Button>
			);
		}

		return (
			<NoSidebarCard
				toAccount={() => navigate('/my-account')}
				fullWidth={true}
				header={stage ? 'Re-Entry Questions' : 'WHERE DO YOU WANT TO GO?'}
				content={
					<form id='form' className='campus-form' onSubmit={this.saveAndContinue}>
						{stage ? this.renderPostSelectionQuestions() : this.renderSelectionPage()}
						<div className='clearfix' />
					</form>
				}
				buttons={
					<>
						{backButton}

						<Button bsStyle='info' className='tertiaryButton' fill onClick={() => navigate('/my-account')}>
							My Account
						</Button>

						<Button
							form='form'
							bsStyle='info'
							className='save-btn'
							fill
							type='submit'
							disabled={!allow_continue}>
							Save and Continue
						</Button>
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => ({
		apps: state.user.apps,
		app: state.user.temp_app_rec.json_obj,
		app_id: state.user.app_id,
		campuses: state.global.campuses.filter(c => !DEFUNCT_CAMPUS_CODES.includes(c.field_abbreviation)),
		campus_exceptions: state.global.campus_exceptions,
		terms: state.global.terms,
		creating_additional_app: state.user.creating_additional_app
	}),
	mapDispatchToProps = dispatch => ({
		updateApp: obj => dispatch(UserActions.updateApp(obj)),
		setScreensCompleted: arg => dispatch(UserActions.setScreensCompleted(arg))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectCampus));
