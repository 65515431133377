import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'components/Card/Card';

export default class MaintenancePage extends Component {
	constructor(props) {
		super(props);

		localStorage.removeItem('global');
		localStorage.removeItem('user');
	}

	render = () => {
		const { msg } = this.props,
			default_msg =
				"We're currently performing some scheduled maintenance and should be back shortly. Thank you for your patience.";

		return (
			<>
				<div className='constellation-bg'></div>
				<div className='wrapper noSidebarWrapper' role='main'>
					<div id='main-panel' className='main-panel noSidebarPanel' ref='mainPanel'>
						<Card
							title='SORRY!'
							content={
								<div className='loading-page'>
									<p>{msg ? msg : default_msg}</p>
								</div>
							}
						/>
					</div>
				</div>
			</>
		);
	};
}

MaintenancePage.propTypes = {
	msg: PropTypes.string
};
