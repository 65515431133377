import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Radio, ControlLabel } from 'react-bootstrap';

export default class RadioList extends Component {
	render() {
		let {
			value,
			opts,
			onChange,
			name,
			autoFocus,
			forceNewLines,
			classVals,
			childClasses,
			tooltip,
			label,
			cols,
			centered,
			oneLine,
			required,
			hide_optional
		} = this.props;

		if (!classVals) classVals = [];
		classVals.push(`col-md-${cols || 12}`);
		if (centered) classVals.push('col-centered');

		opts = opts.map(opt => {
			return typeof opt === 'string' ? { val: opt, label: opt } : opt;
		});

		return (
			<div className={classVals.join(' ')}>
				<FormGroup className='radio-list' aria-label={label || opts[0].label}>
					{label && (
						<ControlLabel className={oneLine ? 'one-line' : ''}>
							{label}
							{!required && !hide_optional && <small>(Optional)</small>}
							{tooltip}
						</ControlLabel>
					)}

					{opts.map((opt, i) => {
						let checked =
							opt.val === value ||
							(value === '1' && opt.val.includes('Yes')) ||
							(value === '0' && opt.val.includes('No'));

						return (
							<React.Fragment key={i}>
								{!!forceNewLines && !!i && <br />}

								<Radio
									autoFocus={!!autoFocus && !i}
									checked={checked}
									value={value}
									name={name}
									onChange={() => onChange(opt.val)}
									className={`${childClasses || ''}`}
									tabIndex={0}>
									{opt.label}
								</Radio>
							</React.Fragment>
						);
					})}
				</FormGroup>
			</div>
		);
	}
}

RadioList.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	opts: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	autoFocus: PropTypes.bool,
	forceNewLines: PropTypes.bool,
	classVals: PropTypes.array,
	childClasses: PropTypes.string,
	cols: PropTypes.number,
	centered: PropTypes.bool,
	oneLine: PropTypes.bool,
	tooltip: PropTypes.object,
	required: PropTypes.bool,
	hide_optional: PropTypes.bool
};
