import React from 'react';
import packageJson from '../../../package.json';

global.appVersion = packageJson.version;

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
	const versionsA = versionA.split(/\./g),
		versionsB = versionB.split(/\./g);

	while (versionsA.length || versionsB.length) {
		const a = Number(versionsA.shift()),
			b = Number(versionsB.shift());

		if (a === b) continue;

		return a > b || isNaN(b);
	}

	return false;
};

class CacheBuster extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			isLatestVersion: false,
			refreshCacheAndReload: () => {
				console.log('Clearing cache and hard reloading...');

				if (caches) {
					// Service worker cache should be cleared with caches.delete()
					caches.keys().then(function (names) {
						// eslint-disable-next-line
						for (let name of names) caches.delete(name);
					});
				}

				// delete browser cache and hard reload
				window.location.reload(true);
			}
		};
	}

	componentDidMount() {
		fetch('/meta.json')
			.then(response => response.json())
			.then(meta => {
				const latestVersion = meta.version,
					currentVersion = global.appVersion,
					shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion),
					strStatus = shouldForceRefresh ? `v${latestVersion} available` : 'latest';

				console.log(`v${currentVersion} (${strStatus})`);

				this.setState({ loading: false, isLatestVersion: !shouldForceRefresh });
			});
	}

	render() {
		const { children } = this.props,
			{ loading, isLatestVersion, refreshCacheAndReload } = this.state;

		if (loading) return null;

		if (!isLatestVersion) refreshCacheAndReload(); // You can decide how and when you want to force reload

		return children;
	}
}

export default CacheBuster;
