import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'components/Wrappers/withRouter';
import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import { adminUrl } from 'components/modules/_misc';
import { GlobalActions } from 'reducers/global';
import { UserActions } from 'reducers/user';
import 'react-accessible-accordion/dist/fancy-example.css';
import API from 'components/api';

class NextSteps extends Component {
	componentDidMount = () => {
		// tms: ns_application_id is the argument when returning from the CASHNet page

		const { app, location, app_id, navigate } = this.props,
			app_id_arg = (location.search.split('ns_application_id=')[1] || '').toLowerCase();

		if (app_id_arg && app_id_arg !== app_id) {
			const tab = app.application_modifier === 'DA' ? '' : 'app';
			navigate('/my-account', { state: { tab: tab } });
		}
	};

	getAPI = () => {
		const { support_student_id, campus_admin } = this.props;
		return new API('', support_student_id, campus_admin);
	};

	getCampusNextSteps = () => {
		let { apps, user_id, apps_in_cart, campus_next_steps } = this.props,
			returnElements = [];

		apps_in_cart.split('|').forEach(_app_id => {
			let app_rec = apps[_app_id],
				{ chosen_campus } = app_rec.json_obj.initial_information,
				{ application_modifier: app_type } = app_rec.json_obj,
				survey_links = {
					FM_D: 'SV_a2xJLUWD1vZogdg',
					TR_D: 'SV_e38NShxJtEbEnoa'
				},
				survey_link = `https://uwsystemadmin.qualtrics.com/jfe/form/${survey_links[app_type]}?appID=${_app_id}&userID=${user_id}&appType=${app_type}`;

			if (chosen_campus) {
				let campus_next_step = '';

				campus_next_steps.forEach(step => {
					if (
						step.field_campuses.includes(chosen_campus.id) &&
						(step.field_application_types.includes(app_type) ||
							(app_type === 'DA' && step.field_application_types.includes('FM_D')))
					) {
						campus_next_step = step;
					}
				});

				returnElements.push(
					campus_next_step ? (
						<div key={`next-step-${chosen_campus.title}`} className={'col-md-12 nextStepsCampus'}>
							<div className={'col-md-7'}>
								<p className={'nextStepsText'}>{chosen_campus.title}</p>
								<div
									dangerouslySetInnerHTML={{
										__html: campus_next_step.field_first_step_ref[0].field_step_text
									}}
								/>
							</div>

							<Button
								bsStyle='info'
								aria-label='Download Next Steps PDF'
								className='col-md-offset-1 col-md-4 back-btn nextStepsDownloadPDF'
								fill
								onClick={() => window.open(`${adminUrl()}next_steps_pdf/${_app_id}`, '_blank')}>
								Download Next Steps
							</Button>

							<div className='survey-wrap'>
								{['FM_D', 'TR_D'].includes(app_type) && (
									<div className='survey-click' onClick={() => window.open(survey_link)}>
										<img
											className='survey-icon'
											src={require('assets/img/survey.png')}
											alt='Experience Survey'
										/>
										<h3>
											We&apos;d love your feedback.{' '}
											<span className='feedback-link'>Complete our survey.</span>
										</h3>
									</div>
								)}
							</div>
						</div>
					) : (
						<div key={`next-step-${chosen_campus.title}`} className={'nextStepsCampus'}>
							<p className={'text'}>
								Check in with {chosen_campus.title} about your next steps.{' '}
								{!!chosen_campus.field_email_address && (
									<>
										{chosen_campus.field_email_address}
										{!!chosen_campus.field_phone_number && ' - '}
									</>
								)}
								{!!chosen_campus.field_phone_number && chosen_campus.field_phone_number}
							</p>
						</div>
					)
				);
			}
		});

		return returnElements;
	};

	returnToMyAccount = e => {
		e.preventDefault();
		e.stopPropagation();

		const { app, navigate, setApps, captureError } = this.props,
			tab = app.application_modifier === 'DA' ? '' : 'app';

		this.getAPI()
			.getAllApplications()
			.then(result => setApps(result.rows))
			.catch(ex => captureError(ex));

		navigate('/my-account', { state: { tab: tab } });
	};

	render() {
		const { app, apps, apps_in_cart } = this.props,
			show_vid = !!apps_in_cart.split('|').find(_app_id => {
				let app_rec = apps[_app_id],
					{ application_modifier: app_type } = app_rec.json_obj;

				return app_type.includes('FM') || app_type.includes('TR');
			});

		return (
			<Card
				title={
					app.application_modifier === 'DA'
						? 'Congratulations! You are admitted to the Universities of Wisconsin!'
						: 'Congratulations on Submitting Your Application'
				}
				content={
					<form>
						<p>
							{app.application_modifier === 'DA'
								? 'You have submitted your Direct Admit Form(s).  Download PDFs with instructions regarding your next steps.'
								: 'Download PDFs with instructions on the next steps for each campus you applied to.'}{' '}
							You will also receive an email that includes this information.
						</p>
						{app.application_modifier === 'DA' && (
							<p>
								The following UW(s) will be given your Direct Admit forms and will begin the process of
								reaching out to you to help you enroll:
							</p>
						)}

						{show_vid && (
							<div className='yt-container-congrats'>
								<iframe
									src='https://www.youtube.com/embed/9nfKjSWYx8M'
									title='YouTube video player'
									frameBorder='0'
									allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
									allowFullScreen={true}></iframe>
							</div>
						)}

						<div className='next-steps-wrap'>{this.getCampusNextSteps()}</div>

						<div className='clearfix' />
					</form>
				}
				buttons={
					<Button bsStyle='info' className='tertiaryButton' fill onClick={this.returnToMyAccount}>
						My Account
					</Button>
				}
			/>
		);
	}
}

const mapStateToProps = state => {
		const { app_id, user_id, apps, apps_in_cart, support_student_id, campus_admin } = state.user;

		return {
			app_id: app_id,
			user_id: user_id,
			app: apps[app_id].json_obj,
			apps: apps,
			apps_in_cart: apps_in_cart,
			support_student_id: support_student_id,
			campus_admin: campus_admin,
			campus_next_steps: state.global.campus_next_steps
		};
	},
	mapDispatchToProps = dispatch => ({
		setApps: arr => dispatch(UserActions.setApps(arr)),
		captureError: err => dispatch(GlobalActions.captureError(err))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NextSteps));
