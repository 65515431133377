import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';

import withRouter from 'components/Wrappers/withRouter';
import { Card } from 'components/Card/Card';
import Textbox from 'components/controls/textbox';
import Button from 'components/CustomButton';
import { REGEXES } from 'components/modules/validation';
import { parseUTCDateTime, strNow } from 'components/modules/date';
import _svg from 'components/modules/svg';
import { GlobalActions } from 'reducers/global';
import { UserActions } from 'reducers/user';
import API from 'components/api';

class VerifyEmail extends Component {
	constructor(props) {
		super(props);

		this.state = {
			change_email: false,
			new_email: '',
			code: '',
			working: false
		};

		this.orig_email = props.email; // use to show something while email is being changed
	}

	componentDidMount() {
		this._mounted = true;

		let { verification_code_generated_date } = this.props,
			send_code = !verification_code_generated_date;

		if (!send_code) {
			let date_email_sent = parseUTCDateTime(verification_code_generated_date),
				verification_code_expiration_time = 30 * 60 * 1000, // 30 min
				now = new Date();

			send_code = now - date_email_sent > verification_code_expiration_time;
		}

		if (send_code) this.onResendEmail(true);
	}

	componentDidUpdate(prevProps) {
		const { email_verified, direct_admit, navigate } = this.props,
			outstanding_hs_email_verification = !!direct_admit?.email && !direct_admit?.user_id;

		if (outstanding_hs_email_verification && email_verified && !prevProps.email_verified) {
			navigate('/link-direct-admit');
		}
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	getAPI = () => {
		const { support_student_id, campus_admin } = this.props;
		return new API('', support_student_id, campus_admin);
	};

	showMessage = strs => {
		if (typeof strs === 'string') strs = [strs];

		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className='react-confirm-alert'>
						<div className='react-confirm-alert-body'>
							{strs.map((str, i) => (
								<p key={i}>{str}</p>
							))}
							<div className='react-confirm-alert-button-group'>
								<button onClick={onClose}>OK</button>
							</div>
						</div>
					</div>
				);
			}
		});
	};

	isValidForm = () => {
		const { email } = this.props,
			{ code, change_email, new_email } = this.state;

		if (!change_email) return code?.length === 6;

		return !!new_email && REGEXES.email.test(new_email) && new_email !== email;
	};

	onSubmit = e => {
		e.preventDefault();
		e.stopPropagation();

		const { change_email, working } = this.state;

		if (!working && this.isValidForm()) {
			this.setState({ working: true });

			if (change_email) {
				this.onChangeEmail();
			} else {
				this.onSubmitCode();
			}
		}
	};

	onChangeEmail = () => {
		const {
				first_name,
				last_name,
				phone_number,
				no_phone,
				permission_to_send_texts,
				setEmail,
				login,
				support_student_id,
				campus_admin,
				captureError
			} = this.props,
			{ new_email } = this.state,
			allow_submit =
				!!first_name &&
				!!last_name &&
				!!new_email &&
				REGEXES.email.test(new_email) &&
				(no_phone || (!!phone_number && !!permission_to_send_texts));

		if (!allow_submit) {
			this.showMessage("Oops!  Something's gone wrong.  Please refresh the page and try again.");
		} else {
			this.getAPI()
				.updateAccount(new_email, first_name, last_name, phone_number, no_phone, permission_to_send_texts)
				.then(resp => {
					if (this._mounted) {
						if (resp?.result === 'success' && resp.user) {
							setEmail(`${new_email}`);

							this.getAPI()
								.resendVerifyEmail()
								.then(resp_resend => {
									if (this._mounted) {
										this.setState({ working: false });

										if (resp_resend?.result === 'success' && resp_resend.user) {
											const objLogin = {
												...resp_resend.user,
												support_student_id: support_student_id,
												campus_admin: campus_admin
											};

											this.setState({ change_email: false, new_email: '' });

											this.showMessage(
												`OK!  We have sent another code to ${new_email}.  This will invalidate any previous codes.`
											);

											login(objLogin);
										} else {
											this.showMessage(
												`That's weird.  We changed your email to ${new_email}, but had some trouble trouble sending you a new code.`
											);
										}
									}
								})
								.catch(ex => captureError(ex));
						} else {
							this.setState({ working: false });
							this.showMessage(
								'Oops!  Looks like a different account with that email address already exists.'
							);
						}
					}
				})
				.catch(ex => captureError(ex));
		}
	};

	onSubmitCode = () => {
		const { setEmailAsVerified, captureError } = this.props,
			{ code } = this.state;

		this.getAPI()
			.verifyEmail(code)
			.then(resp => {
				if (this._mounted) {
					this.setState({ working: false });

					if (resp?.result === 'success') {
						setEmailAsVerified();
					} else {
						this.showMessage(
							"Sorry, that code doesn't seem to work.  It could be expired; did we send you another one?"
						);
					}
				}
			})
			.catch(ex => captureError(ex));
	};

	onResendEmail = autoSend => {
		const { email, setCodeDate, verification_code_generated_date, captureError } = this.props;

		if (email) {
			const msgSuccess = verification_code_generated_date
				? `We just sent another code to ${email} (the previous one has expired).`
				: [
						`Hi!  We need to verify your account, so we sent a code to ${email}.  Please provide that code to continue using your account.`,
						"(This is the only time you'll be asked to do this.)"
				  ];

			this.setState({ working: true });

			this.getAPI()
				.resendVerifyEmail()
				.then(resp_resend => {
					if (this._mounted) {
						this.setState({ working: false });
						setCodeDate(strNow());

						if (autoSend) {
							this.showMessage(
								resp_resend?.result === 'success'
									? msgSuccess
									: `That's weird.  We tried to send another code to ${email}, but we had some trouble.`
							);
						} else {
							this.showMessage(
								resp_resend?.result === 'success'
									? `OK!  We have sent another code to ${email}.  This will invalidate any previous codes.`
									: "That's weird.  We had some trouble trouble sending you a new code."
							);
						}
					}
				})
				.catch(ex => captureError(ex));
		}
	};

	renderSuccess = () => {
		const { navigate } = this.props;

		return (
			<Card
				content={
					<>
						<Row className={'myAccountRow'}>
							<h1>Email Verified</h1>
						</Row>

						<p>That&apos;s what we needed!</p>

						<div className='success-img-wrapper'>
							<img alt='Success' src={_svg.InitInfoYes} />
						</div>
					</>
				}
				buttons={
					<>
						<Button
							form='form'
							bsStyle='info'
							className='save-btn'
							fill
							type='submit'
							onClick={() => navigate('/my-account')}>
							OK, Let&apos;s go!
						</Button>
					</>
				}
			/>
		);
	};

	render() {
		const { navigate, email, email_verified } = this.props,
			{ code, change_email, new_email, working } = this.state;

		if (email_verified) return this.renderSuccess();

		return (
			<Card
				content={
					<>
						<Row className={'myAccountRow'}>
							<h1>{change_email ? 'Change Your Email' : 'Verify Your Email'}</h1>
						</Row>

						{!change_email && (
							<p className='intro-copy'>
								We&apos;ve sent a code to <strong>{email || this.orig_email}</strong>. Please verify
								that email address by providing that code.
							</p>
						)}

						<form id='form' onSubmit={this.onSubmit}>
							<Row>
								{change_email ? (
									<Textbox
										value={new_email}
										type='email'
										name={'new_email'}
										onChange={val => this.setState({ new_email: val })}
										label='Email Address'
										placeholder='Email Address'
										required={true}
										maxLength={256}
										isInvalid={!REGEXES.email.test(new_email)}
										cols={6}
									/>
								) : (
									<Textbox
										value={code}
										name={'code'}
										onChange={val => this.setState({ code: val })}
										label='Code'
										placeholder='Code'
										required={true}
										maxLength={6}
										cols={4}
									/>
								)}
							</Row>

							<Button
								form='form'
								bsStyle='info'
								className='save-btn'
								type='submit'
								disabled={working || !this.isValidForm()}>
								{working ? 'Just a Moment' : 'Submit'}
							</Button>
						</form>

						{change_email ? (
							<p className='verify-email-change'>
								<button
									type='button'
									className='link-button'
									onClick={e => {
										e.preventDefault();
										e.stopPropagation();

										this.setState({ change_email: false });
									}}>
									Never mind
								</button>
								, I found the code in <strong>{email || this.orig_email}</strong>.
							</p>
						) : (
							<>
								<p className='verify-email-change'>
									Did you receive a code? If not, we can{' '}
									<button
										type='button'
										className='link-button'
										onClick={e => {
											e.preventDefault();
											e.stopPropagation();

											this.onResendEmail();
										}}>
										send you another one
									</button>
									, or if there is a problem with <strong>{email}</strong>, you can{' '}
									<button
										type='button'
										className='link-button'
										onClick={e => {
											e.preventDefault();
											e.stopPropagation();

											this.setState({ change_email: true });
										}}>
										change your email
									</button>
									.
								</p>
								<p>Still not receiving your code? Please give us a call at 800-442-6459.</p>
							</>
						)}

						<button
							type='button'
							className='link-button offset-btn'
							onClick={e => {
								e.preventDefault();
								e.stopPropagation();

								navigate('/', { state: { logging_out: true } });
							}}>
							Logout
						</button>
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => ({
		email: state.user.email,
		first_name: state.user.first_name,
		last_name: state.user.last_name,
		phone_number: state.user.phone_number,
		no_phone: state.user.no_phone,
		permission_to_send_texts: state.user.permission_to_send_texts,
		support_student_id: state.user.support_student_id,
		campus_admin: state.user.campus_admin,
		email_verified: state.user.email_verified,
		verification_code_generated_date: state.user.verification_code_generated_date,
		direct_admit: state.user.direct_admit
	}),
	mapDispatchToProps = dispatch => ({
		login: obj => dispatch(UserActions.login(obj)),
		setEmail: val => dispatch(UserActions.setUserProperty('email', val)),
		setEmailAsVerified: () => dispatch(UserActions.setEmailAsVerified()),
		setCodeDate: strDate => dispatch(UserActions.setUserProperty('verification_code_generated_date', strDate)),
		captureError: err => dispatch(GlobalActions.captureError(err))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyEmail));
