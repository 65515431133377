import { cloneDeep } from 'components/modules/_misc';
import { CAMPUS_CODES } from 'components/modules/campus';

export const ACAD_LEVELS = {
		base: {
			Undergraduate: ['A', 'B'],
			Graduate: ['M', 'S', 'D', 'P', 'Y']
		},
		names: {
			A: 'Associate',
			B: 'Bachelor',
			M: 'Masters',
			S: 'Education Specialist',
			D: 'Doctorate-Research/Scholarship',
			P: 'Doctorate-Professional Practice',
			Y: 'Doctorate-Professional Practice'
		}
	},
	MODIFIER_GROUPS = {
		GRAD: ['GR_D', 'GR_I'],
		SPECIAL: ['UG_D_S', 'UG_I_S', 'HS_D_S', 'HS_I_S', 'GR_D_S', 'GR_I_S']
	},
	IS = {
		online: (prog, search_subprograms = true) =>
			['Blended', 'Online 100%'].some(str => prog?.field_flags?.includes(str)) ||
			(search_subprograms &&
				prog?.sub_programs?.some(subProg =>
					['Blended', 'Online 100%'].some(str => subProg?.field_flags?.includes(str))
				)),
		certificate: (prog, search_subprograms = true) =>
			prog?.field_flags?.includes('Certificate') ||
			(search_subprograms && prog?.sub_programs?.some(subProg => subProg?.field_flags?.includes('Certificate'))),
		flex: (prog, search_subprograms = true) =>
			prog?.field_flags?.includes('Flex') ||
			prog?.field_flags?.includes('Belongs to Flex Program') ||
			(search_subprograms &&
				prog?.sub_programs?.some(
					subProg =>
						subProg?.field_flags?.includes('Flex') ||
						subProg?.field_flags?.includes('Belongs to Flex Program')
				))
	},
	MATCHES_SEARCH = {
		_string: (prog, search_string) => {
			try {
				let escaped_chars = ['(', ')', '[', ']', '|'],
					str_regex = `${search_string.trim()}`;

				escaped_chars.forEach(str => {
					str_regex = str_regex.split(str).join(`\\${str}`);
				});

				const name_regex = new RegExp(str_regex || '', 'i');

				return (
					!search_string.trim() ||
					prog.title.match(name_regex) ||
					prog.field_keywords?.some(keyword => keyword.match(name_regex))
				);
			} catch (ex) {
				return false;
			}
		},
		_career_cluster: (prog, career_clusters) =>
			!career_clusters.length || career_clusters.some(cc => prog.field_career_clusters?.includes(cc.value)),
		_campus: (prog, campuses) => {
			const { field_campus_id, field_override_campuses } = prog,
				_match_campus = (campus, code) =>
					campus.code === code || (['XMI', 'XPK'].includes(campus.code) && ['XMI', 'XPK'].includes(code));

			return (
				!campuses.length ||
				(field_override_campuses?.length
					? field_override_campuses.some(code => campuses.find(campus => _match_campus(campus, code)))
					: campuses.find(campus => _match_campus(campus, field_campus_id)))
			);
		},
		_degree_type: (prog, _opts) => {
			const opts = _opts.filter(opt => opt.value.includes('DEGREE-TYPE|')).map(opt => opt.label),
				prog_acad_level = ACAD_LEVELS.names[prog.field_academic_level];

			return !opts.length || opts.some(opt => opt === prog_acad_level);
		},
		_program_type: (prog, _opts) => {
			const opts = _opts.filter(opt => opt.value.includes('PROG-TYPE|')).map(opt => opt.label);

			return (
				!opts.length ||
				opts.some(opt => opt === prog.field_cmc_program_type) ||
				opts.some(opt => prog.sub_programs?.some(sub_prog => opt === sub_prog.field_cmc_program_type))
			);
		},
		_delivery: (prog, _opts) => {
			const opts = _opts.filter(opt => opt.value.includes('DELIVERY|')).map(opt => opt.label),
				is_online = ['Blended', 'Online 100%'].some(str => prog.field_flags?.includes(str)),
				is_in_person = !prog.field_flags?.includes('Online 100%');

			return (
				!opts.length || (opts.includes('Online') && is_online) || (opts.includes('In-Person') && is_in_person)
			);
		}
	},
	GET_LABEL = {
		selectable: (prog, app) => {
			let { title, field_academic_level, field_app_type_special, field_parent_program_id, field_flags } = prog,
				{ field_abbreviation: campus_code } = app.initial_information.chosen_campus;

			let str = `${title || ''}`;

			if (str && !field_app_type_special) {
				if (field_academic_level === 'A') str += ' - Associate Program';

				if (!field_flags.includes('Certificate')) {
					if (field_academic_level === 'B' && !CAMPUS_CODES[campus_code]) str += " - Bachelor's";

					if (ACAD_LEVELS.base.Graduate.includes(field_academic_level) && !field_parent_program_id) {
						str += ` - ${ACAD_LEVELS.names[field_academic_level]}`;
					}
				}
			}

			return str;
		},
		full: (majors, aos, i) => {
			let prog = aos[`selected_primary_${i}`],
				delivery_method = aos[`selected_primary_${i}_delivery_method`],
				parent_prog = prog.field_parent_program_id
					? majors.find(maj => maj.id === prog.field_parent_program_id)
					: null,
				str = '';

			if (parent_prog) {
				const overlappingTitles = prog.title
					.toLowerCase()
					.split(' ')
					.join('')
					.includes(parent_prog.title.toLowerCase().split(' ').join(''));

				str = overlappingTitles ? prog.title : `${parent_prog.title} [${prog.title}]`;
			} else {
				str = prog.title;
			}

			if (delivery_method) str += ` (${delivery_method})`;

			return str;
		}
	},
	prog_sort = (a, b) => {
		if (!a?.title) return -1;
		if (!b?.title) return +1;

		return a.title.localeCompare(b.title);
	},
	combine_majors = (majors, sub_majors) => {
		let _mapApiResp = (rec, is_major) => {
				let obj = {
					id: rec.id,
					title: rec.title,
					field_campus_id: rec.field_campus_id,
					field_academic_level: rec.field_academic_level,
					field_cmc_program_type: is_major ? 'Major' : rec.field_cmc_program_type,
					field_distance_ed_indicator: rec.field_distance_ed_indicator || '',
					field_program_url: rec.field_program_url || '',
					field_selective: rec.field_selective || '0',
					field_plan_code: rec.field_plan_code,
					field_subplan_code: rec.field_subplan_code,
					field_program_code: rec.field_program_code,
					field_online_plan_code: rec.field_online_plan_code,
					field_keywords: rec.field_keywords || [],
					field_career_clusters: rec.field_career_clusters || [],
					field_application_types: rec.field_application_types || [],
					field_override_campuses: rec.field_override_campuses || [],
					field_flags: rec.field_flags || [],
					field_parent_program_id: rec.field_parent_program_id || '',
					sub_programs: []
				};

				if (
					obj.field_parent_program_id === '0' ||
					!['Concentration', 'Credential'].includes(obj.field_cmc_program_type)
				) {
					obj.field_parent_program_id = '';
				}

				return obj;
			},
			arrReturn = majors
				.map(maj => _mapApiResp(maj, true))
				.concat(
					sub_majors
						.filter(
							rec =>
								rec.field_cmc_program_type !== 'Concentration' &&
								!(
									rec.field_cmc_program_type === 'Credential' &&
									(rec.field_parent_program_id || '0') !== '0'
								)
						)
						.map(rec => _mapApiResp(rec))
				);

		arrReturn = arrReturn.map(prog => {
			prog.sub_programs = sub_majors
				.filter(
					subProg =>
						['Concentration', 'Credential'].includes(subProg.field_cmc_program_type) &&
						subProg.field_parent_program_id === prog.id
				)
				.sort((a, b) => (a.title > b.title ? 1 : -1))
				.map(rec => {
					let subProg = _mapApiResp(rec);
					subProg.field_selective =
						subProg.field_selective === '1' || prog.field_selective === '1' ? '1' : '0';

					return subProg;
				});

			return prog;
		});

		return arrReturn;
	},
	filter_programs_by_app_creator = (app, majors, specials, campus) => {
		const { id: campus_id, field_abbreviation: campus_code } = campus, // don't rely on initial_information.chosen_campus in case it's direct admit
			_matches_campus = prog =>
				prog.field_override_campuses?.length
					? prog.field_override_campuses.includes(campus_code)
					: prog.field_campus_id === campus_code,
			allow_prog = prog =>
				_matches_campus(prog) &&
				(prog.field_application_types.includes(app.application_modifier) ||
					(app.application_modifier === 'DA' && prog.field_application_types.includes('FM_D'))) &&
				!(app.initial_information.alien_status === 'Intl Online' && !IS.online(prog));

		if (MODIFIER_GROUPS.SPECIAL.includes(app.application_modifier)) {
			let international = app.application_modifier.split('_')[1] === 'I',
				app_type;

			if (app.application_modifier.includes('HS_')) {
				app_type = 'HS Special';
			} else if (app.application_modifier.includes('UG_')) {
				app_type = 'UG Special';
			} else if (app.application_modifier.includes('GR_')) {
				app_type = 'Grad Special';
			}

			return specials
				.filter(prog => {
					return (
						prog.field_campuses.includes(campus_id) &&
						prog.field_app_type_special === app_type &&
						((prog.field_domestic === '1' && !international) ||
							(prog.field_international === '1' && international))
					);
				})
				.sort((a, b) => a.title.localeCompare(b.title));
		}

		return cloneDeep(majors)
			.filter(maj => {
				let strAcadLevel = MODIFIER_GROUPS.GRAD.includes(app.application_modifier)
					? 'Graduate'
					: 'Undergraduate';

				return ACAD_LEVELS.base[strAcadLevel].includes(maj.field_academic_level) && allow_prog(maj);
			})
			.map(maj => {
				maj.sub_programs = maj.sub_programs.filter(allow_prog);
				return maj;
			})
			.sort(prog_sort);
	};
