import React, { Component } from 'react';
import { connect } from 'react-redux';

import withRouter from 'components/Wrappers/withRouter';
import IdleTimer from 'components/IdleTimer/withIdleTimer';
import IdleModal from 'components/IdleTimer/modal';

// in minutes
const IDLE_TIMES = {
	SHOW_PROMPT: 15,
	LOGOUT: 2
};

class IdleTimerComponent extends Component {
	constructor(props) {
		super(props);

		this.idleTimer = null;
		this.state = {
			show_modal: false
		};
	}

	onReset = () => {
		this.setState({ show_modal: false });
		this.idleTimer.reset();
	};

	onIdle = () => {
		this.props.navigate('/', { state: { logging_out: true } });
	};

	onManualLogout = () => {
		this.setState({ show_modal: false });
		this.props.navigate('/', { state: { logging_out: true } });
	};

	onLogin = () => {
		this.setState({ show_modal: false });
		this.props.navigate('/sign-in');
	};

	render() {
		const { email } = this.props,
			{ show_modal } = this.state;

		return email ? (
			<>
				<IdleModal
					showModal={show_modal}
					onLogout={() => this.onManualLogout()}
					onClose={() => this.setState({ show_modal: false })}
					onReset={() => this.onReset()}
					type='timing_out'
				/>

				<IdleTimer
					ref={ref => {
						this.idleTimer = ref;
					}}
					promptTimeout={1000 * 60 * IDLE_TIMES.LOGOUT}
					timeout={1000 * 60 * IDLE_TIMES.SHOW_PROMPT}
					onPrompt={() => this.setState({ show_modal: true })}
					onIdle={() => this.onIdle()}
				/>
			</>
		) : (
			<IdleModal
				showModal={show_modal}
				onClose={() => this.setState({ show_modal: false })}
				onLogin={() => this.onLogin()}
				type='timed_out'
			/>
		);
	}
}

const mapStateToProps = state => ({
	email: state.user.email
});

export default withRouter(connect(mapStateToProps)(IdleTimerComponent));
