import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel
} from 'react-accessible-accordion';

import { scrollTo } from 'components/modules/_misc';
import _svg from 'components/modules/svg';
import withRouter from 'components/Wrappers/withRouter';
import { UserActions } from 'reducers/user';

import ContactUsModal from 'views/Public/Modals/ContactUs';
import Header from 'views/Public/shared-content/home-header';
import Footer from 'views/Public/shared-content/home-footer';
import ShowNotifications from 'views/Public/shared-content/show-notifications';

import DirectAdmitMap from 'assets/img/constellation-map-da.svg';

import 'assets/css/form-controls.css';
import 'assets/css/normalize.css';
import 'assets/css/webflow.css';
import 'assets/css/uw-help.webflow.css';
import 'assets/css/uw-help-custom.css';

const newPic1 = require('assets/homepageImages/UWHelp-09.jpg'),
	newPic2 = require('assets/homepageImages/UWHelp-10.jpg'),
	newPic3 = require('assets/homepageImages/UWHelp-11.jpg'),
	newPic4 = require('assets/homepageImages/UWHelp-12.jpg');

class Home extends Component {
	constructor(props) {
		super(props);

		this.state = {
			contact_us_modal: false
		};
	}

	componentDidMount() {
		let { location, logout } = this.props,
			{ logging_out } = location.state || {};

		if (logging_out) logout();

		scrollTo();
	}

	render() {
		let { mainContent, directAdmit, faqs } = this.props,
			{ contact_us_modal } = this.state;

		return (
			<div className='body' role='main'>
				<Modal show={contact_us_modal}>
					<ContactUsModal closeModal={() => this.setState({ contact_us_modal: false })} />
				</Modal>

				<Header />

				<div className='content'>
					<div>
						<img
							className='image-2'
							src={directAdmit ? DirectAdmitMap : _svg.constellationMap}
							alt='Map of the Universities of Wisconsin'
						/>
					</div>
					<div>{mainContent}</div>
				</div>

				<ShowNotifications />

				<div className='faqs'>
					<div className='image-bar'>
						<img
							src={newPic1}
							srcSet={`${newPic1} 500w, ${newPic1} 600w`}
							sizes='(max-width: 479px) 100vw, (max-width: 991px) 50vw, (max-width: 1439px) 25vw, 360px'
							alt=''
							className='hp-photo a'
						/>

						<img
							src={newPic2}
							srcSet={`${newPic2} 500w, ${newPic2} 600w`}
							sizes='(max-width: 479px) 100vw, (max-width: 991px) 50vw, (max-width: 1439px) 25vw, 360px'
							alt=''
							className='hp-photo b'
						/>

						<img
							src={newPic3}
							srcSet={`${newPic3} 500w, ${newPic3} 600w`}
							sizes='(max-width: 991px) 100vw, (max-width: 1439px) 25vw, 360px'
							alt=''
							className='hp-photo c'
						/>

						<img
							src={newPic4}
							srcSet={`${newPic4} 500w, ${newPic4} 600w`}
							sizes='(max-width: 991px) 100vw, (max-width: 1439px) 25vw, 360px'
							alt=''
							className='hp-photo d'
						/>
					</div>

					<div className='faq-container'>
						<div className='faqs-heading'>
							<h1 className='heading'>Frequently asked questions</h1>
							<div className='text-block'>
								Your question not on the list?{' '}
								<a
									className='link'
									rel='noopener noreferrer'
									onClick={() => this.setState({ contact_us_modal: true })}>
									Contact us.
								</a>
							</div>
						</div>
						<div className='faq-wrap'>
							<Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
								{faqs.map((faq, i) => (
									<AccordionItem key={i}>
										<AccordionItemHeading>
											<AccordionItemButton>
												{faq.header}
												<img className='accordionPlus' src={_svg.HomepagePlus} alt='UW Logo' />
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>{faq.item}</AccordionItemPanel>
									</AccordionItem>
								))}
							</Accordion>
						</div>
					</div>
				</div>

				<Footer />
			</div>
		);
	}
}

const mapStateToProps = state => ({
		email: state.user.email
	}),
	mapDispatchToProps = dispatch => ({
		logout: () => dispatch(UserActions.logout())
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
