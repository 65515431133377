import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import { SUMMARIZE_INIT_INFO } from 'components/modules/app';
import { getIncompleteRoutes, nextAppPage, prevAppPage } from 'components/modules/nav';
import { app_summary } from 'components/modules/summary';
import _svg from 'components/modules/svg';
import { is_app_open } from 'components/modules/term';
import { GlobalActions } from 'reducers/global';
import { UserActions } from 'reducers/user';
import API from 'components/api';

class Submit extends Component {
	constructor(props) {
		super(props);

		const { app, first_name, last_name, phone_number, waivers, documents } = props;

		this.state = {
			open_sections: {
				'Personal Information': true,
				'Contact Information': false,
				'Parent Information': false,
				Residency: false,
				'Academic Background': false,
				'Holistic Background': false
			}
		};

		this.incomplete_routes = getIncompleteRoutes(app).map(r => (
			<tr key={`tr_${r.name}`}>
				<td data-label='Application'>
					{r.name}
					<Button onClick={() => this.sendToPath(r.path)} className='btn-xs buttonReviewIncomplete'>
						Incomplete
					</Button>
				</td>
			</tr>
		));

		if (!this.incomplete_routes.length) {
			this.summary = app_summary(app, first_name, last_name, phone_number, waivers, documents);
		}
	}

	componentDidMount() {
		this.props.setSubmenu();
		this.checkAppFee();
	}

	getAPI = () => {
		const { support_student_id, campus_admin } = this.props;
		return new API('', support_student_id, campus_admin);
	};

	checkAppFee = () => {
		const { setAppFees, captureError } = this.props;

		this.getAPI()
			.getAllApplications()
			.then(result => {
				let obj = {};

				(result.rows || []).forEach(rec => {
					obj[rec.application_id] = rec.application_fee;
				});

				setAppFees(obj);
			})
			.catch(ex => captureError(ex));
	};

	saveAndContinue = e => {
		e.preventDefault();
		e.stopPropagation();

		const { navigate, location, app, setScreensCompleted } = this.props;

		setScreensCompleted();
		navigate(nextAppPage(app, location.pathname));
	};

	onBack = e => {
		const { navigate, app, location } = this.props;
		navigate(prevAppPage(app, location.pathname));
	};

	sendToPath = (path, e) => {
		this.props.navigate(path);
	};

	renderAppSummary = () => {
		let { app, terms, majors, campuses } = this.props,
			{ open_sections } = this.state,
			renderRow = item => {
				const { label, value, header, essay, download } = item;

				if ('value' in item) {
					if (download) {
						return (
							<>
								<td>{label}</td>
								<td>
									<a
										onClick={() => {
											this.getAPI()
												.downloadDocument(download.key, download.file_name)
												.catch(ex => {
													captureError(ex);

													confirmAlert({
														title: "Hmm, that didn't work",
														message:
															'Your resume could not be downloaded.  Please try again and let us know if the problem continues.',
														buttons: [{ label: 'OK' }]
													});
												});
										}}>
										<img
											alt='downloadIcon'
											className='imgDashboard'
											height='18px'
											width='18px'
											src={_svg.DownloadLink}
										/>
										{value}
									</a>
								</td>
							</>
						);
					}

					if (essay) {
						return <td colSpan='2' className='essay' dangerouslySetInnerHTML={{ __html: value }}></td>;
					}

					return (
						<>
							<td>{label}</td>
							<td>{value}</td>
						</>
					);
				}

				return (
					<td colSpan='2' className={header ? `header-${header}` : ''}>
						{label}
					</td>
				);
			};

		return (
			<>
				{app.application_modifier === 'DA'
					? SUMMARIZE_INIT_INFO.direct_admit(app, majors, campuses, true)
					: SUMMARIZE_INIT_INFO.standard(app, terms, majors, true)}

				<table className='app-summary'>
					<tbody>
						{Object.keys(this.summary).map((strSection, i) => (
							<React.Fragment key={i}>
								<tr>
									<td
										colSpan='2'
										className='app-section'
										onClick={() => {
											this.setState({
												open_sections: {
													...open_sections,
													[strSection]: !open_sections[strSection]
												}
											});
										}}>
										{strSection}
										<span>{open_sections[strSection] ? '\u25BC' : '\u25B2'}</span>
									</td>
								</tr>

								{open_sections[strSection] &&
									this.summary[strSection].map((item, j) => <tr key={j}>{renderRow(item)}</tr>)}
							</React.Fragment>
						))}
					</tbody>
				</table>
			</>
		);
	};

	render() {
		let { app, terms, navigate } = this.props,
			record_type = app.application_modifier === 'DA' ? 'Direct Admit form' : 'application',
			title = `review ${record_type}`,
			continueButton = this.incomplete_routes.length ? null : (
				<Button form={'form'} autoFocus bsStyle='info' className='save-btn' fill type={'submit'}>
					Continue
				</Button>
			),
			submit_page = this.incomplete_routes.length ? (
				<>
					<h4 className={'bold'}>
						Please review incomplete information for your application before submitting.
					</h4>
					<Table striped bordered hover>
						<tbody>{this.incomplete_routes}</tbody>
					</Table>
				</>
			) : (
				<>
					{app.application_modifier !== 'DA' && (
						<p>
							You&apos;re almost there! You have successfully completed your written application. All that
							is left is for your digital signature and payment.
						</p>
					)}

					{this.renderAppSummary()}

					<p>
						<em>
							Note: To make corrections, please select the section you wish to update from the left-hand
							menu. Once you submit your {record_type}, you cannot make changes to it on this website. You
							will need to send changes to the admissions office.
						</em>
					</p>
				</>
			);

		if (!is_app_open(app, terms)) {
			title = 'CLOSED APPLICATION';

			submit_page = (
				<div>
					<h2 className={'uwRedText'}>The deadline for the application you are working on has passed.</h2>
				</div>
			);

			continueButton = (
				<Button bsStyle='info' className='tertiaryButton' fill onClick={() => navigate('/my-account')}>
					My Account
				</Button>
			);
		}

		return (
			<Card
				title={title}
				content={
					<form id='form' onSubmit={this.saveAndContinue}>
						{submit_page}
						<div className='clearfix' />
					</form>
				}
				buttons={
					<>
						<Button bsStyle='info' className='back-btn' fill onClick={this.onBack}>
							Back
						</Button>
						{continueButton}
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => {
		const { apps, app_id, support_student_id, first_name, last_name, phone_number, documents } = state.user,
			{ cdrMajors, terms, waivers, campuses } = state.global;

		return {
			support_student_id: support_student_id,
			majors: cdrMajors,
			terms: terms,
			apps: apps,
			app: apps[app_id].json_obj,
			first_name: first_name,
			last_name: last_name,
			phone_number: phone_number,
			waivers: waivers.map(rec => ({ val: rec.id, label: rec.title })),
			documents: documents,
			campuses: campuses
		};
	},
	mapDispatchToProps = dispatch => ({
		setSubmenu: () => dispatch(UserActions.setSubmenus('submit_open', true)),
		setScreensCompleted: () => dispatch(UserActions.setScreensCompleted({ submit: true })),
		setAppFees: obj => dispatch(UserActions.setAppFees(obj)),
		captureError: err => dispatch(GlobalActions.captureError(err))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Submit));
