import React, { Component } from 'react';
import { connect } from 'react-redux';

//Card used to display cards within app selector. Example: campus cards

class InitialInfoCard extends Component {
	render() {
		const { onChange, stateVariable, initial_information, cards } = this.props;

		let childrenElements = [];

		cards.forEach(card => {
			let onChangePayload = { stateVariable: stateVariable, value: card.value },
				selected = initial_information[stateVariable] === card.value;

			this.handleKeyPress = event => {
				if (event.key === 'Enter') onChange(onChangePayload);
			};

			childrenElements.push(
				<div
					tabIndex='0'
					onKeyPress={this.handleKeyPress}
					onClick={() => onChange(onChangePayload)}
					className={'initialInfoCard ' + (selected && ' test')}
					key={card.value}>
					<div className={'initialInfoCardImg'}>
						<img src={card.image} className={card.extraClass} alt={card.display + ' image'} />
					</div>

					<div className={'initialInfoCardText'}>
						<p>{card.display}</p>
					</div>
				</div>
			);
		});

		return <div className={'initialInfoContainer'}>{childrenElements}</div>;
	}
}

const mapStateToProps = state => ({
	initial_information: state.user.temp_app_rec.json_obj.initial_information
});

export default connect(mapStateToProps)(InitialInfoCard);
