import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'raf/polyfill';
import 'core-js';
import 'core-js/es/set';
import 'core-js/es/map';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

import routes from 'components/routes';
import AppWrapper from 'components/Wrappers/AppWrapper';
import CacheBuster from 'components/Wrappers/CacheBuster';
import { SENTRY } from 'components/modules/_misc';
import { get_store, save_store } from 'components/modules/redux';
import combineReducers from 'reducers/_index';
import AppLayout from 'components/Wrappers/AppLayout';
import ErrorBoundary from 'components/Wrappers/ErrorBoundary';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/animate.min.css';
import './assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0';
import './assets/css/demo.css';
import './assets/css/pe-icon-7-stroke.css';

let { REACT_APP_ENV } = process.env,
	init_redux_state = get_store(),
	store = createStore(combineReducers, init_redux_state),
	root = ReactDOM.createRoot(document.getElementById('root'));

TagManager.initialize({ gtmId: 'GTM-T8F94CZ' });

if (SENTRY.use) {
	Sentry.init({
		dsn: 'https://00ababe309414eb7bc9a56bc004ba945@o446501.ingest.sentry.io/5952803',
		environment: REACT_APP_ENV,
		tracesSampleRate: REACT_APP_ENV === 'production' ? 0.25 : 0.5,
		denyUrls: [/static\.ada\.support/i],
		beforeSend(e, hint) {
			const { values: exception_values } = e.exception?.values || [],
				{ message: err_message } = hint.originalException || {},
				isAdaError = exception_values.length && exception_values.type?.match(/AdaEmbedError/i),
				limit_exceeded =
					err_message?.match(/header field name/i) ||
					err_message?.match(/ResizeObserver loop limit exceeded/i);

			return isAdaError || limit_exceeded ? null : e;
		}
	});
}

root.render(
	<Provider store={store}>
		<CacheBuster>
			<BrowserRouter>
				<ErrorBoundary>
					<AppWrapper>
						<Routes>
							<Route path='/' element={<AppLayout />}>
								{routes.map((r, i) => {
									return r.path === '/' ? (
										<Route key={i} exact element={r.element} index />
									) : (
										<Route key={i} exact element={r.element} path={r.path} />
									);
								})}
							</Route>
						</Routes>
					</AppWrapper>
				</ErrorBoundary>
			</BrowserRouter>
		</CacheBuster>
	</Provider>
);

window.onbeforeunload = () => save_store(store.getState());
