import React, { Component } from 'react';

import _svg from 'components/modules/svg';

export class NoSidebarCard extends Component {
	render() {
		const { fullWidth, no_spacing } = this.props;

		return (
			<div className={`card noSidebarCard${no_spacing ? ' no-spacing' : ''}`}>
				<div className='infoSidebarHeader'>
					<img className='sidebarLogo' src={_svg.UWS_white} alt='UWs Logo' />
				</div>

				{this.props.header !== '' && (
					<div className='noSidebarHeaderContainer'>
						<h1 className='uwBlueText'>{this.props.header}</h1>
					</div>
				)}

				<div className={`content noSidebarContent${fullWidth ? ' fullWidth' : ''}`}>{this.props.content}</div>

				<div className='card-footer extra-margin'>{this.props.buttons}</div>
			</div>
		);
	}
}

export default NoSidebarCard;
