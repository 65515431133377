import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import _svg from 'components/modules/svg';

class SidebarListItem extends Component {
	render() {
		let { hideSpacer, type, routePath, name, disabledRoute, extraClass, img, completed } = this.props,
			specialLogicRoutes = [
				'/',
				'/my-account',
				'/create-account',
				'/sign-in',
				'/save-and-return-later',
				'/contact-us',
				'/how-to-apply',
				'/find-your-term',
				'/find-a-program',
				'/sign-out'
			];

		if (type === 'standalone') {
			let icon;

			if (specialLogicRoutes.includes(routePath)) {
				let imgName = '';

				hideSpacer = true;

				switch (routePath) {
					case '/':
						imgName = 'Homepage';
						break;
					case '/my-account':
						imgName = 'MyAccount';
						break;
					case '/save-and-return-later':
						imgName = 'SaveAndReturn';
						break;
					case '/contact-us':
						imgName = 'ContactUs';
						break;
					case '/how-to-apply':
						imgName = 'HowToApply';
						break;
					case '/find-your-term':
						imgName = 'FindYourTerm';
						break;
					case '/find-a-program':
						imgName = 'FindAProgram';
						break;
					case '/sign-out':
						imgName = 'SignOut';
						routePath = '';
						break;
					case '/sign-in':
						imgName = 'SignIn';
						break;
					case '/create-account':
						imgName = 'CreateAccount';
						break;
					default:
						imgName = 'MyAccount';
						break;
				}

				icon = (
					<>
						<img
							alt='Help nav link'
							className='sidebarIcon'
							height='15px'
							width='15px'
							src={_svg[imgName]}
						/>
						<p className='sidebarRouteName'>{name}</p>
					</>
				);
			} else if (completed) {
				icon = (
					<>
						<img alt='checkmarkDone' className='sidebarIcon' src={_svg.CircleCheck} />{' '}
						<p className='sidebarRouteName'>{name}</p>
					</>
				);
			} else {
				icon = (
					<>
						<img alt='checkmarkNotDone' className='sidebarIcon' src={_svg.CircleX} />{' '}
						<p className='sidebarRouteName'>{name}</p>
					</>
				);
			}

			if (disabledRoute) {
				return (
					<li className={extraClass} key={'sakey' + name}>
						<NavLink to={'/submit'} className='nav-link'>
							<div>
								<img alt='checkmarkDisabled' className='sidebarIcon' src={_svg.RedCircleX} />{' '}
								<p className='sidebarRouteName'>{name}</p>
							</div>
						</NavLink>
					</li>
				);
			}

			let tgt_page = '/';
			if (routePath && routePath !== '/save-and-return-later') tgt_page = routePath;

			return (
				<li className={extraClass + ' '} key={'sakey' + name}>
					<NavLink to={tgt_page} state={routePath ? null : { logging_out: true }} className='nav-link'>
						{icon}
					</NavLink>

					{!hideSpacer && <div className='sidebarSpacer' />}
				</li>
			);
		} else {
			return (
				<li className={extraClass} key={'key' + name}>
					<NavLink to={routePath} target={routePath.includes('http') ? '_blank' : ''} className='nav-link'>
						<img alt='Alt Sidebar Logo' className='sidebarIcon' height='15px' width='15px' src={img} />
						<p className='sidebarRouteName'>{name}</p>
					</NavLink>
				</li>
			);
		}
	}
}

export default SidebarListItem;
